import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Divider } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { fontSizes } from '@theme/theme'
import React from 'react'

type Props = React.PropsWithChildren & {
  headerExtension?: React.ReactNode,
  title?: string,
  defaultExpanded?: boolean
}

export const DashboardAccordion: React.FC<Props> = ({ headerExtension, title, defaultExpanded, children }) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        border: '1px solid',
        borderRadius: '8px',
        borderColor: 'grey.300',
        position: 'relative',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        p: 3,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <AccordionSummary
        expandIcon={<themeIcons.ArrowDown />}
      >

        <Box sx={{
          width: '100%'
        }}
        >
          <Typography
            variant="h6"
            fontSize={fontSizes.lg}
            sx={{
              mb: 1
            }}
          >
            {title}
          </Typography>
          {headerExtension}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{
          my: 2
        }}
          light
        />
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
