import React from 'react'
import { VictoryAnimation, VictoryPie } from 'victory'
import { palette } from '@theme/core/palette/palette'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { fontSizes } from '@theme/theme'

import { DashboardCard, DashboardCardProps } from '../ui/DashboardCard'
import { DashboardLegendItem } from '../ui/DashboardLegendItem'

type Props = {
  primary: number,
  secondary: number,
  cardProps?: DashboardCardProps,
  title?: string,
  hideTitle?: boolean,
  hidePercent?: boolean,
  titlePrimary?: string,
  titleSecondary?: string,
  colorSecondary?: boolean
}

type Data = {
  percent: number,
  data: {
    x: number,
    y: number
  }[]
}

export const DashboardConversionRate: React.FC<Props> = ({
  primary,
  secondary,
  colorSecondary,
  cardProps,
  hideTitle,
  hidePercent,
  title,
  titlePrimary,
  titleSecondary
}) => {
  const { t } = useTranslation()

  const diagramData = React.useMemo<Data>(() => {
    const calculatedPercent = secondary > 0 ? (secondary / primary) * 100 : 0

    return {
      percent: calculatedPercent,
      data: [
        {
          x: 1,
          y: calculatedPercent
        },
        { x: 2, y: 100 - calculatedPercent }
      ]
    }
  }, [primary, secondary])

  const percentage = React.useMemo(() => {
    return Math.round(diagramData.percent)
  }, [diagramData])

  return (
    <DashboardCard {...cardProps}>
      <Box sx={{ position: 'relative' }}>
        <svg viewBox="0 0 400 400" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400}
            height={400}
            innerRadius={160}
            labels={() => null}
            padding={0}
            style={{
              data: {
                fill: (colorSecondary ? palette.secondary?.[300] : palette.primary[100]) || 'transparent'
              }
            }}
          />
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400}
            height={400}
            data={diagramData.data}
            innerRadius={160}
            cornerRadius={diagramData.percent < 100 ? 25 : 0}
            labels={() => null}
            padding={0}
            style={{
              data: {
                fill: ({ datum }) => {
                  return datum.x === 1 ? (colorSecondary ? palette.secondary?.[600] : palette.primary[900]) || 'transparent' : 'transparent'
                }
              }
            }}
          />
        </svg>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <VictoryAnimation
            delay={percentage === 0 ? 0 : 500}
            duration={percentage === 0 ? 0 : 1000}
            data={{
              percent: percentage
            }}
          >
            {(newProps) => {
              return (
                <>
                  {!hideTitle && <Typography mb={1}
                    sx={{
                      width: '80%',
                      textAlign: 'center'
                    }}
                  >{title || t('dashboard.conversionRate')}</Typography>}
                  {!hidePercent &&
                    <Typography sx={{
                      fontSize: fontSizes['2xl'],
                      fontWeight: 500
                    }}
                    >{`${Math.round(newProps.percent as number)}%`}</Typography>
                  }
                </>
              )
            }}
          </VictoryAnimation>
        </Box>
      </Box>
      <Box mt={2}>
        <DashboardLegendItem
          color={colorSecondary ? palette.secondary?.[600] : palette.primary[900]}
          title={titlePrimary || t('dashboard.buttonClicks')}
        />
        <DashboardLegendItem
          color={colorSecondary ? palette.secondary?.[300] : palette.primary[100]}
          title={titleSecondary || t('dashboard.companyClicks')}
        />
      </Box>
    </DashboardCard>
  )
}
