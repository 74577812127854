import { LoadingButton } from '@mui/lab'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateUserInput, useCreateUserMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { Box } from '@mui/material'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { Roles } from '@typings/roles'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { canTrainingCompany } from '@utils/canTrainingCompany'

import { UsersCreateEditInputs } from '../components/UsersCreateEditInputs'
import { UsersProvider, useUsersContext } from '../provider/UsersProvider'

type UserInputType = CreateUserInput & {
  companies?: SearchInputItem[] | null
  trainingCompanies?: SearchInputItem[] | null
}

export const UserCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const navigate = useNavigate()

  const { roles } = useUsersContext()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext<UserInputType>()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const [createUser] = useCreateUserMutation()

  const onSubmit = handleSubmit(async (input) => {
    setErrorMessage('')
    setLoading(true)

    const { email, companies, roleId, trainingCompanies, ...rest } = input

    const role = roles?.find(({ id }) => id === roleId)

    try {
      await createUser({
        variables: {
          data: {
            email: email.toLowerCase().trim(),
            ...rest,
            roleId: roleId as string,
            ...(role?.key === Roles.COMPANY && { companyId: companies?.[0].id ?? null }),
            ...(canTrainingCompany(role?.key as Roles) && { trainingCompanyId: trainingCompanies?.[0]?.id || null })
          }
        }
      })

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (error) {
      setMessageByCode(error, 'users')
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/users')
  }

  return (
    <CreateEditLayout
      title={t('users.createTitle')}
      description={t('users.createDescription')}
      headerExtension={
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box ml={2}>
            <StandaloneSwitchInput
              formKey="inviteUser"
              label={t('users.inviteUser')}
            />
          </Box>
        </Box>
      }
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <UsersCreateEditInputs />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
        onCancel={handleConfirm}
      >
        {t('users.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const UserCreateView:React.FC = () => {
  const formData = useForm<UserInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <UsersProvider disableFetching>
      <FormProvider {...formData}>
        <UserCreateViewContent />
      </FormProvider>
    </UsersProvider>
  )
}
