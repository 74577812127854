import { GridLocaleText, deDE, enUS } from '@mui/x-data-grid-pro'
import i18n from '@services/i18n/i18n'
import React from 'react'

export const useDataGridTranslation = (): Partial<GridLocaleText> => {
  const dataGridTranslations = React.useMemo<Partial<GridLocaleText>>(() => {
    return i18n.language.includes('DE')
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText
  }, [i18n.language])

  return dataGridTranslations
}
