import React from 'react'
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import { useCheckInvitationTokenQuery } from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'

import { PasswordSetForm } from '../components/PasswordSetForm'

export const InvitationAcceptView: React.FC = () => {
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>()

  const { breakpoints } = useTheme()

  const navigate = useNavigate()

  const { data, loading } = useCheckInvitationTokenQuery({
    variables: {
      token: token as string
    }
  })

  const invitationInvalid = React.useMemo(() => {
    if (!data) {
      return false
    }

    return data.checkInvitationToken
  }, [data])

  return <AuthLayout>
    <Box sx={{
      [breakpoints.down('md')]: {
        px: 2
      },
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
    >
      {loading
        ? <CircularProgress sx={{ mt: 2 }} />
        : (
            invitationInvalid
              ? (
                <>
                  <Typography
                    variant='h4'
                    sx={{
                      mt: 2,
                      textAlign: 'center'
                    }}
                  >{t('auth.invalidInvitation')}</Typography>

                  <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/login')}>
                    <Typography>{t('auth.backToLogin')}</Typography>
                  </Button>
                </>
                )
              : (
                <>
                  <Box>
                    <Typography
                      variant='h4'
                      sx={{
                        mb: 3,
                        textAlign: 'center'
                      }}
                    >{t('auth.inviteAcceptDescription')}</Typography>
                  </Box>

                  <Box sx={{
                    width: '100%'
                  }}
                  >
                    <PasswordSetForm />
                  </Box>
                </>
                )
          )}
    </Box>
  </AuthLayout>
}
