import { ThemeOptions, TypographyVariants } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import {
  createTheme
} from '@mui/material'

import { components } from './components'
import { typography } from './core/typography/typography'
import { palette } from './core/palette/palette'

export { customThemeIcons, themeIcons } from './icons'
export { fontSizes, lineHeights, themeTypoVariants, typography } from './core/typography/typography'
export type { ThemeFontSizes, ThemeTypoVariantsType } from './core/typography/typography'

export type CustomThemeOptions = ThemeOptions & {
  typography: TypographyOptions & TypographyVariants
};

export const themeProperties: CustomThemeOptions = {
  typography, palette, components
}

export const theme = createTheme({
  ...themeProperties,
  components
})
