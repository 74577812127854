import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'
import { useParams } from 'react-router'

import { QuestionGroupRowModel, useQuestionGroupsContext } from '../provider/QuestionGroupsProvider'

import { QuestionGroupsColumnType, questionGroupsDataGridColumns } from './questionGroupsDataGridColumns'

export const QuestionGroupsDataGrid: React.FC = () => {
  const { subCategoryId } = useParams<{ subCategoryId: string }>()
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds, handleDelete,
    updateSelectedItem, loading, questionGroups
  } = useQuestionGroupsContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: QuestionGroupRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<QuestionGroupsColumnType[]>(() => {
    return [...questionGroupsDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can(['questions:write', 'questions:write.partial']) && { onEdit: () => navigate(`${params.row.id}`) })}
          {...(can('questions:delete') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as QuestionGroupsColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`questionGroups.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey={`questionGroups-${subCategoryId}`}
      rows={questionGroups}
      loading={loading}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
