import { Box } from '@mui/material'
import { EditableFile, ResizeMode } from '@typings/files'
import React from 'react'

type Props = {
  file: EditableFile<any>
  resizeMode?: ResizeMode
}

export const FilePreview: React.FC<Props> = ({ file, resizeMode }) => {
  const isVideo = React.useMemo(() => {
    return file?.mimeType?.includes('video') || file?.upload?.file?.type?.includes('video')
  }, [file])

  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      ...(!isVideo && {
        backgroundImage: `url(${file?.upload?.preview || file?.url})`,
        backgroundSize: resizeMode || 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      })
    }}
    >
      {isVideo && (
        <video
          src={file?.upload?.preview || file?.url}
          style={{
            width: '100%',
            height: '100%',
            objectFit: resizeMode || 'cover',
            pointerEvents: 'none'
          }}
        />
      )}
    </Box>
  )
}
