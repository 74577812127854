import { useLearningProgress } from '@hooks/useLearningProgress'
import {
  TraineeQuery,
  useTraineeQuery
} from '@typings/graphql'
import { LearningProgressDiagrams } from '@typings/learningProgress'
import React from 'react'
import { useParams } from 'react-router'

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type TraineeType = Exclude<TraineeQuery['user'], null | undefined>
export type TraineesRowModel = Omit<TraineeType, '__typename'>

export type TraineesDetailProviderContextType = {
  traineeData?: TraineesRowModel
  loading: boolean
  learningProgress: LearningProgressDiagrams
}

const TraineesDetailProviderContext = React.createContext<TraineesDetailProviderContextType>(
  {} as any
)

export const TraineesDetailProvider:React.FC<Props> = ({ children }) => {
  const { id } = useParams<{id: string}>()

  const { data, loading } = useTraineeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string,
      withTopics: true
    },
    skip: !id
  })

  const { learningProgress } = useLearningProgress(data?.user?.learningProgress)

  const value = React.useMemo<TraineesDetailProviderContextType>(() => ({
    traineeData: data?.user,
    loading,
    learningProgress
  }), [data, loading, learningProgress])

  return (
    <TraineesDetailProviderContext.Provider value={value}>
      {children}
    </TraineesDetailProviderContext.Provider>
  )
}

export const useTraineesDetailContext = () => React.useContext(TraineesDetailProviderContext)
