import { Box, Divider, Typography } from '@mui/material'
import { useAppSelector } from '@services/store/store'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditorDashboardContent } from '../components/EditorDashboardContent'

export const EditorDashboardView: React.FC = () => {
  const { t } = useTranslation()

  const { user } = useAppSelector(({ auth }) => auth)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'auto'
    }}
    >
      <Box sx={{
        flex: 1,
        p: 4.5
      }}
      >
        <Box sx={{
          height: '100%'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 3.5
          }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h1"
                mb={1}
                fontWeight={600}
              >{t('dashboard.userWelcome', {
                fullName: t('common.fullName', {
                  firstName: user?.firstname,
                  lastName: user?.lastname
                })
              })}</Typography>
              <Typography>
                {t('dashboard.dashboardDescription')}
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <EditorDashboardContent />
        </Box>
      </Box>
    </Box>
  )
}
