import { MenuItem, Select, Tooltip } from '@mui/material'
import { Status } from '@typings/graphql'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { StatusColorLabel } from '../ui/StatusColorLabel'

type Props = {
  formKey: string
  activeDisabled?: boolean
  activeDisabledTooltip?: string
  options?: RegisterOptions
}

export const StatusSelectInput: React.FC<Props> = ({ formKey, activeDisabled, activeDisabledTooltip, options }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { onChange, value } }) => (
        <Select
          value={value}
          onChange={onChange}
          sx={{
            '&.MuiSelect-overwrite': {
              '&.MuiSelect-overwrite': {
                padding: '10px 12px'
              }
            }
          }}
          renderValue={(renderValue) => (
            <StatusColorLabel status={renderValue as Status} />
          )}
        >
          {Object.keys(Status).map((key) => (
            Status.Active === key && activeDisabled
              ? (
                <Tooltip key={key} title={activeDisabledTooltip || ''} placement='top'>
                  <MenuItem value={key} disabled={activeDisabled}>
                    <StatusColorLabel status={key as Status} />
                  </MenuItem>
                </Tooltip>
                )
              : (
                <MenuItem key={key} value={key}>
                  <StatusColorLabel status={key as Status} />
                </MenuItem>
                )
          ))}
        </Select>
      )}
    />
  )
}
