import i18n from 'i18next'

export const timestampToDate = (timestamp: Date, format?: string[]):string => {
  const defaultFormat: Record<string, any> = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  }
  Object.keys(defaultFormat).forEach((key: string) => format?.indexOf(key) === -1 && delete defaultFormat[key])

  const dtFormat = new Intl.DateTimeFormat(i18n.language, defaultFormat)

  return `${dtFormat.format(timestamp.getTime()).replace(',', '')}`
}

export const timestampToWeekday = (timestamp: Date):string => {
  const dtFormat = new Intl.DateTimeFormat(i18n.language, { weekday: 'short' })

  return `${dtFormat.format(timestamp.getTime())}`
}
