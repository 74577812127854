import { palette } from '@theme/core/palette/palette'
import { LearningProgress, LearningProgressData, LearningProgressDiagram, LearningProgressDiagrams } from '@typings/learningProgress'
import React from 'react'

type UseLearningProgress = {
  learningProgress: LearningProgressDiagrams
}

export const useLearningProgress = (progress?: LearningProgress): UseLearningProgress => {
  const mapProgress = React.useCallback((learningProgress: LearningProgressData): LearningProgressDiagram => {
    const correct = (learningProgress?.correctAnswers || 0)
    const partiallyCorrect = (learningProgress?.partiallyCorrectAnswers || 0)
    const incorrect = (learningProgress?.incorrectAnswers || 0)
    const questions = (learningProgress?.questions || 1)

    return {
      correctAnswers: {
        value: learningProgress ? correct / questions * 100 : 0,
        color: palette.success?.[500] ?? ''
      },
      partiallyCorrectAnswers: {
        value: learningProgress ? partiallyCorrect / questions * 100 : 0,
        color: palette.warning?.[400] ?? ''
      },
      incorrectAnswers: {
        value: learningProgress ? incorrect / questions * 100 : 0,
        color: palette.error?.[500] ?? ''
      },
      questions: {
        value: learningProgress
          ? 100 - (correct + incorrect + partiallyCorrect) / questions * 100
          : 100,
        color: palette.grey?.[400] ?? ''
      }
    }
  }, [])

  const mappedProgress = React.useMemo<LearningProgressDiagrams>(() => {
    const combinedProgress = {
      correctAnswers: 0,
      incorrectAnswers: 0,
      partiallyCorrectAnswers: 0,
      questions: 0
    }

    const categories: Record<string, LearningProgressDiagram> = {}
    const topics: Record<string, LearningProgressDiagram> = {}

    if (Object.keys(progress ?? {}).length === 0) {
      return {
        overall: mapProgress(combinedProgress),
        categories,
        topics
      }
    }

    Object.entries(progress?.categories || {})?.forEach(([key, subjectProgress]) => {
      combinedProgress.correctAnswers += subjectProgress.correctAnswers
      combinedProgress.incorrectAnswers += subjectProgress.incorrectAnswers
      combinedProgress.partiallyCorrectAnswers += subjectProgress.partiallyCorrectAnswers
      combinedProgress.questions += subjectProgress.questions

      categories[key] = mapProgress(subjectProgress)
    })

    Object.entries(progress?.topics || {})?.forEach(([key, topicProgress]) => {
      topics[key] = mapProgress(topicProgress)
    })

    const overall = mapProgress(combinedProgress)

    return {
      overall,
      categories,
      topics
    }
  }, [progress])

  return {
    learningProgress: mappedProgress
  }
}
