import { GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { useLocation } from 'react-router'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { QuestionListRowModel } from '@features/questionList/provider/QuestionListProvider'
import { useNewTabHook } from '@hooks/useNewTabHook'
import { useParams } from 'react-router-dom'

import { QuestionRowModel, useQuestionsContext } from '../provider/QuestionsProvider'

import { QuestionsColumnType, questionsDataGridColumns } from './questionsDataGridColumns'
import { categoryColumnModel } from './columns/categoryColumn'
import { topicColumnModel } from './columns/topicColumn'
import { topicIndexColumnModel } from './columns/topicIndexColumn'

const MIN_FILTER_TEXT_LENGTH = 3

const TEXT_FILTER_FIELDS: (keyof QuestionRowModel)[] = ['title']

export const QuestionsDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const { topicId, subCategoryId } = useParams<{ topicId: string, subCategoryId: string }>()

  const navigate = useNewTabHook()

  const isTopics = React.useMemo(() => location.pathname.includes('topics'), [location])

  const {
    updateSelectedIds,
    handleDelete,
    updateSelectedItem,
    selectedIds,
    loading,
    questions,
    updateShowDuplicateDialog,
    paginationModel,
    updatePaginationModel,
    sortModel,
    updateSortModel,
    filterModel,
    updateFilterModel,
    updateInitialState
  } = useQuestionsContext()

  const rowData = React.useMemo(() => {
    return questions?.edges?.map(({ node }) => node) || []
  }, [questions])

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: QuestionRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const handleDuplicateRow = React.useCallback(async (row: QuestionRowModel) => {
    updateSelectedItem(row)
    updateShowDuplicateDialog(true)
  }, [])

  const rowCountState = React.useMemo(() => {
    return questions?.totalCount
      ? questions.totalCount
      : 0
  }, [questions])

  const columnData = React.useMemo<QuestionsColumnType[]>(() => {
    return [
      ...questionsDataGridColumns,
      ...(isTopics ? [categoryColumnModel] : [topicIndexColumnModel, topicColumnModel]),
       {
         ...actionColumnModel,
         renderCell: (params) => (
           <ActionColumnRenderCell
             {...(can(['questions:write', 'questions:write.partial']) && {
               onEdit: () =>
                 navigate(`/professions/${params.row.profession.id}/${params.row.category.parentId}/${params.row.category.id}/${params.row.id}`)
             })}
             {...(can(['questions:write', 'questions:write.partial']) && { onCopy: () => handleDuplicateRow(params.row) })}
             {...(can('questions:delete') && { onDelete: () => handleDeleteRow(params.row) })}
           />
         )
       } as QuestionsColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`questions.column.${column.field}`),
        ...column
      }
    })
  }, [])

  const onUpdateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    const lengthFilter = filter?.items?.filter((item) => TEXT_FILTER_FIELDS.includes(item.field as keyof QuestionListRowModel))

    if (lengthFilter?.some((item) => item.value?.length < MIN_FILTER_TEXT_LENGTH && item.value?.length > 0)) {
      return
    }

    updateFilterModel(filter)
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={rowData}
      rowCount={rowCountState}
      gridKey={`${topicId || subCategoryId}-questions`}
      autoPageSize={false}
      loading={loading}
      paginationModel={paginationModel}
      paginationMode='server'
      onInitialState={updateInitialState}
      onPaginationModelChange={updatePaginationModel}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode='server'
      onSortModelChange={updateSortModel}
      filterModel={filterModel}
      filterMode='server'
      onFilterModelChange={onUpdateFilterModel}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
