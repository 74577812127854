import { Box, Grid } from '@mui/material'
import { DateTimePicker, LocalizationProvider, deDE } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SelectInput } from '@shared/components/inputs/SelectInput'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { BaseTextOutline } from '@shared/components/ui/BaseTextOutline'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import { CreateProfessionInput, Season, UpdateProfessionInput } from '@typings/graphql'
import React from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormData = Omit<CreateProfessionInput | UpdateProfessionInput, 'examDates'> & {
  examDates: {
    id: string
    examDate: Date | null
    season: Season | null
    year: number | null
  }[]
}

export const ProfessionExamDateInput = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormData>()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'examDates'
  })

  const watchedExamDates = useWatch({
    control,
    name: 'examDates'
  })

  const handleOnAddExam = () => {
    append({
      id: Math.random().toString(36).substring(7),
      year: null,
      season: null,
      examDate: null
    })
  }

  const handleRemoveExam = (index: number) => {
    remove(index)
  }

  const handleDateChange = (date: Date | null, index: number) => {
    const year = date?.getFullYear() || null

    if (!year) {
      return
    }

    update(index, {
      ...watchedExamDates[index],
      year,
      examDate: date
    })
  }

  return (
    <>
      <BaseInputRow
        title={t('professions.exam')}
        required
        description={t('professions.examDescription')}
      >
        {
          fields.map((item, index) => (
            <Box key={`exam_${item.id}`}
              mb={2}
            >
              <DefaultAnswerRow
                onRemove={() => handleRemoveExam(index)}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`examDates.${index}.examDate`}
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) => !!value
                        }
                      }}
                      render={({ field: { value } }) =>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                          <DateTimePicker
                            sx={{
                              width: '100%',
                              height: '100%',
                              '.MuiInputBase-root': {
                                '&.MuiOutlinedInput-root': {
                                  height: '100%',
                                  backgroundColor: 'white',
                                  ...(!value && {
                                    '&.MuiOutlinedInput-root': {
                                      '&.Mui-error': {
                                        borderColor: 'grey.400',
                                        color: 'grey.400'
                                      }
                                    }
                                  }
                                  )
                                }
                              }
                            }}
                            onAccept={(v) => {
                              handleDateChange(typeof v === 'string' ? new Date(v) : v, index)
                            }}
                            value={value || ''}
                            format="dd.MM.yyyy HH:mm"
                            timezone='Europe/Berlin'
                            closeOnSelect={false}
                            ampm={false}
                            slotProps={{
                              actionBar: {
                                actions: ['clear', 'accept', 'cancel']
                              }
                            }}
                          />
                        </LocalizationProvider>
                    }
                    />
                  </Grid>
                  <Grid item
                    xs={12}
                    md={3}
                    sx={{
                      '.MuiInputBase-root': {
                        '&.MuiOutlinedInput-root': {
                          height: '100%',
                          backgroundColor: 'white'
                        }
                      }
                    }}
                  >
                    <SelectInput
                      options={{ required: true }}
                      items={Object.values(Season).map((season) => ({
                        id: season,
                        name: t(`season.${season.toLowerCase()}`)
                      }))}
                      formKey={`examDates.${index}.season`}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <BaseTextOutline>{item.year}</BaseTextOutline>
                  </Grid>
                </Grid>
              </DefaultAnswerRow>
            </Box>
          ))
        }

        <AddDefaultRow
          label={t('professions.addExam')}
          onAdd={handleOnAddExam}
        />
      </BaseInputRow>
    </>
  )
}
