import { useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { Typography, Box } from '@mui/material'
import i18n from '@services/i18n/i18n'
import { SimpleDataRow } from '@shared/components/ui/SimpleDataRow'
import { themeIcons } from '@theme/icons'
import { convertMinutesToHoursAndMinutes, upperFirstLetter } from '@utils/format'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const TraineesDetailRows: React.FC = () => {
  const { t } = useTranslation()

  const { traineeData } = useTraineesDetailContext()

  const studyTime = React.useMemo(() => {
    const formattedTime = convertMinutesToHoursAndMinutes(traineeData?.overallStudyTime || 0)

    return t(formattedTime.hours > 0 ? 'common.hoursAndMinutes' : 'common.minutes', formattedTime)
  }, [])

  const instructor = React.useMemo(() => {
    return traineeData?.instructor
      ? t('common.fullName', {
        firstName: traineeData?.instructor.firstname,
        lastName: traineeData?.instructor.lastname
      })
      : '-'
  }, [traineeData])

  const daysUntilExam = React.useMemo(() => {
    if (!traineeData?.examDate) {
      return 0
    }

    const today = new Date()
    const examDate = new Date(traineeData.examDate)

    const days = Math.ceil((examDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))

    return days > 0 ? days : 0
  }, [traineeData])

  const fullName = React.useMemo<string>(() => {
    if (!traineeData) {
      return ''
    }

    return `${upperFirstLetter(traineeData.firstname)} ${upperFirstLetter(traineeData.lastname)}`
  }, [traineeData])

  return (
    <>
      <SimpleDataRow title={t('common.name')}>
        {fullName}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.instructor')}>
        {instructor}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.profession')}>
        {traineeData?.profession?.texts.title}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.trainingYear')}>
        {t('common.trainingYearWithNumber', { year: traineeData?.trainingYear })}
      </SimpleDataRow>
      <SimpleDataRow title={t('trainees.learningSince')}>
        {new Date(traineeData?.createdAt).toLocaleDateString(
          i18n.language,
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }
        )}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.learningTime')}>
        {studyTime}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.examDate')}>
        {traineeData?.examDate
          ? new Date(traineeData?.examDate).toLocaleDateString(
            i18n.language,
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          )
          : '-'}
      </SimpleDataRow>
      <SimpleDataRow title={t('common.daysTillExam')}>
        {daysUntilExam}
      </SimpleDataRow>
      <SimpleDataRow title={t('dashboard.trainingCompany.currentValues')}
        noDivider
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 0.5
        }}
        >
          <themeIcons.Trophy />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {t('dashboard.trainingCompany.duelRankingTooltip', { ranking: traineeData?.player?.duelRanking })}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 0.5
        }}
        >
          <themeIcons.BarChart />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {t('dashboard.trainingCompany.level', { level: traineeData?.player?.level })}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 0.5
        }}
        >
          <themeIcons.Star />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {t('dashboard.trainingCompany.points', { points: traineeData?.player?.points })}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 0.5
        }}
        >
          <themeIcons.Coins />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {t('dashboard.trainingCompany.credits', { credits: traineeData?.player?.credits })}
          </Typography>
        </Box>
      </SimpleDataRow>
    </>
  )
}
