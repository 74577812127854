import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme, Checkbox
} from '@mui/material'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import { palette } from '@theme/core/palette/palette'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'
import { themeIcons } from '@theme/icons'

export type MuiDataGridType = {
  defaultProps?: ComponentsProps['MuiDataGrid'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDataGrid'];
  variants?: ComponentsVariants['MuiDataGrid'];
}

export const MuiDataGrid:MuiDataGridType = {
  defaultProps: {
    components: {
      BaseCheckbox: Checkbox,
      ColumnSortedAscendingIcon: themeIcons.ArrowUpward,
      ColumnSortedDescendingIcon: themeIcons.ArrowDownward
    },
    rowHeight: 72
  },
  styleOverrides: {
    root: {
      height: '100%',
      maxHeight: '100%',
      border: '1px solid',
      borderColor: palette.grey?.[300],
      borderRadius: '12px',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);',
      '.MuiDataGrid-withBorderColor': {
        outline: 'none!important'
      },
      '.MuiDataGrid-columnSeparator': {
        '&:hover': {
          color: palette.grey?.[400]
        }
      }
    },
    panelContent: {
      padding: '0 16px'
    },
    panelHeader: {
      padding: '16px!important'
    },
    panelWrapper: {
      '.MuiSwitch-switchBase': {
        '&.Mui-checked': {
          transform: 'translateX(20px)!important'
        },
        '.MuiSwitch-thumb': {
          width: '20px',
          height: '20px'
        }
      }
    },
    panelFooter: {
      '.MuiButtonBase-root': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: palette.primary?.main,
        '&:hover': {
          color: palette.primary[900]
        }
      }
    },
    overlay: {
      fontSize: fontSizes.lg,
      fontWeight: 600,
      backgroundColor: 'white'
    },
    columnHeaderTitle: {
      color: palette.grey?.[700],
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.xs
    },
    columnHeaders: {
      borderBottom: '1px solid',
      borderBottomColor: palette.grey?.[300],
      backgroundColor: palette.grey?.[100],
      minHeight: 'auto!important'
    },
    cellCheckbox: {
    },
    footerContainer: {
      minHeight: '64px',
      '.MuiToolbar-root': {
        justifyContent: 'space-between'
      }
    },
    row: {
      '&:hover': {
        backgroundColor: palette.grey?.[200]
      },
      '&.Mui-selected': {
        backgroundColor: palette.grey?.[100],
        '&:hover': {
          backgroundColor: palette.grey?.[200]
        }
      }
    },
    cell: {
      color: palette.grey?.A100,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.sm,
      fontWeight: 400,
      borderColor: palette.grey?.[300],
      padding: '0 16px',
      '.MuiTypography-root': {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      '&.MuiDataGrid-cellCheckbox': {
        paddingRight: 0
      }
    },
    columnHeader: {
      height: '44px!important',
      padding: '0 16px',
      '&.MuiDataGrid-cell:focus-within, &.MuiDataGrid-cell:focus': {
        outline: 'none !important'
      },
      '&.MuiDataGrid-columnHeaderCheckbox': {
        paddingRight: 0
      }
    },
    columnSeparator: {
      color: 'transparent'
    },
    filterForm: {
      '.MuiSelect-standard': {
        '&.MuiInputBase-input': {
          lineHeight: '23px',
          padding: '4px 16px 5px 0',
          fontWeight: 400,
          fontSize: fontSizes.md
        }
      }
    },
    filterFormValueInput: {
      width: '250px'
    },
    filterFormOperatorInput: {
      width: '200px'
    },
    filterFormColumnInput: {
      width: '200px'
    }
  }
}
