import { Box, Button } from '@mui/material'
import { LegalTextModal } from '@shared/components/modals/LegalTextModal'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const NavSidebarLegal: React.FC = () => {
  const { t } = useTranslation()

  const [showTerms, setShowTerms] = React.useState(false)
  const [showImprint, setShowImprint] = React.useState(false)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'grey.900',
      mt: 1,
      p: 1.5,
      '.MuiButton-root': {
        '&.MuiButton-text': {
          color: 'grey.500',
          '&:hover': {
            color: 'grey.300'
          }
        }
      }
    }}
    >
      <Button variant="text" onClick={() => setShowImprint(true)}>
        { t('common.imprint') }
      </Button>
      <Button variant="text" onClick={() => setShowTerms(true)}>
        { t('common.termsShort') }
      </Button>

      <LegalTextModal
        legalKey='terms'
        open={showTerms}
        onClose={() => setShowTerms(false)}
        onConfirm={() => setShowTerms(false)}
      />

      <LegalTextModal
        legalKey='imprint'
        open={showImprint}
        onClose={() => setShowImprint(false)}
        onConfirm={() => setShowImprint(false)}
      />
    </Box>
  )
}
