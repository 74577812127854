import React from 'react'
import { Box, CircularProgress } from '@mui/material'

import { PreviewContentProps } from './modalPreviewContent'

export const ModalPreviewContentPdf: React.FC<PreviewContentProps> = ({ src }) => {
  const [loaded, setLoaded] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo(() => {
    return !loaded || artificialLoading
  }, [loaded, artificialLoading])

  return (
    <Box sx={{
      width: '100%',
      height: '70vh',
      position: 'relative'
    }}
    >
      {loading && (
        <Box sx={{
          backgroundColor: 'white',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <iframe
        src={src}
        onLoad={() => setLoaded(true)}
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          border: 'none'
        }}
      />
    </Box>
  )
}
