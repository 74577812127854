import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { themeIcons } from '@theme/theme'

type Props = {
  content: string | React.ReactNode,
  icon: keyof typeof themeIcons
  tooltip?: string
}

export const ListItemIconRow: React.FC<Props> = ({ content, icon, tooltip }) => {
  const Icon = React.useMemo(() => themeIcons[icon], [icon])

  return (
    <Tooltip
      title={tooltip || content}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden'
      }}
      >
        <Icon sx={{
          color: 'grey.600',
          width: 20,
          height: 20
        }}
        />
        <Typography sx={{
          ml: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
          variant="subtitle1"
        >
          {content}
        </Typography>
      </Box>
    </Tooltip>
  )
}
