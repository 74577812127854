import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { CompaniesRowModel, useCompaniesContext } from '../provider/CompaniesProvider'

import { CompanyColumnType, companyDataGridColumns } from './companiesDataGridColumns'

export const CompanyDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds,
    handleDelete,
    updateSelectedItem,
    loading,
    companyData,
    selectedIds,
    paginationModel,
    updatePaginationModel,
    sortModel,
    updateSortModel,
    filterModel,
    updateFilterModel,
    updateInitialState
  } = useCompaniesContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: CompaniesRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const rowCountState = React.useMemo(() => companyData?.totalCount ?? 0, [companyData])

  const rowData = React.useMemo(() => {
    return companyData?.edges?.map(({ node }) => node) || []
  }, [companyData])

  const columnData = React.useMemo<CompanyColumnType[]>(() => {
    return [...companyDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can('companies:read') && { onShow: () => navigate(`/companies/${params.row.id}`) })}
          {...(can('companies:write') && { onEdit: () => navigate(`/companies/${params.row.id}/edit`) })}
          {...(can('companies:delete') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as CompanyColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`companies.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey='companies'
      onInitialState={updateInitialState}
      rows={rowData}
      rowCount={rowCountState}
      paginationModel={paginationModel}
      paginationMode='server'
      autoPageSize={false}
      onPaginationModelChange={updatePaginationModel}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode='server'
      onSortModelChange={updateSortModel}
      filterModel={filterModel}
      filterMode='server'
      onFilterModelChange={updateFilterModel}
      loading={loading}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
