import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { TrainingCompaniesColumnType, TrainingCompaniesRowModel } from '@features/trainingCompanies/provider/TrainingCompaniesProvider'
import { Roles } from '@typings/roles'

const RenderCell: React.FC<{ row: TrainingCompaniesRowModel }> = ({ row }) => {
  const userContacts = React.useMemo(() => {
    const mappedInstructors = row.instructors?.filter((user) => user.role.key === Roles.TRAINING_COMPANY_MANAGER)
      .map((instructor) => instructor.email) ?? []

    return mappedInstructors?.length ? mappedInstructors.join(', ') : '-'
  }, [row])

  return (
    <Tooltip title={userContacts} placement="top">
      <Typography variant="subtitle1" fontWeight={500}>{userContacts}</Typography>
    </Tooltip>
  )
}

export const contactsColumnModel: TrainingCompaniesColumnType = {
  field: 'contacts',
  width: 200,
  filterable: false,
  sortable: false,
  renderCell: (params) => <RenderCell row={params.row} />
}
