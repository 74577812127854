import { useMatchedRoutes } from '@hooks/useMatchedRoute'
import { Box, useTheme } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useNavigate } from 'react-router'
import { AppRouteObject } from '@services/router/router'

import { DashboardBreadcrumbLink } from './DashboardBreadcrumbLink'

type BreadCrumbRoute = {
  path: string
  label: string
  fullPath?: string
  breadcrumb?: React.ReactNode
}

type AppRouteObjectWithFullPath = AppRouteObject & {
  fullPath?: string
}

export const DashboardBreadcrumbs: React.FC = () => {
  const { breakpoints } = useTheme()
  const { matched, location, routes } = useMatchedRoutes()
  const navigate = useNavigate()

  const flattenRoutesWithFullPath = (
    input: AppRouteObjectWithFullPath[],
    childKey: keyof AppRouteObjectWithFullPath,
    parentPath?: string): AppRouteObjectWithFullPath[] => {
    return input.reduce<AppRouteObjectWithFullPath[]>((arr, cur) => {
      const fullPath = parentPath ? `${parentPath}/${cur.path}` : cur.path

      return [...arr, ...flattenRoutesWithFullPath((cur[childKey] || []) as AppRouteObjectWithFullPath[], childKey, fullPath)]
    }, input.slice().map((item) => {
      return {
        ...item,
        fullPath: parentPath ? `${parentPath}/${item.path}` : item.path
      }
    }))
  }

  const nestedBreadcrumbs = React.useMemo<BreadCrumbRoute[]>(() => {
    if (location.pathname === '/') {
      return []
    }

    const flattenedRoutes = flattenRoutesWithFullPath(routes || [], 'children') || []

    const homeRoute = routes?.find((route) => route.path === '/') || null

    return [{
      path: homeRoute?.path || '',
      label: homeRoute?.meta?.name || '',
      fullPath: homeRoute?.path || '',
      breadcrumb: homeRoute?.breadcrumb
    }, ...(matched?.filter((match) => !match.route.index && match.route.breadcrumb !== null).map((filtered) => {
      const route = flattenedRoutes.find((item) => {
        let relevantPath = filtered.pathname

        if (filtered?.route?.path?.includes('/')) {
          relevantPath = filtered.pathname.substring(0, filtered.pathname.lastIndexOf('/'))
        }

        let testPath = filtered.pathname || ''

        Object.keys(filtered.params)?.forEach((param) => {
          testPath = testPath.replace(filtered.params[param] as string, `:${param}`)
        })

        const subPath = relevantPath.substring(0, relevantPath.lastIndexOf('/'))
        const newPath = subPath ? `${subPath}/${filtered.route.path}` : filtered.route.path

        return item.fullPath === (testPath || newPath)
      })

      return {
        path: route?.path,
        label: route?.meta?.name,
        fullPath: filtered.pathname,
        breadcrumb: route?.breadcrumb
      }
    }) || [])] as BreadCrumbRoute[]
  }, [matched, routes])

  const onHandleNavigate = (index: number) => {
    const path = nestedBreadcrumbs[index].fullPath

    if (!path) {
      return
    }

    navigate(path)
  }

  return nestedBreadcrumbs.length
    ? (
      <Box sx={{
        pt: 4,
        pl: 4,
        [breakpoints.down('md')]: {
          pt: 2,
          pl: 1
        },
        display: 'flex',
        flexDirection: 'row'
      }}
      >
        {nestedBreadcrumbs.map((route, index) => (
          <DashboardBreadcrumbLink
            key={route.path}
            isLast={index === nestedBreadcrumbs.length - 1}
            onClick={() => onHandleNavigate(index)}
            label={route.label}
            sx={{
              ...(route.path === '/' && {
                '&>div': {
                  paddingRight: 0
                }
              })
            }}
          >
            {route.path === '/' ? <themeIcons.Home /> : route.breadcrumb || null}
          </DashboardBreadcrumbLink>
        ))}
      </Box>
      )
    : null
}
