import { MenuItem, Select } from '@mui/material'
import { ReviewStatus, Status } from '@typings/graphql'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { ROLE_BASED_STATUS } from '@shared/constants/status'
import { useAppSelector } from '@services/store/store'
import { Roles } from '@typings/roles'

import { StatusColorLabel } from '../ui/StatusColorLabel'

type Props = {
  formKey: string
  options?: RegisterOptions
}

export const ReviewStatusSelectInput: React.FC<Props> = ({ formKey, options }) => {
  const { control } = useFormContext()

  const { user } = useAppSelector(({ auth }) => auth)

  const filteredStatus = React.useMemo(() => {
    return Object.keys(ROLE_BASED_STATUS).filter((key) => {
      return user && ROLE_BASED_STATUS[key as ReviewStatus].includes(user?.role.key as Roles)
    })
  }, [])

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { onChange, value } }) => (
        <Select
          value={value || ReviewStatus.Draft}
          onChange={onChange}
          sx={{
            '&.MuiSelect-overwrite': {
              '&.MuiSelect-overwrite': {
                padding: '10px 12px'
              }
            }
          }}
          renderValue={(renderValue) => (
            <StatusColorLabel status={renderValue as Status} />
          )}
        >
          {filteredStatus.map((key) => (
            <MenuItem key={key} value={key}>
              <StatusColorLabel status={key as Status} />
            </MenuItem>
          ))}
        </Select>
      )}
    />
  )
}
