import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DefaultTabNavigationBar } from './DefaultTabNavigationBar'

export const QuestionsTabBar: React.FC = () => {
  const { professionId, categoryId, subCategoryId } = useParams<{ professionId: string, categoryId: string, subCategoryId: string }>()

  const { t } = useTranslation()

  const items = React.useMemo(() => [
    {
      target: `/professions/${professionId}/${categoryId}/${subCategoryId}`,
      label: t('routes.questions')
    },
    {
      target: `/professions/${professionId}/${categoryId}/${subCategoryId}/groups`,
      label: t('routes.questionGroups')
    }
  ], [])

  return <DefaultTabNavigationBar items={items} />
}
