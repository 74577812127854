import { useErrorMessage } from '@hooks/useErrorMessage'
import {
  GridCallbackDetails,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  GridInitialState
} from '@mui/x-data-grid-pro'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  QuestionsPaginatedQuery,
  ReviewStatus,
  useDeleteQuestionMutation,
  useDeleteQuestionsMutation,
  useQuestionsPaginatedQuery,
  useUpdateQuestionsStatusMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import { useDataGridPagination } from '@hooks/useDataGridPagination'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'

import { ReviewStatusModal } from '../components/ReviewStatusModal'

export type QuestionListType = Exclude<QuestionsPaginatedQuery['questionsPaginated']['edges'], null | undefined>[0]
export type QuestionListRowModel = Omit<QuestionListType, '__typename'>['node'] & {
  title?: string
  key?: string,
  topicTitle?: string
}

export type QuestionListProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: QuestionListRowModel | null
  questions?: QuestionsPaginatedQuery['questionsPaginated']
  loading?: boolean
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  manySelected: boolean
  showDuplicateDialog: boolean
  handleFetchMore: () => void
  updatePaginationModel: (pagination: GridPaginationModel) => void
  updateSortModel: (model: GridSortModel, details: GridCallbackDetails) => void
  updateShowDuplicateDialog: (show: boolean, multiple?: boolean) => void
  updateFilterModel: (filter: GridFilterModel | undefined) => void
  handleStatus: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: QuestionListRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateInitialState: (initialState?: GridInitialState) => void
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const QuestionListProviderContext = React.createContext<QuestionListProviderContextType>(
  {} as any
)

export const QuestionListProvider:React.FC<Props> = ({ children }) => {
  const { t } = useTranslation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [selectedItem, setClickedRow] = React.useState<QuestionListRowModel | null>(null)
  const [showDuplicateDialog, setShowDuplicateDialog] = React.useState<boolean>(false)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [manySelected, setManySelected] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false)
  const [loadMore, setLoadMore] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [updateStatus] = useUpdateQuestionsStatusMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()
  const [deleteQuestions] = useDeleteQuestionsMutation()

  const {
    fetchVariablesRef, filterModel, paginationModel, sortModel,
    setFilterModelState, setPaginationModelState, setSortModelState, setInitialState
  } = useDataGridPagination<QuestionsPaginatedQuery>()

  const updateCallbackRef = React.useRef<() => void>()
  const deleteCallbackRef = React.useRef<() => void>()

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const { data, loading: questionsLoading, refetch } = useQuestionsPaginatedQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: DATA_GRID_PAGE_SIZE
    }
  })

  const questionData = React.useMemo<QuestionsPaginatedQuery['questionsPaginated'] | undefined>(() => {
    return data?.questionsPaginated
  }, [data])

  const handleFetchMore = async () => {
    setErrorMessage('')
    setLoadMore(true)
    try {
      await refetch(fetchVariablesRef.current)
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoadMore(false)
    }
  }

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || questionsLoading || loadMore
  }, [artificialLoading, questionsLoading, loadMore])

  const handleStatus = React.useCallback((multiple: boolean, callback?: () => void) => {
    updateCallbackRef.current = callback || undefined

    setShowUpdateDialog(true)
    setManySelected(multiple)
  }, [])

  const confirmUpdate = React.useCallback(async (reviewStatus: ReviewStatus) => {
    setIsUpdating(true)
    setErrorMessage('')

    try {
      const ids = manySelected ? selectedIds : [selectedItem?.id]

      await updateStatus({
        variables: {
          ids: ids as string[],
          reviewStatus
        }
      })

      setSuccessMessage(t(manySelected ? 'questionList.manySelectedSuccess' : 'questionList.updateSuccess'))

      if (selectedIds && manySelected) {
        setSelectedIds([])
      } else if (selectedItem && !manySelected) {
        setClickedRow(null)
      }

      if (updateCallbackRef.current) {
        updateCallbackRef.current()

        updateCallbackRef.current = undefined
      }

      setShowUpdateDialog(false)
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsUpdating(false)
    }
  }, [selectedIds, manySelected, selectedItem])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteQuestions({
          variables: {
            ids: selectedIds as string[]
          }
        })

        setSelectedIds([])
        setSuccessMessage(t('questions.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteQuestion({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('questions.deleteSuccess'))
      }

      await handleFetchMore()

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updatePaginationModel = React.useCallback((pagination: GridPaginationModel) => {
    setPaginationModelState(pagination, questionData?.pageInfo.startCursor, questionData?.pageInfo.endCursor)

    handleFetchMore()
  }, [questionData])

  const updateSortModel = React.useCallback((model: GridSortModel) => {
    setSortModelState(model)
    handleFetchMore()
  }, [questionData])

  const updateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    setFilterModelState(filter)
    handleFetchMore()
  }, [])

  const updateInitialState = React.useCallback((initialState?: GridInitialState) => {
    setInitialState(initialState)
    handleFetchMore()
  }, [])

  const updateSelectedItem = React.useCallback((row: QuestionListRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateShowDuplicateDialog = React.useCallback((open: boolean, multiple = false) => {
    setShowDuplicateDialog(open)
    setManySelected(multiple)
  }, [])

  const handleDeleteCancel = React.useCallback(() => {
    setClickedRow(null)
    setShowUpdateDialog(false)
  }, [])

  const value = React.useMemo(() => ({
    selectedIds,
    selectedItem,
    paginationModel,
    sortModel,
    filterModel,
    questions: questionData,
    loading,
    manySelected,
    showDuplicateDialog,
    updatePaginationModel,
    handleDelete,
    updateSortModel,
    updateFilterModel,
    updateShowDuplicateDialog,
    handleStatus,
    updateSelectedItem,
    handleFetchMore,
    updateSelectedIds,
    updateInitialState
  }), [questionData,
    questionsLoading,
    showDuplicateDialog,
    loading,
    manySelected,
    sortModel,
    filterModel,
    paginationModel,
    selectedIds,
    selectedItem])

  return (
    <>
      <QuestionListProviderContext.Provider value={value}>
        {children}

        <ReviewStatusModal
          open={showUpdateDialog}
          onClose={handleDeleteCancel}
          onConfirm={confirmUpdate}
          loading={isUpdating}
        >
          <Trans
            i18nKey={manySelected ? 'questionList.updateEntries' : 'questionList.updateEntry'}
            {...(!manySelected ? { values: { name: selectedItem?.key } } : { count: selectedIds.length })}
            components={{ b: <strong /> }}
          />
        </ReviewStatusModal>
      </QuestionListProviderContext.Provider>

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'questions.deleteEntries' : 'questions.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.title } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </>
  )
}

export const useQuestionListContext = () => React.useContext(QuestionListProviderContext)
