import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { useLocation } from 'react-router'
import { QuestionsTabBar } from '@shared/components/ui/QuestionsTabBar'
import { CopyIconButton } from '@shared/components/ui/CopyIconButton'
import { QuestionDuplicateDialog } from '@shared/components/modals/QuestionDuplicateDialog'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionsDataGrid } from '../dataGrid/QuestionsDataGrid'
import { QuestionsProvider, useQuestionsContext } from '../provider/QuestionsProvider'

const QuestionsViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const location = useLocation()
  const {
    selectedIds,
    copyMultiple,
    selectedItem,
    showDuplicateDialog,
    categoryData,
    topic,
    handleFetchMore,
    handleDelete,
    updateShowDuplicateDialog,
    updateSelectedIds
  } = useQuestionsContext()

  const onDuplicated = React.useCallback(() => {
    updateShowDuplicateDialog(false)
    handleFetchMore()
    updateSelectedIds([])
  }, [])

  const onCancel = React.useCallback(() => {
    updateShowDuplicateDialog(false)
    updateSelectedIds([])
  }, [])

  const isTopics = React.useMemo(() => location.pathname.includes('topics'), [location])

  return (
    <DefaultLayout
      title={t('routes.questions')}
      description={
        <Trans
          i18nKey={isTopics ? 'questions.topicQuestionListDescription' : 'questions.questionListDescription'}
          values={{ title: isTopics ? topic?.texts.title : categoryData?.texts?.title }}
          components={{ b: <strong /> }}
        />
      }
      {...(!isTopics && { tabBar: <QuestionsTabBar /> })}
      headerExtension={
        <Box>
          {can(['questions:create', 'questions:create.partial']) && selectedIds?.length > 0 && (
            <CopyIconButton
              sx={{ mr: 2 }}
              onClick={() => updateShowDuplicateDialog(true, true)}
            />
          )}

          {can('questions:delete') && selectedIds?.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['questions:create', 'questions:create.partial']) && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('questions.addQuestion')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <QuestionsDataGrid />
      </Box>

      <QuestionDuplicateDialog
        selectedIds={selectedIds as string[]}
        selectedItem={selectedItem}
        manySelected={!!copyMultiple}
        show={showDuplicateDialog}
        onCompleted={onDuplicated}
        onCancel={onCancel}
      />
    </DefaultLayout>
  )
}

export const QuestionsView: React.FC = () => (
  <QuestionsProvider>
    <QuestionsViewContent />
  </QuestionsProvider>
)
