import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can, isRole } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { Roles } from '@typings/roles'
import { CompanyCreditType } from '@typings/graphql'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { JobsRowModel, useJobsContext } from '../provider/JobWallProvider'
import { JobActionExtension } from '../components/JobActionExtension'

import { JobColumnType, jobDataGridColumns } from './jobDataGridColumns'
import { nameColumnModel } from './columns/nameColumn'
import { companyColumnModel } from './columns/companyColumn'

export const JobDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds, handleDelete,
    updateSelectedItem, loading, jobData,
    handleJobStatusChange, myCompany
  } = useJobsContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: JobsRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<JobColumnType[]>(() => {
    return [
      nameColumnModel,
      ...(isRole(Roles.ADMIN) ? [companyColumnModel] : []),
      ...jobDataGridColumns,
      {
        ...actionColumnModel,
        width: 200,
        renderCell: (params) => (
          <ActionColumnRenderCell
            {...((isRole(Roles.ADMIN) || myCompany) && {
              additionalActions: (
                <JobActionExtension
                  row={params.row as JobsRowModel}
                  onActivate={() => handleJobStatusChange(params.row, CompanyCreditType.JobBasic)}
                  onExtend={() => handleJobStatusChange(params.row, CompanyCreditType.JobExtension)}
                />)
            }) }
            {...(can(['jobs:read', 'jobs:read.own']) && { onShow: () => navigate(`/jobwall/${params.row.id}`) })}
            {...(can(['jobs:delete', 'jobs:delete.own']) && { onDelete: () => handleDeleteRow(params.row) })}
            {...(can(['jobs:write', 'jobs:write.own']) && { onEdit: () => navigate(`/jobwall/${params.row.id}/edit`) })}
          />
        )
      } as JobColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`jobwall.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={jobData}
      gridKey='jobwall'
      onRowSelectionModelChange={onRowSelectionChange}
      loading={loading}
    />
  )
}
