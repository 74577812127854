import { Typography, Box } from '@mui/material'
import React from 'react'

type LegendProps = {
  title: string
  color: string
}

export const LegendRowItem: React.FC<LegendProps> = ({ title, color }) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%'
    }}
    >
      <Box
        sx={{
          height: 10,
          borderRadius: '8px',
          backgroundColor: color,
          mr: 2,
          flex: '0 0 30px'
        }}
      />
      <Box
        sx={{
          flex: 1
        }}
      >
        <Typography variant='subtitle1'>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
