import { BaseSearchInput, SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { FilterOperators, useFilterCompaniesQuery } from '@typings/graphql'
import React from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MIN_VALUE_LENGTH = 3

type Props = {
  description?: string
  options?: RegisterOptions
  additionalFilters?: {
    field: string
    value: string | string[]
    operator: FilterOperators
  }[]
}

export const CompanySearch:React.FC<Props> = ({ description, options, additionalFilters }) => {
  const [queriedCompanies, setQueriedCompanies] = React.useState<SearchInputItem[]>([])
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation()

  const { fetchMore } = useFilterCompaniesQuery({
    skip: true
  })

  const handleInputChange = React.useCallback(async (value: string) => {
    setLoading(true)

    if (!value || value.length < MIN_VALUE_LENGTH) {
      setQueriedCompanies([])
      setLoading(false)

      return
    }

    const { data } = await fetchMore({
      variables: {
        filter: [{
          field: 'name',
          value: value.toLowerCase(),
          operator: FilterOperators.Contains
        }, ...(additionalFilters || [])]
      }
    })

    const Companies = data?.filterCompanies?.map((trainingCompany) => ({
      id: trainingCompany.id,
      name: trainingCompany.name
    }))

    setQueriedCompanies(Companies || [])

    setLoading(false)
  }, [])

  return (
    <BaseSearchInput
      single
      formKey="companies"
      title={t('common.companies')}
      description={description}
      options={options}
      onInputChange={handleInputChange}
      loading={loading}
      minLength={MIN_VALUE_LENGTH}
      searchItems={queriedCompanies}
    />
  )
}
