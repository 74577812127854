import { CSSObject } from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export const wysiwyg:CSSObject = {
  '.wyswigEditor-root': {
    '.ProseMirror': {
      padding: '14px',
      border: 'solid 1px',
      borderColor: palette.grey?.[400],
      borderRadius: '8px',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      color: palette.text?.primary,
      backgroundColor: 'transparent',
      '>p': {
        margin: 0
      }
    },
    '.ProseMirror-focused': {
      outline: 'none'
    },
    '.wyswigEditor-label': {
      position: 'absolute',
      top: '12px',
      left: '12px',
      color: palette?.grey?.['700']
    },
    '.tippy-arrow': {
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: `10px solid ${palette.grey?.A100}`
    },
    '.wysiwygMenu-simple': {
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '.MuiIconButton-root': {
        border: 'none',
        color: palette?.grey?.['600'],

        '&:hover': {
          color: palette?.grey?.['700']
        }
      },
      color: palette?.grey?.['500']
    }
  },
  '.wysiwygMenu-bubble': {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    backgroundColor: palette.grey?.A100,
    color: palette.grey?.['400'],
    hr: {
      width: '1px',
      borderLeftWidth: 0
    },
    '>.MuiBox-root': {
      padding: '0 8px',
      '.MuiButton-root': {
        padding: 0,
        minWidth: 'auto',
        color: palette.grey?.['400'],
        '&.wysiwygMenu-bubble-is-active': {
          color: palette.grey?.['200']
        },
        '&:hover': {
          color: palette.grey?.['300']
        }
      }
    }
  }
}
