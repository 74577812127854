import { Box, Button, Divider, Typography } from '@mui/material'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import { SimpleDataRow } from '@shared/components/ui/SimpleDataRow'
import { ChangePasswordInput, NotificationSubjects } from '@typings/graphql'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { Roles } from '@typings/roles'
import { lowerFirstLetter } from '@utils/format'
import { PersonalSettings } from '@services/store/slices/app'
import { useAppSelector } from '@services/store/store'

import { SettingsProvider, useSettingsContext } from '../provider/SettingsProvider'
import { PasswordChangeInput } from '../components/PasswordChangeInput'
import { NotificationChangeInput } from '../components/NotificationChangeInput'
import { SettingsChangeInput } from '../components/SettingsChangeInput'

export type ChangePasswordInputType = ChangePasswordInput & {
  confirmNewPassword: String
}

export type NotificationSettingsType = {
  subject: NotificationSubjects
  roles: Roles[]
}

export type SettingsType = Record<keyof PersonalSettings, Roles[]>

const ROLE_BASED_NOTIFICATIONS: NotificationSettingsType[] = [{
  subject: NotificationSubjects.NewCompany,
  roles: [Roles.ADMIN]
}, {
  subject: NotificationSubjects.NewTrainingCompany,
  roles: [Roles.ADMIN]
}, {
  subject: NotificationSubjects.RequestUser,
  roles: [Roles.ADMIN]
}, {
  subject: NotificationSubjects.NewTrainee,
  roles: [Roles.TRAINING_COMPANY_MANAGER]
}, {
  subject: NotificationSubjects.TrainingCompanyVerified,
  roles: [Roles.TRAINING_COMPANY_MANAGER]
}]

const ROLE_BASE_SETTINGS: SettingsType = {
  newTab: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR, Roles.COMPANY],
  dataGridState: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR, Roles.TRAINING_COMPANY_MANAGER, Roles.INSTRUCTOR, Roles.COMPANY]
}

const SettingsViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [showToast, setShowToast] = React.useState(false)
  const [showPasswordInputs, setShowPasswordInputs] = React.useState(false)
  const [showNotificationInputs, setShowNotificationInputs] = React.useState(false)
  const [showSettingsInputs, setShowSettingsInputs] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [successMessage, setSuccessMessage] = React.useState<string>('')

  const { personalSettings } = useAppSelector(({ app }) => app)

  const navigate = useNavigate()

  const { handleDelete, loading, me } = useSettingsContext()

  const onSuccess = (message: string) => {
    setShowPasswordInputs(false)
    setShowToast(true)
    setErrorMessage('')
    setSuccessMessage(message)
  }

  const onError = (message: string) => {
    setShowToast(true)
    setErrorMessage(message)
  }

  const filteredNotifications = React.useMemo(() => {
    return ROLE_BASED_NOTIFICATIONS.filter((notification) => notification.roles.includes(me?.role.key as Roles))
  }, [me])

  const filteredSettings = React.useMemo<(keyof PersonalSettings)[]>(() => {
    return Object.keys(ROLE_BASE_SETTINGS).filter((setting) =>
      ROLE_BASE_SETTINGS[setting as keyof PersonalSettings].includes(me?.role.key as Roles)) as (keyof PersonalSettings)[]
  }, [me])

  return (
    <DefaultLayout
      title={t('routes.settings')}
      description={t('settings.settingsDescription')}
      loading={loading}
      headerExtension={
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete()}
          />
          <Button startIcon={<themeIcons.Edit />}
            onClick={() => navigate('edit-profile')}
          >
            {t('settings.editProfile')}
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          pt: 4.5,
          height: '100%',
          position: 'relative'
        }}
      >
        <Box>
          <DashboardCard title={t('settings.userData')}>
            <Divider
              sx={{
                mt: 1,
                mb: 2
              }}
              light
              orientation='horizontal'
            />
            <SimpleDataRow title={t('common.email')} value={me?.email} />

            <SimpleDataRow title={t('common.firstName')} value={me?.firstname} />

            <SimpleDataRow title={t('common.lastName')} value={me?.lastname} />

            {me?.company?.name && <SimpleDataRow title={t('common.company')} value={me?.company.name} />}

            <SimpleDataRow title={t('common.role')} value={t(`roles.${me?.role.key}`)}/>

            <SimpleDataRow
              title={t('auth.password')}
              actionWidth={3}
              actionTop
              noDivider={!filteredNotifications?.length && !filteredSettings?.length}
              actions={
                !showPasswordInputs &&
                <Button
                  startIcon={<themeIcons.Edit />}
                  onClick={() => setShowPasswordInputs(true)}
                >
                  {t('common.change')}
                </Button>
              }
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
              >
                {!showPasswordInputs
                  ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%'
                    }}
                    >
                      <Typography sx={{ mr: 2 }}>*********</Typography>
                    </Box>
                    )
                  : (
                    <PasswordChangeInput
                      onCancel={() => setShowPasswordInputs(false)}
                      onSuccess={() => onSuccess(t('settings.passwordChangedSuccess'))}
                      onError={onError}
                    />
                    )}
              </Box>
            </SimpleDataRow>
            {!!filteredNotifications?.length && (
            <SimpleDataRow
              title={t('common.notifications')}
              actionWidth={3}
              actionTop
              noDivider={!filteredSettings?.length}
              actions={
              !showNotificationInputs &&
              <Button
                startIcon={<themeIcons.Edit />}
                onClick={() => setShowNotificationInputs(true)}
              >
                {t('common.change')}
              </Button>
            }
            >
              {!showNotificationInputs
                ? (

                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Box>
                      {me?.notifications?.map((notification) => (
                        <Box key={notification.subject}>
                          <Typography>{t(`notifications.${lowerFirstLetter(notification.subject)}`)}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  )
                : (
                  <NotificationChangeInput
                    onCancel={() => setShowNotificationInputs(false)}
                    onSuccess={() => onSuccess(t('settings.notificationsChangedSuccess'))}
                    onError={onError}
                    notifications={filteredNotifications}
                  />
                  )}
            </SimpleDataRow>
            )}

            {!!filteredSettings?.length && (
              <SimpleDataRow
                noDivider
                title={t('settings.personalSettings')}
                actionWidth={3}
                actionTop
                actions={
                !showSettingsInputs &&
                <Button
                  startIcon={<themeIcons.Edit />}
                  onClick={() => setShowSettingsInputs(true)}
                >
                  {t('common.change')}
                </Button>
              }
              >
                {!showSettingsInputs
                  ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <Box>
                        {Object.entries(personalSettings).filter((item) => !!item[1])
                          .map(([key]) => (
                            <Box key={key}>
                              <Typography>{t(`settings.${key}`)}</Typography>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                    )
                  : (
                    <SettingsChangeInput
                      onCancel={() => setShowSettingsInputs(false)}
                      onSuccess={() => onSuccess(t('settings.personalSettingsChanged'))}
                      settings={filteredSettings}
                    />
                    )}
              </SimpleDataRow>
            )}
          </DashboardCard>
        </Box>
      </Box>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </DefaultLayout>
  )
}

export const SettingsView: React.FC = () => {
  return (
    <SettingsProvider>
      <SettingsViewContent />
    </SettingsProvider>
  )
}
