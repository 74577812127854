import { Box, DialogProps, IconButton, Modal, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { EditableFile } from '@typings/files'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { modalPreviewContent } from './preview/modalPreviewContent'

type Props = DialogProps & {
  onClose: () => void
  file?: EditableFile<any> | null
}

export const FilePreviewModal:React.FC<Props> = ({ file, onClose, ...props }) => {
  const { t } = useTranslation()

  const fileInfo = React.useMemo(() => {
    const src = file?.upload?.preview || file?.remote?.url
    const type = file?.upload?.file?.type || file?.remote?.mimeType
    const name = file?.upload?.file?.name || file?.remote?.fileName

    if (!src || !type) {
      return null
    }

    return {
      name,
      src,
      type
    }
  }, [file])

  const PreviewContent = React.useMemo(() => {
    if (!fileInfo) {
      return null
    }

    if (fileInfo.type.startsWith('image')) {
      return modalPreviewContent.image
    } else if (fileInfo.type.startsWith('video')) {
      return modalPreviewContent.video
    } else if (fileInfo.type.startsWith('application/pdf')) {
      return modalPreviewContent.pdf
    }

    return null
  }, [fileInfo])

  return (
    <Modal
      onClose={onClose}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{
        maxWidth: '80%',
        maxHeight: '80%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '8px',
        backgroundColor: 'white'
      }}
      >
        <Box sx={{
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1.5,
          borderBottom: 'solid 1px',
          borderColor: 'grey.300'
        }}
        >
          <Box>
            <Typography variant="h4">
              {t('common.previewFile', { fileName: fileInfo?.name })}
            </Typography>
          </Box>
          <IconButton
            onClick={() => onClose()}
          >
            <themeIcons.Cancel />
          </IconButton>
        </Box>
        {PreviewContent && <PreviewContent src={fileInfo?.src} type={fileInfo?.type} />}
      </Box>
    </Modal>
  )
}
