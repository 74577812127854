import { Box } from '@mui/material'
import { STATUS_COLOR } from '@shared/constants/color'
import { ReviewStatus, Status } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ColorStatus = Status | ReviewStatus

type Props = {
  status: ColorStatus
}

export const StatusColorLabel: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}
    >
      <Box sx={{
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        mr: 1,
        backgroundColor: STATUS_COLOR[status as ColorStatus]
      }}
      />
      {t(`status.${status.toLocaleLowerCase()}`)}
    </Box>
  )
}
