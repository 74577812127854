import React from 'react'
import { Button, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router'
import { ProfessionRowModel } from '@features/professions/provider/ProfessionsProvider'

import { ProfessionsColumnType } from '../professionsDataGridColumns'

const RenderCell: React.FC<{row: ProfessionRowModel}> = ({ row }) => {
  const navigate = useNavigate()

  return (
    <Button
      variant="text"
      onClick={() => navigate(`${row.id}`)}
      sx={{
        '&.MuiButton-root': {
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: 'flex-start'
        }
      }}
    >
      <Typography
        variant="subtitle1"
        fontWeight={500}
      >{row.texts.title}</Typography>
    </Button>
  )
}
export const titleColumnModel: ProfessionsColumnType = {
  field: 'title',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
