import React from 'react'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import { Box } from '@mui/material'

import { PasswordSetForm } from '../components/PasswordSetForm'

export const PasswordResetView: React.FC = () => {
  return <AuthLayout>
    <Box sx={{ mt: 2 }}>
      <PasswordSetForm />
    </Box>
  </AuthLayout>
}
