import { Divider } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  initialBaseCredits?: number
  initialJobExtensionCredits?: number
}

export const CompanyCreateInputExtensions:React.FC<Props> = ({ initialBaseCredits, initialJobExtensionCredits }) => {
  const { t } = useTranslation()

  return (
    <>
      <BaseTextInput
        title={t('credits.jobBasicCredits')}
        description={t('credits.jobBasicCreditsDescription')}
        formKey='credits.jobBasic'
        defaultValue={0}
        type='number'
        validationText={t('errors.creditsCantBeLower', { count: initialBaseCredits || 0 })}
        options={{
          valueAsNumber: true,
          validate: (value) => Number(value) >= (initialBaseCredits || 0) ||
          t('errors.creditsCantBeLower', { count: initialBaseCredits || 0 })
        }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        title={t('credits.jobExtensionCredits')}
        description={t('credits.jobExtensionCreditsDescription')}
        formKey='credits.jobExtension'
        defaultValue={0}
        type='number'
        validationText={t('errors.creditsCantBeLower', { count: initialJobExtensionCredits || 0 })}
        options={{
          setValueAs: (value) => Number(value || 0),
          validate: (value) => Number(value) >= (initialJobExtensionCredits || 0) ||
          t('errors.creditsCantBeLower', { count: initialJobExtensionCredits || 0 })
        }}
      />
    </>
  )
}
