import React from 'react'
import { Stack } from '@mui/material'
import {
  GridFeatureMode,
  GridFooterContainer,
  gridExpandedRowCountSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro'

import { DataGridPagination } from './footer/DataGridPagination'
import { DataGridPageLabel } from './footer/DataGridPageLabel'
import { DataGridPageEntriesLabel } from './footer/DataGridPageEntriesLabel'
import { DataGridRowSizeSelection } from './footer/DataGridRowSizeSelection'

type Props = {
  mode?: GridFeatureMode
}

export const DataGridFooter: React.FC<Props> = ({ mode = 'client' }) => {
  const apiRef = useGridApiContext()

  const totalRows = useGridSelector(apiRef, gridRowCountSelector)
  const visibleRows = useGridSelector(apiRef, gridExpandedRowCountSelector)

  const rowCount = React.useMemo(() => (mode !== 'client' ? totalRows : visibleRows), [mode, visibleRows, totalRows])

  return (
    <GridFooterContainer
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        px: 1.5
      }}
    >
      <Stack
        direction='row'
        spacing={1.5}
      >
        <DataGridPageLabel rowCount={rowCount} />
        <DataGridPageEntriesLabel rowCount={rowCount} />
      </Stack>
      <Stack
        direction='row'
        spacing={1.5}
      >
        <DataGridRowSizeSelection />
        <DataGridPagination rowCount={rowCount} />
      </Stack>
    </GridFooterContainer>
  )
}
