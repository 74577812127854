import React from 'react'
import { TraineesRowModel } from '@features/trainees/provider/TraineesProvider'
import { Tooltip, Typography } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { useProfessionsMinimalQuery } from '@typings/graphql'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'

import { TraineeColumnType } from '../traineesDataGridColumns'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { data } = useProfessionsMinimalQuery()

  const selectItems = React.useMemo(() => {
    if (!data) {
      return []
    }

    return data.professions.map((profession) => ({
      id: profession.id,
      name: profession.texts.title,
      key: profession.id
    }))
  }, [data])

  return (
    <FilterSelectInput
      items={selectItems}
      {...props}
    />
  )
}

const RenderCell: React.FC<{row: TraineesRowModel}> = ({ row }) => {
  const titles = React.useMemo(() => {
    return row?.profession?.texts?.title || '-'
  }, [row])

  return <Tooltip title={titles}>
    <Typography
      variant="subtitle1"
      fontWeight={500}
    >
      {titles }
    </Typography>
  </Tooltip>
}

export const professionColumnModel: TraineeColumnType = {
  field: 'profession',
  width: 150,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  sortable: false,
  valueGetter: ({ row }) => (row as TraineesRowModel)?.profession?.id || '',
  renderCell: (params) => (<RenderCell row={params.row as TraineesRowModel} />)
}
