import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { TraineeData } from '../provider/DashboardTrainingCompanyProvider'

import { DashboardTraineeListItem } from './DashboardTraineeListItem'

type Props = {
  title?: string
  students: TraineeData[]
}

export const DashboardTraineeList: React.FC<Props> = ({ title, students }) => {
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      {title && (
        <Box sx={{
          px: 4.5
        }}
        >
          <Typography variant='h4' fontWeight={600}>{title}</Typography>
        </Box>
      )}

      <Box sx={{
        position: 'relative'
      }}
      >
        {
          /*
          <Box sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 50,
            zIndex: 1,
            background: 'linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 75%)'
          }}
          />
          */
        }
        <Box sx={{
          maxWidth: '100%',
          mt: 2,
          p: 4.5,
          pt: 0,
          position: 'relative'
        }}
        >
          <Grid container spacing={2}>
            {students.map((student) => (
              <Grid key={student.id}
                item
                xs={4}
              >
                <DashboardTraineeListItem student={student} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {
          /**
            <Box sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              height: '100%',
              width: 75,
              zIndex: 1,
              background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%)'
            }}
            />
          */
        }
      </Box>
    </Box>
  )
}
