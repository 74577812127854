import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  TrainingCompaniesQuery,
  useConfirmTraineeMutation,
  useDisconnectTraineeMutation,
  useDisconnectTraineesMutation,
  useTrainingCompaniesTraineesQuery,
  useMyTraineesQuery,
  MyTraineesQuery,
  InviteTraineeInput,
  useInviteOrCreateTraineeMutation
} from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import React from 'react'
import {
  Trans,
  useTranslation
} from 'react-i18next'

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

type TrainingCompany = Exclude<TrainingCompaniesQuery['trainingCompanies'], null | undefined>[0]

export type TraineeType = Exclude<MyTraineesQuery['me']['trainees'], null | undefined>[0] & {
  name?: TrainingCompany['name']
}
export type TraineesRowModel = Omit<TraineeType, '__typename'>

export type TraineeColumnType = Omit<GridColDef<TraineesRowModel>, 'field'> & {
  field: keyof TraineesRowModel | 'action',
  hideHeader?: boolean,
}

export type TraineesProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: TraineesRowModel | null
  traineeData: TraineesRowModel[]
  loading: boolean
  newTrainee: boolean
  handleInviteUser: (input: InviteTraineeInput, callback?: () => void) => void
  handleVerifyUser: (item: TraineesRowModel) => void
  handleDisconnect: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: TraineesRowModel | null) => void
  updateNewTrainee: (value: boolean) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

const TraineesProviderContext = React.createContext<TraineesProviderContextType>(
  {} as any
)

export const TraineesProvider:React.FC<Props> = ({ children }) => {
  const { t } = useTranslation()
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDisconnectDialog, setShowDisconnectDialog] = React.useState(false)
  const [showVerifyDialog, setShowVerifyDialog] = React.useState(false)
  const [isDisconnectLoading, setIsDisconnectLoading] = React.useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<TraineesRowModel | null>(null)
  const [disconnectMany, setDisconnectMany] = React.useState<Boolean>(false)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [newTrainee, setNewTrainee] = React.useState(false)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [inviteTrainee] = useInviteOrCreateTraineeMutation()

  const isTrainingCompany = React.useMemo<boolean>(() => {
    return isRole(Roles.TRAINING_COMPANY_MANAGER)
  }, [])

  const { data, loading: dataLoading, refetch } = useTrainingCompaniesTraineesQuery({
    variables: {
      all: true
    },
    fetchPolicy: 'cache-and-network',
    skip: !isTrainingCompany
  })

  const { data: traineesData, loading: traineesLoading } = useMyTraineesQuery({
    fetchPolicy: 'cache-and-network',
    skip: isTrainingCompany
  })

  const [disconnectTrainees] = useDisconnectTraineesMutation()
  const [disconnectTrainee] = useDisconnectTraineeMutation()
  const [confirmTrainee] = useConfirmTraineeMutation()

  const deleteCallbackRef = React.useRef<() => void>()

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading || traineesLoading
  }, [artificialLoading, dataLoading])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: TraineesRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDisconnect = React.useCallback((multiple: Boolean, callback?: () => void) => {
    setDisconnectMany(multiple)
    setShowDisconnectDialog(true)
    deleteCallbackRef.current = callback
  }, [])

  const handleVerifyUser = React.useCallback((item: TraineesRowModel) => {
    setClickedRow(item)
    setShowVerifyDialog(true)
  }, [])

  const confirmDisconnect = React.useCallback(async () => {
    if (disconnectMany ? selectedIds.length === 0 : !selectedItem?.id) {
      return
    }

    setErrorMessage('')
    setIsDisconnectLoading(true)

    try {
      if (disconnectMany) {
        await disconnectTrainees({
          variables: {
            traineeIds: selectedIds as string[]
          }
        })
        setSuccessMessage(t('trainees.disconnectManySuccess'))
      } else {
        await disconnectTrainee({
          variables: {
            traineeId: selectedItem?.id as string
          }
        })
        setSuccessMessage(t('trainees.disconnectSuccess'))
      }

      updateSelectedIds([])
      refetch()
      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setShowDisconnectDialog(false)
      setIsDisconnectLoading(false)
    }
  }, [selectedIds, selectedItem, disconnectMany])

  const confirmVerifyUser = React.useCallback(async () => {
    setIsVerifyLoading(true)
    setErrorMessage('')

    try {
      await confirmTrainee({
        variables: {
          id: selectedItem?.id as string
        }
      })

      setSuccessMessage(t('trainees.verifySuccess'))
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setIsVerifyLoading(false)
      setShowToast(true)
      setShowVerifyDialog(false)
    }
  }, [selectedItem])

  const traineeData = React.useMemo(() => {
    if (isTrainingCompany) {
      const trainee = data?.trainingCompanies?.flatMap((trainingCompany) => {
        return trainingCompany.trainees.map((user) => ({
          ...user,
          name: trainingCompany.name
        }))
      })

      return trainee || []
    } else {
      const trainee = traineesData?.me?.trainees?.map((user) => ({
        ...user,
        name: user.trainingCompanies?.[0]?.name
      }))

      return trainee || []
    }
  }, [data, traineesData])

  const handleInviteUser = React.useCallback(async (input: InviteTraineeInput, callback?: () => void) => {
    setErrorMessage('')

    try {
      await inviteTrainee({
        variables: {
          data: input
        }
      })

      setSuccessMessage(t('trainees.inviteSuccess'))
      callback?.()
      refetch()
    } catch (error: any) {
      if (error.message.includes('missingName') && !newTrainee) {
        setErrorMessage(t('errors.notRegistered'))
        setNewTrainee(true)
      } else if (error.message.includes('userNotTrainee') ||
      error.message.includes('userAlreadyInTrainingCompany') ||
      error.message.includes('missingName')) {
        setErrorMessage(t(error.message))
        setShowToast(true)
      } else {
        setMessageByCode(error)
        setShowToast(true)
      }
    } finally {
      setShowToast(true)
    }
  }, [])

  const updateNewTrainee = React.useCallback((value: boolean) => {
    setNewTrainee(value)
  }, [])

  const value = React.useMemo<TraineesProviderContextType>(() => ({
    traineeData,
    selectedIds,
    selectedItem,
    loading,
    newTrainee,
    handleInviteUser,
    handleVerifyUser,
    updateNewTrainee,
    handleDisconnect,
    updateSelectedItem,
    updateSelectedIds
  }), [traineeData, loading, newTrainee, selectedIds, selectedItem])

  return (
    <TraineesProviderContext.Provider value={value}>
      {children}
      <ConfirmDialog
        title={t('common.disconnectEntry')}
        onCancel={() => setShowDisconnectDialog(false)}
        onConfirm={confirmDisconnect}
        loading={isDisconnectLoading}
        open={showDisconnectDialog}
      >
        <Trans
          i18nKey={disconnectMany ? 'trainees.disconnectEntries' : 'trainees.disconnectEntry'}
          {...(!disconnectMany ? { values: { name: selectedItem?.email } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <ConfirmDialog
        title={t('trainees.verifyUser')}
        onCancel={() => setShowVerifyDialog(false)}
        onConfirm={confirmVerifyUser}
        loading={isVerifyLoading}
        open={showVerifyDialog}
      >
        <Trans
          i18nKey={'trainees.verifyUserDescription'}
          values={{ name: t('common.fullName', { firstName: selectedItem?.firstname, lastName: selectedItem?.lastname }) }}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </TraineesProviderContext.Provider>
  )
}

export const useTraineesContext = () => React.useContext(TraineesProviderContext)
