import { useErrorMessage } from '@hooks/useErrorMessage'
import { Box } from '@mui/material'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { getAuthUser } from '@services/store/slices/auth'
import { useAppSelector } from '@services/store/store'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  TrainingCompanyUserMinimalFragment,
  TrainingCompaniesQuery,
  useDeleteUserMutation,
  useDeleteUsersMutation,
  useTrainingCompaniesQuery,
  useInviteUserMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

type TrainingCompany = Exclude<TrainingCompaniesQuery['trainingCompanies'], null | undefined>[0]

export type InstructorType = TrainingCompanyUserMinimalFragment & {
  name?: TrainingCompany['name']
}

export type InstructorsRowModel = Omit<InstructorType, '__typename'>

export type InstructorColumnType = Omit<GridColDef<InstructorsRowModel>, 'field'> & {
  field: keyof InstructorsRowModel | 'action',
  hideHeader?: boolean,
}

export type InstructorsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: InstructorsRowModel | null
  instructorsData: InstructorsRowModel[]
  loading: boolean
  handleDelete: (multiple: boolean, callback?: () => void) => void
  handleSendInvite: (id: string) => void
  updateSelectedItem: (row: InstructorsRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

const InstructorsProviderContext = React.createContext<InstructorsProviderContextType>(
  {} as any
)

export const InstructorsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [selectedItem, setClickedRow] = React.useState<InstructorsRowModel | null>(null)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const authUser = useAppSelector(getAuthUser)

  const deleteCallbackRef = React.useRef<() => void>()

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [deleteUser] = useDeleteUserMutation()
  const [deleteManyUsers] = useDeleteUsersMutation()

  const [inviteUser] = useInviteUserMutation()

  const { data, loading: dataLoading, refetch } = useTrainingCompaniesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !!disableFetching
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading
  }, [artificialLoading, dataLoading])

  const instructorsData = React.useMemo(() => {
    const instructors = data?.trainingCompanies?.flatMap((trainingCompany) => {
      return trainingCompany.instructors.map((user) => ({
        ...user,
        name: trainingCompany.name
      })).filter((user) => user?.id !== authUser?.id)
    })

    // filter out duplicates
    const uniqueInstructors = instructors?.filter((instructor, index, self) => {
      return self.findIndex((item) => item?.id === instructor?.id) === index && instructor?.id
    })

    return uniqueInstructors || []
  }, [data])

  const handleSendInvite = React.useCallback(async (id: string) => {
    setErrorMessage('')
    setSuccessMessage('')

    try {
      await inviteUser({
        variables: {
          id
        }
      })

      setSuccessMessage(t('instructors.inviteSuccess'))
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setShowToast(true)
    }
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: InstructorsRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteManyUsers({
          variables: {
            userIds: selectedIds as string[]
          }
        })
        updateSelectedIds([])
        setSuccessMessage(t('instructors.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteUser({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('instructors.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    }
  }, [selectedIds, deleteMany, selectedItem])

  const value = React.useMemo<InstructorsProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    instructorsData,
    loading,
    handleDelete,
    handleSendInvite,
    updateSelectedIds,
    updateSelectedItem
  }), [selectedIds, selectedItem, instructorsData, loading])

  return (
    <InstructorsProviderContext.Provider value={value}>
      <Box sx={{ height: '100%', position: 'relative' }}>
        {children}
        <ConfirmDialog
          title={t('common.deleteEntry')}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={confirmDelete}
          loading={isDeleteLoading}
          open={showDeleteDialog}
        >
          <Trans
            i18nKey={deleteMany ? 'instructors.deleteEntries' : 'instructors.deleteEntry'}
            {...(!deleteMany ? { values: { name: selectedItem?.email } } : { count: selectedIds.length })}
            components={{ b: <strong /> }}
          />
        </ConfirmDialog>

        <DefaultSnackbar
          open={showToast}
          severity={errorMessage ? 'error' : 'success'}
          onClose={() => setShowToast(false)}
          message={errorMessage || t('instructors.inviteSuccess')}
        />

        <DefaultSnackbar
          open={showToast}
          severity={errorMessage ? 'error' : 'success'}
          onClose={() => setShowToast(false)}
          message={errorMessage || successMessage}
        />

      </Box>
    </InstructorsProviderContext.Provider>
  )
}

export const useInstructorsContext = () => React.useContext(InstructorsProviderContext)
