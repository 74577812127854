import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { CompaniesRowModel } from '@features/companies/provider/CompaniesProvider'

import { CompanyColumnType } from '../companiesDataGridColumns'

const RenderCell: React.FC<{ row: CompaniesRowModel }> = ({ row }) => {
  const userMails = React.useMemo(() => {
    const mappedContacts = row.users?.map((user) => user.email) ?? []

    return mappedContacts?.length ? mappedContacts.join(', ') : '-'
  }, [row])

  return (
    <Tooltip title={userMails} placement="top">
      <Typography variant="subtitle1" fontWeight={500}>{userMails}</Typography>
    </Tooltip>
  )
}

export const contactsColumnModel: CompanyColumnType = {
  field: 'contacts',
  width: 200,
  filterable: false,
  sortable: false,
  renderCell: (params) => <RenderCell row={params.row} />
}
