// import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { useNavigate } from 'react-router'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { ProfessionRowModel, useProfessionsContext } from '../provider/ProfessionsProvider'

import { ProfessionsColumnType, professionsDataGridColumns } from './professionsDataGridColumns'

export const ProfessionsDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const navigateNewTab = useNewTabHook()

  const {
    // updateSelectedIds,
    parentProfessionId,
    loading,
    professionData,
    handleDelete,
    updateSelectedItem
  } = useProfessionsContext()

  const handleDeleteRow = React.useCallback(async (row: ProfessionRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  /**
    const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
      updateSelectedIds(params)
    }, [])
  */

  const columnData = React.useMemo<ProfessionsColumnType[]>(() => {
    return [...professionsDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can('professions:read') && { onShow: () => navigate(`${params.row.id}`) })}
          {...(can('professions:delete') && { onDelete: () => handleDeleteRow(params.row) })}
          {...(can(['professions:write', 'professions:write.partial']) && { onEdit: () => navigateNewTab(`${params.row.id}/edit`) })}
        />
      )
    } as ProfessionsColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`professions.column.${column.field}`),
        ...column
      }
    })
  }, [parentProfessionId])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey='professions'
      rows={professionData}
      loading={loading}
      rowSelection={false}
      checkboxSelection={false}
      // onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
