import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraineesRowModel } from '@features/trainees/provider/TraineesProvider'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

import { TraineeColumnType } from '../traineesDataGridColumns'

const RenderCell:React.FC<{row: TraineesRowModel}> = ({ row }) => {
  const { t } = useTranslation()

  const fullName = React.useMemo(() => {
    return t('common.fullName', {
      firstName: row?.instructor?.firstname,
      lastName: row?.instructor?.lastname
    })
  }, [row])

  return row.instructor
    ? (
      <Tooltip title={fullName}>
        <Typography variant="subtitle1" fontWeight={500}>{fullName}</Typography>
      </Tooltip>
      )
    : (
      <Typography variant="subtitle1" fontWeight={500}>-</Typography>
      )
}

export const instructorModel: TraineeColumnType = {
  field: 'instructor',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => <RenderCell row={params.row} />
}
