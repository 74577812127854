import React from 'react'
import { Typography } from '@mui/material'

import { JobColumnType } from '../jobDataGridColumns'

export const favoriteCountColumnModel: JobColumnType = {
  field: 'favoriteCount',
  width: 70,
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{params.value}</Typography>
  )
}
