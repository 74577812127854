import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '@services/i18n/i18n'
import { TraineePie } from '@shared/components/ui/TraineePie'
import { useNavigate } from 'react-router'
import { convertMinutesToHoursAndMinutes, upperFirstLetter } from '@utils/format'
import { LegendRowItem } from '@shared/components/ui/LegendRowItem'
import { useLearningProgress } from '@hooks/useLearningProgress'

import { TraineeData } from '../provider/DashboardTrainingCompanyProvider'

import { ListItemIconRow } from './ListItemIconRow'

type Props = {
  student: TraineeData
}

export const DashboardTraineeListItem: React.FC<Props> = ({ student }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { learningProgress } = useLearningProgress(student?.learningProgress)

  const instructor = React.useMemo(() => {
    return student.instructor
      ? t('common.fullName', {
        firstName: student.instructor.firstname,
        lastName: student.instructor.lastname
      })
      : '-'
  }, [student])

  const learningSince = React.useMemo(() => {
    return new Date(student.createdAt).toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }, [student])

  const studyTime = React.useMemo(() => {
    const formattedTime = convertMinutesToHoursAndMinutes(student?.overallStudyTime || 0)

    return t(formattedTime.hours > 0 ? 'common.hoursAndMinutesShort' : 'common.minutesShort', formattedTime)
  }, [student])

  const daysUntilExam = React.useMemo(() => {
    if (!student.examDate) {
      return 0
    }

    const today = new Date()
    const examDate = new Date(student.examDate)

    const days = Math.ceil((examDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))

    return days > 0 ? days : 0
  }, [student])

  return (
    <DashboardCard
      sx={{
        pb: 0
      }}
      contentContainerProps={{
        sx: {
          position: 'relative',
          overflow: 'visible'
        }
      }}
      additionalActions={student.examDate && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderRadius: '0 0 0 8px',
          px: 1,
          backgroundColor: 'primary.100'
        }}
        >
          <Typography
            sx={{
              color: 'primary.main'
            }}
            variant="subtitle2"
          >{t('dashboard.trainingCompany.examData', {
            date: new Date(student.examDate).toLocaleDateString(i18n.language, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })
          })}</Typography>
        </Box>
      )}
    >
      <Typography fontWeight={600}>
        {t('common.fullName', {
          firstName: upperFirstLetter(student.firstname),
          lastName: upperFirstLetter(student.lastname)
        })}</Typography>
      <Grid
        container
        spacing={0.5}
        sx={{
          mt: 0.5
        }}
      >
        {daysUntilExam !== null && daysUntilExam >= 0 && (
          <Grid
            item
            xs={12}
          >
            <ListItemIconRow
              content={
                <Trans
                  i18nKey={'dashboard.trainingCompany.daysUntilExam'}
                  values={{ days: daysUntilExam }}
                  components={{ b: <b /> }}
                />
              }
              icon="AccessAlarm"
            />
          </Grid>
        )}

        <Grid
          item
          xs={6}
        >
          <ListItemIconRow
            tooltip={t('dashboard.trainingCompany.instructorTooltip', { name: instructor })}
            content={instructor}
            icon="Person"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <ListItemIconRow
            tooltip={t('dashboard.trainingCompany.learningSinceTooltip', { date: learningSince })}
            content={t('common.since', { date: learningSince })}
            icon="CalendarToday"
          />
        </Grid>

        {!!student.profession && (
          <Grid
            item
            xs={6}
          >
            <ListItemIconRow
              tooltip={t('dashboard.trainingCompany.professionTooltip', { profession: student.profession?.texts.title })}
              content={student.profession?.texts.title}
              icon="Building"
            />
          </Grid>
        )}
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <ListItemIconRow
            content={t('common.trainingYearWithNumber', { year: student.trainingYear })}
            icon="Book"
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          mt: 0.5
        }}
      >
        <Grid item xs={5}>
          <TraineePie data={learningProgress?.overall} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>{t('dashboard.trainingCompany.currentValues')}</Typography>
          <Box sx={{ mt: 0.5 }}>
            <ListItemIconRow
              tooltip={t('dashboard.trainingCompany.studyTimeTooltip', { time: studyTime })}
              content={studyTime}
              icon="Clock"
            />
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <ListItemIconRow
              tooltip={student.player?.duelRanking
                ? t('dashboard.trainingCompany.duelRankingTooltip', { ranking: student.player?.duelRanking })
                : t('dashboard.trainingCompany.duelRankingTooltipNoRanking')
              }
              content={student.player?.duelRanking ? `${student.player?.duelRanking}.` : '-'}
              icon="Trophy"
            />
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <ListItemIconRow
              content={t('dashboard.trainingCompany.level', { level: student.player?.level })}
              icon="BarChart"
            />
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <ListItemIconRow
              content={t('dashboard.trainingCompany.points', { points: student.player?.points })}
              icon="Star"
            />
          </Box>
          <Box sx={{ mt: 0.5 }}>
            <ListItemIconRow
              content={t('dashboard.trainingCompany.credits', { credits: student.player?.credits })}
              icon="Coins"
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 2
        }}
      >
        {Object.entries(learningProgress.overall).map(([key, value]) => (
          <Box
            key={key}
          >
            <LegendRowItem
              title={t(`learningProgress.${key}`, { value: `${value.value.toFixed(1)}%` })}
              color={value.color}
            />
          </Box>
        ))}
      </Box>

      <Box sx={{
        mt: 2,
        mr: '-24px',
        ml: '-24px'
      }}
      >
        <Divider light />
        <Button
          variant="text"
          sx={{
            textAlign: 'center',
            width: '100%',
            '&:hover': {
              backgroundColor: 'grey.200'
            }
          }}
          onClick={() => navigate(`trainees/${student.id}`)}
          disableRipple
        >{t('common.showDetails')}</Button>
      </Box>
    </DashboardCard>
  )
}
