import React from 'react'
import { Button, Tooltip, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { QuestionRowModel } from '@features/questions/provider/QuestionsProvider'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionsColumnType } from '../questionsDataGridColumns'

const RenderCell: React.FC<{row: QuestionRowModel}> = ({ row }) => {
  const navigate = useNewTabHook()

  const handleNavigate = () => {
    navigate(`/professions/${row.profession.id}/${row.category.parentId}/${row.category.id}/${row.id}`)
  }

  return (
    <Tooltip title={row?.texts.text}>
      <Button
        variant="text"
        sx={{
          '&.MuiButton-root': {
            paddingLeft: 0,
            paddingRight: 0,
            justifyContent: 'flex-start'
          }
        }}
        onClick={handleNavigate}
      >
        <Typography
          variant="subtitle1"
          fontWeight={500}
        >{row?.texts.text}</Typography>
      </Button>
    </Tooltip>
  )
}

export const titleColumnModel: QuestionsColumnType = {
  field: 'title',
  flex: 1,
  sortable: false,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter: (params) => params.row?.texts?.text,
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
