import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { themeIcons } from '@theme/theme'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { TopicsTabBar } from '@shared/components/ui/TopicsTabBar'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { TopicsProvider, useTopicsContext } from '../provider/TopicsProvider'
import { TopicsDataGrid } from '../dataGrid/TopicsDataGrid'

const TopicsViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { selectedIds, handleDelete } = useTopicsContext()
  const navigate = useNewTabHook()

  return (
    <DefaultLayout
      title={t('routes.topics')}
      description={t('topics.topicListDescription')}
      tabBar={<TopicsTabBar />}
      headerExtension={
        <Box>
          {can('topics:delete') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['topics:create', 'topics:create.partial']) && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('topics.addTopic')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <TopicsDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const TopicsView: React.FC = () => (
  <TopicsProvider>
    <TopicsViewContent />
  </TopicsProvider>
)
