import { Box, Divider } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { TrainingCompanySearch } from '@shared/components/inputs/TrainingCompanySearch'
import { UserSearch } from '@shared/components/inputs/UserSearch'
import { Roles } from '@typings/roles'
import { t } from 'i18next'
import React from 'react'

export const InstructorsCreateEditInputs: React.FC = () => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='email'
        options={{ required: true }}
        title={t('common.email')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='firstname'
        options={{ required: true }}
        title={t('common.firstName')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='lastname'
        options={{ required: true }}
        title={t('common.lastName')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <TrainingCompanySearch
        options={{ required: true }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <UserSearch
        title={t('trainingCompanies.trainee')}
        description={t('trainingCompanies.traineeDescription')}
        roles={[Roles.TRAINEE]}
        canRead
      />
    </Box>
  )
}
