import { Box } from '@mui/material'
import { updateDataGridState } from '@services/store/slices/dataGrid'
import { useAppDispatch } from '@services/store/store'
import { DashboardItemList, DashboardTopItemsType } from '@shared/components/cards/DashboardItemList'
import { StatusChip } from '@shared/components/ui/StatusChip'
import { FilterOperators, OrderDirection, QuestionsOrderField, ReviewStatus, useQuestionsCountQuery, useQuestionsQuery } from '@typings/graphql'
import React from 'react'
import { useNavigate } from 'react-router'

type Props = {
  title: string
  reviewStatus: ReviewStatus
  onLoaded?: () => void
}

export const DashboardQuestionsStatus: React.FC<Props> = ({ title, reviewStatus, onLoaded }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data: questionCount, loading: questionCountLoading } = useQuestionsCountQuery({
    variables: {
      filter: [{
        field: 'reviewStatus',
        operator: FilterOperators.Equals,
        value: [reviewStatus]
      }]
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: questionData, loading } = useQuestionsQuery({
    variables: {
      data: {
        count: 10,
        filter: [{
          field: 'reviewStatus',
          operator: FilterOperators.Equals,
          value: [reviewStatus]
        }],
        orderBy: [{
          field: QuestionsOrderField.UpdatedAt,
          direction: OrderDirection.Asc
        }]
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const mappedData = React.useMemo<DashboardTopItemsType[]>(() => {
    return questionData?.questions.map((question) => ({
      id: question.id,
      key: question.questionKey,
      name: question.texts?.text,
      extension: <StatusChip value={question.reviewStatus} />
    })
    ) || []
  }, [questionData])

  React.useEffect(() => {
    if ((!loading && !questionCountLoading) && onLoaded) {
      onLoaded()
    }
  }, [loading, questionCountLoading])

  const handleNavigate = (id: string) => {
    const question = questionData?.questions.find((q) => q.id === id)

    if (question) {
      navigate(`/professions/${question.profession.id}/${question.category.parentId}/${question.category.id}/${question.id}`)
    }
  }

  const handleShowAll = () => {
    dispatch(updateDataGridState({
      key: 'questionList',
      value: {
        filter: {
          filterModel: {
            items: [{
              field: 'reviewStatus',
              operator: FilterOperators.Equals,
              value: [reviewStatus]
            }]
          }
        }
      }
    }))

    navigate('/question-list')
  }

  return (
    <DashboardItemList
      title={title}
      {...((questionCount && questionCount.questionsCount > 0) && {
        titleExtension: (
          <Box sx={{
            border: '2px solid',
            backgroundColor: 'warning.200',
            color: 'warning.600',
            borderColor: 'warning.200',
            borderRadius: '8px',
            px: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            {questionCount?.questionsCount}
          </Box>
        )
      })}
      data={mappedData}
      onItemClicked={handleNavigate}
      onShowAll={handleShowAll}
    />
  )
}
