import { Box, Grid, LinearProgress } from '@mui/material'
import { ReviewStatus } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DashboardQuestionsStatus } from '@features/dashboard/components/DashboardQuestionsStatus'

export const EditorDashboardContent: React.FC = () => {
  const { t } = useTranslation()

  const [reviewLoading, setReviewLoading] = React.useState(true)
  const [rejectLoading, setRejectLoading] = React.useState(true)

  const loading = React.useMemo(() => {
    return reviewLoading || rejectLoading
  }, [reviewLoading, rejectLoading])

  return <Box sx={{
    position: 'relative'
  }}
  >
    {loading && <LinearProgress sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    }}
    />}
    <Box py={4.5}
      sx={{
        opacity: loading ? 0 : 1
      }}
    >
      <Box py={4.5}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DashboardQuestionsStatus
              title={t('dashboard.newestQuestionsReview')}
              onLoaded={() => setReviewLoading(false)}
              reviewStatus={ReviewStatus.Review}
            />
          </Grid>
          <Grid item xs={6}>
            <DashboardQuestionsStatus
              title={t('dashboard.newestQuestionsRejected')}
              onLoaded={() => setRejectLoading(false)}
              reviewStatus={ReviewStatus.Review}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Box>
}
