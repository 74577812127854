import { Box, Divider } from '@mui/material'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseMultiFileInput } from '@shared/components/inputs/BaseMultiFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'

type Props = React.PropsWithChildren & {
  loading?: boolean
}

export const QuestionGroupsCreateEditInputs: React.FC<Props> = ({ loading }) => (
  <Box sx={{
    pt: 4.5
  }}
  >
    <BaseTextInput
      formKey='texts.title'
      options={{ required: true }}
      title={t('common.title')}
    />

    <Divider
      sx={{
        mb: 3.5,
        mt: 1.5
      }}
      light
      orientation='horizontal'
    />

    <BaseMarkDownEditor
      formKey='texts.text'
      options={{ required: true }}
      title={t('questionGroups.explanation')}
    />

    <Divider
      sx={{
        my: 3.5
      }}
      light
      orientation='horizontal'
    />

    <BaseMultiFileInput
      formKey="attachments"
      fileKey="file"
      title={t('common.attachments')}
      description={t('common.attachmentsDescription')}
      acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg', '.png', '.webp'], 'application/pdf': ['.pdf'], 'video/*': ['.mp4'] }}
      maxFileSize={10485760}
      loading={loading}
    />
  </Box>
)
