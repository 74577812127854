import { nameColumnModel } from '@shared/dataGrid/columns/nameColumn'
import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'
import { activeColumnModel } from '@shared/dataGrid/columns/activeColumn'

import { TrainingCompaniesColumnType, TrainingCompaniesRowModel } from '../provider/TrainingCompaniesProvider'

import { addressColumnModel } from './columns/addressColumn'
import { zipColumnModel } from './columns/zipColumn'
import { cityColumnModel } from './columns/cityColumn'
import { contactsColumnModel } from './columns/contactsColumn'

export type TrainingCompanyColumnField = keyof TrainingCompaniesRowModel

export const trainingCompaniesDataGridColumns: TrainingCompaniesColumnType[] = [
  nameColumnModel as TrainingCompaniesColumnType,
  addressColumnModel,
  zipColumnModel,
  cityColumnModel,
  contactsColumnModel,
  activeColumnModel as TrainingCompaniesColumnType,
  createdColumnModel as TrainingCompaniesColumnType
]
