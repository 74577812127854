import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiTextFieldType = {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
}

export const MuiTextField:MuiTextFieldType = {
  defaultProps: {
    variant: 'outlined'
  },
  styleOverrides: {
    root: {
      padding: 0,
      '.MuiOutlinedInput-root': {
      }
    }
  }
}
