import { QueryHookOptions } from '@apollo/client'
import { GridPaginationModel, GridSortModel, GridFilterModel, GridInitialState } from '@mui/x-data-grid-pro'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import { OrderDirection } from '@typings/graphql'
import { parseStringToBoolean } from '@utils/parse'
import React from 'react'

type UseDataGridPagination<T> = {
  fetchVariablesRef: React.MutableRefObject<QueryHookOptions<T>['variables']>
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  setPaginationModelState: (pagination: GridPaginationModel, startCursor?: string | null, endCursor?: string | null) => void
  setSortModelState: (model: GridSortModel) => void
  setFilterModelState: (filter: GridFilterModel | undefined) => void
  setInitialState: (initialState?: GridInitialState) => void
}

export function useDataGridPagination<T> (): UseDataGridPagination<T> {
  const fetchVariablesRef = React.useRef<QueryHookOptions<T>['variables']>({
    first: DATA_GRID_PAGE_SIZE
  })

  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GRID_PAGE_SIZE
  })

  const [sortModel, setSortModel] = React.useState<GridSortModel[0] | undefined>()

  const [filterModel, setFilterModel] = React.useState<GridFilterModel | undefined>()

  const setPaginationModelState = React.useCallback((pagination: GridPaginationModel,
    startCursor?: string | null,
    endCursor?: string | null,
    initial?: boolean) => {
    setPaginationModel((prev) => {
      const directionForward = pagination.page >= prev.page

      const pageSizeChanged = pagination.pageSize !== prev.pageSize
      const currentPage = pageSizeChanged && !initial
        ? Math.floor(prev.page * prev.pageSize / pagination.pageSize)
        : pagination.page

      fetchVariablesRef.current = {
        ...fetchVariablesRef.current,
        ...(directionForward
          ? {
              first: pagination.pageSize,
              last: undefined,
              skip: pageSizeChanged ? currentPage * pagination.pageSize : 1,
              after: pageSizeChanged ? undefined : endCursor,
              before: undefined
            }
          : {
              first: undefined,
              last: pagination.pageSize,
              skip: 1,
              after: undefined,
              before: startCursor
            })
      }

      return {
        page: currentPage,
        pageSize: pagination.pageSize
      }
    })
  }, [])

  const setSortModelState = React.useCallback((model: GridSortModel, ignorePagination?: boolean) => {
    const sorting = model?.[0]?.field ? model[0] : undefined

    setSortModel(sorting)

    fetchVariablesRef.current = {
      ...fetchVariablesRef.current,
      first: paginationModel.pageSize,
      last: undefined,
      skip: undefined,
      after: undefined,
      before: undefined,
      orderBy: sorting
        ? {
            field: sorting.field,
            direction: sorting.sort as OrderDirection
          }
        : null
    }

    if (!ignorePagination) {
      setPaginationModel((prev) => ({
        page: 0,
        pageSize: prev.pageSize
      }))
    }
  }, [paginationModel])

  const setFilterModelState = React.useCallback((filter: GridFilterModel | undefined, ignorePagination?: boolean) => {
    setFilterModel(filter)

    fetchVariablesRef.current = {
      ...fetchVariablesRef.current,
      first: paginationModel.pageSize,
      last: undefined,
      skip: undefined,
      after: undefined,
      before: undefined,
      filter: filter?.items?.filter(({ value }) => value).map((item) => {
        return {
          field: item.field,
          value: typeof item.value === 'string' ? parseStringToBoolean(item.value) : item.value,
          operator: item.operator
        }
      }) || []
    }

    if (!ignorePagination) {
      setPaginationModel((prev) => ({
        page: 0,
        pageSize: prev.pageSize
      }))
    }
  }, [paginationModel])

  const setInitialState = React.useCallback((initialState?: GridInitialState) => {
    if (initialState?.filter?.filterModel) {
      setFilterModelState(initialState.filter?.filterModel, true)
    }
    if (initialState?.sorting?.sortModel) {
      setSortModelState(initialState.sorting?.sortModel, true)
    }
    if (initialState?.pagination?.paginationModel) {
      setPaginationModelState(initialState.pagination.paginationModel as GridPaginationModel, null, null, true)

      fetchVariablesRef.current = {
        ...fetchVariablesRef.current,
        first: initialState?.pagination?.paginationModel?.pageSize || DATA_GRID_PAGE_SIZE,
        skip: (initialState?.pagination?.paginationModel?.page || 0) * (initialState?.pagination?.paginationModel?.pageSize || DATA_GRID_PAGE_SIZE)
      }
    }
  }, [])

  return {
    fetchVariablesRef,
    paginationModel,
    sortModel,
    filterModel,
    setPaginationModelState,
    setSortModelState,
    setFilterModelState,
    setInitialState
  }
}
