import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = {
  text: string
  required?: boolean
}

export const InputTitleField: React.FC<Props> = ({ required, text }) => (
  <Box position="relative"
    display="inline"
  >
    {required && <Typography
      variant="subtitle1"
      color="error.main"
      sx={{
        position: 'absolute',
        top: '-4px',
        right: '-12px'
      }}
    >*</Typography>}
    <Typography
      sx={{
        display: 'inline'
      }}
      variant="subtitle1"
      fontWeight={600}
    >{text}</Typography>
  </Box>
)
