import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { RELEVANT_NUMBER_OPERATORS } from '@shared/constants/dataGrid'

import { QuestionsColumnType } from '../questionsDataGridColumns'

export const keyColumnModel: QuestionsColumnType = {
  field: 'key',
  width: 100,
  filterOperators: getGridNumericOperators().filter((operator) =>
    Object.keys(RELEVANT_NUMBER_OPERATORS).includes(operator.value)).map((operator) => ({
    ...operator,
    InputComponentProps: {
      inputProps: {
        type: 'text'
      }
    },
    value: RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS],
    label: t(`filterOperator.${RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS]}`)
  })),
  valueGetter: (params) => params.row.questionKey
}
