import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiTooltipType = {
  defaultProps?: ComponentsProps['MuiTooltip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
  variants?: ComponentsVariants['MuiTooltip'];
}

export const MuiTooltip:MuiTooltipType = {
  defaultProps: {
    followCursor: true,
    arrow: true
  },
  styleOverrides: {
  }
}
