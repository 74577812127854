import { Box, Button } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { CompanyDataGrid } from '../dataGrid/CompaniesDataGrid'
import { CompaniesProvider, useCompaniesContext } from '../provider/CompaniesProvider'

const CompaniesViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { selectedIds, handleDelete } = useCompaniesContext()

  return (
    <DefaultLayout
      title={t('routes.companies')}
      description={t('companies.companyListDescription')}
      headerExtension={
        <Box>
          {can('companies:delete') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can('companies:create') && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('companies.addCompany')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <CompanyDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const CompaniesView: React.FC = () => (
  <CompaniesProvider>
    <CompaniesViewContent />
  </CompaniesProvider>
)
