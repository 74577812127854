import { Box, Divider, LinearProgress, Typography, useTheme } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  title: string
  description?: string | React.ReactNode
  buttonComp: React.ReactNode,
  headerExtension?: React.ReactNode,
  tabBar?: React.ReactNode,
  loading?: boolean,
  errorMessage?: string
};

export const CreateEditLayout: React.FC<Props> = ({ title, description, loading, children, headerExtension, tabBar, errorMessage, buttonComp }) => {
  const { breakpoints } = useTheme()
  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <Box sx={{
        flex: 1,
        pt: 4.5,
        [breakpoints.down('md')]: {
          pt: 2
        },
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        >
          <Box sx={{
            flex: '0 1 auto',
            px: 4.5,
            [breakpoints.down('md')]: {
              px: 2
            }
          }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
            >
              <Box>
                <Typography variant="h1"
                  fontWeight={600}
                  mb={1}
                >
                  {title}
                </Typography>
                {description &&
                <Typography mt={0.5}>
                  {description}
                </Typography>
              }
                {tabBar}
              </Box>
              <Box>
                {headerExtension}
              </Box>
            </Box>

            <Divider
              light
              orientation='horizontal'
              sx={{
                mt: 3.5,
                [breakpoints.down('md')]: {
                  mt: 1
                },
                borderColor: 'grey.300'
              }}
            />
          </Box>
          <Box sx={{
            flex: '1 1 auto',
            overflow: 'auto',
            p: 4.5,
            [breakpoints.down('md')]: {
              p: 2
            },
            pt: 0
          }}
          >
            {loading
              ? (
                <LinearProgress />
                )
              : (
                  children
                )
          }
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid',
          borderColor: 'grey.300',
          p: 2,
          flex: '0 1 auto'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            {errorMessage && (
            <>
              <themeIcons.Error sx={{
                color: 'error.main',
                mr: 1
              }}
              />
              <Typography variant="body2" color="error">{errorMessage}</Typography>
            </>
            )}
          </Box>
          {buttonComp}
        </Box>
      </Box>
    </Box>
  )
}
