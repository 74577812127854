import React from 'react'
import { Button, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { CompaniesRowModel } from '@features/companies/provider/CompaniesProvider'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { CompanyColumnType } from '../companiesDataGridColumns'

const RenderCell: React.FC<{row: CompaniesRowModel}> = ({ row }) => {
  const navigate = useNewTabHook()

  return (
    <Button variant="text"
      sx={{
        '&.MuiButton-root': {
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: 'flex-start'
        }
      }}
      onClick={() => navigate(`${row.id}`)}
    >
      <Typography
        variant="subtitle1"
        fontWeight={500}
      >{row.name}</Typography>
    </Button>
  )
}

export const nameColumnModel: CompanyColumnType = {
  field: 'name',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => (<RenderCell row={params.row} />)

}
