import { Divider, Box } from '@mui/material'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { UserSearch } from '@shared/components/inputs/UserSearch'
import { Roles } from '@typings/roles'
import { postalPattern } from '@utils/pattern'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  loading?: boolean
}

export const TrainingCompaniesCreateEditInputs: React.FC<Props> = ({ loading }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseSingleFileInput
        formKey="files.logo"
        fileKey="logo"
        title={t('common.logo')}
        description={t('trainingCompanies.logoDescription')}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='name'
        options={{ required: true }}
        title={t('common.name')}
      />
      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='address'
        options={{ required: true }}
        title={t('common.address')}
      />
      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='postalCode'
        options={{ required: true, pattern: postalPattern }}
        title={t('common.postalCode')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='city'
        options={{ required: true }}
        title={t('common.location')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseMarkDownEditor
        formKey='texts.description'
        title={t('common.description')}
        max={1000}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      {can('training-companies:write') &&
        <UserSearch
          description={t('trainingCompanies.usersDescription')}
          roles={[Roles.INSTRUCTOR, Roles.TRAINING_COMPANY_MANAGER]}
        />
      }
    </Box>
  )
}
