import React from 'react'
import { Box } from '@mui/material'

import { PreviewContentProps } from './modalPreviewContent'

export const ModalPreviewContentImage: React.FC<PreviewContentProps> = ({ src, type }) => {
  const isSvg = React.useMemo(() => {
    return type?.includes('svg')
  }, [type])

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      padding: isSvg ? 4 : 0,
      overflow: 'auto'
    }}
    >
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
      }}
      >
        <img src={src}
          style={{
            display: 'block',
            width: isSvg ? '50%' : '100%',
            height: 'auto'
          }}
        />
      </Box>
    </Box>
  )
}
