import React from 'react'
import { Typography } from '@mui/material'
import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { TopicRowModel } from '@features/topics/provider/TopicsProvider'
import { useTranslation } from 'react-i18next'
import { RELEVANT_NUMBER_OPERATORS } from '@shared/constants/dataGrid'
import { t as trans } from 'i18next'

import { TopicsColumnType } from '../topicsDataGridColumns'

const RenderCell: React.FC<{row: TopicRowModel}> = ({ row }) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant="subtitle1"
      fontWeight={500}
    >{t('common.trainingYearWithNumber', { year: row.trainingYear })}</Typography>
  )
}

export const trainingYearColumnModel: TopicsColumnType = {
  field: 'trainingYear',
  width: 125,
  filterOperators: getGridNumericOperators().filter((operator) =>
    Object.keys(RELEVANT_NUMBER_OPERATORS).includes(operator.value)).map((operator) => ({
    ...operator,
    type: 'number',
    label: trans(`filterOperator.${RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS]}`)
  })),
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter (params) {
    return params.row.trainingYear
  },
  renderCell: (params) => <RenderCell row={params.row} />
}
