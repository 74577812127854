import { Box, Divider } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'
import { SortableList } from '@shared/components/sortable/SortableList'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const QuestionSortableInput: React.FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues } = useFormContext()

  const [answers, setAnswers] = React.useState<number[]>([])

  const getAnswerKeys = () => {
    const texts = getValues('texts')

    const answerTextIds = Object.keys(texts).filter((key) => key.startsWith('answer_')).map((key) => parseInt(key.split('_')[1], 10))

    return answerTextIds.sort((a, b) => a - b)
  }

  React.useEffect(() => {
    const solution = getValues('config.solution') as number[]
    const sortedAnswers = getAnswerKeys()

    setAnswers(solution ? solution.map((answer) => sortedAnswers[answer]) : [])
  }, [])

  React.useEffect(() => {
    const sortedAnswers = getAnswerKeys()

    setValue('config.solution', answers.map((answer) => sortedAnswers.indexOf(answer)), {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [answers])

  const removeText = (key: string) => {
    const texts = getValues('texts')

    delete texts[key]

    setValue('texts', texts, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleOnAddAnswer = () => {
    setAnswers([...answers, answers.length ? Math.max(...answers) + 1 : 0])
  }

  const handleRemoveAnswer = (answer: string) => {
    setAnswers(answers.filter((a) => a !== parseInt(answer.split('_')[1], 10)))

    removeText(answer)
  }

  const reorderAnswers = (order: {id: string}[]) => {
    setAnswers(order.map(({ id }) => parseInt(id.split('_')[1], 10)))
  }

  return (
    <>
      <BaseInputRow
        title={t('questions.answers')}
        required
        description={t('questions.answerDnDDescription')}
      >
        <SortableList
          items={answers.map((answer) => ({ id: `answer_${answer}` }))}
          onDragEnd={reorderAnswers}
          id="sortable_answers"
          grabber
          renderItem={(item, activeId, index, level, clone) => (
            <Box
              key={`sort_answer_${item.id}`}
              sx={{
                mb: 2,
                opacity: (activeId === item.id && !clone) ? 0 : 1
              }}
            >
              <DefaultAnswerRow
                grabber={(
                  <SortableGrabber id={item.id}>
                    <themeIcons.Grab />
                  </SortableGrabber>
                )}
                onRemove={() => handleRemoveAnswer(item.id)}
              >
                <TextInput
                  formKey={`texts.${item.id}`}
                  hideValidationText
                  options={{
                    required: true
                  }}
                  sx={{
                    backgroundColor: 'white'
                  }}
                />
              </DefaultAnswerRow>
            </Box>
          )
          }
        />

        {
          <AddDefaultRow
            label={t('questions.addAnswer')}
            onAdd={handleOnAddAnswer}
          />
          }
      </BaseInputRow>

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

    </>
  )
}
