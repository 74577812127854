import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parseISO } from 'date-fns'

import { InputTitleField } from '../ui/InputRequiredIndicator'

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  formatter?: (value: Date) => string
}

export const BaseDateInput: React.FC<Props> = ({ formKey, options, title, formatter, description }) => {
  const { control } = useFormContext()

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <Controller
          name={formKey}
          control={control}
          rules={options}
          render={({ field: { onChange, value } }) =>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <DatePicker
                sx={{
                  width: '100%'
                }}
                onChange={(v) => {
                  onChange(formatter ? formatter(v) : v)
                }}
                value={typeof value === 'string' ? parseISO(value) : (value || new Date())}
                format="dd.MM.yyyy"
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
              />
            </LocalizationProvider>
          }
        />
      </Grid>
    </Grid>

  )
}
