import { Box } from '@mui/material'
import {
  QuestionType,
  ReviewStatus,
  UpdateQuestionInput,
  useQuestionQuery,
  useUpdateQuestionMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import i18n from '@services/i18n/i18n'
import { EditableFile } from '@typings/files'
import { getFileByKey, getFilesWithPrefix } from '@utils/files'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useFileUpload } from '@hooks/useFileUpload'
import { firstLetterToLowerCase } from '@utils/string'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { ReviewStatusSelectInput } from '@shared/components/inputs/ReviewStatusSelectInput'

import { QuestionsProvider, useQuestionsContext } from '../provider/QuestionsProvider'
import { QuestionsCreateEditInputs } from '../components/QuestionsCreateEditInputs'

type QuestionInputType = Omit<UpdateQuestionInput, 'texts'> & {
  files: Record<string, EditableFile<any> | null>
  attachments: Record<string, EditableFile<any> | null>
  texts: Record<string, string>
  questionGroupIdOrder: string[]
  type: QuestionType,
  categoryId: string,
  subCategoryId: string,
  professionId: string,
}

const QuestionsEditViewContent: React.FC = () => {
  const location = useLocation()

  const { questionId, professionId } = useParams<{ questionId: string, professionId: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()
  const { handleDelete, updateSelectedItem, updateProfessionId } = useQuestionsContext()

  const isTopics = React.useMemo(() => location.pathname.includes('topics'), [location])

  const { formState: { isValid, isSubmitted, dirtyFields }, handleSubmit, reset } = useFormContext<QuestionInputType>()

  useUnsavedChanges(Object.keys(dirtyFields)?.length > 0 && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateQuestion] = useUpdateQuestionMutation()

  const { data, loading: questionLoading } = useQuestionQuery({
    variables: {
      id: questionId as string
    },
    skip: !questionId
  })

  const { uploadAndDeleteFiles } = useFileUpload()

  React.useEffect(() => {
    if (data?.question) {
      reset({
        type: data.question.type,
        texts: data.question.texts,
        config: data.question.config,
        inDuelAvailable: data.question.inDuelAvailable,
        topicId: data.question.topicId,
        categoryId: data.question.category.parentId || '',
        subCategoryId: data.question.category.id || '',
        questionGroupId: data.question.questionGroupId || null,
        questionGroupOrder: (data.question.questionGroupOrder ?? -1) >= 0 ? data.question.questionGroupOrder : null,
        reviewStatus: data.question.reviewStatus || ReviewStatus.Draft,
        files: {
          explanation: getFileByKey('explanation', data.question.files),
          video: getFileByKey('video', data.question.files)
        },
        attachments: getFilesWithPrefix('file_', data.question.files)
      })

      if (!professionId) {
        updateProfessionId?.(data.question.profession.id)
      }
    }
  }, [data])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateQuestion({
        variables: {
          id: questionId as string,
          data: {
            config: input.config,
            questionGroupId: input.questionGroupId,
            questionGroupOrder: input.questionGroupId ? input.questionGroupOrder : null,
            topicId: !isTopics ? input.topicId : data?.question.topicId,
            inDuelAvailable: input.inDuelAvailable,
            categoryId: isTopics ? input.subCategoryId : (data?.question.category.id || ''),
            reviewStatus: input.reviewStatus,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          },
          removedTexts: Object.keys(data?.question?.texts || {}).filter((key) => !Object.keys(input.texts).includes(key))
        }
      })

      await uploadAndDeleteFiles(
        {
          ...input.files,
          ...input.attachments
        },
        'Question',
        questionId,
        data?.question.files
      )

      setShowToast(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setShowToast(true)
      setMessageByCode(e, 'questions')
    }
  })

  const deleteCallback = () => {
    setDeleted(true)
    navigate(location.pathname.replace(`/${data?.question.id}`, ''))
  }

  const onDelete = async () => {
    if (!data?.question) {
      return
    }

    updateSelectedItem(data.question)
    handleDelete(false, deleteCallback)
  }

  return (
    <CreateEditLayout
      title={t('questions.editTitle', {
        type: data?.question?.type
          ? `(${t(`questions.types.${firstLetterToLowerCase(data?.question?.type || '')}`)})`
          : '',
        key: data?.question?.questionKey
      })}
      description={
        <Trans
          i18nKey={'questions.editDescription'}
          values={{ name: data?.question?.texts.text }}
          components={{ b: <strong /> }}
        />
      }
      loading={questionLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <Box mr={2}>
            <ReviewStatusSelectInput formKey="reviewStatus" />
          </Box>
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!(Object.keys(dirtyFields)?.length > 0) || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <QuestionsCreateEditInputs loading={loading}/>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('questions.entryEdited', { name: data?.question?.texts.title })}
      />
    </CreateEditLayout>
  )
}

export const QuestionsEditView:React.FC = () => {
  const formData = useForm<QuestionInputType>({
    defaultValues: {
      questionGroupId: null,
      questionGroupOrder: null
    },
    mode: 'onChange'
  })

  return (
    <QuestionsProvider disableFetching>
      <FormProvider {...formData}>
        <QuestionsEditViewContent />
      </FormProvider>
    </QuestionsProvider>
  )
}
