import { Box, IconButton, Tooltip } from '@mui/material'
import { PASSWORD_SYMBOLS, PASSWORD_MIN_LENGTH } from '@shared/constants/password'
import { themeIcons } from '@theme/icons'
import { passwordPattern } from '@utils/pattern'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TextInput } from '../inputs/TextInput'

type Props = {
  formKey: string
  formKeyConfirm: string
  formKeyOld?: string
  label?: string
  labelConfirm?: string
  labelOld?: string
}

export const PasswordInput: React.FC<Props> = ({ formKey, formKeyConfirm, labelOld, formKeyOld, label, labelConfirm }) => {
  const { t } = useTranslation()
  const [passwordVisible, setPasswordVisible] = React.useState(false)

  const { control, watch } = useFormContext()

  const watchedPassword = useWatch({
    control,
    name: formKey
  })

  return (
    <>
      {formKeyOld &&
        <Box mb={1}>
          <TextInput formKey={formKeyOld}
            label={labelOld || t('auth.oldPassword')}
            type={passwordVisible ? 'text' : 'password'}
          />
        </Box>
      }
      <Box mb={1}>
        <TextInput
          label={label || t('auth.password') as string}
          formKey={formKey}
          fullWidth
          options={{
            required: true,
            validate: (value) => passwordPattern(value.trim()) && (!formKeyOld || watch(formKeyOld).trim() !== value.trim())
          }}
          type={passwordVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (<Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title={t('errors.passwordRequirements', {
                chars: PASSWORD_SYMBOLS,
                length: PASSWORD_MIN_LENGTH
              })}
                sx={{
                  cursor: 'pointer',
                  mr: 1
                }}
              >
                <IconButton
                  sx={{
                    p: 0,
                    mx: 1,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <themeIcons.Info />
                </IconButton>
              </Tooltip>
              <IconButton
                sx={{
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <themeIcons.VisibilityOff /> : <themeIcons.Visibility />}
              </IconButton>
            </Box>)
          }}
          validationText={!passwordPattern(watchedPassword)
            ? t('errors.passwordDoesNotMatchRequirements')
            : (formKeyOld && watch(formKeyOld).trim() === watchedPassword.trim()) ? t('errors.passwordMustBeDifferent') : undefined}
        />
      </Box>
      <Box mb={1}>
        <TextInput
          label={labelConfirm || t('auth.passwordConfirmation') as string}
          formKey={formKeyConfirm}
          fullWidth
          options={{ required: true, validate: (value) => value.trim() === watchedPassword }}
          validationText={t('errors.invalidPasswordMatch')}
          type={passwordVisible ? 'text' : 'password'}
        />
      </Box>
    </>
  )
}
