import React from 'react'
import { Button, Tooltip, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { TraineesRowModel } from '@features/trainees/provider/TraineesProvider'
import { useNavigate } from 'react-router'

import { TraineeColumnType } from '../traineesDataGridColumns'

const RenderCell: React.FC<{row: TraineesRowModel }> = ({ row }) => {
  const navigate = useNavigate()

  return (
    <Tooltip title={row.email}>
      <Button
        variant="text"
        sx={{
          '&.MuiButton-root': {
            paddingLeft: 0,
            paddingRight: 0,
            justifyContent: 'flex-start'
          }
        }}
        onClick={() => navigate(`${row.id}`)}
      >
        <Typography
          variant="subtitle1"
          fontWeight={500}
        >{row?.email}</Typography>
      </Button>
    </Tooltip>
  )
}

export const emailColumnModel: TraineeColumnType = {
  field: 'email',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => <RenderCell row={params.row}/>
}
