import { LoadingButton } from '@mui/lab'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateQuestionInput, ReviewStatus, useCreateQuestionMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useFileUpload } from '@hooks/useFileUpload'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { EditableFile } from '@typings/files'
import i18n from '@services/i18n/i18n'
import { firstLetterToLowerCase } from '@utils/string'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { ReviewStatusSelectInput } from '@shared/components/inputs/ReviewStatusSelectInput'

import { QuestionsProvider } from '../provider/QuestionsProvider'
import { QuestionsCreateEditInputs } from '../components/QuestionsCreateEditInputs'
import { QuestionsTypeDialog } from '../components/QuestionTypeDialog'

type QuestionInputType = Omit<CreateQuestionInput, 'texts'> & {
  texts: Record<string, string>
  files: Record<string, EditableFile<any> | null>
  attachments: Record<string, EditableFile<any> | null>,
  professionId: string,
}

const QuestionsCreateViewContent: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { subCategoryId, topicId } = useParams<{ subCategoryId: string, topicId: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [showTypeDialog, setShowTypeDialog] = React.useState(true)
  const [created, setCreated] = React.useState(false)

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const navigate = useNavigate()

  const { control, formState: { dirtyFields, isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const watchedQuestionType = useWatch({
    name: 'type',
    control
  })
  const [createQuestion] = useCreateQuestionMutation()

  const { uploadAndDeleteFiles } = useFileUpload()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      const result = await createQuestion({
        variables: {
          data: {
            categoryId: subCategoryId as string || input.subCategoryId,
            type: input.type,
            inDuelAvailable: input.inDuelAvailable,
            config: input.config,
            questionGroupId: input.questionGroupId || null,
            questionGroupOrder: input.questionGroupOrder || null,
            topicId: topicId || input.topicId,
            reviewStatus: input.reviewStatus,
            texts: Object.keys(input.texts).map((key) => ({
              key,
              lang: i18n.resolvedLanguage,
              value: input.texts[key]
            }))
          }
        }
      })

      await uploadAndDeleteFiles(
        {
          ...input.files,
          ...input.attachments ?? {}
        },
        'Question',
        result.data?.createQuestion.id
      )

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleRedirect = React.useCallback(() => {
    navigate(location.pathname.replace('/create', ''))
  }, [])

  return (
    <CreateEditLayout
      title={t('questions.createTitle', {
        type: watchedQuestionType
          ? `(${t(`questions.types.${firstLetterToLowerCase(watchedQuestionType || '')}`)})`
          : ''
      })}
      description={t('questions.createDescription')}
      headerExtension={(watchedQuestionType && !showTypeDialog) && (
        <ReviewStatusSelectInput formKey="reviewStatus" />
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!dirtyFields || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <QuestionsTypeDialog open={showTypeDialog} onClose={() => setShowTypeDialog(false)} />

      {(watchedQuestionType && !showTypeDialog) && <QuestionsCreateEditInputs loading={loading} />}

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleRedirect}
        onCancel={handleRedirect}
      >
        {t('questions.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const QuestionsCreateView: React.FC = () => {
  const formData = useForm<QuestionInputType>({
    defaultValues: {
      inDuelAvailable: false,
      reviewStatus: ReviewStatus.Draft,
      questionGroupId: '',
      questionGroupOrder: null,
      files: {}
    },
    mode: 'onChange'
  })

  return (
    <QuestionsProvider disableFetching>
      <FormProvider {...formData}>
        <QuestionsCreateViewContent />
      </FormProvider>
    </QuestionsProvider>
  )
}
