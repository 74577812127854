import { Grid, Typography } from '@mui/material'
import React from 'react'
import { RegisterOptions } from 'react-hook-form'

import { InputTitleField } from '../ui/InputRequiredIndicator'
import { MarkdownEditor } from '../wysiwyg/MarkdownEditor'

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  max?: number,
  height?: number,
  previewLabel?: boolean
}

export const BaseMarkDownEditor: React.FC<Props> = ({ formKey, options, title, height, max, description, previewLabel }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={3} xl={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <MarkdownEditor
          {...(height && { height })}
          name={formKey}
          options={options}
          max={max}
          previewLabel={previewLabel}
        ></MarkdownEditor>
      </Grid>
    </Grid>

  )
}
