import { GridColDef } from '@mui/x-data-grid-pro'
import { firstnameColumnModel } from '@shared/dataGrid/columns/firstnameColumn'
import { lastnameColumnModel } from '@shared/dataGrid/columns/lastnameColumn'

import { TraineesRowModel } from '../provider/TraineesProvider'

import { instructorModel } from './column/instructorColumn'
import { examDateColumnModel } from './column/examDateColumn'
import { confirmedColumnModel } from './column/confirmedColumn'
import { emailColumnModel } from './column/emailColumn'
import { professionColumnModel } from './column/professionColumn'
import { trainingYearColumnModel } from './column/trainingYearColumn'

export type TraineesField = keyof TraineesRowModel

export type TraineeColumnType = Omit<GridColDef, 'field'> & {
  field: TraineesField,
  hideHeader?: boolean,
}

export const traineesDataGridColumns: TraineeColumnType[] = [
  emailColumnModel,
  lastnameColumnModel as TraineeColumnType,
  firstnameColumnModel as TraineeColumnType,
  professionColumnModel,
  trainingYearColumnModel,
  instructorModel,
  examDateColumnModel,
  confirmedColumnModel
]
