import { BaseColumnType } from '@shared/dataGrid/types/types'
import { clicksColumnModel } from '@shared/dataGrid/columns/clicksColumn'
import { performanceColumnModel } from '@shared/dataGrid/columns/performanceColumn'
import { GridColDef } from '@mui/x-data-grid-pro'

import { JobsRowModel } from '../provider/JobWallProvider'

import { favoriteCountColumnModel } from './columns/favoriteCountColumn'
import { jobDepartmentColumnModel } from './columns/jobDepartmentColumn'
import { expiresAtColumnModel } from './columns/expiresAtColumn'
import { statusColumnModel } from './columns/statusColumn'

export type JobColumnField = keyof JobsRowModel

export type JobColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: JobColumnField,
  hideHeader?: boolean,
}

export const jobDataGridColumns: JobColumnType[] = [
  jobDepartmentColumnModel,
  statusColumnModel,
  clicksColumnModel,
  performanceColumnModel,
  favoriteCountColumnModel,
  expiresAtColumnModel
]
