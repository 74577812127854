import { Typography, Box } from '@mui/material'
import { fontSizes } from '@theme/theme'
import { LearningProgressDiagram } from '@typings/learningProgress'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { VictoryPie } from 'victory'

type Props = {
  data: LearningProgressDiagram
  large?: boolean
}

export const TraineePie: React.FC<Props> = ({ data, large }) => {
  const { t } = useTranslation()

  const mappedData = React.useMemo(() => {
    return Object.values(data).map((value, index) => ({
      x: index + 1,
      y: value.value
    }))
  }, [data])

  const overall = React.useMemo(() => {
    const values = Object.values(data).map((item) => item.value)
    const overallProgress = values.slice(0, values.length - 1).reduce((acc, item) => acc + item, 0)

    return overallProgress > 0 && overallProgress < 100 ? overallProgress.toFixed(1) : overallProgress
  }, [data])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{
        position: 'relative'
      }}
      >
        <VictoryPie
          padAngle={2}
          innerRadius={140}
          labels={() => null}
          padding={0}
          colorScale={Object.values(data).map((item) => item.color)}
          data={mappedData}
        />

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
        >
          <Typography sx={{
            mt: -0.5,
            color: 'grey.600'
          }}
            variant={large ? 'h3' : 'subtitle1'}
          >
            {t('common.overall')}
          </Typography>

          <Typography sx={{
            mt: -1,
            fontSize: large ? fontSizes['3xl'] : fontSizes.lg,
            lineHeight: large ? '3.5rem' : 'initial',
            fontWeight: 600
          }}
          >{`${overall}%`}</Typography>
        </Box>

      </Box>
    </Box>
  )
}
