import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useRoutesContext } from '@providers/RoutesProvider'

import { NavSidebarLinkItem } from './NavSidebarLinkItem'

export const NavSidebarLinkList: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const { routes } = useRoutesContext()

  const mappedRoutes = React.useMemo(() => {
    if (!routes) {
      return []
    }

    return routes.filter((route) => route.meta?.navItem).map((filtered) => {
      return {
        name: filtered.meta?.name,
        path: filtered.path,
        label: t(`routes.${filtered.meta?.name}`),
        icon: filtered.meta?.navIcon,
        order: filtered.meta?.navOrder
      }
    }).sort((a, b) => (a.order || 0) - (b.order || 0))
  }, [routes])

  const selectedRoute = React.useMemo(() => {
    const filteredRoutes = routes?.filter((route) => route.path !== '/') || []

    return location.pathname === '/'
      ? mappedRoutes.find((route) => route.path === '/')?.path
      : filteredRoutes.find((route) => route.path && location.pathname.includes(route.path))?.path
  }, [mappedRoutes, location.pathname])

  const handleNavigate = (path?: string) => {
    if (!path) {
      return
    }

    navigate(path)
  }

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}
    >
      <Box sx={{ flex: 1 }}>
        {mappedRoutes.map((route) => (
          <Box key={route.path} mb="4px">
            <NavSidebarLinkItem
              label={route.label}
              icon={route.icon}
              onNavigate={() => handleNavigate(route.path)}
              selected={selectedRoute === route.path}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ flex: 0 }}>
        <NavSidebarLinkItem
          label={t('routes.settings')}
          icon="Settings"
          onNavigate={() => handleNavigate('/settings')}
          selected={selectedRoute === '/settings'}
        />
      </Box>
    </Box>
  )
}
