export const DATA_GRID_PAGE_SIZE = 10

export const DATA_GRID_PAGE_SIZES = [10, 20, 50, 100]

export const RELEVANT_NUMBER_OPERATORS = {
  '=': 'equals',
  '>': 'gt',
  '<': 'lt',
  '>=': 'gte',
  '<=': 'lte'
}

export const NOT_HIDABLE_COLUMNS = ['action', '__check__']
