import React from 'react'
import { Typography } from '@mui/material'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'
import { useCompaniesContext } from '@features/companies/provider/CompaniesProvider'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'

import { CompanyColumnType } from '../companiesDataGridColumns'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { categoryData } = useCompaniesContext()

  return (
    <FilterSelectInput
      {...props}
      items={categoryData ?? []}
    />
  )
}

export const categoryColumnModel: CompanyColumnType = {
  field: 'categoryId',
  width: 150,
  sortable: false,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{params.row?.category?.name}</Typography>
  )
}
