import { Typography, MenuItem } from '@mui/material'
import Select from '@mui/material/Select/Select'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { StatusColorLabel } from '@shared/components/ui/StatusColorLabel'
import { Status, ReviewStatus } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useQuestionListContext } from '../provider/QuestionListProvider'

type Props = React.PropsWithChildren & {
  open: boolean
  loading?: boolean
  reviewStatus?: ReviewStatus
  onClose: () => void
  onConfirm: (status: ReviewStatus) => void
}

export const ReviewStatusModal: React.FC<Props> = ({
  open,
  loading,
  onClose,
  onConfirm,
  children
}) => {
  const { t } = useTranslation()

  const { manySelected, selectedItem } = useQuestionListContext()

  const [status, setStatus] = React.useState<ReviewStatus | null>(null)

  React.useEffect(() => {
    if (open) {
      setStatus(manySelected ? null : selectedItem?.reviewStatus || null)
    }
  }, [open, selectedItem, manySelected])

  const handleClose = React.useCallback(() => {
    setStatus(null)
    onClose()
  }, [onClose])

  const handleConfirm = React.useCallback(() => {
    if (status) {
      onConfirm(status)
    }
  }, [status])

  return (
    <ConfirmDialog
      title={t('common.changeStatus')}
      open={open}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      loading={loading}
      sx={{
        '.MuiDialog-paper': {
          width: '30%'
        }
      }}
    >
      {children}
      <Select
        value={status || ''}
        onChange={(e) => setStatus(e.target.value as ReviewStatus)}
        displayEmpty
        fullWidth
        sx={{
          mt: 1,
          '&.MuiSelect-overwrite': {
            '&.MuiSelect-overwrite': {
              padding: '10px 12px'
            }
          }
        }}
        renderValue={(renderValue) =>
          renderValue
            ? <StatusColorLabel status={renderValue as ReviewStatus} />
            : <Typography sx={{
              color: 'grey.500'
            }}
            >{t('common.status')}</Typography>
      }
      >
        {Object.keys(ReviewStatus).map((key) => (
          <MenuItem key={key} value={key}>
            <StatusColorLabel status={key as Status} />
          </MenuItem>
        ))}
      </Select>
    </ConfirmDialog>
  )
}
