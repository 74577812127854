import { Box, Divider, IconButton, Typography } from '@mui/material'
import React from 'react'
import { themeIcons } from '@theme/theme'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { useTranslation } from 'react-i18next'
import { useLogoutMutation, useMeQuery } from '@typings/graphql'
import { resetAuthData } from '@services/store/slices/auth'
import { useNavigate } from 'react-router'
import { UserAvatar } from '@shared/components/ui/UserAvatar'
import { getFileByKey } from '@utils/files'
import { apolloClient } from '@services/apollo/apollo'

import { NavSidebarLinkList } from './NavSidebarLinkList'
import { NavSidebarLegal } from './NavSidebarLegal'

export const Sidebar: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(({ auth }) => auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logoUrl = `/${import.meta.env.REACT_APP_ID}/logo-sidebar.svg`

  const { data: meData } = useMeQuery({
    fetchPolicy: 'network-only'
  })

  const [logout] = useLogoutMutation({
    update: (cache) => {
      cache.reset()
    }
  })

  const handleLogout = async () => {
    await logout()

    await apolloClient.cache.reset()

    dispatch(resetAuthData())

    navigate('/login')
  }

  const avatar = React.useMemo(() => {
    return getFileByKey('avatar', meData?.me.files)
  }, [meData])

  return (
    <Box sx={{
      backgroundColor: 'grey.A100',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    }}
    >
      <Box sx={{
        flex: 0,
        px: 4,
        pt: 4,
        pb: 2
      }}
      >
        <Box onClick={() => navigate('/')}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img src={logoUrl}
            alt="logo"
            style={{
              width: '100%',
              maxHeight: '60px',
              color: '#fff'
            }}
          />
        </Box>
      </Box>
      <Box sx={{
        flex: 1,
        px: 2,
        display: 'flex',
        flexDirection: 'column'
      }}
      >
        {
          /**
            <Box sx={{ flex: 0, px: 2 }}>
            <NavSidebarSearch />
            </Box>
          */
        }
        <Box sx={{ flex: 1, mt: 2 }}>
          <NavSidebarLinkList />
        </Box>
      </Box>
      <Box sx={{
        flex: 0,
        p: 2,
        px: 4
      }}
      >
        <Divider sx={{
          borderColor: 'grey.700'
        }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          pt: 3
        }}
        >
          <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
            alignItems: 'center'
          }}
          >
            <Box flex={0}>
              <UserAvatar
                src={avatar?.url}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                color: 'text.secondary',
                overflow: 'hidden',
                pl: '12px'
              }}
            >
              <Typography fontWeight={600}>{t('common.fullName', { firstName: user?.firstname, lastName: user?.lastname })}</Typography>
              <Typography sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
              >{user?.email}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 0,
              pl: '12px'
            }}
          >
            <IconButton sx={{
              color: 'grey.500',
              p: 0,
              '&:hover': {
                color: 'grey.700'
              }
            }}
              disableRipple
              onClick={handleLogout}
            >
              <themeIcons.Logout />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <NavSidebarLegal />
    </Box>
  )
}
