import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'

import { QuestionGroupRowModel } from '../provider/QuestionGroupsProvider'

import { titleColumnModel } from './columns/titleColumn'
import { questionCountColumnModel } from './columns/questionCountColumn'

export type QuestionGroupsColumnField = keyof QuestionGroupRowModel

export type QuestionGroupsColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: QuestionGroupsColumnField,
  hideHeader?: boolean,
}

export const questionGroupsDataGridColumns: QuestionGroupsColumnType[] = [
  questionCountColumnModel,
  titleColumnModel
]
