import React from 'react'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { Status } from '@typings/graphql'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'
import { StatusChip } from '@shared/components/ui/StatusChip'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'
import { useTranslation } from 'react-i18next'

import { ProductsColumnType } from '../productsDataGridColumns'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { t } = useTranslation()

  return (
    <FilterSelectInput
      {...props}
      items={Object.keys(Status).map((key) => {
        return {
          id: key,
          name: t(`status.${key.toLowerCase()}`)
        }
      }) ?? []}
    />
  )
}

export const statusColumnModel: ProductsColumnType = {
  field: 'status',
  width: 75,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  renderCell: (params) => (<StatusChip value={params.value} />)
}
