import { MuiSelect } from './components/select'
import { MuiToggleButton, MuiToggleButtonGroup } from './components/toggleButton'
import { MuiCheckbox } from './components/checkbox'
import { MuiIconButton } from './components/iconButton'
import { MuiCssBaseline } from './components/baseline'
import { MuiDivider } from './components/divider'
import { MuiTextField } from './components/textField'
import { MuiFormLabel } from './components/formLabel'
import { MuiButton } from './components/button'
import { MuiDataGrid } from './components/dataGrid'
import { MuiChip } from './components/chip'
import { MuiDialog } from './components/dialog'
import { MuiInputBase } from './components/input'
import { MuiAlert } from './components/alert'
import { MuiSwitch } from './components/switch'
import { MuiPickersLayout } from './components/timePicker'
import { MuiTooltip } from './components/tooltip'
import { MuiAccordion } from './components/accordion'

export const components = {
  MuiAccordion,
  MuiAlert,
  MuiButton,
  MuiCssBaseline,
  MuiCheckbox,
  MuiChip,
  MuiDataGrid,
  MuiDialog,
  MuiDivider,
  MuiIconButton,
  MuiInputBase,
  MuiFormLabel,
  MuiSelect,
  MuiSwitch,
  MuiTextField,
  MuiTooltip,
  MuiPickersLayout,
  MuiToggleButton,
  MuiToggleButtonGroup
}
