import { LoadingButton } from '@mui/lab'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateUserInput, useCreateInstructorMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { Box } from '@mui/material'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { InstructorsCreateEditInputs } from '../components/InstructorsCreateEditInputs'

type InstructorsInput = CreateUserInput & {
  trainingCompanies: SearchInputItem[]
  users: SearchInputItem[]
}

export const InstructorsCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const navigate = useNavigate()

  const { setErrorMessage, errorMessage } = useErrorMessage()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, control } = useFormContext<InstructorsInput>()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const watchedTrainingCompanies = useWatch({
    name: 'trainingCompanies',
    control
  })

  const [createUser] = useCreateInstructorMutation()

  const onSubmit = handleSubmit(async (input) => {
    setErrorMessage('')
    setLoading(true)

    const { trainingCompanies, email, users, ...rest } = input

    try {
      await createUser({
        variables: {
          data: {
            ...rest,
            email: email.toLowerCase().trim(),
            trainingCompanyIds: trainingCompanies?.map((trainingCompany) => trainingCompany.id),
            traineeIds: users?.map((user) => user.id)
          }
        }
      })

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setErrorMessage(t((e as any).message))
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/instructors')
  }

  return (
    <CreateEditLayout
      title={t('routes.instructorsCreate')}
      description={t('instructors.createDescription')}
      headerExtension={
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box ml={2}>
            <StandaloneSwitchInput
              formKey="inviteUser"
              label={t('instructors.inviteUser')}
            />
          </Box>
        </Box>
      }
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid || !watchedTrainingCompanies?.length}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <InstructorsCreateEditInputs />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
      >
        {t('instructors.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const InstructorsCreateView:React.FC = () => {
  const formData = useForm<InstructorsInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <InstructorsCreateViewContent />
    </FormProvider>
  )
}
