import { LoadingButton } from '@mui/lab'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateQuestionGroupInput, useCreateQuestionGroupMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { EditableFile } from '@typings/files'
import i18n from '@services/i18n/i18n'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { useFileUpload } from '@hooks/useFileUpload'

import { QuestionGroupsProvider } from '../provider/QuestionGroupsProvider'
import { QuestionGroupsCreateEditInputs } from '../components/QuestionGroupsCreateEditInputs'

type QuestionGroupInputType = Omit<CreateQuestionGroupInput, 'texts'> & {
  texts: Record<string, string>
  files: Record<string, EditableFile<any> | null>
}

const QuestionGroupsCreateViewContent: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { subCategoryId } = useParams<{ subCategoryId: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const navigate = useNavigate()

  const { formState: { dirtyFields, isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const [createQuestionGroup] = useCreateQuestionGroupMutation()

  const { uploadAndDeleteFiles } = useFileUpload()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      const result = await createQuestionGroup({
        variables: {
          data: {
            categoryId: subCategoryId as string,
            texts: Object.keys(input.texts).map((key) => ({
              key,
              lang: i18n.resolvedLanguage,
              value: input.texts[key]
            }))
          }
        }
      })

      if (input.files) {
        await uploadAndDeleteFiles(
          input.files,
          'QuestionGroup',
          result?.data?.createQuestionGroup?.id as string
        )
      }

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleRedirect = React.useCallback(() => {
    navigate(location.pathname.replace('/create', ''))
  }, [])

  return (
    <CreateEditLayout
      title={t('questionGroups.createTitle')}
      description={t('questionGroups.createDescription')}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!dirtyFields || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >

      <QuestionGroupsCreateEditInputs loading={loading} />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleRedirect}
        onCancel={handleRedirect}
      >
        {t('questionGroups.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const QuestionGroupsCreateView: React.FC = () => {
  const formData = useForm<QuestionGroupInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <QuestionGroupsProvider disableFetching>
      <FormProvider {...formData}>
        <QuestionGroupsCreateViewContent />
      </FormProvider>
    </QuestionGroupsProvider>
  )
}
