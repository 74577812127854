import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { RELEVANT_NUMBER_OPERATORS } from '@shared/constants/dataGrid'
import { t } from 'i18next'

import { QuestionGroupsColumnType } from '../questionGroupsDataGridColumns'

export const questionCountColumnModel: QuestionGroupsColumnType = {
  field: 'questionCount',
  width: 100,
  filterOperators: getGridNumericOperators().filter((operator) =>
    Object.keys(RELEVANT_NUMBER_OPERATORS).includes(operator.value)).map((operator) => ({
    ...operator,
    type: 'number',
    label: t(`filterOperator.${RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS]}`)
  })),
  type: 'number',
  align: 'left',
  headerAlign: 'left'
}
