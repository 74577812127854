import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { PersonalSettings, updatePersonalSettings } from '@services/store/slices/app'
import { FormProvider, useForm } from 'react-hook-form'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { resetDataGridState } from '@services/store/slices/dataGrid'

type Props = {
  onCancel: () => void
  onSuccess: () => void
  settings: (keyof PersonalSettings)[]
}

export const SettingsChangeInput: React.FC<Props> = ({ onCancel, onSuccess, settings }) => {
  const { t } = useTranslation()
  const { personalSettings } = useAppSelector(({ app }) => app)

  const dispatch = useAppDispatch()

  const formData = useForm<PersonalSettings>({
    defaultValues: {
      newTab: !!personalSettings?.newTab,
      dataGridState: !!personalSettings?.dataGridState
    },
    mode: 'onChange'
  })
  const { reset, formState: { isDirty, isValid }, handleSubmit } = formData

  const handleOnCancel = () => {
    reset()
    onCancel()
  }

  const handleOnSubmit = handleSubmit((input) => {
    dispatch(updatePersonalSettings(input))

    if (!input.dataGridState) {
      dispatch(resetDataGridState())
    }

    onSuccess()
    handleOnCancel()
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
      <form>
        <FormProvider {...formData}>
          {settings.map((setting) => (
            <Box key={setting} mb={2}>
              <StandaloneSwitchInput

                formKey={setting}
                label={t(`settings.${setting}`)}
              />
            </Box>
          ))}
          <LoadingButton
            variant='contained'
            disabled={!isValid || !isDirty}
            onClick={handleOnSubmit}
          >
            {t('common.save')}
          </LoadingButton>
          <Button
            sx={{ ml: 2 }}
            variant="outlined"
            onClick={handleOnCancel}
          >
            {t('common.cancel')}
          </Button>
        </FormProvider>
      </form>
    </Box>
  )
}
