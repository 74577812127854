import { InMemoryCache } from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        usersPaginated: {
          keyArgs: ['id'],
          merge: false
        }
      }
    }
  }
})
