import React, { PropsWithChildren } from 'react'
import { can, checkPartnerRestriction } from '@utils/permissions'

import { DashboardTabData, DashboardTabTypes, dashboardTabs } from '../tabs/DashboardTabs'

export type DashboardProviderContextType = {
  selectedTab?: DashboardTabTypes
  updateSelectedTab: (tab: DashboardTabTypes) => void
  availableTabs: DashboardTabData[]
}

const DashboardProviderContext = React.createContext<DashboardProviderContextType>(
  {} as any
)

export const DashboardProvider:React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedTab, setSelectedTab] = React.useState<DashboardTabTypes>()

  const availableTabs = React.useMemo(() => {
    return dashboardTabs.filter((tab) => {
      const hasPermission = !tab.permission || can(tab.permission)
      const hasNoRestriction = !tab.partnerRestriction || checkPartnerRestriction()

      return hasPermission && hasNoRestriction
    })
  }, [])

  React.useEffect(() => {
    if (!selectedTab && availableTabs.length) {
      setSelectedTab(availableTabs[0].key)
    }
  }, [availableTabs, selectedTab])

  const updateSelectedTab = React.useCallback((tab: DashboardTabTypes) => {
    setSelectedTab(tab)
  }, [])

  const value = React.useMemo<DashboardProviderContextType>(() => ({
    selectedTab,
    availableTabs,
    updateSelectedTab
  }), [selectedTab, availableTabs])

  return (
    <DashboardProviderContext.Provider value={value}>
      {children}
    </DashboardProviderContext.Provider>
  )
}

export const useDashboardContext = () => React.useContext(DashboardProviderContext)
