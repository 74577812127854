import { LoadingButton } from '@mui/lab'
import i18n from '@services/i18n/i18n'
// import { StatusSelectInput } from '@shared/components/inputs/StatusSelectInput'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import {
  CreateJobInput,
  useCreateJobMutation,
  useCompaniesNameOnlyQuery,
  useJobDepartmentsQuery,
  Status,
  useJobEmploymentTypesQuery
} from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { StatusSelectInput } from '@shared/components/inputs/StatusSelectInput'
import { useFileUpload } from '@hooks/useFileUpload'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { JobWallCreateEditInputs } from '../components/JobWallCreateEditInputs'
import { JobWallProvider, useJobsContext } from '../provider/JobWallProvider'

type JobsInputType = Omit<CreateJobInput, 'texts'> & {
  texts: Record<string, string>
  status: Status
}

const JobWallCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const { myCompany } = useJobsContext()

  const navigate = useNavigate()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const [createJob] = useCreateJobMutation()

  const { uploadFile } = useFileUpload()

  const { data: companyData, loading: companyLoading } = useCompaniesNameOnlyQuery({
    skip: !isRole(Roles.ADMIN)
  })

  const { data: jobDepartmentsData, loading: jobDepartmentsLoading } = useJobDepartmentsQuery()

  const { data: jobEmploymentTypeData, loading: jobEmploymentTypeLoading } = useJobEmploymentTypesQuery()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      const result = await createJob({
        variables: {
          data: {
            ...(input.companyId && { companyId: input.companyId }),
            code: input.code,
            contact: input.contact.toLowerCase(),
            link: input.link,
            status: input.status,
            jobDepartmentId: input.jobDepartmentId,
            postalCode: input.postalCode,
            jobEmploymentTypeId: input.jobEmploymentTypeId,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        if (file?.upload && result.data?.createJob) {
          await uploadFile(
            file.upload.file,
            file.upload.data.key,
            'Job',
            result.data.createJob.id,
            file.config
          )
        }
      })

      setLoading(false)
      setCreated(true)
      setShowCreatedDialog(true)
    } catch (error) {
      setMessageByCode(error)
      setLoading(false)
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/jobwall')
  }

  return (
    <CreateEditLayout
      title={t('jobwall.createTitle')}
      description={t('jobwall.createDescription')}
      loading={companyLoading || jobDepartmentsLoading || jobEmploymentTypeLoading}
      {...(myCompany && myCompany.isPartner && {
        headerExtension: (
          <StatusSelectInput formKey="status" options={{ required: true }} />
        )
      })}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <JobWallCreateEditInputs
        companies={companyData?.companies}
        jobDepartments={jobDepartmentsData?.jobDepartments}
        jobEmploymentTypes={jobEmploymentTypeData?.jobEmploymentTypes}
        loading={loading}
      />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
      >
        {t('jobwall.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />

    </CreateEditLayout>
  )
}

export const JobWallCreateView: React.FC = () => {
  const formData = useForm<JobsInputType>({
    defaultValues: {
      status: Status.Draft
    },
    mode: 'onChange'
  })

  return (
    <JobWallProvider disableFetching>
      <FormProvider {...formData}>
        <JobWallCreateViewContent />
      </FormProvider>
    </JobWallProvider>
  )
}
