import { LoadingButton } from '@mui/lab'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { UpdateUserInput, useUpdateUserMutation, useUserQuery } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Box } from '@mui/material'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { Roles } from '@typings/roles'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { canTrainingCompany } from '@utils/canTrainingCompany'

import { UsersProvider, useUsersContext } from '../provider/UsersProvider'
import { UsersCreateEditInputs } from '../components/UsersCreateEditInputs'

type UserInputType = UpdateUserInput & {
  companies?: SearchInputItem[] | null
  trainingCompanies?: SearchInputItem[] | null
}

const UserEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setErrorMessage, setMessageByCode, errorMessage } = useErrorMessage()

  const { handleDelete, updateSelectedItem, roles } = useUsersContext()

  const { data: userData, loading: userLoading } = useUserQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const [updateUser] = useUpdateUserMutation()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext<UserInputType>()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  React.useEffect(() => {
    if (userData?.user) {
      reset({
        email: userData.user.email,
        firstname: userData.user.firstname,
        lastname: userData.user.lastname,
        roleId: userData.user.roleId,
        trainingYear: userData.user.trainingYear,
        companies: userData.user.company
          ? [{
              id: userData.user.companyId ?? '',
              name: userData.user.company?.name ?? ''
            }]
          : [],
        trainingCompanies: userData.user.trainingCompanies?.map((item) => ({
          id: item.id ?? '',
          name: item.name ?? ''
        })) ?? []
      })
    }
  }, [userData])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    const { email, companies, roleId, trainingCompanies, ...rest } = input

    const role = roles.find((item) => item.id === roleId)

    try {
      await updateUser({
        variables: {
          id: id as string,
          data: {
            ...(email && { email: email.toLowerCase().trim() }),
            ...rest,
            roleId: roleId as string,
            ...(role?.key === Roles.COMPANY && { companyId: companies?.[0].id || null }),
            ...(canTrainingCompany(role?.key as Roles) && { trainingCompanyId: trainingCompanies?.[0]?.id || null })
          }
        }
      })
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setShowToast(true)
      setLoading(false)
    }
  })

  const handleUserDeleted = () => {
    setDeleted(true)
    navigate('/users')
  }

  const onDelete = async () => {
    if (!userData?.user) {
      return
    }

    updateSelectedItem(userData.user)
    handleDelete(false, handleUserDeleted)
  }

  return (
    <CreateEditLayout
      title={t('users.editTitle')}
      loading={userLoading}
      description={
        <Trans
          i18nKey={'users.editDescription'}
          values={{ name: userData?.user.email }}
          components={{ b: <strong /> }}
        />
      }
      headerExtension={
        (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DeleteIconButton onClick={onDelete} />
          </Box>
        )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >

      <UsersCreateEditInputs />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('users.editSuccess')}
      />
    </CreateEditLayout>
  )
}

export const UserEditView:React.FC = () => {
  const formData = useForm<UserInputType>({
    defaultValues: {
      trainingCompanies: []
    },
    mode: 'onChange'
  })

  return (
    <UsersProvider disableFetching>
      <FormProvider {...formData}>
        <UserEditViewContent />
      </FormProvider>
    </UsersProvider>
  )
}
