import { useMemo } from 'react'
import { matchRoutes, useLocation, Location } from 'react-router'
import { AgnosticRouteMatch } from '@sentry/react/types/types'
import { AppRouteObject } from '@services/router/router'
import { useRoutesContext } from '@providers/RoutesProvider'

type MatchedRoutes = {
  matched: AgnosticRouteMatch<string, AppRouteObject>[] | null
  location: Location
  routes: AppRouteObject[]
}

export const useMatchedRoutes = (): MatchedRoutes => {
  const location = useLocation()
  const { routes } = useRoutesContext()

  const matched = useMemo(() => routes ? matchRoutes(routes, location) : null, [location])

  return {
    matched,
    location,
    routes
  }
}
