import { Box, Button, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { useUpdateUsersMutation } from '@typings/graphql'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useNewTabHook } from '@hooks/useNewTabHook'
import { DefaultIconButton } from '@shared/components/ui/DefaultIconButton'
import { useXlsxExport } from '@hooks/useXlsxExport'

import { UserDataGrid } from '../dataGrid/UserDataGrid'
import { UsersProvider, useUsersContext } from '../provider/UsersProvider'

const UsersViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const [showEditDialog, setShowEditDialog] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showEditedSnackbar, setShowEditedSnackbar] = React.useState(false)
  const [isDownloading, setIsDownloading] = React.useState(false)

  const { selectedIds, handleDelete, updateSelectedIds, handleFetchMore } = useUsersContext()
  const { setValue, handleSubmit } = useFormContext()
  const { setErrorMessage, setMessageByCode, errorMessage } = useErrorMessage()
  const handleExport = useXlsxExport()

  const [updateUsers] = useUpdateUsersMutation()

  const isActive = useWatch({
    name: 'active',
    defaultValue: false
  })

  const handleConfirm = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateUsers({
        variables: {
          data: {
            ids: selectedIds as string[],
            active: !input.active
          }
        }
      })

      setValue('active', !input.active)

      handleFetchMore()
      updateSelectedIds([])
    } catch (e) {
      setMessageByCode(e, 'users')
    } finally {
      setLoading(false)
      setShowEditDialog(false)
      setShowEditedSnackbar(true)
    }
  })

  const onHandleExport = async () => {
    setIsDownloading(true)

    await handleExport('users', (e) => {
      setMessageByCode(e, 'xlsx')
      setShowEditedSnackbar(true)
    }, () => {
      setIsDownloading(false)
    })
  }

  return (
    <DefaultLayout
      title={t('routes.users')}
      description={t('users.userListDescription')}
      headerExtension={
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
        >
          {
            can('users:delete') && selectedIds.length > 0 && (
            <DeleteIconButton
              sx={{ mr: 2 }}
              onClick={() => handleDelete(true)}
            />
            )
         }
          {
            can('users:read') && (
              <Tooltip title={t('users.exportUsers')}>
                <Box>
                  <DefaultIconButton
                    icon="Download"
                    sx={{ mr: 2 }}
                    loading={isDownloading}
                    onClick={onHandleExport}
                  />
                </Box>
              </Tooltip>
            )
         }

          {can('users:create') && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('/users/create')}
          >
            {t('users.addUser')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%',
        position: 'relative'
      }}
      >
        <UserDataGrid />
      </Box>
      <ConfirmDialog
        title={t('users.changeUsers')}
        open={showEditDialog}
        onConfirm={handleConfirm}
        onCancel={() => setShowEditDialog(false)}
        loading={loading}
      >
        <Trans
          i18nKey="users.changeActiveUsers"
          values={{ count: selectedIds.length, status: t(`status.${isActive ? 'inactive' : 'active'}`) }}
          components={{
            b: <strong />
          }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showEditedSnackbar}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowEditedSnackbar(false)}
        message={errorMessage || t('users.editSuccess')}
      />
    </DefaultLayout>
  )
}

export const UsersView: React.FC = () => {
  const formData = useForm({
    defaultValues: {},
    mode: 'onChange'
  })
  return (
    <UsersProvider>
      <FormProvider {...formData}>
        <UsersViewContent />
      </FormProvider>
    </UsersProvider>
  )
}
