import { Box, Switch, SwitchProps, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

type Props = SwitchProps & {
  formKey: string
  options?: RegisterOptions
  label: string
}

export const StandaloneSwitchInput: React.FC<Props> = ({ formKey, options, label, onChange, ...props }) => {
  const { disabled } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { onChange: onFieldChange, value, ...restFields } }) => (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
          opacity: disabled ? 0.4 : 1
        }}
        >
          <Switch
            {...props}
            {...restFields}
            checked={value || false}
            onChange={onChange || onFieldChange}
            sx={{
              '.Mui-disabled': {
                '.MuiSwitch-track': {
                  opacity: 1
                }
              }
            }}
          />
          <Typography variant="subtitle1"
            ml={1}
            fontWeight={600}
          >{label}</Typography>
        </Box>
      )
      }
    />
  )
}
