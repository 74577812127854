import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { useCategoriesContext } from '@features/categories/provider/CategoriesProvider'

import { CategoriesColumnType } from '../categoriesDataGridColumns'

const RenderCell: React.FC<{ params: any }> = ({ params }) => {
  const { profession } = useCategoriesContext()

  const title = React.useMemo(() => {
    return profession?.texts?.title
  }, [params])

  return (
    <Tooltip title={title} placement="top">
      <Typography variant="subtitle1" fontWeight={500}>{`${title}`}</Typography>
    </Tooltip>
  )
}

export const professionColumnModel: CategoriesColumnType = {
  field: 'profession',
  width: 250,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter (params) {
    return params.row.parent?.title
  },
  renderCell: (params) => <RenderCell params={params} />
}
