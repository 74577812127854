import { Box, Button, ButtonGroup, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { InputTitleField } from '../ui/InputRequiredIndicator'

export type SelectButton = {
  id: string
  label: string
  value?: any
  tooltip?: string
  disabled?: boolean
}

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  items: SelectButton[]
}

export const BaseButtonGroupInput: React.FC<Props> = ({ formKey, options, title, description, items }) => {
  const { control } = useFormContext()

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <Controller
          name={formKey}
          control={control}
          rules={options}
          render={({ field: { onChange, value } }) => (
            <ButtonGroup variant="outlined">
              {items.map((item) => (
                <Button
                  disabled={item.disabled}
                  key={item.id}
                  onClick={() => onChange(item.value !== undefined ? item.value : item.id)}
                  sx={{
                    '&.Mui-disabled': {
                      pointerEvents: 'all',
                      '&:hover': {
                        backgroundColor: 'inherit'
                      }
                    },
                    ...((value === item.value || value === item.id) && {
                      '&.MuiButton-outlined': {
                        backgroundColor: 'primary.main',
                        borderColor: 'primary.main',
                        color: 'white',
                        ':hover': {
                          backgroundColor: 'primary.800',
                          borderColor: 'primary.800'
                        }
                      }
                    })
                  }}
                >
                  {item.tooltip && item.disabled
                    ? (
                      <Tooltip title={item.tooltip}>
                        <Box>{item.label}</Box>
                      </Tooltip>
                      )
                    : item.label
                  }
                </Button>
              ))}
            </ButtonGroup>
          )}
        />
      </Grid>
    </Grid>

  )
}
