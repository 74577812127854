import { useErrorMessage } from '@hooks/useErrorMessage'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { NotificationType, useSubscribeNotificationsMutation, useUnsubscribeNotificationsMutation } from '@typings/graphql'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { lowerFirstLetter } from '@utils/format'

import { NotificationSettingsType } from '../views/SettingsView'
import { useSettingsContext } from '../provider/SettingsProvider'

type Props = {
  onCancel: () => void
  onSuccess: () => void
  onError: (message: string) => void
  notifications: NotificationSettingsType[]
}

type NotificationChangeInputType = {
  notifications: string[]
}

export const NotificationChangeInput: React.FC<Props> = ({ notifications, onCancel, onSuccess, onError }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const { me, refetchMe } = useSettingsContext()

  const { getMessageByCode } = useErrorMessage()

  const { reset, setValue, control, formState: { isDirty, isValid }, handleSubmit } = useForm<NotificationChangeInputType>({
    defaultValues: {
      notifications: []
    },
    mode: 'onChange'
  })

  const watchedNotifications = useWatch({
    control,
    name: 'notifications'
  })

  React.useEffect(() => {
    reset({
      notifications: me?.notifications?.map((notification) => notification.subject) || []
    })
  }, [])

  const [subscribeNotifications] = useSubscribeNotificationsMutation()
  const [unsubscribeNotifications] = useUnsubscribeNotificationsMutation()

  const handleOnCancel = () => {
    reset()
    onCancel()
  }

  const handleOnSubmit = handleSubmit(async (input) => {
    setLoading(true)

    const deletedNotifications = me?.notifications?.filter((notification) => !input.notifications.includes(notification.subject)) || []
    const addedNotifications = notifications.filter((notification) => input.notifications.includes(notification.subject))

    try {
      await subscribeNotifications({
        variables: {
          data: addedNotifications.map((notification) => ({
            subject: notification.subject,
            type: NotificationType.Mail
          }))
        }
      })

      await unsubscribeNotifications({
        variables: {
          subjects: deletedNotifications.map((notification) => notification.subject)
        }
      })

      refetchMe()
      handleOnCancel()
      onSuccess()
    } catch (error) {
      onError(getMessageByCode(error))
    } finally {
      setLoading(false)
    }
  })

  const onNotificationChange = (notification: NotificationSettingsType, checked: boolean) => {
    if (checked) {
      setValue('notifications', [...watchedNotifications, notification.subject], {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      setValue('notifications', watchedNotifications.filter((watchedNotification) => watchedNotification !== notification.subject), {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
      <form>
        <Box mb={2}>
          {notifications.map((notification) => (
            <Box
              key={notification.subject}
              mb={1}
            >
              <FormControlLabel
                sx={{
                  margin: 0
                }}
                control={<Checkbox sx={{ mr: 1 }}
                  checked={watchedNotifications.includes(notification.subject)}
                  onChange={(e, checked) => onNotificationChange(notification, checked)}
                />}
                label={t(`notifications.${lowerFirstLetter(notification.subject)}`)}
              />
            </Box>
          ))}
        </Box>
        <LoadingButton
          variant='contained'
          disabled={!isValid || !isDirty}
          onClick={handleOnSubmit}
          loading={loading}
        >
          {t('common.save')}
        </LoadingButton>
        <Button
          sx={{ ml: 2 }}
          variant="outlined"
          onClick={handleOnCancel}
        >
          {t('common.cancel')}
        </Button>
      </form>
    </Box>
  )
}
