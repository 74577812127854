import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  CategoryQuery,
  CategoryWithQuestionGroupsQuery,
  useCategoryWithQuestionGroupsQuery,
  useDeleteQuestionGroupMutation,
  useDeleteQuestionGroupsMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export type QuestionGroupType = Exclude<CategoryWithQuestionGroupsQuery['category']['questionGroups'], null | undefined>[0]
export type QuestionGroupRowModel = Omit<QuestionGroupType, '__typename'> & {
  title?: string
}

export type CategoryType = Omit<CategoryQuery['category'], 'questionGroups'>

export type QuestionGroupsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: QuestionGroupRowModel | null
  categoryData?: CategoryType
  questionGroups: QuestionGroupRowModel[]
  loading?: boolean
  updateSelectedItem: (row: QuestionGroupRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const QuestionGroupsProviderContext = React.createContext<QuestionGroupsProviderContextType>(
  {} as any
)

export const QuestionGroupsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()
  const { subCategoryId } = useParams<{ subCategoryId: string }>()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<QuestionGroupRowModel | null>(null)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [questionItems, setQuestionGroupItems] = React.useState<QuestionGroupRowModel[]>()
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const [deleteQuestionGroup] = useDeleteQuestionGroupMutation()
  const [deleteQuestionGroups] = useDeleteQuestionGroupsMutation()

  const { data: categoryData, loading: categoryLoading, refetch } = useCategoryWithQuestionGroupsQuery({
    variables: {
      id: subCategoryId as string
    },
    fetchPolicy: 'network-only',
    skip: !subCategoryId
  })

  const category = React.useMemo(() => {
    if (!categoryData?.category) {
      return undefined
    }

    const { questionGroups, ...rest } = categoryData.category

    setQuestionGroupItems(questionGroups)

    return rest
  }, [categoryData])

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteQuestionGroups({
          variables: {
            ids: selectedIds as string[]
          }
        })
        setSelectedIds([])
        setSuccessMessage(t('questionGroups.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteQuestionGroup({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('questionGroups.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updateSelectedItem = React.useCallback((row: QuestionGroupRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const loading = React.useMemo(() => {
    return categoryLoading || artificialLoading
  }, [categoryLoading, artificialLoading])

  const value = React.useMemo(() => ({
    categoryData: category,
    selectedIds,
    selectedItem,
    questionGroups: questionItems || [],
    loading,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [category, loading, questionItems, selectedIds, selectedItem])

  return (
    <QuestionGroupsProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'questionGroups.deleteEntries' : 'questionGroups.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.texts.title } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </QuestionGroupsProviderContext.Provider>
  )
}

export const useQuestionGroupsContext = () => React.useContext(QuestionGroupsProviderContext)
