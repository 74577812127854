import { EditableFile } from '@typings/files'
import { FileDataFragment } from '@typings/graphql'

export const getFileByKey = (key: string, files?: FileDataFragment[] | null): EditableFile<any> | null => {
  if (!files) {
    return null
  }

  const match = files.find((file) => file.key === key)

  return match
    ? {
        id: match.id,
        remote: match,
        mimeType: match.mimeType,
        config: match.config,
        url: match.url
      }
    : null
}

export const getFilesWithPrefix = (prefix: string, files?: FileDataFragment[] | null): Record<string, EditableFile<any>> => {
  const result: Record<string, EditableFile<any>> = {}

  if (!files) {
    return result
  }

  const filtered = files
    .filter((file) => file.key.startsWith(prefix))

  filtered.forEach((file) => {
    result[file.key] = {
      id: file.id,
      remote: file,
      mimeType: file.mimeType,
      config: file.config,
      url: file.url
    }
  })

  return result
}
