import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { t } from 'i18next'

import { QuestionsColumnType } from '../questionsDataGridColumns'

export const topicIndexColumnModel: QuestionsColumnType = {
  field: 'topic',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter (params) {
    return params.row?.topic?.index ? t('common.topicWithIndex', { index: params.row.topic.index }) : '-'
  }
}
