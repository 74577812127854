import React from 'react'
import { useTranslation } from 'react-i18next'
import { EditableFile } from '@typings/files'
import { UpdateCompanyInput, useCompanyCategoriesQuery, useDeleteFileMutation, useUpdateMyCompanyMutation } from '@typings/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { useFileUpload } from '@hooks/useFileUpload'
import { getFileByKey } from '@utils/files'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import i18n from '@services/i18n/i18n'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { MyCompanyProvider, useMyCompanyContext } from '../provider/MyCompanyProvider'
import { CompanyProfileEditInputs } from '../components/CompanyProfileEditInputs'

type CompanyInputType = UpdateCompanyInput & {
  files: Record<string, EditableFile<any> | null>
  texts: Record<string, string>
}

const MyCompanyEditViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { companyData, loading: providerLoading } = useMyCompanyContext()

  const { data: categoryData, loading: categoryLoading } = useCompanyCategoriesQuery({
    fetchPolicy: 'cache-and-network'
  })

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [updateMyCompany] = useUpdateMyCompanyMutation({
    update (cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: 'Company',
          id: companyData?.id
        }),
        fields: {
          name () {
            return data?.updateMyCompany?.name || companyData?.name
          }
        }
      })
    }
  })

  const formData = useForm<CompanyInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = formData

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage))

  const { uploadFile } = useFileUpload()

  const [deleteFile] = useDeleteFileMutation()

  React.useEffect(() => {
    if (companyData) {
      reset({
        name: companyData.name,
        category: categoryData?.companyCategories.find((category) => category.id === companyData?.category?.id)?.id || '',
        postalCode: companyData.postalCode,
        city: companyData.city,
        address: companyData.address,
        texts: companyData.texts,
        files: {
          keyvisual: getFileByKey('keyvisual', companyData.files),
          logo: getFileByKey('logo', companyData.files)
        }
      })
    }
  }, [companyData, categoryData])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateMyCompany({
        variables: {
          data: {
            name: input.name,
            category: input.category,
            postalCode: input.postalCode,
            city: input.city,
            address: input.address,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        const fileId = getFileByKey(key, companyData?.files)?.id

        if (file?.upload) {
          await uploadFile(file.upload.file, file.upload.data.key, 'Company', companyData?.id as string)
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        }
      })
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setShowToast(true)
      setLoading(false)
    }
  })

  return (
    <CreateEditLayout
      title={t('routes.companyProfileEdit')}
      description={t('myCompany.editDescription')}
      loading={providerLoading || categoryLoading}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <FormProvider {...formData}>
        <CompanyProfileEditInputs loading={loading} categories={categoryData?.companyCategories}/>
      </FormProvider>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('myCompany.editSuccess')}
      />

    </CreateEditLayout>
  )
}

export const MyCompanyEditView: React.FC = () => (
  <MyCompanyProvider>
    <MyCompanyEditViewContent />
  </MyCompanyProvider>
)
