import { Box, Divider } from '@mui/material'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import React from 'react'
import { UserSearch } from '@shared/components/inputs/UserSearch'
import { useTranslation } from 'react-i18next'
import { Roles } from '@typings/roles'
import { AutocompleteSelectItem, BaseAutoCompleteInput } from '@shared/components/inputs/BaseAutoCompleteInput'
import { postalPattern } from '@utils/pattern'

import { useCompaniesContext } from '../provider/CompaniesProvider'

type Props = React.PropsWithChildren & {
  loading?: boolean
}

export const CompanyCreateEditInputs:React.FC<Props> = ({
  loading, children
}) => {
  const { t } = useTranslation()

  const { updateSelectedCategory, categoryData } = useCompaniesContext()

  const handleOnDelete = (item?: AutocompleteSelectItem) => {
    updateSelectedCategory(item)
  }

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='name'
        options={{ required: true }}
        title={t('common.name')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseAutoCompleteInput
        formKey="category"
        options={{ required: true }}
        title={t('common.department')}
        items={categoryData}
        onDeleteItem={handleOnDelete}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='texts.description'
        title={t('common.tagline')}
        inputProps={{
          max: 100
        }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='address'
        options={{ required: true }}
        title={t('common.address')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='postalCode'
        options={{ required: true, pattern: postalPattern }}
        title={t('common.postalCode')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='city'
        options={{ required: true }}
        title={t('common.city')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.keyvisual"
        fileKey="keyvisual"
        title={t('common.keyVisual')}
        description={t('companies.keyVisualDescription')}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.logo"
        fileKey="logo"
        title={t('common.logo')}
        description={t('companies.logoDescription')}
        loading={loading}
      />

      {children && (
        <>
          <Divider
            sx={{
              my: 3.5
            }}
            light
            orientation='horizontal'
          />

          {children}
        </>
      )}

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <UserSearch description={t('companies.usersDescription')} roles={[Roles.COMPANY]} />

    </Box>
  )
}
