import React from 'react'
// import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

type AlternativeTexts = Record<string, string>

type ErrorMessageHook = {
  getMessageByCode: (error: any, origin?: ErrorViews, params?: Record<string, any>, alternativeTexts?: AlternativeTexts) => string,
  setMessageByCode: (error: any, origin?: ErrorViews, params?: Record<string, any>, alternativeTexts?: AlternativeTexts) => void,
  setErrorMessage: (message: string) => void,
  errorMessage: string
}

type ErrorViews = 'auth' | 'users' | 'company' | 'settings' | 'myCompany' | 'questions' | 'xlsx'

export const useErrorMessage = (): ErrorMessageHook => {
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const getMessageByCode = React.useCallback((error: any, origin?: ErrorViews,
    params?: Record<string, any>, alternativeTexts?: AlternativeTexts): string => {
    const response = error?.graphQLErrors?.[0]?.extensions?.originalError as Record<string, any>

    setErrorMessage(response?.message)
    console.error(error)
    // Sentry.captureException(error)

    if (response?.statusCode && alternativeTexts?.[response.statusCode]) {
      return alternativeTexts[response.statusCode]
    }

    return response?.statusCode
      ? t(`errors.${origin || 'response'}.${response.statusCode}`, params)
      : t(`errors.${origin || 'response'}.default`, params)
  }, [])

  const setMessageByCode = React.useCallback((error: any, origin?: ErrorViews,
    params?: Record<string, any>, alternativeTexts?: AlternativeTexts) => {
    setErrorMessage(getMessageByCode(error, origin, params, alternativeTexts))
  }, [])

  return {
    getMessageByCode,
    setMessageByCode,
    setErrorMessage,
    errorMessage
  }
}
