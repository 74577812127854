import { LoginInput, useLoginMutation } from '@typings/graphql'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, Button, Box } from '@mui/material'
import { useAppDispatch } from '@services/store/store'
import { setAuthData } from '@services/store/slices/auth'
import { useLocation, useNavigate } from 'react-router'
import { LoadingButton } from '@mui/lab'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

type Props = {}

const LoginForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [login, { loading: isLoggingIn }] = useLoginMutation()

  const { getMessageByCode } = useErrorMessage()

  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [loginBlocked, setLoginBlocked] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { handleSubmit, register } = useForm<LoginInput>({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onLogin = async (input: LoginInput) => {
    try {
      const { data: authData } = await login({
        variables: { data: input },
        fetchPolicy: 'network-only'
      })

      if (authData) {
        dispatch(setAuthData(authData.login))

        const origin = (location.state as any)?.from || '/'
        navigate(origin, { replace: true })
      }
    } catch (e) {
      setLoginBlocked(true)

      window.setTimeout(() => {
        setLoginBlocked(false)
      }, 10000)

      setErrorMessage(getMessageByCode(e, 'auth', { blockedFor: 10 }))
    } finally {
      setShowToast(true)
    }
  }

  const onSubmit = async (input: LoginInput) => {
    if (!loginBlocked) {
      await onLogin(input)
    }
  }

  return (<>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={t('auth.email') as string}
        {...register('email')}
        id="email"
        type="email"
      />
      <TextField
        fullWidth
        label={t('auth.password') as string}
        {...register('password')}
        id="password"
        type="password"
      />
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
      >
        <Button variant="text" onClick={() => navigate('/forgot-password')}>
          {t('auth.forgotPassword')}
        </Button>
      </Box>

      <LoadingButton
        variant="contained"
        fullWidth
        disabled={!!loginBlocked}
        loading={isLoggingIn}
        color="secondary"
        sx={{
          backgroundColor: 'primary.main'
        }}
        type="submit"
      >
        {t('auth.title')}
      </LoadingButton>

      <DefaultSnackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        severity="error"
        message={errorMessage}
      />
    </form>
  </>)
}

export default LoginForm
