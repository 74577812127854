import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { themeIcons } from '@theme/theme'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { ProfessionsProvider } from '../provider/ProfessionsProvider'
import { ProfessionsDataGrid } from '../dataGrid/ProfessionsDataGrid'

const ProfessionsViewContent: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNewTabHook()

  return (
    <DefaultLayout
      title={t('routes.professions')}
      description={t('professions.professionListDescription')}
      headerExtension={
        <Box>
          {can(['professions:create', 'professions:create.partial']) && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('professions.addProfession')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <ProfessionsDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const ProfessionsView: React.FC = () => (
  <ProfessionsProvider>
    <ProfessionsViewContent />
  </ProfessionsProvider>
)
