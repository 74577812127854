import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { TrainingCompaniesColumnType, TrainingCompaniesRowModel, useTrainingCompaniesContext } from '../provider/TrainingCompaniesProvider'

import { trainingCompaniesDataGridColumns } from './trainingCompaniesDataGridColumns'

export const TrainingCompaniesDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds,
    loading,
    trainingCompaniesData,
    updateSelectedItem,
    handleDelete,
    selectedIds,
    paginationModel,
    updatePaginationModel,
    sortModel,
    updateSortModel,
    filterModel,
    updateFilterModel,
    updateInitialState
  } = useTrainingCompaniesContext()

  const rowData = React.useMemo(() => {
    return trainingCompaniesData?.edges?.map(({ node }) => node) || []
  }, [trainingCompaniesData])

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: TrainingCompaniesRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const rowCountState = React.useMemo(() => trainingCompaniesData?.totalCount ?? 0, [trainingCompaniesData])

  const columnData = React.useMemo<TrainingCompaniesColumnType[]>(() => {
    return [...trainingCompaniesDataGridColumns, {
      ...actionColumnModel,
      width: (can('training-companies:write') && can('training-companies:delete')) ? 90 : 0,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...((can('training-companies:write') || can('training-companies:write.own')) && { onEdit: () => navigate(`${params.row.id}`) })}
          {...(can('training-companies:delete') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as TrainingCompaniesColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`trainingCompanies.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey='training-companies'
      onInitialState={updateInitialState}
      rows={rowData}
      rowCount={rowCountState}
      paginationModel={paginationModel}
      paginationMode='server'
      autoPageSize={false}
      onPaginationModelChange={updatePaginationModel}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode='server'
      onSortModelChange={updateSortModel}
      filterModel={filterModel}
      filterMode='server'
      onFilterModelChange={updateFilterModel}
      loading={loading}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
