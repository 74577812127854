import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestCompanyUserInput, useRequestCompanyUserMutation } from '@typings/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

import { MyCompanyProvider } from '../provider/MyCompanyProvider'
import { CompanyRequestUserInputs } from '../components/CompanyRequestUserInputs'

const MyCompanyRequestUserViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [requestUser] = useRequestCompanyUserMutation()

  const formData = useForm<RequestCompanyUserInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  const { formState: { isDirty, isValid }, handleSubmit } = formData

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await requestUser({
        variables: {
          data: input
        }
      })
    } catch (e) {
      setMessageByCode(e, 'myCompany')
    } finally {
      setShowToast(true)
      setLoading(false)
    }
  })

  return (
    <CreateEditLayout
      title={t('routes.companyRequestUser')}
      description={t('myCompany.requestUserDescription')}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <FormProvider {...formData}>
        <CompanyRequestUserInputs />
      </FormProvider>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('myCompany.requestUserSuccess')}
      />

    </CreateEditLayout>
  )
}

export const MyCompanyRequestUserView: React.FC = () => (
  <MyCompanyProvider>
    <MyCompanyRequestUserViewContent />
  </MyCompanyProvider>
)
