import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'
import { emailColumnModel } from '@shared/dataGrid/columns/emailColumn'
import { activeColumnModel } from '@shared/dataGrid/columns/activeColumn'
import { firstnameColumnModel } from '@shared/dataGrid/columns/firstnameColumn'
import { lastnameColumnModel } from '@shared/dataGrid/columns/lastnameColumn'

import { UserColumnType } from '../provider/UsersProvider'

import { roleColumnModel } from './columns/roleColumn'
import { playerColumnModel } from './columns/playerColumn'
import { professionColumnModel } from './columns/professionColumn'
import { trainingYearColumnModel } from './columns/trainingYearColumn'

export const userDataGridColumns: UserColumnType[] = [
  emailColumnModel as UserColumnType,
  firstnameColumnModel as UserColumnType,
  lastnameColumnModel as UserColumnType,
  playerColumnModel,
  professionColumnModel,
  trainingYearColumnModel,
  roleColumnModel,
  activeColumnModel as UserColumnType,
  createdColumnModel as UserColumnType
]
