import { Box, BoxProps, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

type Props = PropsWithChildren & BoxProps & {
  onClick: () => void
  isLast?: boolean
  label: string
}

export const DashboardBreadcrumbLink: React.FC<Props> = ({ onClick, label, isLast, children, sx, ...props }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...sx
      }}
      {...props}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: !isLast ? 'pointer' : 'default',
        backgroundColor: isLast ? 'grey.100' : 'transparent',
        borderRadius: '6px',
        px: 1,
        py: 0.5,
        fontWeight: isLast ? 600 : 'normal',
        ...(!isLast && { '&:hover': { color: 'grey.500' } })
      }}
        {...(!isLast && { onClick })}
      >
        {children || (
        <Typography
          variant="subtitle1"
          fontWeight="inherit"
        >
            {t(`routes.${label}`)}
        </Typography>
        )}
      </Box>
      {!isLast && <themeIcons.ArrowRight sx={{
        color: 'grey.400',
        mx: 2,
        width: '16px',
        height: '16px'
      }}
      />}
    </Box>
  )
}
