import { Grid, Typography, Box } from '@mui/material'
import { DashboardAccordion } from '@shared/components/ui/DashboardAccordion'
import { LegendRowItem } from '@shared/components/ui/LegendRowItem'
import { TraineePie } from '@shared/components/ui/TraineePie'
import React from 'react'
import { useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { useTranslation } from 'react-i18next'

import { TraineesDetailRows } from './TraineeDetailsRows'

export const TraineeDetailPersonalInformation: React.FC = () => {
  const { t } = useTranslation()

  const { traineeData, learningProgress } = useTraineesDetailContext()

  return <DashboardAccordion
    defaultExpanded
    title={t('trainees.traineeData')}
  >
    <Grid container spacing={10}>
      <Grid item xs={12} md={8}>
        {!!traineeData &&
        <TraineesDetailRows />
      }
      </Grid>
      <Grid item
        xs={12}
        md={4}
        sx={{
          textAlign: 'center'
        }}
      >
        <Typography sx={{
          mb: 2
        }}
          fontWeight={600}
        >
          {t('trainees.learningProgress')}
        </Typography>
        {learningProgress && (
        <Box sx={{
          textAlign: 'left'
        }}
        >
          <TraineePie
            data={learningProgress.overall}
            large
          />
          <Box sx={{
            mt: 2
          }}
          >
            {Object.entries(learningProgress.overall).map(([key, value]) => (
              <Box
                key={key}
              >
                <LegendRowItem
                  title={t(`learningProgress.${key}`, { value: `${value.value.toFixed(1)}%` })}
                  color={value.color}
                />
              </Box>
            ))}
          </Box>
        </Box>
        )}
      </Grid>
    </Grid>
  </DashboardAccordion>
}
