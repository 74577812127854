import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { palette } from '@theme/core/palette/palette'

export type MuiToggleButtonType = {
  defaultProps?: ComponentsProps['MuiToggleButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiToggleButton'];
  variants?: ComponentsVariants['MuiToggleButton'];
}

export type MuiToggleButtonGroupType = {
  defaultProps?: ComponentsProps['MuiToggleButtonGroup'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiToggleButtonGroup'];
  variants?: ComponentsVariants['MuiToggleButtonGroup'];
}

export const MuiToggleButtonGroup:MuiToggleButtonGroupType = {
  styleOverrides: {
    root: {
    }
  }
}

export const MuiToggleButton:MuiToggleButtonType = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      textTransform: 'none',
      color: palette.text?.primary,
      borderColor: palette.grey?.['400'],
      padding: '10px 16px',
      '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: 0
      },
      '&.Mui-selected': {
        backgroundColor: palette.grey?.['100']
      }
    }
  }
}
