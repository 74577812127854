import { useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { Grid } from '@mui/material'
import { DashboardAccordion } from '@shared/components/ui/DashboardAccordion'
import { IconWithTextRow } from '@shared/components/ui/IconWithTextRow'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const TraineeDetailDuelStatistic: React.FC = () => {
  const { t } = useTranslation()

  const { traineeData } = useTraineesDetailContext()

  const duelStatistic = React.useMemo(() => {
    if (!traineeData?.player) {
      return null
    }

    return traineeData.player.duelStatistic
  }, [traineeData])

  const overall = React.useMemo(() => {
    return duelStatistic?.wins + duelStatistic?.losses + duelStatistic?.draws ?? 0
  }, [duelStatistic])

  return (
    <DashboardAccordion title={t('trainees.duelStatistic')}
      headerExtension={
        <IconWithTextRow
          title={t('common.overall')}
          icon="Award"
          content={t('trainees.duelCount', { count: overall })}
          inline
        />
    }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <IconWithTextRow
            title={t('trainees.duelStatisticWon')}
            icon="FaceContent"
            content={t('trainees.duelCount', { count: duelStatistic?.wins ?? 0 })}
          />
        </Grid>
        <Grid item xs={4}>
          <IconWithTextRow
            title={t('trainees.duelStatisticLost')}
            icon="FaceFrown"
            content={t('trainees.duelCount', { count: duelStatistic?.losses ?? 0 })}
          />
        </Grid>
        <Grid item xs={4}>
          <IconWithTextRow
            title={t('trainees.duelStatisticDraw')}
            icon="Scale"
            content={t('trainees.duelCount', { count: duelStatistic?.draws ?? 0 })}
          />
        </Grid>
      </Grid>
    </DashboardAccordion>
  )
}
