import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { PasswordInput } from '@shared/components/ui/PasswordInput'
import { useResetPasswordMutation } from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'

type ResetPasswordInput = {
  token: string
  newPassword: string
}

export const PasswordSetForm: React.FC = () => {
  const { t } = useTranslation()

  const { token } = useParams<{ token: string }>()

  const navigate = useNavigate()

  const [showToast, setShowToast] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const formData = useForm<ResetPasswordInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  const [resetPassword] = useResetPasswordMutation()

  const { handleSubmit, formState: { isDirty, isValid } } = formData

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()

  const onSubmit = handleSubmit(async (input) => {
    setErrorMessage('')
    setLoading(true)

    try {
      await resetPassword({
        variables: {
          token: token as string,
          password: input.newPassword
        }
      })
      setSuccess(true)
      setErrorMessage('')
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setLoading(false)
      setShowToast(true)
    }
  })

  return (
    <Box>
      {!success
        ? (
          <>
            <Typography
              variant='h4'
              sx={{
                mb: 3,
                textAlign: 'center'
              }}
            >{t('auth.setPasswordDescription')}</Typography>
            <FormProvider {...formData}>
              <PasswordInput
                formKey="newPassword"
                formKeyConfirm='newPasswordConfirm'
                label={t('settings.newPassword')}
                labelConfirm={t('settings.newPasswordConfirm')}
              />

              <LoadingButton
                variant='contained'
                disabled={!isDirty || !isValid}
                loading={loading}
                onClick={onSubmit}
                sx={{
                  mt: 2
                }}
              >
                {t('auth.changePassword')}
              </LoadingButton>
            </FormProvider>
          </>
          )
        : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          >
            <Typography
              sx={{
                my: 2,
                textAlign: 'center'
              }}
            >{t('auth.passwordResetSuccess')}</Typography>
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/login')}>
              <Typography>{t('auth.backToLogin')}</Typography>
            </Button>
          </Box>
          )}

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('auth.passwordResetSuccess')}
      />
    </Box>
  )
}
