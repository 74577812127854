import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  ProfessionsQuery,
  useProfessionsQuery,
  useDeleteProfessionsMutation,
  useDeleteProfessionMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export type ProfessionType = Exclude<ProfessionsQuery['professions'], null | undefined>[0]
export type ProfessionRowModel = Omit<ProfessionType, '__typename'> & {
  title: string,
  trainingYears?: number
}

export type ProfessionsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: ProfessionRowModel | null
  parentProfessionId?: string
  professionData: ProfessionRowModel[]
  loading?: boolean
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: ProfessionRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

export type ProfessionSelectionType = {
  id: string
  name: string
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const ProfessionsProviderContext = React.createContext<ProfessionsProviderContextType>(
  {} as any
)

export const ProfessionsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()
  const { professionId } = useParams<{ professionId?: string }>()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<ProfessionRowModel | null>(null)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  const deleteCallbackRef = React.useRef<() => void>()

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [deleteProfession] = useDeleteProfessionMutation()
  const [deleteProfessions] = useDeleteProfessionsMutation()

  const { data, loading: dataLoading, refetch } = useProfessionsQuery({
    variables: {},
    fetchPolicy: 'cache-and-network',
    skip: disableFetching
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo(() => {
    return dataLoading || artificialLoading
  }, [dataLoading, artificialLoading])

  const professionData = React.useMemo<ProfessionRowModel[]>(() => {
    if (!data) {
      return []
    }

    return data.professions.map((profession) => {
      return {
        ...profession,
        title: profession.texts.title
      }
    }) || []
  }, [professionId, data])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteProfessions({
          variables: {
            ids: selectedIds as string[]
          }
        })
        setSelectedIds([])
        setSuccessMessage(t('professions.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteProfession({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('professions.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updateSelectedItem = React.useCallback((row: ProfessionRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const value = React.useMemo(() => ({
    parentProfessionId: professionId,
    professionData,
    loading,
    selectedIds,
    selectedItem,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [professionId, data, loading, selectedIds, selectedItem])

  return (
    <ProfessionsProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'professions.deleteEntries' : 'professions.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.texts.title } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </ProfessionsProviderContext.Provider>
  )
}

export const useProfessionsContext = () => React.useContext(ProfessionsProviderContext)
