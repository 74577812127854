import { convertBytesToHumanReadable } from '@utils/format'
import i18n, { ResourceKey } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import deepmerge from 'deepmerge'

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend(async (language, namespace, callback) => {
    let baseData = {}
    try {
      baseData = await import(`../../locales/${language}.json`)
    } catch (_) {}

    let clientData = {}
    try {
      clientData = await import(`../../locales/${language}-${import.meta.env.REACT_APP_ID}.json`)
    } catch (_) {}

    const data = deepmerge(baseData, clientData)

    callback(null, data as ResourceKey)
  }))
  .init({
    fallbackLng: 'de',
    debug: import.meta.env.DEV,
    backend: {
      loadPath: ''
    },
    load: 'languageOnly',
    supportedLngs: ['de'],
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'filesize') return convertBytesToHumanReadable(parseInt(value))
        return value
      }
    }
  })

export default i18n
