import React from 'react'
import { can } from '@utils/permissions'

import { useDashboardContext } from '../provider/DashboardProvider'

export const DashboardTabView: React.FC = () => {
  const { selectedTab, availableTabs } = useDashboardContext()

  const filteredViews = React.useMemo(() => {
    return availableTabs.filter((tab) => !tab.permission || can(tab.permission))
  }, [availableTabs])

  const SelectedTabView = React.useMemo(() => {
    const View = filteredViews.find((tab) => tab.key === selectedTab)?.view

    return View ? <View /> : null
  }, [selectedTab, filteredViews])

  return SelectedTabView
}
