import { UploadFileInput } from './graphql'

export type EditableFile<T> = {
  id: string
  remote?: T
  mimeType?: string
  metadata?: Record<string, any>
  config?: Record<string, any>
  url?: string
  upload?: {
    data: UploadFileInput
    file: File
    preview?: string
  }
}

export enum ResizeMode {
  CONTAIN = 'contain',
  COVER = 'cover',
}
