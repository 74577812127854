import React from 'react'
import { StatusChip } from '@shared/components/ui/StatusChip'
import i18n from '@services/i18n/i18n'
import { firstLetterToLowerCase } from '@utils/string'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'
import { ReviewStatus } from '@typings/graphql'
import { useTranslation } from 'react-i18next'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'

import { QuestionListColumnType } from '../questionListDataGridColumns'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { t } = useTranslation()

  const selectItems = React.useMemo(() => {
    return Object.values(ReviewStatus).map((type) => ({
      id: type,
      name: t(`status.${firstLetterToLowerCase(type)}`),
      key: type
    }))
  }, [])

  return (
    <FilterSelectInput
      {...props}
      selectProps={{
        multiple: true
      }}
      items={selectItems}
    />
  )
}

export const reviewStatusColumnModel: QuestionListColumnType = {
  field: 'reviewStatus',
  width: 120,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  valueGetter: (params) => i18n.t(`status.${firstLetterToLowerCase(params.row?.reviewStatus)}`),
  renderCell: (params) => (<StatusChip value={params.row.reviewStatus} />)
}
