import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { PasswordInput } from '@shared/components/ui/PasswordInput'
import { useChangePasswordMutation } from '@typings/graphql'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { FormProvider, useForm } from 'react-hook-form'

import { ChangePasswordInputType } from '../views/SettingsView'

type Props = {
  onCancel: () => void
  onSuccess: () => void
  onError: (message: string) => void
}

export const PasswordChangeInput: React.FC<Props> = ({ onCancel, onSuccess, onError }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const { getMessageByCode } = useErrorMessage()

  const formData = useForm<ChangePasswordInputType>({
    defaultValues: {
      confirmNewPassword: '',
      newPassword: '',
      oldPassword: ''
    },
    mode: 'onChange'
  })
  const { reset, formState, handleSubmit } = formData

  const { isDirty, isValid } = formState

  const [changePassword] = useChangePasswordMutation()

  const handleOnCancel = () => {
    reset()
    onCancel()
  }

  const handleOnSubmit = handleSubmit(async (input) => {
    setLoading(true)

    const { newPassword, oldPassword } = input

    try {
      await changePassword({
        variables: {
          data: {
            newPassword: newPassword.trim(),
            oldPassword: oldPassword.trim()
          }
        }
      })
      handleOnCancel()
      onSuccess()
    } catch (error) {
      onError(getMessageByCode(error))
    } finally {
      setLoading(false)
    }
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
      <FormProvider {...formData}>
        <form>
          <PasswordInput
            formKey="newPassword"
            formKeyConfirm='newPasswordConfirm'
            formKeyOld='oldPassword'
            label={t('settings.newPassword')}
            labelConfirm={t('settings.newPasswordConfirm')}
            labelOld={t('settings.oldPassword')}
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            <LoadingButton
              variant='contained'
              disabled={!isValid || !isDirty}
              onClick={handleOnSubmit}
              loading={loading}
            >
              {t('common.save')}
            </LoadingButton>
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              onClick={handleOnCancel}
            >
              {t('common.cancel')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}
