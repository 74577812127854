import { Divider } from '@mui/material'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { BaseTimeInput } from '@shared/components/inputs/BaseTimeInput'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MINUTE_STEPS = [1, 5, 15, 30]

export const QuestionTimeInput: React.FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues, control } = useFormContext()

  const mappedSteps = React.useMemo(() => {
    return MINUTE_STEPS.map((step) => ({
      id: step,
      name: t('common.minute', { count: step })
    }))
  }, [])

  const watchedMinuteSteps = useWatch({
    name: 'config.minuteSteps',
    control,
    defaultValue: MINUTE_STEPS[1]
  })

  const watchedSolution = useWatch({
    name: 'config.solution',
    control
  }) as string

  const timeDigitalClockFormat = React.useCallback((value: Date) => {
    return value.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
  }, [])

  const currentTime = React.useCallback((minuteStep: number) => {
    const now = new Date()
    let hours = watchedSolution ? parseInt(watchedSolution.split(':')[0]) : now.getHours()
    let minutes = watchedSolution ? parseInt(watchedSolution.split(':')[1]) : now.getMinutes()

    minutes = Math.round(minutes / minuteStep) * minuteStep

    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    if (hours === 24) {
      hours = 0
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }, [watchedSolution])

  React.useEffect(() => {
    const minuteSteps = getValues('config.minuteSteps') || MINUTE_STEPS[1]
    const solution = getValues('config.solution')

    setValue('config.minuteSteps', minuteSteps)

    if (!solution) {
      setValue('config.solution', currentTime(minuteSteps))
    }
  }, [])

  React.useEffect(() => {
    setValue('config.solution', currentTime(watchedMinuteSteps), {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [watchedMinuteSteps])

  return (
    <>
      <BaseSelectInput
        title={t('questions.minuteSteps')}
        formKey={'config.minuteSteps'}
        options={{ required: true }}
        items={mappedSteps}
        required
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTimeInput
        formKey='config.solution'
        options={{ required: true }}
        title={t('questions.answer')}
        formatter={timeDigitalClockFormat}
        minutesStep={watchedMinuteSteps}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.displayCalculator'
        title={t('questions.displayCalculator')}
        description={t('questions.displayCalculatorDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
    </>
  )
}
