import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

import { useDashboardContext } from '../provider/DashboardProvider'
import { DashboardTabTypes } from '../tabs/DashboardTabs'

export type PermissionTabs = {
  key: string,
  label?: string,
  icon?: keyof typeof themeIcons,
  permission?: string[],
  partnerRestriction?: boolean
}

export const DashboardTabBar: React.FC = () => {
  const { selectedTab, updateSelectedTab, availableTabs } = useDashboardContext()

  const handleTabSelected = (e: React.MouseEvent<HTMLElement>, tab: DashboardTabTypes | null) => {
    if (tab) {
      updateSelectedTab(tab)
    }
  }

  return availableTabs.length > 1
    ? (
      <ToggleButtonGroup
        value={selectedTab}
        exclusive
        onChange={handleTabSelected}
      >
        {availableTabs.map((tab) => {
          const Icon = tab.icon ? themeIcons[tab.icon] : false
          return (
            <ToggleButton
              value={tab.key}
              key={tab.key}
            >
              {Icon && <Icon sx={{
                mr: 1
              }}
              />}
              {tab.label}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
      )
    : null
}
