import { Chip } from '@mui/material'
import { CHIP_STATUS_COLORS } from '@shared/constants/chips'
import { Status } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string
}

export const StatusChip: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()

  return (
    <Chip
      sx={CHIP_STATUS_COLORS[value as Status]}
      label={t(`status.${value.toLowerCase()}`) as string}
    />
  )
}
