import React from 'react'
import { PermissionTabs } from '@features/dashboard/roleViews/company/components/DashboardTabBar'
import { t } from 'i18next'

import { DashboardProductView } from '../views/DashboardProductView'
import { DashboardJobView } from '../views/DashboardJobView'

export enum DashboardTabTypes {
  General = 'general',
  Products = 'products',
  Jobs = 'jobs'
}

export type DashboardTabData = PermissionTabs & {
  key: DashboardTabTypes,
  createPermission?: string[],
  view: React.FC
}

export const dashboardTabs: DashboardTabData[] = [
  {
    key: DashboardTabTypes.Products,
    icon: 'Layers',
    label: t(`dashboard.tabs.${DashboardTabTypes.Products}`),
    permission: ['incentives:read', 'incentives:read.own'],
    createPermission: ['incentives:create', 'incentives:create.own'],
    partnerRestriction: true,
    view: DashboardProductView
  }, {
    key: DashboardTabTypes.Jobs,
    icon: 'PushPin',
    label: t(`dashboard.tabs.${DashboardTabTypes.Jobs}`),
    permission: ['jobs:read', 'jobs:read.own'],
    createPermission: ['jobs:create', 'jobs:create.own'],
    view: DashboardJobView
  }]
