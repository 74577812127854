import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'

import { TraineesDataGrid } from '../dataGrid/TraineesDataGrid'
import { TraineesProvider, useTraineesContext } from '../provider/TraineesProvider'
import { TraineeInvitationDialog } from '../components/TraineeInvitationDialog'

const TraineesViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { selectedIds, handleDisconnect, newTrainee } = useTraineesContext()

  const [showInviteDialog, setShowInviteDialog] = React.useState(false)

  return (
    <DefaultLayout
      title={t('routes.trainees')}
      description={t('trainees.traineesDescription')}
      headerExtension={
        <Box>
          {can('users:delete.trainee') && selectedIds.length > 0 && (
            <DeleteIconButton
              sx={{ mr: 2 }}
              onClick={() => handleDisconnect(true)}
            />
          )}
          {can('users:write.trainee') && (
            <Button onClick={() => setShowInviteDialog(true)}>
              {t('trainees.inviteTrainee')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <TraineesDataGrid />
      </Box>

      <TraineeInvitationDialog
        open={showInviteDialog}
        newTrainee={newTrainee}
        onClose={() => setShowInviteDialog(false)}
      />
    </DefaultLayout>
  )
}

export const TraineesView: React.FC = () => (
  <TraineesProvider>
    <TraineesViewContent />
  </TraineesProvider>
)
