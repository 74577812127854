import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { RELEVANT_NUMBER_OPERATORS } from '@shared/constants/dataGrid'
import { t } from 'i18next'
import { UserColumnType } from '@features/users/provider/UsersProvider'

export const trainingYearColumnModel: UserColumnType = {
  field: 'trainingYear',
  width: 80,
  filterOperators: getGridNumericOperators().filter((operator) =>
    Object.keys(RELEVANT_NUMBER_OPERATORS).includes(operator.value)).map((operator) => {
    return {
      ...operator,
      value: RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS],
      type: 'number',
      label: t(`filterOperator.${RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS]}`)
    }
  }),
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: (params) => params.row.trainingYear ? params.row.trainingYear : null
}
