import { Typography, useTheme } from '@mui/material'
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector
} from '@mui/x-data-grid-pro'
import { theme } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DataGridPageEntriesLabel: React.FC<{rowCount: number}> = ({ rowCount }) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef,
    gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)

  const lastEntries = React.useMemo(() => {
    return (pageSize * (page + 1)) > rowCount ? rowCount : (pageSize * (page + 1))
  }, [pageSize, page, rowCount])

  const firstEntries = React.useMemo(() => {
    return rowCount > 0 ? (pageSize * page) + 1 : 0
  }, [pageSize, page, rowCount])

  return (
    <Typography sx={{
      ml: 2,
      [breakpoints.down('md')]: {
        ...theme.typography.subtitle2,
        ml: 0.5
      }
    }}
    >
      {t('dataGrid.entriesOf', {
        from: firstEntries,
        till: lastEntries,
        maxEntries: rowCount
      })}
    </Typography>
  )
}
