import { Divider } from '@mui/material'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { useProfessionWithCategoriesQuery } from '@typings/graphql'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useQuestionsContext } from '../provider/QuestionsProvider'

export const QuestionTopicInputs: React.FC = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()
  const { professionId } = useQuestionsContext()

  const watchedCategory = useWatch({
    name: 'categoryId',
    control
  })

  const { data: categoryData } = useProfessionWithCategoriesQuery({
    variables: {
      id: professionId as string
    },
    skip: !professionId
  })

  const mappedCategories = React.useMemo(() => {
    if (!categoryData) {
      return []
    }

    return categoryData.profession.categories.map((category) => ({
      id: category.id,
      name: category.texts.title
    }))
  }, [categoryData])

  const mappedSubCategories = React.useMemo(() => {
    if (!categoryData || !watchedCategory) {
      return []
    }

    const currentCategory = categoryData.profession.categories.find((category) => category.id === watchedCategory)

    return currentCategory?.childCategories.map((subCategory) => ({
      id: subCategory.id,
      name: subCategory.texts.title
    })) ?? []
  }, [watchedCategory, categoryData])

  return (
    <>
      <BaseSelectInput
        formKey="categoryId"
        title={t('common.category')}
        options={{
          required: true
        }}
        items={mappedCategories}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSelectInput
        formKey="subCategoryId"
        title={t('common.subCategory')}
        disabled={!watchedCategory}
        options={{
          required: true
        }}
        items={mappedSubCategories}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
    </>
  )
}
