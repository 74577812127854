import React from 'react'
import { AppRouteObject, baseRoutes, restrictedRoutes } from '@services/router/router'
import { useAppSelector } from '@services/store/store'
import { Roles } from '@typings/roles'
import { checkPartnerRestriction } from '@utils/permissions'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export type RoutesProviderContextType = {
  routes: AppRouteObject[]
}

const RoutesProviderContext = React.createContext<RoutesProviderContextType>(
  {} as any
)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter
)

export const RoutesProvider:React.FC = () => {
  const [routes, setAllowedRoutes] = React.useState<AppRouteObject[]>(baseRoutes)

  const { user: authUser } = useAppSelector(({ auth }) => auth)

  const filterNestedRoutes = React.useCallback((relevantRoutes: AppRouteObject[]): AppRouteObject[] => {
    if (!authUser) {
      return []
    }

    const routesClone = [...relevantRoutes]

    return routesClone.reduce<AppRouteObject[]>((acc, route) => {
      if (route.access) {
        const { roles, partner } = route.access
        let hasAccess = roles.includes(authUser.role.key as Roles)
        const partnerRestriction = partner ? checkPartnerRestriction() : true

        let children: AppRouteObject[] = []

        if (hasAccess && !authUser.company && authUser.role.key === Roles.COMPANY) {
          hasAccess = false
        }

        if (route.children) {
          children = filterNestedRoutes(route.children)
        }

        return hasAccess && partnerRestriction
          ? [...acc, {
            ...route,
            children
          } as AppRouteObject]
          : acc
      }

      return [...acc, route]
    }, [] as AppRouteObject[])
  }, [authUser])

  const router = React.useMemo(() => {
    const filteredRoutes = [...baseRoutes, ...filterNestedRoutes(restrictedRoutes)]
    setAllowedRoutes(filteredRoutes)

    return sentryCreateBrowserRouter(filteredRoutes)
  }, [authUser])

  const value = React.useMemo<RoutesProviderContextType>(() => ({
    routes
  }), [routes, authUser])

  return (
    <RoutesProviderContext.Provider value={value}>
      <RouterProvider router={router} />
    </RoutesProviderContext.Provider>
  )
}

export const useRoutesContext = () => React.useContext(RoutesProviderContext)
