import { GridColDef } from '@mui/x-data-grid-pro'
import { activeColumnModel } from '@shared/dataGrid/columns/activeColumn'
import { emailColumnModel } from '@shared/dataGrid/columns/emailColumn'
import { firstnameColumnModel } from '@shared/dataGrid/columns/firstnameColumn'
import { lastnameColumnModel } from '@shared/dataGrid/columns/lastnameColumn'

import { InstructorsRowModel } from '../provider/InstructorsProvider'

import { trainingCompanyColumnModel } from './column/trainingCompanyColumn'
import { traineesColumnModel } from './column/traineesColumn'

export type InstructorsColumnField = keyof InstructorsRowModel

export type InstructorColumnType = Omit<GridColDef, 'field'> & {
  field: InstructorsColumnField,
  hideHeader?: boolean,
}

export const instructorsDataGridColumns: InstructorColumnType[] = [
  emailColumnModel as InstructorColumnType,
  firstnameColumnModel as InstructorColumnType,
  lastnameColumnModel as InstructorColumnType,
  trainingCompanyColumnModel,
  traineesColumnModel,
  activeColumnModel as InstructorColumnType
]
