import React from 'react'
import { InvitationAcceptView } from '@features/auth/views/InvitationAcceptView'
import LoginView from '@features/auth/views/LoginView'
import { PasswordForgotView } from '@features/auth/views/PasswordForgotView'
import { PasswordResetView } from '@features/auth/views/PasswordResetView'
import { RegistrationView } from '@features/auth/views/RegistrationView'
import { RegistrationConfirmationView } from '@features/auth/views/registrationConfirmation'

import { AppRouteObject } from '../router'

export const authRoutes: AppRouteObject[] = [
  {
    path: '/login',
    element: <LoginView />
  },

  {
    path: '/registration-training-company',
    element: <RegistrationView type='trainingCompany'/>
  },

  {
    path: '/registration-company',
    element: <RegistrationView type='company'/>
  },

  {
    path: '/forgot-password',
    element: <PasswordForgotView />
  },

  {
    path: '/email-confirmed/:token',
    element: <RegistrationConfirmationView />
  },

  {
    path: '/set-password/:token',
    element: <PasswordResetView />
  },

  {
    path: '/accept-invite/:token',
    element: <InvitationAcceptView />
  }
]
