import React from 'react'
import { themeIcons } from '@theme/theme'
import { palette } from '@theme/core/palette/palette'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { FilterOperators } from '@typings/graphql'

import { CompanyColumnType } from '../companiesDataGridColumns'

export const isPartnerColumnModel: CompanyColumnType = {
  field: 'isPartner',
  width: 100,
  filterOperators: getGridBooleanOperators().map((operator) => {
    if (operator.value === 'is') {
      operator.value = FilterOperators.Equals
    }

    return operator
  }),
  renderCell: (params) => (
    <themeIcons.Star sx={{
      path: {
        fill: params.value ? palette.primary.main : 'transparent',
        stroke: params.value ? palette.primary.main : 'currentColor'
      }
    }}
    />
  )
}
