import React from 'react'
import { Box, IconButton, SxProps, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/theme'
import { useTranslation } from 'react-i18next'

import { BaseColumnType } from '../types/types'

type ActionsCellProps = {
  onShow?: () => void,
  onEdit?: () => void,
  onDelete?: () => void
  onCopy?: () => void,
  additionalActions?: React.ReactNode,
}

export const actionColumnIconStyles: SxProps = {
  width: '20px',
  height: '20px'
}

export const ActionColumnRenderCell: React.FC<ActionsCellProps> = ({ onShow, onCopy, onDelete, onEdit, additionalActions }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }}
    >
      {additionalActions}
      {onShow &&
      <Tooltip title={t('tooltips.showDetails')}>
        <IconButton onClick={onShow}>
          <themeIcons.Visibility sx={actionColumnIconStyles} />
        </IconButton>
      </Tooltip>
      }
      {onCopy &&
      <Tooltip title={t('tooltips.copyEntry')}>
        <IconButton onClick={onCopy}>
          <themeIcons.ContentCopy sx={actionColumnIconStyles} />
        </IconButton>
      </Tooltip>
      }
      {onDelete &&
      <Tooltip title={t('tooltips.deleteEntry')}>
        <IconButton onClick={onDelete}>
          <themeIcons.Delete sx={actionColumnIconStyles} />
        </IconButton>
      </Tooltip>
      }
      {onEdit &&
      <Tooltip title={t('tooltips.editEntry')}>
        <IconButton onClick={onEdit}>
          <themeIcons.Edit sx={actionColumnIconStyles} />
        </IconButton>
      </Tooltip>
      }
    </Box>
  )
}

export const actionColumnModel: BaseColumnType = {
  field: 'action',
  width: 125,
  filterable: false,
  sortable: false,
  hideHeader: true
}
