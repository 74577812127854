import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material'
import React from 'react'

type Props = SnackbarProps & {
  severity: AlertProps['severity']
  message: string
  onClose: () => void
}

export const DefaultSnackbar: React.FC<Props> = ({ severity, message, sx, onClose, ...props }) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        ...sx,
        position: 'absolute'
      }}
      onClose={onClose}
      {...props}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
