import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { TextInput } from '@shared/components/inputs/TextInput'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import React from 'react'
import { useController, useFormContext /*, useWatch */ } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MIN_DROP_ZONES = 2

type Answer = {
  id: number
  solution?: number
}

export const QuestionDragDropInput: React.FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues, control, watch } = useFormContext()

  const [answers, setAnswers] = React.useState<Answer[]>([])
  const [dropZones, setDropZones] = React.useState<number[]>(new Array(MIN_DROP_ZONES).fill(null).map((_, index) => index))

  // const watchedSolution = useWatch({
  //   name: 'config.solution',
  //   control
  // }) as number[][]

  useController({
    name: 'config.solution',
    control,
    defaultValue: [],
    rules: {
      validate: (value) => {
        return value?.flatMap((item: any) => item).length > 0 && answers.length > 0
      }
    }
  })

  React.useEffect(() => {
    const solutionItems = getValues('config.solution') as number[][]
    const texts = getValues('texts')

    const targetTextKeys = Object.keys(texts).filter((key) => key.startsWith('target_'))
      .map((key) => parseInt(key.split('_')[1], 10))
    targetTextKeys.sort((a, b) => a - b)

    const answerTextKeys = Object.keys(texts).filter((key) => key.startsWith('answer_'))
      .map((key) => parseInt(key.split('_')[1], 10))
    answerTextKeys.sort((a, b) => a - b)

    const targetDropZones = targetTextKeys.length > 0 ? targetTextKeys : new Array(MIN_DROP_ZONES).fill(null).map((_, index) => index)

    const existingAnswers = answerTextKeys.map((key, index) => {
      const solutionIndex = solutionItems?.findIndex((item) => item.includes(index))
      return {
        id: key,
        ...(solutionIndex !== -1 && { solution: targetDropZones[solutionIndex] })
      }
    })

    setDropZones(targetDropZones)
    setAnswers(existingAnswers)
  }, [])

  const dropZonePlaceholder = React.useMemo(() => {
    return [t('questions.debit'), t('questions.credit')]
  }, [])

  const updateSolutionConfig = (newAnswers: Answer[], zones: number[]) => {
    const newSolution = zones.map((zone) => {
      const solutions = newAnswers.filter((answer) => answer.solution === zone)
      return solutions.map((solution) => newAnswers.findIndex((answer) => answer.id === solution.id))
    })

    setValue('config.solution', newSolution, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const removeText = (key: string) => {
    const texts = getValues('texts')

    delete texts[key]

    setValue('texts', texts, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleRemoveAnswer = (id: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.filter((item) => item.id !== id)

      updateSolutionConfig(newAnswers, dropZones)
      return newAnswers
    })

    removeText(`answer_${id}`)
  }

  const handleDropZoneRemove = (id: number) => {
    setDropZones((prev) => {
      const newDropZones = prev.filter((item) => item !== id)

      updateSolutionConfig(answers, newDropZones)
      return newDropZones
    })

    removeText(`target_${id}`)
  }

  // const handleOnAddDropzone = () => {
  //   const newDropZoneId = dropZones.length ? Math.max(...dropZones) + 1 : 0

  //   setDropZones([...dropZones, newDropZoneId])
  //   setValue('config.solution', [...watchedSolution, []])
  // }

  const handleOnAddAnswer = () => {
    const newAnswerId = answers.length ? Math.max(...answers.map(({ id }) => id)) + 1 : 0

    setAnswers([...answers, {
      id: newAnswerId
    }])
  }

  const handleOnAddSolution = (answerId: number, dropZoneId: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.map((answer) => {
        if (answer.id === answerId) {
          const { solution, ...rest } = answer
          return {
            ...rest,
            ...(solution !== dropZoneId && { solution: dropZoneId })
          }
        }

        return answer
      })

      updateSolutionConfig(newAnswers, dropZones)

      return newAnswers
    })
  }

  return (
    <>
      <BaseInputRow
        title={t('questions.answers')}
        required
        description={t('questions.answerDnDDescription')}
      >
        {
          answers?.map((answer) => (
            <Box key={`dnd_answer_${answer.id}`} mb={2}>
              <DefaultAnswerRow
                onRemove={() => handleRemoveAnswer(answer.id)}
                renderedActions={(
                  <Box sx={{
                    ml: 1.5
                  }}
                  >
                    {
                    dropZones?.map((item, index) => (
                      <FormControlLabel
                        key={`target_${index}`}
                        sx={{
                          pr: 1
                        }}
                        control={
                          <Checkbox
                            checked={answer.solution === item}
                            onChange={() => handleOnAddSolution(answer.id, item)}
                            sx={{
                              mr: 1
                            }}
                          />
                        }
                        label={watch(`texts.target_${item}`) || dropZonePlaceholder[index] || ''}
                      />
                    ))}
                  </Box>
                )}
              >
                <TextInput
                  formKey={`texts.answer_${answer.id}`}
                  hideValidationText
                  options={{
                    required: true
                  }}
                  sx={{
                    backgroundColor: 'white'
                  }}
                />
              </DefaultAnswerRow>
            </Box>
          ))
        }

        <AddDefaultRow
          label={t('questions.addAnswer')}
          onAdd={handleOnAddAnswer}
        />
      </BaseInputRow>

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
      <BaseInputRow
        title={t('questions.solutionFields')}
        required
        description={t('questions.fieldDnDDescription')}
      >
        {
          dropZones?.map((id) => (
            <Box key={`drop_zone_${id}`} mb={2}>
              <DefaultAnswerRow
                {...(id >= MIN_DROP_ZONES && { onRemove: () => handleDropZoneRemove(id) })}
              >
                <TextInput
                  formKey={`texts.target_${id}`}
                  hideValidationText
                  placeholder={dropZonePlaceholder[id] || ''}
                  sx={{
                    backgroundColor: 'white'
                  }}
                />
              </DefaultAnswerRow>
            </Box>
          ))
        }

        {
        /* <AddDefaultRow
          label={t('questions.addField')}
          onAdd={handleOnAddDropzone}
        /> */
        }
      </BaseInputRow>

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.shuffle'
        defaultChecked
        title={t('questions.shuffle')}
        description={t('questions.shuffleDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
    </>
  )
}
