import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'

import { TraineeColumnType } from '../traineesDataGridColumns'

const RenderCell: React.FC<{ value: boolean }> = ({ value }) => {
  const { t } = useTranslation()

  return <Typography
    variant="subtitle1"
    fontWeight={500}
    sx={{
      color: value ? 'success.main' : 'warning.main'
    }}
  >
    {t(value ? 'common.yes' : 'common.no')}
  </Typography>
}

export const confirmedColumnModel: TraineeColumnType = {
  field: 'confirmed',
  width: 100,
  filterOperators: getGridBooleanOperators(),
  renderCell: (params) => <RenderCell value={!!params.value} />
}
