import { Box, Divider } from '@mui/material'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'
import { Roles } from '@typings/roles'
import { CompanySearch } from '@shared/components/inputs/CompanySearch'
import { useFormContext, useWatch } from 'react-hook-form'
import { mailPattern } from '@utils/pattern'
import { MAX_TRAINING_YEARS, MIN_TRAINING_YEARS } from '@shared/constants/trainingYears'
import { TrainingCompanySearch } from '@shared/components/inputs/TrainingCompanySearch'
import { canTrainingCompany } from '@utils/canTrainingCompany'

import { useUsersContext } from '../provider/UsersProvider'

export const UsersCreateEditInputs: React.FC = () => {
  const { roles } = useUsersContext()

  const { control } = useFormContext()

  const filteredRoles = React.useMemo(() => {
    return roles?.filter((role) => role.key !== Roles.TRAINEE) || []
  }, [roles])

  const watchedRole = useWatch({
    name: 'roleId',
    control
  })

  const watchedRoleKey = React.useMemo(() => {
    return roles?.find((role) => role.id === watchedRole)?.key
  }, [roles, watchedRole])

  const roleKey = React.useMemo(() => {
    return roles?.find((role) => role.id === watchedRole)?.key
  }, [roles, watchedRole])

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='email'
        options={{ required: true, pattern: mailPattern }}
        title={t('common.email')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='firstname'
        options={{ required: true }}
        title={t('common.firstName')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='lastname'
        options={{ required: true }}
        title={t('common.lastName')}
      />

      {roleKey !== Roles.TRAINEE && (
        <>
          <Divider
            sx={{
              mb: 3.5,
              mt: 1
            }}
            light
            orientation='horizontal'
          />

          <BaseSelectInput
            formKey="roleId"
            options={{ required: true }}
            title={t('common.role')}
            items={filteredRoles}
          />
        </>
      )}

      {roleKey === Roles.COMPANY && (
        <>
          <Divider
            sx={{
              mb: 3.5,
              mt: 3.5
            }}
            light
            orientation='horizontal'
          />

          <CompanySearch />
        </>
      )}

      {canTrainingCompany(roleKey as Roles) && (
        <>
          <Divider
            sx={{
              mb: 3.5,
              mt: 3.5
            }}
            light
            orientation='horizontal'
          />

          <TrainingCompanySearch />
        </>
      )}

      {roleKey === Roles.TRAINEE && (
        <>
          <Divider
            sx={{
              mb: 3.5,
              mt: watchedRoleKey !== Roles.TRAINEE ? 3.5 : 1
            }}
            light
            orientation='horizontal'
          />

          <BaseTextInput
            formKey='trainingYear'
            title={t('common.trainingYear')}
            description={t('users.trainingYearsDescription', { min: MIN_TRAINING_YEARS, max: MAX_TRAINING_YEARS }) }
            options={{
              validate: {
                valid: (value: number) => value >= MIN_TRAINING_YEARS && value <= MAX_TRAINING_YEARS
              }
            }
            }
            type='number'
          />
        </>
      )}
    </Box>
  )
}
