import React from 'react'
import { TrainingCompaniesCreateView } from '@features/trainingCompanies/views/TrainingCompaniesCreateView'
import { TrainingCompaniesEditView } from '@features/trainingCompanies/views/TrainingCompaniesEditView'
import { TrainingCompaniesView } from '@features/trainingCompanies/views/TrainingCompaniesView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const trainingCompaniesRoutes: AppRouteObject[] = [
  {
    path: '/training-companies',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.ADMIN]
    },
    meta: {
      name: 'trainingCompanies',
      navItem: true,
      navIcon: 'School',
      navOrder: 5
    },
    children: [{
      index: true,
      element: <TrainingCompaniesView />
    }, {
      path: 'create',
      meta: {
        name: 'trainingCompaniesCreate'
      },
      element: <TrainingCompaniesCreateView />,
      access: {
        roles: [Roles.ADMIN, Roles.TRAINING_COMPANY_MANAGER]
      }
    }, {
      path: ':id',
      meta: {
        name: 'trainingCompaniesEdit'
      },
      element: <TrainingCompaniesEditView />,
      access: {
        roles: [Roles.ADMIN, Roles.TRAINING_COMPANY_MANAGER]
      }
    }]
  }
]
