import React from 'react'
import { ProfileEditView } from '@features/settings/views/ProfileEditView'
import { SettingsView } from '@features/settings/views/SettingsView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'

import { AppRouteObject } from '../router'

export const settingsRoutes: AppRouteObject[] = [
  {
    path: '/settings',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    meta: {
      name: 'settings'
    },
    children: [{
      index: true,
      element: <SettingsView />
    }, {
      path: 'edit-profile',
      element: <ProfileEditView />,
      meta: {
        name: 'profileEdit'
      }
    }]
  }
]
