import React from 'react'
import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { typography } from '@theme/core/typography/typography'
import { themeIcons } from '@theme/theme'

export type MuiSelectType = {
  defaultProps?: ComponentsProps['MuiSelect'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
  variants?: ComponentsVariants['MuiSelect'];
}

export const MuiSelect:MuiSelectType = {
  defaultProps: {
    IconComponent: () => <themeIcons.ArrowDown sx={{
      position: 'absolute',
      width: '20px',
      height: '20px',
      right: '12px',
      pointerEvents: 'none'
    }}
    />,
    className: 'MuiSelect-overwrite'
  },
  styleOverrides: {
    select: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      ...typography.button
    }
  }
}
