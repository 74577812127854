import type React from 'react'
import {
  FormatQuote,
  SvgIconComponent
} from '@mui/icons-material'
import type { SvgIconProps } from '@mui/material'

import { Trophy } from './icons/Trophy'
import { FaceContent } from './icons/FaceContent'
import { FaceFrown } from './icons/FaceFrown'
import { Book } from './icons/Book'
import { FileQuestion } from './icons/FileQuestion'
import { FolderQuestion } from './icons/FolderQuestion'
import { ContentPaste } from './icons/ContentPaste'
import { Refresh } from './icons/Refresh'
import { File } from './icons/File'
import { BarChart } from './icons/BarChart'
import { Coins } from './icons/Coins'
import { CalendarToday } from './icons/CalendarToday'
import { FormatListNumbered } from './icons/FormatListNumbered'
import { FormatItalic } from './icons/FormatItalic'
import { FormatBold } from './icons/FormatBold'
import { Check } from './icons/Check'
import { Download } from './icons/Download'
import { Remove } from './icons/Remove'
import { ArrowBack } from './icons/ArrowBack'
import { ArrowForward } from './icons/ArrowForward'
import { Logout } from './icons/Logout'
import { Search } from './icons/Search'
import { Home } from './icons/Home'
import { Analytics } from './icons/Analytics'
import { Settings } from './icons/Settings'
import { Person } from './icons/Person'
import { Layers } from './icons/Layers'
import { ArrowLeft } from './icons/ArrowLeft'
import { ArrowUp } from './icons/ArrowUp'
import { ArrowDown } from './icons/ArrowDown'
import { ArrowRight } from './icons/ArrowRight'
import { PushPin } from './icons/PushPin'
import { VerifiedUser } from './icons/VerifiedUser'
import { Add } from './icons/Add'
import { ArrowDownward } from './icons/ArrowDownward'
import { ArrowUpward } from './icons/ArrowUpward'
import { Edit } from './icons/Edit'
import { ContentCopy } from './icons/ContentCopy'
import { Delete } from './icons/Delete'
import { Visibility } from './icons/Visibility'
import { Email } from './icons/Email'
import { Link } from './icons/Link'
import { FormatListBulleted } from './icons/FormatListBulleted'
import { FormatLink } from './icons/FormatLink'
import { Work } from './icons/Work'
import { Group } from './icons/Group'
import { Error } from './icons/Error'
import { Star } from './icons/Star'
import { CloudUpload } from './icons/CloudUpload'
import { Fullscreen } from './icons/Fullscreen'
import { Cancel } from './icons/Cancel'
import { School } from './icons/School'
import { Building } from './icons/Building'
import { VisibilityOff } from './icons/VisibilityOff'
import { Info } from './icons/Info'
import { DirectionsCar } from './icons/DirectionsCar'
import { Mail } from './icons/Mail'
import { Drafts } from './icons/Drafts'
import { CreditCard } from './icons/CreditCard'
import { Filter } from './icons/Filter'
import { LineWeight } from './icons/LineWeigth'
import { Share } from './icons/Share'
import { SearchBig } from './icons/SearchBig'
import { FormatHeading } from './icons/FormatHeading'
import { Help } from './icons/Help'
import { Expand } from './icons/Expand'
import { Minimize } from './icons/Minimize'
import { Grab } from './icons/Grab'
import { AnnotationCheck } from './icons/AnnotationCheck'
import { Flag } from './icons/Flag'
import { Certificate } from './icons/Certificate'
import { Clock } from './icons/Clock'
import { AccessAlarm } from './icons/AccessAlarm'
import { Award } from './icons/Award'
import { Scale } from './icons/Scale'
import { Hourglass } from './icons/Hourglass'

export type ThemeIcons = Record<string, SvgIconComponent | React.FC<SvgIconProps>>

export const customThemeIcons = {
  AccessAlarm,
  Add,
  Analytics,
  AnnotationCheck,
  ArrowBack,
  ArrowDown,
  ArrowDownward,
  ArrowForward,
  ArrowLeft,
  ArrowUp,
  ArrowUpward,
  ArrowRight,
  Award,
  BarChart,
  Book,
  Building,
  Cancel,
  CalendarToday,
  Certificate,
  Check,
  Clock,
  CloudUpload,
  Coins,
  ContentPaste,
  CreditCard,
  ContentCopy,
  Delete,
  DirectionsCar,
  Download,
  Drafts,
  Edit,
  Email,
  Error,
  Expand,
  FaceContent,
  FaceFrown,
  File,
  FileQuestion,
  Filter,
  Flag,
  FolderQuestion,
  FormatBold,
  FormatHeading,
  FormatItalic,
  FormatLink,
  FormatListNumbered,
  FormatListBulleted,
  FormatQuote,
  Fullscreen,
  Grab,
  Help,
  Home,
  Hourglass,
  Info,
  Group,
  Person,
  PushPin,
  Layers,
  LineWeight,
  Link,
  Logout,
  Mail,
  Minimize,
  Remove,
  Scale,
  School,
  Search,
  SearchBig,
  Settings,
  Share,
  Star,
  Trophy,
  Refresh,
  VerifiedUser,
  Visibility,
  VisibilityOff,
  Work
}

export const themeIcons = {
  ...customThemeIcons
}
