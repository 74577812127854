import { useAppDispatch, useAppSelector } from '@services/store/store'
import { isLoggedIn, resetAuthData } from '@services/store/slices/auth'
import React, { ReactElement } from 'react'
import { Navigate } from 'react-router'
import { useMatchedRoutes } from '@hooks/useMatchedRoute'

type Props = {
  component: ReactElement,
}

const ProtectedRoute: React.FC<Props> = ({ component }) => {
  const isUserLoggedIn = useAppSelector(isLoggedIn)
  const { matched } = useMatchedRoutes()
  const dispatch = useAppDispatch()

  if (!isUserLoggedIn) {
    dispatch(resetAuthData())
    return <Navigate to="/login"
      state={{
        from: matched?.[0].pathname || ''
      }}
    />
  }

  return component
}

export default ProtectedRoute
