import { Box, BoxProps, Typography } from '@mui/material'
import { fontSizes } from '@theme/theme'
import React from 'react'

export type DashboardCardProps = BoxProps & {
  title?: string
  titlePrefix?: React.ReactNode,
  additionalActions?: React.ReactNode,
  contentContainerProps?: BoxProps
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  titlePrefix,
  additionalActions,
  contentContainerProps,
  children,
  sx,
  ...props
}) => {
  const { sx: containerSx, ...containerProps } = contentContainerProps || {}

  return (
    <Box sx={{
      border: '1px solid',
      borderRadius: '8px',
      borderColor: 'grey.300',
      position: 'relative',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      ...sx
    }}
      {...props}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 0
      }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {titlePrefix && (<Box mr={1}>{titlePrefix}</Box>)}
          {title && <Typography
            variant="h6"
            fontSize={fontSizes.lg}
          >{title}</Typography>}
        </Box>
        {!!additionalActions && additionalActions}
      </Box>
      <Box sx={{
        overflow: 'hidden',
        flex: 1,
        ...containerSx
      }}
        {...containerProps}
      >
        {children}
      </Box>
    </Box>
  )
}
