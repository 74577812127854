import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'

import { QuestionListRowModel } from '../provider/QuestionListProvider'

import { titleColumnModel } from './columns/titleColumn'
import { typeColumnModel } from './columns/typeColumn'
import { reviewStatusColumnModel } from './columns/reviewStatusColumn'
import { keyColumnModel } from './columns/keyColumn'
import { topicColumnModel } from './columns/topicColumn'
import { categoryColumnModel } from './columns/categoryColumn'
import { professionColumnModel } from './columns/professionColumn'
import { inDuelAvailableColumnModel } from './columns/inDuelAvailableColumn'
import { topicIndexColumnModel } from './columns/topicIndexColumn'

export type QuestionListColumnField = keyof QuestionListRowModel

export type QuestionListColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: QuestionListColumnField,
  hideHeader?: boolean,
}

export const questionListDataGridColumns: QuestionListColumnType[] = [
  keyColumnModel,
  typeColumnModel,
  titleColumnModel,
  reviewStatusColumnModel,
  inDuelAvailableColumnModel,
  professionColumnModel,
  categoryColumnModel,
  topicIndexColumnModel,
  topicColumnModel
]
