import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = {
  title: string
  color?: string
}

export const DashboardLegendItem: React.FC<Props> = ({ title, color }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}
    >
      <Box sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color || 'primary.main',
        mr: 1
      }}
      />
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  )
}
