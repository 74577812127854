import { Box, Button } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { PaymentRedirectionButton, PaymentRedirectionTypes } from '@shared/components/ui/PaymentRedirectionButton'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { JobDataGrid } from '../dataGrid/JobDataGrid'
import { JobWallProvider, useJobsContext } from '../provider/JobWallProvider'
import { JobCreditExtension } from '../components/JobCreditExtension'

const JobWallViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { selectedIds, handleDelete, myCompany } = useJobsContext()

  return (
    <DefaultLayout
      title={t('routes.jobwall')}
      description={t('jobwall.jobListDescription')}
      headerExtension={
        <Box sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          height: '100%'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative'
          }}
          >
            {can(['jobs:delete', 'jobs:delete.own']) && selectedIds?.length > 0 && (
            <DeleteIconButton
              sx={{ mr: 2 }}
              onClick={() => handleDelete(true)}
            />
            )}

            {can(['jobs:create', 'jobs:create.own']) && (
            <Button startIcon={<themeIcons.Add />}
              onClick={() => navigate('create')}
            >
              {t('jobwall.addJob')}
            </Button>
            )}
          </Box>
          {myCompany && !myCompany.isPartner && (
            <Box sx={{
              display: 'flex',
              mt: 1,
              flexDirection: 'row'
            }}
            >
              <JobCreditExtension
                jobBasicCredits={myCompany.credits.jobBasic}
                jobExtensionCredits={myCompany.credits.jobExtension}
              ></JobCreditExtension>
              <Box mr={1} />
              <PaymentRedirectionButton type={PaymentRedirectionTypes.JOB_BASIC_CREDIT} params={{ id: myCompany.id }} />
            </Box>

          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <JobDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const JobWallView: React.FC = () => (
  <JobWallProvider>
    <JobWallViewContent />
  </JobWallProvider>
)
