import { Box, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'

type Props = {
  label: string
  icon?: keyof typeof themeIcons,
  onNavigate?: () => void,
  selected: boolean
}

export const NavSidebarLinkItem: React.FC<Props> = ({ label, icon, onNavigate, selected }) => {
  const Icon = React.useMemo(() => icon ? themeIcons[icon] : null, [icon])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'text.secondary',
      borderRadius: '6px',
      cursor: 'pointer',
      px: 2,
      py: 1,
      backgroundColor: selected ? 'grey.800' : 'transparent',
      '&:hover': {
        color: 'grey.500'
      }
    }}
      onClick={onNavigate}
    >
      {Icon && <Icon sx={{ mr: 2 }} />}
      <Typography variant="h6">{label}</Typography>
    </Box>
  )
}
