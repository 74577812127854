import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDashboardTrainingCompanyContext } from '../provider/DashboardTrainingCompanyProvider'

export const TrainingYearFilter: React.FC = () => {
  const { t } = useTranslation()
  const {
    availableTrainingYears,
    trainingYearFilter,
    updateTrainingYearFilter
  } = useDashboardTrainingCompanyContext()

  const tooltipText = React.useMemo(() => {
    const trainingYears = availableTrainingYears.filter((year) => trainingYearFilter.includes(year))

    return trainingYears.map((year) => t('common.trainingYearWithNumber', { year })).join(', ')
  }, [availableTrainingYears, trainingYearFilter])

  return (
    <FormControl fullWidth>
      <InputLabel
        id={'training_year_label'}
      >
        {t('common.trainingYear')}
      </InputLabel>
      <Select
        fullWidth
        labelId="training_year_label"
        multiple
        input={<OutlinedInput label={t('common.trainingYear')} />}
        renderValue={() => (
          <Tooltip title={tooltipText}>
            <Typography sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            >
              {tooltipText}
            </Typography>
          </Tooltip>
        )}
        value={trainingYearFilter}
        onChange={(e) => updateTrainingYearFilter(e.target.value as number[])}
      >
        {availableTrainingYears?.map((year) => (
          <MenuItem key={`training_year_${year}`} value={year}>
            {t('common.trainingYearWithNumber', { year })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
