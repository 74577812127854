import React from 'react'
import { Button, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router'
import { CategoryRowModel } from '@features/categories/provider/CategoriesProvider'

import { CategoriesColumnType } from '../categoriesDataGridColumns'

const RenderCell: React.FC<{row: CategoryRowModel}> = ({ row }) => {
  const navigate = useNavigate()

  return (
    <Button
      variant="text"
      sx={{
        '&.MuiButton-root': {
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: 'flex-start'
        }
      }}
      onClick={() => navigate(`${row.id}`)}
    >
      <Typography
        variant="subtitle1"
        fontWeight={500}
      >{row.texts?.title}</Typography>
    </Button>
  )
}
export const titleColumnModel: CategoriesColumnType = {
  field: 'title',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter (params) {
    return params.row.texts?.title
  },
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
