import { LoadingButton } from '@mui/lab'
import i18n from '@services/i18n/i18n'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateTrainingCompanyInput, CreateTrainingCompanyMutation, useCreateTrainingCompanyMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { Box } from '@mui/material'
import { useFileUpload } from '@hooks/useFileUpload'
import { EditableFile } from '@typings/files'
import { FetchResult } from '@apollo/client'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { TrainingCompaniesCreateEditInputs } from '../components/TrainingCompaniesCreateEditInputs'

type TrainingCompanyInputType = Omit<CreateTrainingCompanyInput, 'texts'> & {
  texts: Record<string, string>
  files: Record<string, EditableFile<any> | null>
  users: SearchInputItem[]
}

const TrainingCompaniesCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const { errorMessage, setErrorMessage, setMessageByCode } = useErrorMessage()

  const { uploadFile } = useFileUpload()

  const navigate = useNavigate()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext<TrainingCompanyInputType>()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const [createTrainingCompany] = useCreateTrainingCompanyMutation()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    const { texts, files, users, active, ...rest } = input

    let result: FetchResult<CreateTrainingCompanyMutation>

    try {
      result = await createTrainingCompany({
        variables: {
          data: {
            ...rest,
            instructorIds: users?.map(({ id }) => id) ?? [],
            ...(isRole(Roles.ADMIN) && { active }),
            texts: Object.keys(texts).filter((key) => !!texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: texts[filtered]
            }))
          }
        }
      })

      Object.keys(files)?.forEach(async (key) => {
        const file = files[key]

        if (file?.upload && result.data?.createTrainingCompany) {
          await uploadFile(file.upload.file, file.upload.data.key, 'TrainingCompany', result.data.createTrainingCompany.id)
        }
      })

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/training-companies')
  }

  return (
    <CreateEditLayout
      title={t('routes.trainingCompaniesCreate')}
      description={t('trainingCompanies.createDescription')}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          {isRole(Roles.ADMIN) &&
          <>
            <StandaloneSwitchInput
              formKey="active"
              label={t('trainingCompanies.column.active')}
            />
          </>
          }
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >

      <TrainingCompaniesCreateEditInputs loading={loading} />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
        onCancel={handleConfirm}
      >
        {t('trainingCompanies.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const TrainingCompaniesCreateView: React.FC = () => {
  const formData = useForm<TrainingCompanyInputType>({
    defaultValues: {
      active: false
    },
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <TrainingCompaniesCreateViewContent />
    </FormProvider>
  )
}
