import { useDataGridPagination } from '@hooks/useDataGridPagination'
import { useErrorMessage } from '@hooks/useErrorMessage'
import {
  GridCallbackDetails,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  GridInitialState
} from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import {
  CategoryQuery,
  FilterOperators,
  QuestionsPaginatedQuery,
  TopicQuery,
  useCategoryQuery,
  useDeleteQuestionMutation,
  useDeleteQuestionsMutation,
  useQuestionsPaginatedQuery,
  useTopicQuery
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'

export type QuestionType = Exclude<QuestionsPaginatedQuery['questionsPaginated']['edges'], null | undefined>[0]
export type QuestionRowModel = Omit<QuestionType, '__typename'>['node'] & {
  title?: string
  key?: string,
  topicTitle?: string,
}

export type CategoryType = CategoryQuery['category']
export type TopicType = TopicQuery['topic']

export type QuestionsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: QuestionRowModel | null
  categoryData?: CategoryType
  topic?: TopicType
  questions?: QuestionsPaginatedQuery['questionsPaginated']
  loading?: boolean
  copyMultiple?: boolean
  showDuplicateDialog: boolean
  professionId?: string
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  handleFetchMore: () => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updatePaginationModel: (pagination: GridPaginationModel) => void
  updateSortModel: (model: GridSortModel, details: GridCallbackDetails) => void
  updateShowDuplicateDialog: (show: boolean, multiple?: boolean) => void
  updateFilterModel: (filter: GridFilterModel | undefined) => void
  updateSelectedItem: (row: QuestionRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  updateProfessionId?: (id?: string) => void
  updateInitialState: (initialState?: GridInitialState) => void
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const QuestionsProviderContext = React.createContext<QuestionsProviderContextType>(
  {} as any
)

export const QuestionsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()
  const { subCategoryId, professionId, topicId } = useParams<{ topicId: string, subCategoryId: string, professionId: string }>()
  const location = useLocation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<QuestionRowModel | null>(null)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [copyMultiple, setCopyMultiple] = React.useState(false)
  const [showDuplicateDialog, setShowDuplicateDialog] = React.useState<boolean>(false)
  const [currentProfessionId, setCurrentProfessionId] = React.useState<string>(professionId || '')
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [loadMore, setLoadMore] = React.useState(false)

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const [deleteQuestion] = useDeleteQuestionMutation()
  const [deleteQuestions] = useDeleteQuestionsMutation()

  const {
    fetchVariablesRef, filterModel, paginationModel, sortModel,
    setFilterModelState, setPaginationModelState, setSortModelState, setInitialState
  } = useDataGridPagination<QuestionsPaginatedQuery>()

  const isTopics = React.useMemo(() => location.pathname.includes('topics'), [location])

  const baseFilter = React.useMemo(() => {
    return [isTopics
      ? {
          field: 'topicId',
          operator: FilterOperators.Equals,
          value: topicId as string
        }
      : {
          field: 'categoryId',
          operator: FilterOperators.Equals,
          value: subCategoryId as string
        }]
  }, [isTopics])

  const { data: paginationData, loading: questionsLoading, refetch } = useQuestionsPaginatedQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: baseFilter,
      first: DATA_GRID_PAGE_SIZE
    }
  })

  const questionData = React.useMemo<QuestionsPaginatedQuery['questionsPaginated'] | undefined>(() => {
    return paginationData?.questionsPaginated
  }, [paginationData])

  const { data: categoryData, loading: categoryLoading } = useCategoryQuery({
    variables: {
      id: subCategoryId as string
    },
    fetchPolicy: 'cache-and-network',
    skip: !subCategoryId || isTopics
  })

  const { data: topicQuestions, loading: topicQuestionsLoading } = useTopicQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: topicId as string
    },
    skip: !topicId || !isTopics
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const handleFetchMore = async () => {
    setErrorMessage('')
    setLoadMore(true)
    try {
      await refetch({
        ...fetchVariablesRef.current,
        filter: [
          ...fetchVariablesRef.current?.filter || [],
          ...baseFilter
        ]
      })
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoadMore(false)
    }
  }

  const topic = React.useMemo(() => {
    return topicQuestions?.topic
  }, [topicQuestions])

  const category = React.useMemo(() => {
    return categoryData?.category
  }, [categoryData])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteQuestions({
          variables: {
            ids: selectedIds as string[]
          }
        })

        setSelectedIds([])
        setSuccessMessage(t('questions.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteQuestion({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('questions.deleteSuccess'))
      }

      if (!disableFetching) {
        await handleFetchMore()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updateSelectedItem = React.useCallback((row: QuestionRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateShowDuplicateDialog = React.useCallback((open: boolean, multiple = false) => {
    setShowDuplicateDialog(open)
    setCopyMultiple(multiple)
  }, [])

  const handleDeleteCancel = React.useCallback(() => {
    setClickedRow(null)
    setShowDeleteDialog(false)
  }, [])

  const updateProfessionId = React.useCallback((id?: string) => {
    setCurrentProfessionId(id || '')
  }, [])

  const loading = React.useMemo(() => {
    return categoryLoading || topicQuestionsLoading || artificialLoading || questionsLoading || loadMore
  }, [categoryLoading, topicQuestionsLoading, artificialLoading, questionsLoading, loadMore])

  const updatePaginationModel = React.useCallback((pagination: GridPaginationModel) => {
    setPaginationModelState(pagination, questionData?.pageInfo.startCursor, questionData?.pageInfo.endCursor)

    handleFetchMore()
  }, [questionData])

  const updateSortModel = React.useCallback((model: GridSortModel) => {
    setSortModelState(model)
    handleFetchMore()
  }, [questionData])

  const updateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    setFilterModelState(filter)
    handleFetchMore()
  }, [])

  const updateInitialState = React.useCallback((initialState?: GridInitialState) => {
    setInitialState(initialState)
    handleFetchMore()
  }, [])

  const value = React.useMemo(() => ({
    categoryData: category,
    selectedIds,
    selectedItem,
    questions: questionData,
    loading,
    copyMultiple,
    topic,
    showDuplicateDialog,
    professionId: currentProfessionId,
    paginationModel,
    sortModel,
    filterModel,
    handleDelete,
    handleFetchMore,
    updatePaginationModel,
    updateSortModel,
    updateFilterModel,
    updateShowDuplicateDialog,
    updateSelectedItem,
    updateSelectedIds,
    updateProfessionId,
    updateInitialState
  }), [category,
    loading,
    questionData,
    copyMultiple,
    showDuplicateDialog,
    topic,
    selectedIds,
    selectedItem,
    sortModel,
    filterModel,
    paginationModel,
    currentProfessionId])

  return (
    <>
      <QuestionsProviderContext.Provider value={value}>
        {children}
      </QuestionsProviderContext.Provider>

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={handleDeleteCancel}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'questions.deleteEntries' : 'questions.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.key } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </>
  )
}

export const useQuestionsContext = () => React.useContext(QuestionsProviderContext)
