import { Button, ButtonGroup } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'

type TabItem = {
  target: string
  label: string
}

type Props = {
  items: TabItem[]
}

export const DefaultTabNavigationBar: React.FC<Props> = ({ items }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleNavigate = (target: string) => {
    navigate(target)
  }

  return (
    <ButtonGroup variant="outlined">
      {items.map((item) => (
        <Button
          key={item.target}
          onClick={() => handleNavigate(item.target)}
          sx={{
            '&.Mui-disabled': {
              pointerEvents: 'all',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            },
            ...((pathname === item.target) && {
              '&.MuiButton-outlined': {
                backgroundColor: 'primary.main',
                borderColor: 'primary.main',
                color: 'white',
                ':hover': {
                  backgroundColor: 'primary.800',
                  borderColor: 'primary.800'
                }
              }
            })
          }}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}
