import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { t } from 'i18next'

import { TopicsColumnType } from '../topicsDataGridColumns'

export const topicColumnModel: TopicsColumnType = {
  field: 'topic',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter (params) {
    return t('common.topicWithIndex', { index: params.row.index })
  }
}
