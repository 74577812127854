import React from 'react'
import { Provider } from 'react-redux'
import { store } from '@services/index'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '@services/apollo/apollo'
import { theme } from '@theme/theme'
import { useTranslation } from 'react-i18next'

import { RoutesProvider } from './RoutesProvider'
import { DialogProvider } from './DialogProvider'

const CoreProvider: React.FC = () => {
  const { ready } = useTranslation()

  if (!ready) {
    return null
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DialogProvider>
            <RoutesProvider />
          </DialogProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default CoreProvider
