import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'
import { GridColDef } from '@mui/x-data-grid-pro'

import { CompaniesRowModel } from '../provider/CompaniesProvider'

import { isPartnerColumnModel } from './columns/isPartnerColumn'
import { categoryColumnModel } from './columns/categoryColumn'
import { jobCreditsColumnModel } from './columns/jobCreditsColumn'
import { idColumnModel } from './columns/idColumn'
import { nameColumnModel } from './columns/nameColumn'
import { contactsColumnModel } from './columns/contactsColumn'

export type CompanyColumnField = keyof CompaniesRowModel

export type CompanyColumnType = Omit<GridColDef, 'field'> & {
  field: CompanyColumnField,
  hideHeader?: boolean,
}

export const companyDataGridColumns: CompanyColumnType[] = [
  nameColumnModel,
  idColumnModel,
  categoryColumnModel,
  contactsColumnModel,
  jobCreditsColumnModel,
  isPartnerColumnModel,
  createdColumnModel as CompanyColumnType
]
