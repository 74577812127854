import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { FilterOperators } from '@typings/graphql'

import { BaseColumnType } from '../types/types'

const RenderCell: React.FC<{ value: boolean }> = ({ value }) => {
  const { t } = useTranslation()

  return <Typography
    variant="subtitle1"
    fontWeight={500}
    sx={{
      color: value ? 'success.main' : 'warning.main'
    }}
  >
    {t(value ? 'status.active' : 'status.inactive')}
  </Typography>
}

export const activeColumnModel: BaseColumnType = {
  field: 'active',
  width: 75,
  filterOperators: getGridBooleanOperators().map((operator) => {
    if (operator.value === 'is') {
      operator.value = FilterOperators.Equals
    }

    return operator
  }),
  renderCell: (params) => <RenderCell value={!!params.value} />
}
