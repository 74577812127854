import { ReviewStatus } from '@typings/graphql'
import { Roles } from '@typings/roles'

type RoleBasedStatus = Record<ReviewStatus, Roles[]>

export const ROLE_BASED_STATUS: RoleBasedStatus = {
  [ReviewStatus.Review]: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR],
  [ReviewStatus.Approved]: [Roles.ADMIN, Roles.MANAGER],
  [ReviewStatus.Rejected]: [Roles.ADMIN, Roles.MANAGER],
  [ReviewStatus.Draft]: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
}
