import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useCompanyCategoriesQuery } from '@typings/graphql'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const RegistrationFormCompanyExtension: React.FC = () => {
  const { t } = useTranslation()

  const { data: categoryData } = useCompanyCategoriesQuery({
    fetchPolicy: 'cache-and-network'
  })

  const { control } = useFormContext()

  return (
    <Box mb={2}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <TextInput
            label={t('common.company') as string}
            formKey="name"
            fullWidth
            options={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="categoryId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...restFields } }) => (
              <FormControl fullWidth>
                <InputLabel>{t('common.department')}</InputLabel>
                <Select fullWidth
                  {...restFields}
                  value={value || ''}
                  onChange={onChange}
                >
                  {categoryData?.companyCategories.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
