import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import type {} from '@mui/x-date-pickers/themeAugmentation'

export type MuiPickersLayoutType = {
  defaultProps?: ComponentsProps['MuiPickersLayout'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersLayout'];
  variants?: ComponentsVariants['MuiPickersLayout'];
}

export const MuiPickersLayout:MuiPickersLayoutType = {
  defaultProps: {
    isLandscape: true
  },
  styleOverrides: {
    root: {
      '.MuiMultiSectionDigitalClock-root': {
        width: '250px',
        '.MuiList-root': {
          flex: '1 1 auto',
          padding: '8px',
          '.MuiMenuItem-root': {
            margin: '0',
            width: '100%',
            '&.Mui-selected': {
              borderRadius: '8px'
            }
          }
        }
      }
    }
  }
}
