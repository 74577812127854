import { Box, Typography } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { mailPattern } from '@utils/pattern'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useTraineesContext } from '../provider/TraineesProvider'

type Props = {
  open: boolean
  newTrainee?: boolean
  onClose: () => void
}

const TraineeInvitationDialogContent: React.FC<Props> = ({ open, newTrainee = false, onClose }) => {
  const { t } = useTranslation()

  const { handleSubmit, formState, reset } = useFormContext()
  const { handleInviteUser, updateNewTrainee } = useTraineesContext()

  const handleOnClose = React.useCallback(() => {
    reset()
    onClose()
    updateNewTrainee(false)
  }, [onClose])

  const onSubmit = handleSubmit(async (input) => {
    await handleInviteUser({
      email: input.email.toLowerCase().trim(),
      firstname: input.firstName,
      lastname: input.lastName
    }, handleOnClose)
  })

  return (
    <ConfirmDialog
      title={t('trainees.inviteTrainee')}
      open={open}
      onCancel={handleOnClose}
      onConfirm={onSubmit}
      confirmProps={{
        disabled: !formState.isValid || !formState.isDirty,
        loading: formState.isSubmitting
      }}
    >
      <form>
        <Typography>{t('trainees.inviteTraineeDescription')}</Typography>

        <Box mt={2}>
          <TextInput
            formKey='email'
            options={{
              required: true,
              pattern: mailPattern
            }}
            placeholder={t('common.email')}
          />
        </Box>

        {!!newTrainee && (
          <>
            <Typography sx={{
              color: 'error.main'
            }}
            >{t('trainees.createUser')}</Typography>
            <Box mt={2}>
              <TextInput
                formKey='firstName'
                options={{
                  required: true
                }}
                placeholder={t('common.firstName')}
              />
            </Box>

            <Box>
              <TextInput
                formKey='lastName'
                options={{
                  required: true
                }}
                placeholder={t('common.lastName')}
              />
            </Box>
          </>
        )}
      </form>
    </ConfirmDialog>
  )
}

export const TraineeInvitationDialog: React.FC<Props> = (props) => {
  const formData = useForm({
    defaultValues: {
      createUser: false,
      email: ''
    },
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <TraineeInvitationDialogContent {...props} />
    </FormProvider>
  )
}
