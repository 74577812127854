import { Grid, Typography } from '@mui/material'
import React from 'react'

import { InputTitleField } from '../ui/InputRequiredIndicator'

import { TextInput, TextInputProps } from './TextInput'

type Props = TextInputProps & {
  title: string
  description?: string,
}

export const BaseTextInput: React.FC<Props> = ({ title, description, ...props }) => {
  const { options } = props

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <TextInput {...props}></TextInput>
      </Grid>
    </Grid>

  )
}
