import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnIconStyles, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { IconButton, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { TraineesRowModel, useTraineesContext } from '../provider/TraineesProvider'

import { TraineeColumnType, traineesDataGridColumns } from './traineesDataGridColumns'

export const TraineesDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    loading,
    traineeData,
    updateSelectedItem,
    updateSelectedIds,
    handleDisconnect,
    handleVerifyUser
  } = useTraineesContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDisconnectClick = React.useCallback((row: TraineesRowModel) => {
    updateSelectedItem(row)
    handleDisconnect(false)
  }, [])

  const columnData = React.useMemo<TraineeColumnType[]>(() => {
    return [...traineesDataGridColumns, {
      ...actionColumnModel,
      width: 150,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can('users:write.trainee') && !params.row.confirmed && {
            additionalActions: (
              <Tooltip title={t('trainees.verifyUserTooltip')}>
                <IconButton onClick={() => handleVerifyUser(params.row)}>
                  <themeIcons.Check sx={actionColumnIconStyles} />
                </IconButton>
              </Tooltip>
            )
          })}
          {...(can('users:read.trainee') && { onShow: () => navigate(`${params.row.id}`) })}
          // {...(can('users:write.trainee') && { onEdit: () => navigate(`${params.row.id}/edit`) })}
          {...(can('users:delete.trainee') && { onDelete: () => handleDisconnectClick(params.row) })}
        />
      )
    } as TraineeColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`trainees.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={traineeData}
      gridKey='trainees'
      loading={loading}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
