import React from 'react'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'
import { TraineesDetailView } from '@features/trainees/views/TraineesDetailView'
import { TraineesView } from '@features/trainees/views/TraineesView'

import { AppRouteObject } from '../router'

export const traineesRoutes: AppRouteObject[] = [{
  path: '/trainees',
  element: <ProtectedRoute component={<BreadcrumbLayout />} />,
  access: {
    roles: [Roles.TRAINING_COMPANY_MANAGER, Roles.INSTRUCTOR]
  },
  meta: {
    name: 'trainees',
    navItem: true,
    navIcon: 'School',
    navOrder: 3
  },
  children: [{
    index: true,
    element: <TraineesView />
  }, {
    path: ':id',
    meta: {
      name: 'traineesDetail'
    },
    element: <TraineesDetailView />,
    access: {
      roles: [Roles.TRAINING_COMPANY_MANAGER, Roles.INSTRUCTOR]
    }
  }
  /**
  , {
    path: ':id/edit',
    meta: {
      name: 'traineesEdit'
    },
    element: <TraineesEditView />,
    access: {
      roles: [Roles.TRAINING_COMPANY_MANAGER, Roles.INSTRUCTOR]
    }
  }
   */
  ]
}]
