import React from 'react'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { Tooltip, Typography } from '@mui/material'

import { QuestionsColumnType } from '../questionsDataGridColumns'

export const topicColumnModel: QuestionsColumnType = {
  field: 'topicTitle',
  width: 150,
  filterable: false,
  sortable: false,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter: (params) => params.row?.topic?.texts?.title || '',
  renderCell: (params) => {
    const title = params.row?.topic?.texts?.title || ''

    return (title
      ? <Tooltip title={title}>
        <Typography
          variant="subtitle1"
          fontWeight={500}
        >
          {title}
        </Typography>
      </Tooltip>
      : <Typography variant="subtitle1" fontWeight={500}>-</Typography>
    )
  }
}
