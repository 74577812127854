import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { TraineesRowModel, useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { DashboardAccordion } from '@shared/components/ui/DashboardAccordion'
import { IconWithTextRow } from '@shared/components/ui/IconWithTextRow'
import { useTranslation } from 'react-i18next'
import { DRY_RUN_COUNT } from '@shared/constants/trainees'
import { themeIcons } from '@theme/icons'

const TraineeDetailDryRunItem: React.FC<{data: Exclude<TraineesRowModel['dryRuns'], null | undefined>[0]}> = ({ data }) => {
  const { t, i18n } = useTranslation()

  const errorRate = React.useMemo(() => {
    const wrongAnswers = data.questionCount - data.correctCount
    const percentage = wrongAnswers / data.questionCount * 100

    return percentage > 0 && percentage < 100 ? percentage.toFixed(1) : percentage.toFixed(0)
  }, [data])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 1,
        border: '1px solid',
        borderRadius: '8px',
        borderColor: 'grey.300',
        backgroundColor: 'grey.100',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          borderRadius: '6px',
          border: '2px solid',
          borderColor: data.passed ? 'success.500' : 'error.500',
          p: 0.5,
          marginRight: 1.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: '0 0 auto'
        }}
      >
        {data.passed
          ? <themeIcons.Check sx={{ color: 'success.500' }} />
          : <themeIcons.Cancel sx={{ color: 'error.500' }} />}
      </Box>
      <Box
        sx={{
          flex: '1 1 auto'
        }}
      >
        <Typography fontWeight="bold">
          {t(`common.${data.passed ? 'passed' : 'failed'}`)}
        </Typography>
        <Typography>
          {data.category?.texts?.title}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        <IconWithTextRow
          icon={'FaceFrown'}
          content={t('common.errorRate', { rate: errorRate })}
          inline
        />
        <Box sx={{
          ml: 1
        }}
        >
          <IconWithTextRow
            icon={'Clock'}
            content={t('common.minute', { count: data.duration })}
            inline
          />
        </Box>
        <Box sx={{
          ml: 1
        }}
        >
          <IconWithTextRow
            icon={'CalendarToday'}
            content={new Date(data.submittedAt).toLocaleDateString(i18n.language, {
              year: 'numeric',
              month: 'short',
              day: '2-digit'
            })}
            inline
          />
        </Box>
      </Box>
    </Box>
  )
}

export const TraineeDetailDryRun: React.FC = () => {
  const { t } = useTranslation()

  const { traineeData } = useTraineesDetailContext()

  const overall = React.useMemo(() => {
    return traineeData?.dryRuns?.length ?? 0
  }, [traineeData])

  const passed = React.useMemo(() => {
    return traineeData?.dryRuns?.filter(dryRun => dryRun.passed)?.length ?? 0
  }, [traineeData])

  const lastEntries = React.useMemo(() => {
    const { dryRuns } = traineeData ?? {}

    return (dryRuns?.filter(({ submittedAt }) => !!submittedAt)
      ?.sort((a, b) => new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime()))
      ?.slice(0, DRY_RUN_COUNT) ?? []
  }, [traineeData])

  return (
    <DashboardAccordion
      title={t('trainees.dryRuns')}
      headerExtension={
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <IconWithTextRow
              title={t('common.overall')}
              icon="BarChart"
              content={t('trainees.dryRuns', { count: overall })}
              inline
            />
          </Grid>
          <Grid item xs={4}>
            <IconWithTextRow
              title={t('common.passed')}
              icon="Check"
              iconColor="success.500"
              content={t('trainees.dryRuns', { count: passed })}
              inline
            />
          </Grid>
          <Grid item xs={4}>
            <IconWithTextRow
              title={t('common.failed')}
              icon="Cancel"
              iconColor="error.500"
              content={t('trainees.dryRuns', { count: overall - passed })}
              inline
            />
          </Grid>
        </Grid>
    }
    >
      <Typography fontWeight="bold">
        {t('trainees.dryRunLastEntries', { count: DRY_RUN_COUNT })}
      </Typography>

      {
        lastEntries.map(dryRun => (
          <Box
            key={dryRun.submittedAt}
            sx={{ mt: 1 }}
          >
            <TraineeDetailDryRunItem data={dryRun} />
          </Box>
        ))
      }
    </DashboardAccordion>
  )
}
