import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { useNavigate, useParams } from 'react-router'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { TopicRowModel, useTopicsContext } from '../provider/TopicsProvider'

import { TopicsColumnType, topicsDataGridColumns } from './topicsDataGridColumns'

export const TopicsDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const { professionId } = useParams<{ professionId: string }>()
  const navigate = useNavigate()
  const navigateNewTab = useNewTabHook()

  const {
    updateSelectedIds, handleDelete, parentTopicId,
    updateSelectedItem, loading, topicData
  } = useTopicsContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: TopicRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<TopicsColumnType[]>(() => {
    return [...topicsDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can(['topics:read']) && { onShow: () => navigate(`${params.row.id}`) })}
          {...(can(['topics:write', 'topics:write.partial']) && { onEdit: () => navigateNewTab(`${params.row.id}/edit`) })}
          {...(can('topics:delete') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as TopicsColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`topics.column.${column.field}`),
        ...column
      }
    })
  }, [parentTopicId])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey={`${professionId}-topics`}
      rows={topicData}
      loading={loading}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
