import { Box, Button, Typography } from '@mui/material'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SignupInput, useSignupCompanyMutation, useSignupTrainingCompanyMutation } from '@typings/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

import { RegistrationForm } from '../components/RegistrationForm'
import { RegistrationFormCompanyExtension } from '../components/RegistrationFormCompanyExtension'
import { RegistrationFormTrainingCompanyExtension } from '../components/RegistrationFormTrainingCompanyExtension'

type Props = {
  type: 'trainingCompany' | 'company'
}

export type RegistrationInput = SignupInput & {
  passwordConfirm: string
  acceptTerms: boolean,
  city: string,
  postalCode: string,
  street: string,
  name: string
  categoryId: string
}

export const RegistrationView: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [signupBlocked, setSignupBlocked] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()

  const formData = useForm<RegistrationInput>({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      firstname: '',
      lastname: '',
      city: '',
      postalCode: '',
      street: '',
      name: ''
    },
    mode: 'onChange'
  })

  const { handleSubmit, formState: { isDirty, isValid } } = formData

  const [signUpTrainingCompany] = useSignupTrainingCompanyMutation()
  const [signUpCompany] = useSignupCompanyMutation()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      switch (type) {
        case 'trainingCompany':
          await signUpTrainingCompany({
            variables: {
              data: {
                email: input.email.toLowerCase().trim(),
                password: input.password,
                firstname: input.firstname,
                lastname: input.lastname,
                postalCode: input.postalCode,
                city: input.city,
                address: input.street,
                name: input.name
              }
            }
          })
          break
        case 'company':
          await signUpCompany({
            variables: {
              data: {
                email: input.email.toLowerCase().trim(),
                password: input.password,
                firstname: input.firstname,
                lastname: input.lastname,
                categoryId: input.categoryId,
                city: input.city,
                address: input.street,
                name: input.name,
                postalCode: input.postalCode
              }
            }
          })
          break
      }

      setErrorMessage('')
      setSuccess(true)
    } catch (error) {
      if ((error as any).message.includes(input.name)) {
        setErrorMessage(t('errors.auth.companyNameAlreadyExists', { blockedFor: 10 }))
      } else {
        setMessageByCode(error, 'auth', { blockedFor: 10 })
      }

      setSignupBlocked(true)

      window.setTimeout(() => {
        setSignupBlocked(false)
      }, 10000)
    } finally {
      setLoading(false)
      setShowToast(true)
    }
  })

  return (
    <AuthLayout>
      <Box sx={{ width: '100%' }}>
        {success
          ? (
            <Box my={2} textAlign="center">
              <Typography variant="h3">{t('auth.registrationSuccess')}</Typography>
              <Typography mt={2}>{type === 'trainingCompany'
                ? t('auth.registrationSuccessDescription')
                : t('auth.registrationSuccessDescriptionCompany')}</Typography>
              <Button sx={{
                mt: 2
              }}
                onClick={() => navigate('/login')}
              >
                {t('auth.backToLogin')}
              </Button>
            </Box>
            )
          : (<FormProvider {...formData}>
            <Box my={2} textAlign="center">
              <Button variant="text" onClick={() => navigate('/login')}>
                <Typography>{t('auth.existingAccount')}</Typography>
              </Button>
            </Box>
            <RegistrationForm>
              {type === 'trainingCompany' && (<RegistrationFormTrainingCompanyExtension />)}
              {type === 'company' && (<RegistrationFormCompanyExtension />)}
            </RegistrationForm>
            <LoadingButton
              variant="contained"
              fullWidth
              color="secondary"
              disabled={!isDirty || !isValid || signupBlocked}
              loading={loading}
              sx={{
                alignSelf: 'right',
                marginTop: '8px',
                backgroundColor: 'primary.main'
              }}
              type="submit"
              onClick={onSubmit}
            >
              {t('auth.register')}
            </LoadingButton>
          </FormProvider>
            )}
      </Box>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('auth.registrationSuccess')}
      />
    </AuthLayout>
  )
}
