import React from 'react'
import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'
import { RELEVANT_NUMBER_OPERATORS } from '@shared/constants/dataGrid'
import { t } from 'i18next'

import { QuestionsColumnType } from '../questionsDataGridColumns'

export const questionGroupOrderColumnModel: QuestionsColumnType = {
  field: 'questionGroupOrder',
  width: 100,
  filterable: false,
  sortable: false,
  filterOperators: getGridNumericOperators().filter((operator) =>
    Object.keys(RELEVANT_NUMBER_OPERATORS).includes(operator.value)).map((operator) => ({
    ...operator,
    type: 'number',
    label: t(`filterOperator.${RELEVANT_NUMBER_OPERATORS[operator.value as keyof typeof RELEVANT_NUMBER_OPERATORS]}`)
  })),
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: (params) => params.row?.questionGroupOrder,
  renderCell: (params) => {
    return params.row?.questionGroupOrder !== null
      ? <Typography variant="subtitle1" fontWeight={500}>{ params.row?.questionGroupOrder + 1}</Typography>
      : '-'
  }
}
