import { Box, Button } from '@mui/material'
import {
  GridFilterPanel, gridFilterModelSelector, useGridApiContext, useGridSelector
} from '@mui/x-data-grid-pro'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DataGridFilterPanel: React.FC = () => {
  const { t } = useTranslation()

  const apiRef = useGridApiContext()

  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)

  const handleAddFilter = React.useCallback(() => {
    const columns = apiRef.current.getAllColumns().slice(1)

    const defaultFilterItem = {
      field: columns[0].field,
      operator: columns[0].filterOperators![0].value
    }

    const newFilterItems = filterModel.items.length === 0 ? [defaultFilterItem, defaultFilterItem] : [defaultFilterItem]

    apiRef.current.upsertFilterItems([...filterModel.items, ...newFilterItems])
  }, [filterModel.items])

  const handleRemoveAllFilter = React.useCallback(() => {
    apiRef.current.setFilterModel({ items: [] })
  }, [])

  const handelOnClose = React.useCallback(() => {
    apiRef.current.hideFilterPanel()
  }, [])

  return (
    <Box>
      <GridFilterPanel
        disableAddFilterButton
        disableRemoveAllButton
        logicOperators={[]}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 1
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
        >
          <Button
            variant="text"
            disableRipple
            sx={{
              p: 1
            }}
            onClick={handleAddFilter}
            startIcon={<themeIcons.Add />}
          >
            {t('dataGrid.addFilter')}
          </Button>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
        >
          <Button
            variant="text"
            disableRipple
            sx={{
              mr: 1,
              p: 1
            }}
            startIcon={<themeIcons.Delete />}
            onClick={handleRemoveAllFilter}
          >
            {t('dataGrid.removeAllFilter')}
          </Button>
          <Button
            variant="text"
            disableRipple
            sx={{
              p: 1
            }}
            startIcon={<themeIcons.Cancel />}
            onClick={handelOnClose}
          >
            {t('common.close')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
