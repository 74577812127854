import { IconButton, IconButtonProps } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = IconButtonProps

export const CopyIconButton:React.FC<Props> = ({ sx, ...props }) => {
  return (
    <IconButton
      sx={{
        minWidth: 'auto',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'grey.400',
        color: 'grey.800',
        boxShadow: '0px 1px 2px 0px #1018280D',
        p: '11px',
        '.MuiButton-startIcon': {
          m: 0
        },
        ...sx
      }}
      {...props}
    >
      <themeIcons.ContentCopy sx={{
        width: '20px',
        height: '20px'
      }}
      />
    </IconButton>
  )
}
