import { Box, Divider, MenuItem, Select } from '@mui/material'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { TextInput } from '@shared/components/inputs/TextInput'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ALPHABET = Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i))

type Answer = {
  id: number
  solution: string
}

export const QuestionAssignmentInput: React.FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues } = useFormContext()

  const [answers, setAnswers] = React.useState<Answer[]>([])

  React.useEffect(() => {
    const solution = getValues('config.solution') as number[]
    const texts = getValues('texts')

    const answerTextKeys = Object.keys(texts).filter((key) => key.startsWith('answer_'))
      .map((key) => parseInt(key.split('_')[1], 10))
    answerTextKeys.sort((a, b) => a - b)

    const existingAnswers = answerTextKeys?.map((answer, index) => {
      const solutionIndex = solution.indexOf(index)

      return {
        id: answer,
        solution: solutionIndex < 0 ? '' : ALPHABET[solutionIndex] ?? ''
      }
    })

    setAnswers(existingAnswers)
  }, [])

  const removeText = (key: string) => {
    const texts = getValues('texts')

    delete texts[key]

    setValue('texts', texts)
  }

  const setSolution = (newAnswers: Answer[]) => {
    const sortedAnswers = newAnswers.filter((answer) => answer.solution).sort((a, b) => a.solution.localeCompare(b.solution))
    const newSolution = sortedAnswers.map((answer) => newAnswers.findIndex((item) => item.id === answer.id))

    setValue('config.solution', newSolution)
  }

  const handleRemoveAnswer = (answer: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.filter((item) => item.id !== answer)

      setSolution(newAnswers)

      return newAnswers
    })

    removeText(`answer_${answer}`)
  }

  const handleOnAddAnswer = () => {
    const newAnswerId = answers.length ? Math.max(...answers.map(({ id }) => id)) + 1 : 0

    setAnswers([...answers, {
      id: newAnswerId,
      solution: ''
    }])
  }

  const handleAddSolution = (solution: string, answerId: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.map((item) => {
        if (item.id === answerId) {
          return {
            ...item,
            solution
          }
        }

        return item
      })

      setSolution(newAnswers)

      return newAnswers
    })
  }

  return (
    <>
      <BaseInputRow
        title={t('questions.answers')}
        required
        description={t('questions.answersAssignmentDescription')}
      >
        {
          answers?.map((answer) => (
            <Box key={`assignment_answer_${answer.id}`} mb={2}>
              <DefaultAnswerRow
                onRemove={() => handleRemoveAnswer(answer.id)}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                >
                  <Box flex="1">

                    <TextInput
                      formKey={`texts.answer_${answer.id}`}
                      hideValidationText
                      options={{
                        required: true
                      }}
                      sx={{
                        backgroundColor: 'white'
                      }}
                    />
                  </Box>
                  <Box flex="0" ml={2}>
                    <Select
                      sx={{
                        width: '80px',
                        '&.MuiInputBase-root': {
                          backgroundColor: 'white'
                        }
                      }}
                      value={answer.solution}
                      onChange={(event) => handleAddSolution(event.target.value as string, answer.id)}
                    >
                      <MenuItem value="">
                        {t('common.none')}
                      </MenuItem>
                      {ALPHABET.map((letter) => (
                        <MenuItem
                          key={`assignment_answer_${answer.id}_solution_${letter}`}
                          value={letter}
                          sx={{
                            ...(answers.find((item) => item.solution === letter) && {
                              display: 'none'
                            })
                          }}
                        >
                          {letter}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </DefaultAnswerRow>
            </Box>
          ))
        }

        {
          <AddDefaultRow
            label={t('questions.addAnswer')}
            onAdd={handleOnAddAnswer}
          />
          }
      </BaseInputRow>

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
      <BaseSwitchInput
        formKey='config.displayCalculator'
        title={t('questions.displayCalculator')}
        description={t('questions.displayCalculatorDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.shuffle'
        defaultChecked
        title={t('questions.shuffle')}
        description={t('questions.shuffleDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

    </>
  )
}
