import React from 'react'
import { Typography } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { QuestionRowModel } from '@features/questions/provider/QuestionsProvider'
import { useTranslation } from 'react-i18next'
import { firstLetterToLowerCase } from '@utils/string'
import { QuestionType } from '@typings/graphql'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'

import { QuestionListColumnType } from '../questionListDataGridColumns'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { t } = useTranslation()

  const selectItems = React.useMemo(() => {
    return Object.values(QuestionType).map((type) => ({
      id: type,
      name: t(`questions.types.${firstLetterToLowerCase(type)}`),
      key: type
    }))
  }, [])

  return (
    <FilterSelectInput
      {...props}
      selectProps={{
        multiple: true
      }}
      items={selectItems}
    />
  )
}

const RenderCell: React.FC<{row: QuestionRowModel}> = ({ row }) => {
  const { t } = useTranslation()

  const type = React.useMemo(() => {
    if (row.type === QuestionType.Choice) {
      return row.config.multiple ? 'multipleChoice' : 'singleChoice'
    }

    return row.type
  }, [])

  return (
    <Typography
      variant="subtitle1"
      fontWeight={500}
    >{t(`questions.types.${firstLetterToLowerCase(type)}`)}</Typography>
  )
}
export const typeColumnModel: QuestionListColumnType = {
  field: 'type',
  width: 150,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  valueGetter: (params) => {
    if (params.row.type === QuestionType.Choice) {
      return params.row.config.multiple ? 'multipleChoice' : 'singleChoice'
    }

    return params.row.type
  },
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
