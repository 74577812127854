import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { BoxProps, Box } from '@mui/material'

type Props = BoxProps & {
  id: string,
}

export const SortableGrabber:React.FC<Props> = ({
  id, children, sx, ...boxProps
}) => {
  const {
    attributes,
    listeners,
    setNodeRef
  } = useSortable({ id })

  return (
    <Box
      {...boxProps}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{
        userSelect: 'none',
        cursor: 'grab',
        alignItems: 'center',
        display: 'flex',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}
