import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'

import { CategoryRowModel } from '../provider/CategoriesProvider'

import { titleColumnModel } from './columns/titleColumn'
import { professionColumnModel } from './columns/professionColumnModel'

export type CategoriesColumnField = keyof CategoryRowModel

export type CategoriesColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: CategoriesColumnField,
  hideHeader?: boolean,
}

export const categoriesDataGridColumns: CategoriesColumnType[] = [
  professionColumnModel,
  titleColumnModel
]
