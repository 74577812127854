import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { palette } from '@theme/core/palette/palette'

export type MuiButtonType = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

export const MuiButton:MuiButtonType = {
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    root: {
      backgroundColor: palette.primary?.main,
      color: '#ffffff',
      boxShadow: 'none',
      padding: '10px 16px',
      minWidth: '68px',
      width: 'max-content',
      alignItems: 'center',
      textTransform: 'none',
      minHeight: '44px',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: palette.primary?.[800]
      },
      '&:active': {
        boxShadow: 'none'
      },
      '&.Mui-disabled': {
        backgroundColor: palette.grey?.[300]
      },
      '&.MuiButton-outlined': {
        backgroundColor: 'transparent',
        color: palette.text?.primary,
        borderColor: palette.grey?.[400],
        borderRadius: '8px',
        minHeight: '44px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        '&:hover': {
          backgroundColor: palette.grey?.[100]
        },
        '&.Mui-disabled': {
          borderColor: palette.grey?.[300],
          color: palette.grey?.[400]
        }
      },
      '&.MuiButton-contained': {
        color: palette.text?.secondary,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
      },
      '&.MuiButton-text': {
        backgroundColor: 'transparent',
        color: palette.primary?.main,
        '&:hover': {
          color: palette.primary[900]
        }
      }
    }
  }
}
