import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserColumnType, useUsersContext } from '@features/users/provider/UsersProvider'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { FilterSelectInput } from '@shared/dataGrid/filter/FilterSelectInput'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { roles } = useUsersContext()

  return (
    <FilterSelectInput
      {...props}
      items={roles ?? []}
    />
  )
}

const RoleRenderCell: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation()

  return (
    <Typography variant="subtitle1" fontWeight={500}>{t(`roles.${value}`)}</Typography>
  )
}

export const roleColumnModel: UserColumnType = {
  field: 'roleId',
  width: 150,
  sortable: false,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  renderCell: (params) => (
    <RoleRenderCell value={params.row?.role.key as string} />
  )
}
