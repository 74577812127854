import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can, isRole } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'
import { Roles } from '@typings/roles'

import { ProductsRowModel, useProductsContext } from '../provider/ProductsProvider'

import { ProductsColumnType, productsDataGridColumns } from './productsDataGridColumns'
import { companyColumnModel } from './columns/companyColumn'
import { nameColumnModel } from './columns/nameColumn'

export const ProductDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds, handleDelete,
    updateSelectedItem, loading, productData
  } = useProductsContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: ProductsRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<ProductsColumnType[]>(() => {
    return [
      nameColumnModel,
      ...(isRole(Roles.ADMIN) ? [companyColumnModel] : []),
      ...productsDataGridColumns,
      {
        ...actionColumnModel,
        renderCell: (params) => (
          <ActionColumnRenderCell
            {...(can(['incentives:read', 'incentives:read.own']) && { onShow: () => navigate(`/products/${params.row.id}`) })}
            {...(can(['incentives:read', 'incentives:write.own']) && { onEdit: () => navigate(`/products/${params.row.id}/edit`) })}
            {...(can(['incentives:delete', 'incentives:delete.own']) && { onDelete: () => handleDeleteRow(params.row) })}
          />
        )
      } as ProductsColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`products.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey='products'
      rows={productData}
      loading={loading}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
