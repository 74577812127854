import { Box, CircularProgress, ClickAwayListener, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputTitleField } from '../ui/InputRequiredIndicator'

export type SearchInputItem = {
  id: string
  name: string
}

type Props = {
  formKey: string
  title: string
  description?: string
  options?: RegisterOptions
  loading?: boolean
  searchItems?: SearchInputItem[]
  minLength?: number
  cantRead?: boolean
  single?: boolean
  onInputChange?: (value: string) => void
}

export const BaseSearchInput:React.FC<Props> = ({
  formKey,
  title,
  description,
  options,
  cantRead,
  searchItems,
  minLength,
  onInputChange,
  loading,
  single
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const [focused, setFocused] = React.useState(false)
  const [internalLoading, setInternalLoading] = React.useState(false)

  const anchorRef = React.useRef<HTMLDivElement>(null)

  const { control, setValue } = useFormContext()

  const inputTimeout = React.useRef<number | null>(null)

  const watchedItems = useWatch({
    control,
    name: formKey
  }) as SearchInputItem[]

  React.useEffect(() => {
    setInternalLoading(!minLength || inputValue.length >= minLength)

    if (inputTimeout.current) {
      clearTimeout(inputTimeout.current)
    }

    inputTimeout.current = window.setTimeout(() => {
      setInternalLoading(false)
      onInputChange?.(inputValue)
    }, 1000)

    return () => {
      if (inputTimeout.current) {
        clearTimeout(inputTimeout.current)
      }
    }
  }, [inputValue])

  const filteredItems = React.useMemo(() => {
    return searchItems?.filter((item) => {
      return !watchedItems?.find((watched) => watched.id === item.id)
    })
  }, [searchItems, watchedItems])

  const handleSelectItem = React.useCallback((item: SearchInputItem) => {
    setValue(formKey, single ? [item] : [...(watchedItems || []), item], {
      shouldDirty: true
    })
  }, [watchedItems])

  const handleRemoveItem = React.useCallback((item: SearchInputItem) => {
    setValue(formKey, watchedItems?.filter((watched) => watched.id !== item.id), {
      shouldDirty: true
    })
  }, [watchedItems])

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      {!cantRead &&
        <Grid item xs={4} xl={3}>
          <ClickAwayListener onClickAway={() => setFocused(false)}>
            <Box sx={{
              position: 'relative'
            }}
            >
              <TextField
                ref={anchorRef}
                value={inputValue}
                fullWidth
                onFocus={() => setFocused(true)}
                onChange={(event) => setInputValue(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <themeIcons.Search />
                  ),
                  endAdornment: (
                    inputValue && (
                    <themeIcons.Cancel
                      sx={{
                        cursor: 'pointer'
                      }}
                      onClick={() => setInputValue('')}
                    />
                    )
                  )
                }}
              />

              <Box sx={{
                opacity: inputValue && inputValue.length >= (minLength || 0) && focused ? 1 : 0,
                borderRadius: '8px',
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: 'white',
                border: 'solid 1px',
                borderColor: 'grey.400',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                maxHeight: '150px',
                minHeight: '36px',
                overflowY: 'auto'
              }}
              >
                {loading || internalLoading
                  ? (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: '36px'
                    }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                    )
                  : filteredItems?.length === 0
                    ? (
                      <Typography variant="subtitle1" fontWeight={600} p={2}>{t('common.noResults')}</Typography>
                      )
                    : (
                      <Box>
                        {single && watchedItems?.length > 0 && (
                          <MenuItem onClick={() => handleRemoveItem(watchedItems[0])} />
                        )}
                        {filteredItems?.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            onClick={() => handleSelectItem(item)}
                          >
                            <Typography variant="subtitle1" fontWeight={600}>{item.name}</Typography>
                          </MenuItem>
                        ))}
                      </Box>
                      )
                }
              </Box>
            </Box>
          </ClickAwayListener>
        </Grid>
      }
      <Grid item xs={4} xl={3}>
        {watchedItems?.length > 0 && (

          <Box sx={{
            borderRadius: '8px',
            px: 1,
            backgroundColor: 'white',
            border: 'solid 1px',
            borderColor: 'grey.400',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            maxHeight: '150px',
            minHeight: '36px',
            overflowY: 'auto'
          }}
          >
            {watchedItems?.map((item, index) => (
              <Box key={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: index < watchedItems.length - 1 ? 'solid 1px' : 'none',
                  borderBottomColor: 'grey.400',
                  p: 1
                }}
              >
                <Typography variant="subtitle1" fontWeight={600}>{item.name}</Typography>
                <IconButton sx={{
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'grey.700'
                  }
                }}
                  onClick={() => handleRemoveItem(item)}
                >
                  <themeIcons.Cancel />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
