import { setAuthToken } from '@services/store/slices/auth'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { useRefreshTokenMutation } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type HandleExport = (type: string, onError: (error: any) => void, onComplete: () => void) => Promise<void>

export const useXlsxExport = (): HandleExport => {
  const { i18n } = useTranslation()
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const [handleTokenRefresh] = useRefreshTokenMutation()

  const tokenRefreshedRef = React.useRef(false)

  const fetchXlsx = React.useCallback(async (token: string, type: string) => {
    return await fetch(`${import.meta.env.REACT_APP_API_ENDPOINT}/xlsx/export/${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
  }, [accessToken])

  const handleUnauthorized = React.useCallback(async () => {
    const response = await handleTokenRefresh({
      variables: {
        token: refreshToken
      }
    })

    if (response.data) {
      dispatch(setAuthToken({
        accessToken: response.data.refreshToken.accessToken,
        refreshToken: response.data.refreshToken.refreshToken
      }))
    }

    tokenRefreshedRef.current = true

    return response.data?.refreshToken.accessToken
  }, [refreshToken])

  const handleExport = React.useCallback(async (
    type: string,
    onError: (error: any) => void,
    onComplete: () => void,
    token?: string
  ) => {
    try {
      await fetchXlsx(token || accessToken, type).then(async (response) => {
        if (response.status === 401 && !tokenRefreshedRef.current) {
          const newToken = await handleUnauthorized()

          await handleExport(type, onError, onComplete, newToken)

          return
        }
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        tokenRefreshedRef.current = false

        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(
            new Blob([blob])
          )

          const dateFormatted = new Intl.DateTimeFormat(i18n.language, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(new Date())

          const date = dateFormatted.replace(/\./g, '-')

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${type}-${date}.xlsx`)

          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      }).catch((error) => {
        onError(error)
      })
    } catch (e) {
      onError(e)
    } finally {
      onComplete()
    }
  }, [accessToken])

  return handleExport
}
