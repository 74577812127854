import { Divider, Box } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'

type Props = {
  loading?: boolean
}

export const CategoryCreateEditInputs: React.FC<Props> = ({ loading }) => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='texts.title'
        options={{ required: true }}
        title={t('common.title')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.icon"
        fileKey="icon"
        acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg', '.png', '.webp'] }}
        title={t('common.icon')}
        description={t('categories.iconDescription')}
        loading={loading}
      />

    </Box>
  )
}
