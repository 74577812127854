import { Box } from '@mui/material'
import {
  Status, UpdateJobInput, useCompaniesNameOnlyQuery, useDeleteFileMutation, useJobDepartmentsQuery,
  useJobEmploymentTypesQuery,
  useJobQuery, useUpdateFileMutation, useUpdateJobMutation
} from '@typings/graphql'
import { isRole } from '@utils/permissions'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Roles } from '@typings/roles'
import i18n from '@services/i18n/i18n'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { StatusSelectInput } from '@shared/components/inputs/StatusSelectInput'
import { useFileUpload } from '@hooks/useFileUpload'
import { getFileByKey } from '@utils/files'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { JobWallProvider, useJobsContext } from '../provider/JobWallProvider'
import { JobWallCreateEditInputs } from '../components/JobWallCreateEditInputs'

type JobsInputType = Omit<UpdateJobInput, 'texts'> & {
  texts: Record<string, string>
}

export const JobWallEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { handleDelete, updateSelectedItem, myCompany } = useJobsContext()

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const { uploadFile } = useFileUpload()

  const [deleteFile] = useDeleteFileMutation()
  const [updateFile] = useUpdateFileMutation()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateJob] = useUpdateJobMutation()

  const { data, loading: jobLoading } = useJobQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !id
  })

  React.useEffect(() => {
    if (data?.job) {
      reset({
        status: data.job.status,
        code: data.job.code,
        contact: data.job.contact?.toLowerCase(),
        link: data.job.link,
        texts: data.job.texts,
        postalCode: data.job.postalCode,
        expiresAt: data.job.expiresAt,
        files: {
          keyvisual: getFileByKey('keyvisual', data.job.files)
        },
        jobDepartmentId: data.job.jobDepartment?.id || '',
        jobEmploymentTypeId: data.job.jobEmploymentType?.id || '',
        ...(isRole(Roles.ADMIN) && {
          companyId: data.job.company?.id || ''
        })
      })
    }
  }, [data])

  const { data: companyData, loading: companyLoading } = useCompaniesNameOnlyQuery({
    skip: !isRole(Roles.ADMIN)
  })

  const { data: jobDepartmentsData, loading: jobDepartmentsLoading } = useJobDepartmentsQuery()

  const { data: jobEmploymentTypeData, loading: jobEmploymentTypeLoading } = useJobEmploymentTypesQuery()

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      await updateJob({
        variables: {
          id: id as string,
          data: {
            ...(isRole(Roles.ADMIN) && { companyId: input.companyId }),
            code: input.code,
            contact: input.contact,
            link: input.link,
            status: input.status,
            jobDepartmentId: input.jobDepartmentId,
            postalCode: input.postalCode,
            jobEmploymentTypeId: input.jobEmploymentTypeId,
            ...(input.expiresAt && { expiresAt: input.expiresAt }),
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        const fileId = getFileByKey(key, data?.job?.files)?.id

        if (file?.upload) {
          await uploadFile(
            file.upload.file,
            file.upload.data.key,
            'Job',
            id as string,
            file.config
          )
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        } else if (file && fileId) {
          await updateFile({
            variables: {
              id: fileId as string,
              data: {
                config: file.config
              }
            }
          })
        }
      })

      setLoading(false)
      setShowToast(true)
    } catch (error) {
      setMessageByCode(error)
      setLoading(false)
      setShowToast(true)
    }
  })

  const deleteCallback = () => {
    setDeleted(true)
    navigate('/jobwall')
  }

  const onDelete = async () => {
    if (!data?.job) {
      return
    }

    updateSelectedItem(data.job)
    handleDelete(false, deleteCallback)
  }

  return (
    <CreateEditLayout
      title={t('jobwall.editTitle')}
      description={
        <Trans
          i18nKey={'jobwall.editDescription'}
          values={{ name: data?.job?.texts.name }}
          components={{ b: <strong /> }}
        />
      }
      loading={companyLoading || jobLoading || jobDepartmentsLoading || jobEmploymentTypeLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >

          {((myCompany && myCompany.isPartner) || isRole(Roles.ADMIN)) && (
            <Box mr={2}>
              <StatusSelectInput formKey="status" options={{ required: true }} />
            </Box>
          )}
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <JobWallCreateEditInputs
        companies={companyData?.companies}
        jobDepartments={jobDepartmentsData?.jobDepartments}
        jobEmploymentTypes={jobEmploymentTypeData?.jobEmploymentTypes}
      />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('jobwall.entryEdited')}
      />

    </CreateEditLayout>
  )
}

export const JobWallEditView:React.FC = () => {
  const formData = useForm<JobsInputType>({
    defaultValues: {
      status: Status.Draft
    },
    mode: 'onChange'
  })

  return (
    <JobWallProvider disableFetching>
      <FormProvider {...formData}>
        <JobWallEditViewContent />
      </FormProvider>
    </JobWallProvider>
  )
}
