import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DefaultTabNavigationBar } from './DefaultTabNavigationBar'

export const TopicsTabBar: React.FC = () => {
  const { professionId } = useParams<{ professionId: string }>()

  const { t } = useTranslation()

  const items = React.useMemo(() => [
    {
      target: `/professions/${professionId}`,
      label: t('routes.categories')
    },
    {
      target: `/professions/${professionId}/topics`,
      label: t('routes.topics')
    }
  ], [])

  return <DefaultTabNavigationBar items={items} />
}
