import React from 'react'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { FilterOperators } from '@typings/graphql'
import { QuestionRowModel } from '@features/questions/provider/QuestionsProvider'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { QuestionsColumnType } from '../questionsDataGridColumns'

const RenderCell: React.FC<{row: QuestionRowModel}> = ({ row }) => {
  const { t } = useTranslation()
  return (
    <Typography
      variant="subtitle1"
      fontWeight={500}
      sx={{
        color: row.inDuelAvailable ? 'success.main' : 'error.main'
      }}
    >{t(`common.${row.inDuelAvailable ? 'yes' : 'no'}`)}</Typography>
  )
}

export const inDuelAvailableColumnModel: QuestionsColumnType = {
  field: 'inDuelAvailable',
  width: 75,
  filterOperators: getGridBooleanOperators().map((operator) => {
    if (operator.value === 'is') {
      operator.value = FilterOperators.Equals
    }

    return operator
  }),
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
