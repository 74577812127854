import { Chip, ChipProps, SxProps } from '@mui/material'
import { CHIP_PERFORMANCE_COLORS } from '@shared/constants/chips'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = ChipProps & {
  value: number
}

export const PerformanceChip: React.FC<Props> = ({ value, sx, ...props }) => {
  const isPositive = React.useMemo(() => value > 0, [value])
  const renderedValue = React.useMemo(() => `${(value || Math.abs(value)).toFixed(1).replace('.', ',')}%`, [value])

  return (
    <Chip
      icon={!value ? <themeIcons.ArrowForward /> : isPositive ? <themeIcons.ArrowUpward /> : <themeIcons.ArrowDownward />}
      sx={{
        ...(!value ? CHIP_PERFORMANCE_COLORS.neutral : isPositive ? CHIP_PERFORMANCE_COLORS.positive : CHIP_PERFORMANCE_COLORS.negative),
        ...sx
      } as SxProps}
      label={renderedValue}
      {...props}
    />
  )
}
