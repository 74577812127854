import { ButtonGroup, Button } from '@mui/material'
import React from 'react'

type Props<T extends {id: string}> = {
  items: T[];
  selectedId: string;
  fullWidth?: boolean;
  onChange: (item: T) => void;
  renderLabel: (item: T) => React.ReactNode;
};

export const DefaultButtonGroup = <T extends {id: string}>({
  items,
  onChange,
  fullWidth,
  renderLabel,
  selectedId
}: Props<T>) => {
  return (
    <ButtonGroup
      variant="outlined"
      sx={{
        ...(fullWidth && {
          width: '100%',
          justifyContent: 'stretch'
        })
      }}
    >
      {items.map((item) => (
        <Button
          key={item.id}
          onClick={() => onChange(item)}
          sx={{
            ...(fullWidth && {
              width: '100%'
            }),
            '&.Mui-disabled': {
              pointerEvents: 'all',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            },
            ...((selectedId === item.id) && {
              '&.MuiButton-outlined': {
                backgroundColor: 'primary.main',
                borderColor: 'primary.main',
                color: 'white',
                ':hover': {
                  backgroundColor: 'primary.800',
                  borderColor: 'primary.800'
                }
              }
            })
          }}
        >
          {renderLabel(item)}
        </Button>
      ))}
    </ButtonGroup>
  )
}
