import React from 'react'
import { Typography } from '@mui/material'
import { getGridNumericOperators } from '@mui/x-data-grid-pro'
import { TopicRowModel } from '@features/topics/provider/TopicsProvider'

import { TopicsColumnType } from '../topicsDataGridColumns'

const RenderCell: React.FC<{row: TopicRowModel}> = ({ row }) => {
  return (
    <Typography
      variant="subtitle1"
      fontWeight={500}
    >{row.index}</Typography>
  )
}

export const indexColumnModel: TopicsColumnType = {
  field: 'index',
  width: 75,
  filterOperators: getGridNumericOperators(),
  valueGetter (params) {
    return params.row.index
  },
  renderCell: (params) => <RenderCell row={params.row} />
}
