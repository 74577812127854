import { Button, Typography, Box, useTheme } from '@mui/material'
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector
} from '@mui/x-data-grid-pro'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DataGridPagination: React.FC<{rowCount: number}> = ({ rowCount }) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)

  const pageCount = React.useMemo(() => {
    return Math.ceil(rowCount / pageSize)
  }, [pageSize, rowCount])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Box>
        <Button
          variant='outlined'
          startIcon={<themeIcons.ArrowBack />}
          sx={{
            mr: 1
          }}
          onClick={() => {
            apiRef.current.setPage(page - 1)
          }}
          disabled={page === 0}
        >
          <Typography variant='button'
            sx={{
              textTransform: 'none',
              [breakpoints.down('md')]: {
                display: 'none'
              }
            }}
          >
            {t('dataGrid.previousSite')}
          </Typography>
        </Button>
        <Button
          variant='outlined'
          endIcon={<themeIcons.ArrowForward />}
          onClick={() => {
            apiRef.current.setPage(page + 1)
          }}
          disabled={page >= (pageCount - 1) || pageCount === 0 }
        >
          <Typography variant='button'
            sx={{
              textTransform: 'none',
              [breakpoints.down('md')]: {
                display: 'none'
              }
            }}
          >
            {t('dataGrid.nextSite')}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
