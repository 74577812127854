import {
  TrainingCompaniesTraineesQuery,
  useTrainingCompaniesTraineesQuery,
  useMyTraineesQuery
} from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import React from 'react'

export type TrainingCompanies = Exclude<TrainingCompaniesTraineesQuery['trainingCompanies'], null | undefined>[0]

export type TraineeData = Exclude<TrainingCompanies['trainees'], null | undefined>[0]

// type TraineeCategories = Partial<Record<StudyStatus, TraineeData[]>>

export type ProfessionFilter = {
  id: string
  name: string
}

export type DashboardTrainingCompanyProviderContextType = {
  trainees?: TraineeData[]
  notConfirmedTrainees?: TraineeData[]
  trainingYearFilter: number[]
  professionFilter: string[]
  availableTrainingYears: number[]
  availableProfessions: ProfessionFilter[]
  loading?: boolean
  updateTrainingYearFilter: (trainingYears: number[]) => void
  updateProfessionFilter: (profession: string[]) => void
}

const DashboardTrainingCompanyProviderContext = React.createContext<DashboardTrainingCompanyProviderContextType>(
  {} as any
)

export const DashboardTrainingCompanyProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const [trainingYearFilter, setTrainingYearFilter] = React.useState<number[]>([])
  const [professionFilter, setProfessionFilter] = React.useState<string[]>([])

  const isTrainingCompany = React.useMemo<boolean>(() => {
    return isRole(Roles.TRAINING_COMPANY_MANAGER)
  }, [])

  const { data, loading: trainingCompanyLoading } = useTrainingCompaniesTraineesQuery({
    variables: {
      all: true
    },
    fetchPolicy: 'cache-and-network',
    skip: !isTrainingCompany
  })

  const { data: traineesData, loading: traineesLoading } = useMyTraineesQuery({
    fetchPolicy: 'cache-and-network',
    skip: isTrainingCompany
  })

  const loading = React.useMemo(() => {
    return trainingCompanyLoading || traineesLoading
  }, [trainingCompanyLoading, traineesLoading])

  const notConfirmedTrainees = React.useMemo<TraineeData[]>(() => {
    if (!data?.trainingCompanies) {
      return []
    }
    const notConfirmed = [] as TraineeData[]

    data?.trainingCompanies?.forEach((trainingCompany) => {
      const filtered = trainingCompany.trainees.filter((student) => !student.confirmed).map((student) => ({
        ...student
      }))

      notConfirmed.push(...filtered)
    })

    return notConfirmed
  }, [data?.trainingCompanies])

  const availableTrainingYears = React.useMemo(() => {
    if (!data?.trainingCompanies) {
      return []
    }

    const trainingYears: number[] = []

    data?.trainingCompanies?.forEach((trainingCompany) => {
      trainingCompany.trainees.forEach((trainee) => {
        if (trainee.trainingYear && !trainingYears.includes(trainee.trainingYear) && trainee.confirmed) {
          trainingYears.push(trainee.trainingYear)
        }
      })
    })

    return trainingYears.sort((a, b) => a - b)
  }, [data?.trainingCompanies])

  const availableProfessions = React.useMemo<ProfessionFilter[]>(() => {
    if (!data?.trainingCompanies) {
      return []
    }

    const professions: ProfessionFilter[] = []

    data?.trainingCompanies?.forEach((trainingCompany) => {
      trainingCompany.trainees.forEach((trainee) => {
        if (trainee.profession && !professions.find((item) => item.id === trainee.profession?.id) && trainee.confirmed) {
          professions.push({
            id: trainee.profession?.id,
            name: trainee.profession?.texts?.title ?? ''
          })
        }
      })
    })

    return professions
  }, [data?.trainingCompanies])

  const mappedTrainees = React.useMemo<TraineeData[]>(() => {
    if (!data?.trainingCompanies && !traineesData?.me?.trainees?.length) {
      return []
    }

    // const categories: TraineeCategories = {}

    let trainees = []

    if (isTrainingCompany) {
      trainees = data?.trainingCompanies?.reduce<TraineeData[]>((acc, curr) => {
        return [
          ...acc,
          ...curr.trainees.map((items) => ({
            ...items
          }))
        ]
      }, []) ?? []
    } else {
      trainees = traineesData?.me?.trainees?.map((student) => ({
        ...student
      })) ?? []
    }

    const filteredTrainees = trainees.filter((student) => {
      const checkProfession = !professionFilter.length || professionFilter.includes(student.profession?.id ?? '')
      const checkTrainingYear = !trainingYearFilter.length || trainingYearFilter.includes(student.trainingYear ?? 0)

      const hasProfession = !!student.profession?.id

      return checkProfession && checkTrainingYear && student.confirmed && hasProfession
    })

    /**
    filteredTrainees.forEach((student) => {
      if (student.confirmed) {
        if (!categories[student.studyStatus]) {
          categories[student.studyStatus] = []
        }

        categories?.[student.studyStatus]?.push(student)
      }
    })
    */

    return filteredTrainees
  }, [data, traineesData, professionFilter, trainingYearFilter])

  const updateTrainingYearFilter = React.useCallback((value: number[]) => {
    setTrainingYearFilter(value)
  }, [])

  const updateProfessionFilter = React.useCallback((value: string[]) => {
    setProfessionFilter(value)
  }, [])

  const value = React.useMemo(() => ({
    trainingYearFilter,
    trainees: mappedTrainees,
    notConfirmedTrainees,
    professionFilter,
    loading,
    availableTrainingYears,
    availableProfessions,
    updateTrainingYearFilter,
    updateProfessionFilter
  }), [
    mappedTrainees,
    notConfirmedTrainees,
    professionFilter,
    availableTrainingYears,
    trainingYearFilter,
    loading
  ])

  return (
    <DashboardTrainingCompanyProviderContext.Provider value={value}>
      {children}
    </DashboardTrainingCompanyProviderContext.Provider>
  )
}

export const useDashboardTrainingCompanyContext = () => React.useContext(DashboardTrainingCompanyProviderContext)
