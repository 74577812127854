import { Box, Divider } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'
import { MIN_TRAINING_YEARS, MAX_TRAINING_YEARS } from '@shared/constants/trainingYears'

import { ProfessionExamDateInput } from './ProfessionExamDateInput'

export const ProfessionCreateEditInputs: React.FC = () => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='texts.title'
        options={{ required: true }}
        title={t('common.title')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='config.trainingYears'
        options={{
          required: true,
          validate: {
            valid: (value: number) => value >= MIN_TRAINING_YEARS && value <= MAX_TRAINING_YEARS
          }
        }}
        title={t('common.trainingYears')}
        description={t('professions.trainingYearsDescription', { min: MIN_TRAINING_YEARS, max: MAX_TRAINING_YEARS }) }
        type='number'
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <ProfessionExamDateInput />
    </Box>
  )
}
