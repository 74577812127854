import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { fontSizes, lineHeights } from '@theme/theme'

export type MuiInputBaseType = {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
}

export const MuiInputBase:MuiInputBaseType = {
  styleOverrides: {
    root: {
      '&.MuiInputBase-multiline': {
        padding: '14px'
      },
      '&.MuiOutlinedInput-root': {
        border: '1px solid',
        borderColor: palette.grey?.[400],
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        color: palette.text?.primary,
        backgroundColor: 'transparent',
        input: {
          padding: '14px'
        },
        fieldset: {
          border: 'none'
        },
        '&.Mui-error': {
          borderColor: palette.error[700],
          color: palette.error[700]
        },
        '&.MuiSelect-overwrite': {
          padding: '14px',
          minHeight: '42px',
          '.MuiOutlinedInput-input': {
            fontSize: fontSizes.md,
            fontWeight: 400,
            fontFamily: 'Signika',
            lineHeight: lineHeights.md
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover': {
            backgroundColor: palette.grey?.[100]
          },
          '&.Mui-disabled': {
            borderColor: palette.grey?.[300],
            color: palette.grey?.[400]
          }
        }
      },
      'input:-webkit-autofill': {
        backgroundColor: 'white !important',
        transition: 'background-color 600000s 0s, color 600000s 0s'
      },
      'input:-webkit-autofill:hover': {
        backgroundColor: 'white !important',
        transition: 'background-color 600000s 0s, color 600000s 0s'
      },
      'input:-webkit-autofill:focus': {
        backgroundColor: 'white !important',
        transition: 'background-color 600000s 0s, color 600000s 0s'
      },
      'input:-webkit-autofill:active': {
        backgroundColor: 'white !important',
        transition: 'background-color 600000s 0s, color 600000s 0s'
      }

    }
  }
}
