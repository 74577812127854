import { Box, Grid, IconButton } from '@mui/material'
import { DashboardClicks } from '@shared/components/cards/DashboardClicks'
import { DashboardConversionRate } from '@shared/components/cards/DashboardConversionRate'
import { DashboardItemList, DashboardTopItemsType } from '@shared/components/cards/DashboardItemList'
import { DashboardYearOverview } from '@shared/components/cards/DashboardYearOverview'
import { CONVERSION_TIME_SPAN } from '@shared/constants/dashboard'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { palette } from '@theme/core/palette/palette'
import { themeIcons } from '@theme/theme'
import { useJobQuery, useJobTrackingQuery, useJobsQuery } from '@typings/graphql'
import { Roles } from '@typings/roles'
import { mapYearData } from '@utils/dashboard'
import { can, isRole } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

export const JobWallDetailView: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { data: jobs, loading: jobsLoading } = useJobsQuery()

  const { data: jobData, loading: jobLoading } = useJobQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const { data, loading } = useJobTrackingQuery({
    variables: {
      jobId: id as string
    },
    skip: !id
  })

  const navigate = useNavigate()

  const mappedJobs = React.useMemo<DashboardTopItemsType[]>(() => {
    return jobs?.jobs.map((job) => ({
      id: job.id,
      name: job.texts?.name,
      performance: job.bimonthlyPerformance
    })
    )?.sort((a, b) => b.performance - a.performance) || []
  }, [jobs])

  const mappedJobData = React.useMemo(() => {
    if (!data) {
      return {}
    }

    const {
      job_application_click: jobApplicationClick,
      job_click: jobClick
    } = data?.dashboardTrackingJob || {}

    return {
      jobApplicationClick: jobApplicationClick as number[][],
      jobClick: jobClick as number[][]
    }
  }, [data])

  const mappedYearData = React.useMemo(() => {
    if (!mappedJobData) {
      return []
    }

    return [
      {
        name: 'jobClicks',
        color: palette.primary[900] || '',
        values: mapYearData(mappedJobData.jobClick || [])
      },
      {
        name: 'jobApplicationClicks',
        color: palette.secondary[300] || '',
        values: mapYearData(mappedJobData.jobApplicationClick || [])
      }
    ]
  }, [mappedJobData])

  const conversionData = React.useMemo(() => {
    const { jobApplicationClick, jobClick } = mappedJobData

    const flattenedJobClicks = jobClick?.flat() || []
    const flattenedApplicationClicks = jobApplicationClick?.flat() || []

    return {
      jobClicks: flattenedApplicationClicks.slice(flattenedApplicationClicks.length -
        CONVERSION_TIME_SPAN, flattenedApplicationClicks.length).reduce((a, b) => a + b, 0),
      applicationClicks: flattenedJobClicks.slice(flattenedJobClicks.length -
        CONVERSION_TIME_SPAN, flattenedJobClicks.length).reduce((a, b) => a + b, 0)
    }
  }, [mappedJobData])

  return (
    <DefaultLayout
      title={jobData?.job?.texts?.name}
      {...(isRole(Roles.ADMIN) && { subtitle: jobData?.job?.company?.name })}
      description={t('jobwall.performanceDescription')}
      loading={jobsLoading || loading || jobLoading}
      headerExtension={
        can(['jobs:write', 'jobs:write.own']) && <IconButton onClick={() => navigate('edit')}>
          <themeIcons.Edit />
        </IconButton>
      }
    >
      <Box sx={{
        pt: 4.5
      }}
      >
        <Grid container spacing={3}>
          <Grid height="auto" item xs={9}>
            <DashboardYearOverview data={mappedYearData} showYAxis/>
          </Grid>
          <Grid item xs={3}>
            <DashboardConversionRate
              titlePrimary={t('dashboard.jobApplicationClicks')}
              titleSecondary={t('dashboard.jobClicks')}
              primary={conversionData.applicationClicks}
              secondary={conversionData.jobClicks}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DashboardClicks
                  keyVal="jobs"
                  title={t('dashboard.jobClicks')}
                  values={mappedJobData.jobClick || []}
                />
              </Grid>
              <Grid item xs={6}>
                <DashboardClicks
                  keyVal="jobs"
                  title={t('dashboard.jobApplicationClicks')}
                  values={mappedJobData.jobApplicationClick || []}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={4}
            sx={{
              height: '100%'
            }}
          >
            <DashboardItemList
              title={t('dashboard.topJobs')}
              data={mappedJobs}
              max={3}
              onItemClicked={(itemId) => navigate(`/jobwall/${itemId}`)}
              onShowAll={() => navigate('/jobwall')}
            />
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  )
}
