import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { themeIcons } from '@theme/theme'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { TopicsTabBar } from '@shared/components/ui/TopicsTabBar'
import { useNewTabHook } from '@hooks/useNewTabHook'
import { useParams } from 'react-router'

import { CategoriesProvider, useCategoriesContext } from '../provider/CategoriesProvider'
import { CategoriesDataGrid } from '../dataGrid/CategoriesDataGrid'

const CategoriesViewContent: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const { t } = useTranslation()

  const { selectedIds, handleDelete, profession } = useCategoriesContext()
  const navigate = useNewTabHook()

  const validParent = React.useMemo(() => {
    return !!profession
  }, [profession])

  return (
    <DefaultLayout
      title={t(`${categoryId ? 'routes.subCategories' : 'routes.categories'}`)}
      description={t(`${categoryId ? 'categories.subCategoryListDescription' : 'categories.categoryListDescription'}`)}
      {...(!categoryId && { tabBar: <TopicsTabBar /> })}
      headerExtension={
        <Box>
          {can('categories:delete') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['categories:create', 'categories:create.partial']) && validParent && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t(`${categoryId ? 'categories.addSubCategory' : 'categories.addCategory'}`)}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <CategoriesDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const CategoriesView: React.FC = () => (
  <CategoriesProvider>
    <CategoriesViewContent />
  </CategoriesProvider>
)
