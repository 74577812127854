import { Typography, useTheme } from '@mui/material'
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector
} from '@mui/x-data-grid-pro'
import { theme } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DataGridPageLabel: React.FC<{rowCount: number}> = ({ rowCount }) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)

  const pageCount = React.useMemo(() => {
    return Math.ceil((rowCount) / pageSize)
  }, [pageSize, rowCount])

  return (
    <Typography sx={{
      [breakpoints.down('md')]: {
        ...theme.typography.subtitle2
      }
    }}
    >
      {t('dataGrid.siteOf', { current: page + 1, count: page > pageCount ? page : (pageCount === 0) ? 1 : pageCount })}
    </Typography>
  )
}
