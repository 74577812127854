import { Typography } from '@mui/material'
import { useCategoryQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const CategoryBreadcrumb:React.FC = () => {
  const { t } = useTranslation()
  const { categoryId } = useParams<{ categoryId: string }>()

  const { data } = useCategoryQuery({
    variables: {
      id: categoryId as string
    },
    skip: !categoryId
  })

  const title = React.useMemo(() => {
    if (!data) {
      return t('categories.unknown')
    }

    return data.category.texts?.title
  }, [data])

  return (
    <Typography
      variant="subtitle1"
      fontWeight="inherit"
    >
      {title}
    </Typography>
  )
}
