import React from 'react'
import { UserCreateView } from '@features/users/views/UserCreateView'
import { UserEditView } from '@features/users/views/UserEditView'
import { UsersView } from '@features/users/views/UsersView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const usersRoutes: AppRouteObject[] = [
  {
    path: '/users',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.ADMIN]
    },
    meta: {
      name: 'users',
      navItem: true,
      navIcon: 'Group',
      navOrder: 7
    },
    children: [{
      index: true,
      element: <UsersView />
    }, {
      path: 'create',
      meta: {
        name: 'usersCreate'
      },
      element: <UserCreateView />,
      access: {
        roles: [Roles.ADMIN]
      }
    }, {
      path: ':id',
      meta: {
        name: 'usersEdit'
      },
      element: <UserEditView />,
      access: {
        roles: [Roles.ADMIN]
      }
    }]
  }
]
