import React from 'react'
import { Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { upperFirstLetter } from '@utils/format'

import { BaseColumnType } from '../types/types'

export const firstnameColumnModel: BaseColumnType = {
  field: 'firstname',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{upperFirstLetter(params.value)}</Typography>
  )
}
