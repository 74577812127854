import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { fontSizes } from '@theme/core/typography/typography'

export type MuiDialogType = {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
}

export const MuiDialog:MuiDialogType = {
  styleOverrides: {
    container: {
      width: '100%',
      '.MuiDialogContent-root': {
        padding: 0,
        marginTop: '16px',
        marginBottom: '24px'
      },
      '.MuiDialogTitle-root': {
        fontSize: fontSizes.lg,
        padding: 0
      },
      '.MuiDialogActions-root': {
        padding: 0
      },
      '.MuiPaper-root': {
        padding: 24,
        borderRadius: 8
      }
    },
    paper: {
      margin: 0,
      maxWidth: '50%'
    }
  }
}
