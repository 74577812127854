import { BaseColumnType } from '@shared/dataGrid/types/types'
import { clicksColumnModel } from '@shared/dataGrid/columns/clicksColumn'
import { performanceColumnModel } from '@shared/dataGrid/columns/performanceColumn'
import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'
import { GridColDef } from '@mui/x-data-grid-pro'

import { ProductsRowModel } from '../provider/ProductsProvider'

import { statusColumnModel } from './columns/statusColumn'

export type ProductsColumnField = keyof ProductsRowModel

export type ProductsColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: ProductsColumnField,
  hideHeader?: boolean,
}

export const productsDataGridColumns: ProductsColumnType[] = [
  statusColumnModel,
  clicksColumnModel,
  performanceColumnModel,
  createdColumnModel
]
