import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { InstructorsProvider, useInstructorsContext } from '../provider/InstructorsProvider'
import { InstructorsDataGrid } from '../dataGrid/InstructorsDataGrid'

const InstructorsViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { selectedIds, handleDelete } = useInstructorsContext()

  return (
    <DefaultLayout
      title={t('routes.instructors')}
      description={t('instructors.instructorsDescription')}
      headerExtension={
        <Box>
          {can('users:delete.instructor') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can('users:create.instructor') && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('instructors.addInstructor')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <InstructorsDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const InstructorsView: React.FC = () => (
  <InstructorsProvider>
    <InstructorsViewContent />
  </InstructorsProvider>
)
