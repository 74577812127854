import React, {
  ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo
} from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box, BoxProps, CircularProgress, Fade, Snackbar, Typography
} from '@mui/material'
import { FileDataFragment } from '@typings/graphql'
import { getImageDimensions } from '@utils/image'

export type FileDimensions = {
  width: number
  height: number
}

export type FileFormats = {
  'image/*'?: string[],
  'video/*'?: string[],
  'audio/*'?: string[],
  'text/html'?: string[],
  'text/csv'?: string[],
  'application/json'?: string[]
}

export type FileDragDropProps = BoxProps & {
  accept: FileFormats
  onFilesChanged: (files: File[]) => void
  dragPlaceholder?: string
  dropPlaceholder?: string
  initialFile?: FileDataFragment
  loading?: boolean
  limit?: number
  maxFileSize?: number
  maxFileDimensions?: FileDimensions
  renderedPreview?: (acceptedFileTypes: string[], maxSize: number) => React.ReactNode
};

export type FileDragDropHandler = {
  open: () => void,
  reset: () => void
}

type FileDragDropRenderFn = ForwardRefRenderFunction<FileDragDropHandler, FileDragDropProps>

const FileDragDrop: FileDragDropRenderFn = (props, ref) => {
  const {
    onFilesChanged,
    accept,
    dragPlaceholder,
    dropPlaceholder,
    loading,
    limit,
    maxFileSize,
    maxFileDimensions,
    renderedPreview,
    ...boxProps
  } = props
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const acceptedFileTypes = useMemo(() => {
    return Object.keys(accept).reduce((acc, key) => {
      const values = accept[key as keyof FileFormats] || []
      return [...acc, ...values]
    }, [] as string[])
  }, [accept])

  const maxSize = useMemo(() => {
    return maxFileSize || 1048576
  }, [maxFileSize])

  const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles?: FileRejection[]) => {
    if (rejectedFiles?.length) {
      setErrorMessage(t(`errors.${rejectedFiles?.[0]?.errors[0].code.replaceAll('-', '')}`))
      setSnackbarOpen(true)
      return
    }

    if (maxFileDimensions) {
      const { width, height } = await getImageDimensions(acceptedFiles[0])
      if (width > maxFileDimensions.width || height > maxFileDimensions.height) {
        setErrorMessage(t('errors.filedimensions', maxFileDimensions))
        setSnackbarOpen(true)
        return
      }
    }

    if (limit && acceptedFiles.length > limit) {
      setErrorMessage(t('errors.fileLimitError'))
      setSnackbarOpen(true)
      return
    }

    onFilesChanged(acceptedFiles)
  }, [])

  const {
    getRootProps, getInputProps, isDragActive, open
  } = useDropzone({
    accept,
    maxSize,
    multiple: false,
    onDrop
  })

  useImperativeHandle(ref, () => ({
    open,
    reset: () => onDrop([])
  }))

  return (
    <Box display="flex"
      justifyContent="center"
      {...getRootProps()}
      {...boxProps}
      position="relative"
      overflow="hidden"
    >
      <Box
        sx={{
          borderRadius: '8px',
          border: 'solid 1px',
          borderColor: 'grey.300',
          height: 'auto',
          maxHeight: '60vh',
          width: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          p: 0,
          '&:hover': {
            borderColor: 'grey.500'
          }
        }}
      >
        {renderedPreview?.(acceptedFileTypes, maxSize)}
      </Box>
      <Box position="relative">
        <input {...getInputProps()} />
        {
        isDragActive
          ? dropPlaceholder && <Typography>{dropPlaceholder}</Typography>
          : dragPlaceholder && <Typography>{dragPlaceholder}</Typography>
      }
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarOpen(false)
        }}
      >
        <Alert severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Fade in={loading}>
        <Box
          position="absolute"
          left={-10}
          top={-10}
          right={-10}
          bottom={-10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backdropFilter: 'blur(5px)' }}
        >
          <CircularProgress />
        </Box>
      </Fade>
    </Box>
  )
}

export default React.forwardRef(FileDragDrop)
