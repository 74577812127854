import React from 'react'
import ReactPlayer from 'react-player'
import { Box } from '@mui/material'

import { PreviewContentProps } from './modalPreviewContent'

export const ModalPreviewContentVideo: React.FC<PreviewContentProps> = ({ src }) => {
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '.react-player': {
        video: {
          display: 'block',
          marginBottom: '-1px'
        }
      }
    }}
    >
      <ReactPlayer
        className='react-player'
        url={src}
        controls={true}
        height={'auto'}
        width={'100%'}
      />
    </Box>
  )
}
