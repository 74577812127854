import { useDataGridPagination } from '@hooks/useDataGridPagination'
import { useErrorMessage } from '@hooks/useErrorMessage'
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-pro'
import { AutocompleteSelectItem } from '@shared/components/inputs/BaseAutoCompleteInput'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import {
  CompaniesQuery,
  useDeleteCompanyMutation,
  useDeleteCompaniesMutation,
  useDeleteCompanyCategoryMutation,
  useCompaniesPaginatedQuery,
  CompaniesPaginatedQuery,
  useCompanyCategoriesQuery
} from '@typings/graphql'
import { SelectType } from '@typings/select'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export type CompanyType = Exclude<CompaniesQuery['companies'], null | undefined>[0]
export type CompaniesRowModel = Omit<CompanyType, '__typename'> & {
  contacts?: string[]
}

export type CompanyColumnType = Omit<GridColDef<CompaniesRowModel>, 'field'> & {
  field: keyof CompaniesRowModel | 'action',
  hideHeader?: boolean,
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type CompaniesProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: CompaniesRowModel | null
  companyData?: CompaniesPaginatedQuery['companiesPaginated']
  loading: boolean
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  categoryData: SelectType[]
  updatePaginationModel: (pagination: GridPaginationModel) => void
  updateSortModel: (model: GridSortModel, details: GridCallbackDetails) => void
  updateFilterModel: (filter: GridFilterModel | undefined) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: CompaniesRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  updateSelectedCategory: (category?: AutocompleteSelectItem) => void
  handleRefetchCategories: () => void
  updateInitialState: (initialState?: GridInitialState) => void
}

const CompaniesProviderContext = React.createContext<CompaniesProviderContextType>(
  {} as any
)

export const CompaniesProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [deleteCompanies] = useDeleteCompaniesMutation()
  const [deleteCompany] = useDeleteCompanyMutation()
  const [deleteCompanyCategory] = useDeleteCompanyCategoryMutation({
    update (cache, { data }) {
      cache.evict({
        id: cache.identify({
          __typename: 'CompanyCategory',
          id: data?.deleteCompanyCategory?.id
        })
      })
      cache.gc()
    }
  })

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [selectedCategory, setSelectedCategory] = React.useState<AutocompleteSelectItem | undefined>()
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [showCategoryDeleteDialog, setShowCategoryDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<CompaniesRowModel | null>(null)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [showToast, setShowToast] = React.useState(false)
  const [loadMore, setLoadMore] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const { data, loading: dataLoading, refetch } = useCompaniesPaginatedQuery({
    skip: !!disableFetching,
    fetchPolicy: 'network-only',
    variables: {
      first: DATA_GRID_PAGE_SIZE
    }
  })

  const { data: categoryData, loading: categoryLoading, refetch: refetchCategories } = useCompanyCategoriesQuery({
    fetchPolicy: 'cache-and-network'
  })

  const mappedCategories = React.useMemo(() => {
    return categoryData?.companyCategories.map((category) => {
      return {
        id: category.id,
        name: category.name
      }
    }) || []
  }, [categoryData])

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading || loadMore || categoryLoading
  }, [artificialLoading, dataLoading, loadMore, categoryLoading])

  const companyData = React.useMemo<CompaniesPaginatedQuery['companiesPaginated'] | undefined>(() => {
    return data?.companiesPaginated
  }, [data])

  const {
    fetchVariablesRef, filterModel, paginationModel, sortModel,
    setFilterModelState, setPaginationModelState, setSortModelState, setInitialState
  } = useDataGridPagination<CompaniesPaginatedQuery>()

  const handleFetchMore = async () => {
    setErrorMessage('')
    setLoadMore(true)
    try {
      await refetch(fetchVariablesRef.current)
    } catch (e) {
      setMessageByCode(e, 'users')
      setShowToast(true)
    } finally {
      setLoadMore(false)
    }
  }

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: CompaniesRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteCompanies({
          variables: {
            data: {
              companyIds: selectedIds as string[]
            }
          }
        })
        updateSelectedIds([])
        setSuccessMessage(t('companies.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteCompany({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('companies.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updatePaginationModel = React.useCallback((pagination: GridPaginationModel) => {
    setPaginationModelState(pagination, companyData?.pageInfo.startCursor, companyData?.pageInfo.endCursor)

    handleFetchMore()
  }, [companyData])

  const updateSortModel = React.useCallback((model: GridSortModel) => {
    setSortModelState(model)
    handleFetchMore()
  }, [companyData])

  const updateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    setFilterModelState(filter)
    handleFetchMore()
  }, [])

  const updateSelectedCategory = React.useCallback((category?: AutocompleteSelectItem) => {
    setSelectedCategory(category)
    setShowCategoryDeleteDialog(true)
  }, [])

  const updateInitialState = React.useCallback((initialState?: GridInitialState) => {
    setInitialState(initialState)
    handleFetchMore()
  }, [])

  const handleConfirmCategoryDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)

    if (!selectedCategory) {
      return
    }

    try {
      deleteCompanyCategory({
        variables: {
          id: selectedCategory.id
        }
      })
      setSuccessMessage(t('companies.deleteCategorySuccess'))
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowCategoryDeleteDialog(false)
      setShowToast(true)
      setSelectedCategory(undefined)
      setIsDeleteLoading(false)
    }
  }, [selectedCategory])

  const handleRefetchCategories = React.useCallback(async () => {
    try {
      await refetchCategories()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const value = React.useMemo<CompaniesProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    companyData,
    loading,
    paginationModel,
    sortModel,
    filterModel,
    categoryData: mappedCategories,
    updatePaginationModel,
    updateSortModel,
    updateFilterModel,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds,
    updateSelectedCategory,
    handleFetchMore,
    handleRefetchCategories,
    updateInitialState
  }), [selectedIds, mappedCategories, selectedItem, companyData, loading])

  return (
    <CompaniesProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'companies.deleteEntries' : 'companies.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.name } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowCategoryDeleteDialog(false)}
        onConfirm={handleConfirmCategoryDelete}
        loading={isDeleteLoading}
        open={showCategoryDeleteDialog}
      >
        <Trans
          i18nKey={'companies.deleteCategory'}
          values={{ name: selectedCategory?.name }}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />

    </CompaniesProviderContext.Provider>
  )
}

export const useCompaniesContext = () => React.useContext(CompaniesProviderContext)
