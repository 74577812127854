import { Box, Divider } from '@mui/material'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'

type Props = {
  loading?: boolean
}

export const ProfileEditInputs: React.FC<Props> = ({ loading }) => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseSingleFileInput
        formKey="files.avatar"
        fileKey="avatar"
        title={t('common.avatar')}
        description={t('settings.avatarDescription')}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='firstname'
        options={{ required: true }}
        title={t('common.firstName')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='lastname'
        options={{ required: true }}
        title={t('common.lastName')}
      />

    </Box>
  )
}
