import { Divider } from '@mui/material'
import { BaseDateInput } from '@shared/components/inputs/BaseDateInput'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const QuestionDateInput: React.FC = () => {
  const { t } = useTranslation()

  const formatter = (value: Date) => {
    const date = new Date(value)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
  }

  return (
    <>
      <BaseDateInput
        formKey='config.solution'
        options={{ required: true }}
        title={t('questions.answer')}
        formatter={formatter}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.displayCalculator'
        title={t('questions.displayCalculator')}
        description={t('questions.displayCalculatorDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
    </>
  )
}
