import React from 'react'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { FilterOperators } from '@typings/graphql'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { QuestionListRowModel } from '@features/questionList/provider/QuestionListProvider'

import { QuestionListColumnType } from '../questionListDataGridColumns'

const RenderCell: React.FC<{row: QuestionListRowModel}> = ({ row }) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant="subtitle1"
      fontWeight={500}
      sx={{
        color: row.inDuelAvailable ? 'success.main' : 'error.main'
      }}
    >{t(`common.${row.inDuelAvailable ? 'yes' : 'no'}`)}</Typography>
  )
}

export const inDuelAvailableColumnModel: QuestionListColumnType = {
  field: 'inDuelAvailable',
  width: 75,
  filterOperators: getGridBooleanOperators().map((operator) => {
    if (operator.value === 'is') {
      operator.value = FilterOperators.Equals
    }

    return operator
  }),
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
