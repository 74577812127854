import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { ResizeMode } from '@typings/files'
import { themeIcons } from '@theme/theme'

import { ConfirmDialog, ConfirmDialogProps } from '../ui/ConfirmDialog'

type Props = Omit<ConfirmDialogProps, 'title' | 'onConfirm'> & {
  previewImage?: string
  resizeMode?: ResizeMode
  onConfirm: (resizeMode: ResizeMode) => void
}

const RatioOptions:Record<ResizeMode, React.ReactElement> = {
  [ResizeMode.COVER]: <themeIcons.Expand />,
  [ResizeMode.CONTAIN]: <themeIcons.Minimize />
}

export const FileUploadRatioModal: React.FC<Props> = ({ previewImage, resizeMode, onConfirm, onClose, ...props }) => {
  const { t } = useTranslation()

  const [selectedValue, setSelectedValue] = React.useState<ResizeMode>(resizeMode || ResizeMode.COVER)

  const handleResizeModeChange = (event: React.MouseEvent<HTMLElement>, newResizeMode: ResizeMode) => {
    if (newResizeMode) {
      setSelectedValue(newResizeMode)
    }
  }

  const handleConfirm = () => {
    onConfirm?.(selectedValue)
  }

  return (
    <ConfirmDialog
      title={t('common.formatImage')}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '90%',
          maxHeight: '95%',
          width: '40%',
          height: '50%'
        }
      }}
      stretchButtons
      confirmLabel={t('common.save')}
      onConfirm={handleConfirm}
      onClose={onClose}
      {...props}
    >
      <IconButton
        onClick={() => onClose?.()}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10
        }}
      >
        <themeIcons.Cancel />
      </IconButton>
      <Box sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      >
        <Box sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden'
        }}
        >
          <Box sx={{
            position: 'absolute',
            width: '50%',
            backgroundColor: 'white',
            aspectRatio: '16 / 9',
            overflow: 'visible',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <Box sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: '1px solid black',
              boxShadow: '0 0 0 9999px rgba(0, 0, 0, .3)'
            }}
            />
            <img src={previewImage}
              style={{
                objectFit: selectedValue,
                display: 'block',
                width: '100%',
                height: '100%',
                overflow: 'visible'
              }}
            />
          </Box>
        </Box>

        <Box sx={{
          flex: 0,
          pt: '24px',
          display: 'flex',
          flexDirection: 'row'
        }}
        >
          <Typography sx={{
            pr: 4
          }}
          >{t('common.containOrCover')}</Typography>

          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={handleResizeModeChange}
            sx={{
              '.MuiToggleButton-root': {
                '&.Mui-selected': {
                  color: 'primary.main'
                }
              }
            }}
          >
            {Object.entries(RatioOptions).map(([key, value]) => (
              <ToggleButton key={key} value={key}>
                {value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
    </ConfirmDialog>
  )
}
