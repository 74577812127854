import { store, RootState } from '@services/store/store'
import { LoginMutation } from '@typings/graphql'
import { Roles } from '@typings/roles'

const checkPermission = (permission: string, user: LoginMutation['login']['user']) => {
  const [resource, ability] = permission.split(':')

  if (user.role.key === Roles.COMPANY && !user.company) {
    return false
  }

  return user.role.permissions.some((p) => p.resource === resource && p.ability === ability)
}

export const can = (permission: string | string[]) => {
  const state: RootState = store.getState()

  if (!state.auth.user?.role.key) {
    return false
  }

  if (typeof permission === 'string') {
    return checkPermission(permission, state.auth.user!)
  } else {
    return permission.some((p) => checkPermission(p, state.auth.user!))
  }
}

export const isRole = (role: string) => {
  const state: RootState = store.getState()

  if (!state.auth.user?.role.key) {
    return false
  }

  return state.auth.user?.role.key === role
}

export const checkPartnerRestriction = () => {
  const state: RootState = store.getState()

  if (!state.auth.user?.role.key) {
    return false
  }

  if (state.auth.user.role.key === Roles.COMPANY) {
    return !!state.auth.user.company?.isPartner
  }

  return true
}
