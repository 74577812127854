import { useAppSelector } from '@services/store/store'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'

export const useNewTabHook = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { personalSettings } = useAppSelector(({ app }) => app)

  const handleNewTab = React.useCallback((path: string) => {
    if (personalSettings?.newTab) {
      const mappedPath = path[0] === '/' ? path : `${pathname}/${path}`
      window.open(mappedPath, '_blank')
    } else {
      navigate(path)
    }
  }, [navigate, personalSettings])

  return handleNewTab
}
