import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { useAppSelector } from '@services/store/store'
import { useCompanyNameOnlyQuery, useMyCompanyQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFileByKey } from '@utils/files'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import { useParams } from 'react-router'

import { DashboardTabBar } from '../components/DashboardTabBar'
import { DashboardProvider } from '../provider/DashboardProvider'
import { DashboardCreateButton } from '../components/DashboardCreateButton'

import { DashboardTabView } from './DashboardTabView'

const DashboardCompanyViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()

  const { user } = useAppSelector(({ auth }) => auth)

  const isAdmin = React.useMemo<boolean>(() => {
    return isRole(Roles.ADMIN)
  }, [])

  const { data, loading: myCompanyLoading } = useMyCompanyQuery({
    fetchPolicy: 'cache-and-network',
    skip: !user?.company
  })

  const { data: companyData, loading: companyLoading } = useCompanyNameOnlyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !isAdmin || !id
  })

  const loading = React.useMemo(() => {
    return myCompanyLoading || companyLoading
  }, [myCompanyLoading, companyLoading])

  const keyvisual = React.useMemo(() => {
    return getFileByKey('keyvisual', data?.myCompany?.files) || null
  }, [data])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'auto'
    }}
    >
      {keyvisual?.remote.url && <Box sx={{
        flex: '0 0 240px',
        width: '100%',
        backgroundImage: `url(${keyvisual?.remote.url})`,
        backgroundSize: 'cover'
      }}
      />}
      <Box sx={{
        flex: 1,
        p: 4.5
      }}
      >
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          <Box sx={{ flex: 0, width: '100%' }}>
            <Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                mb: 3.5
              }}
              >
                <Box sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h1"
                    mb={1}
                    fontWeight={600}
                  >{isAdmin
                    ? companyData?.company?.name || ''
                    : t('dashboard.userWelcome', {
                      fullName: t('common.fullName', {
                        firstName: user?.firstname,
                        lastName: user?.lastname
                      })
                    })}</Typography>
                  <Typography>
                    {isAdmin
                      ? t('dashboard.company.dashboardAdmin',
                        { company: companyData?.company?.name })
                      : t('dashboard.company.dashboardDescription')}
                  </Typography>
                </Box>
              </Box>

            </Box>
            <Divider light />
          </Box>
          <Box sx={{
            flex: 1,
            position: 'relative'
          }}
          >
            {loading && <LinearProgress sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%'
            }}
            />}
            {!loading && !data?.myCompany && !isAdmin && (
              <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute'
              }}
              >
                <Typography
                  variant="h2"
                  mb={1}
                  fontWeight={600}
                  sx={{ width: '75%', textAlign: 'center' }}
                >
                  {t('dashboard.company.noCompany')}
                </Typography>
              </Box>
            )}
            {(data?.myCompany || isAdmin) && (
            <Box>
              <Box sx={{
                mt: 4,
                mb: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
              >
                <Box>
                  <DashboardTabBar />
                </Box>

                <DashboardCreateButton />
              </Box>
              <DashboardTabView />
            </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const DashboardCompanyView: React.FC = () => (
  <DashboardProvider>
    <DashboardCompanyViewContent />
  </DashboardProvider>
)
