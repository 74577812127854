import { LoadingButton } from '@mui/lab'
import i18n from '@services/i18n/i18n'
import { StatusSelectInput } from '@shared/components/inputs/StatusSelectInput'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CreateIncentiveInput, Status, useCompaniesNameOnlyQuery, useCreateIncentiveMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useFileUpload } from '@hooks/useFileUpload'
import { isRole } from '@utils/permissions'
import { Roles } from '@typings/roles'
import { EditableFile } from '@typings/files'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { ProductCreateEditInputs } from '../components/ProductCreateEditInputs'

type ProductInputType = Omit<CreateIncentiveInput, 'texts'> & {
  texts: Record<string, string>
  files: Record<string, EditableFile<any> | null>
  code: string
}

const ProductCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const navigate = useNavigate()

  const { formState: { dirtyFields, isDirty, isValid, isSubmitted }, handleSubmit } = useFormContext()

  useUnsavedChanges(isDirty && !created && (!isSubmitted || !!errorMessage))

  const [createIncentive] = useCreateIncentiveMutation()

  const { uploadFile } = useFileUpload()

  const { data: companyData, loading: companyLoading } = useCompaniesNameOnlyQuery({
    skip: !isRole(Roles.ADMIN)
  })

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      const result = await createIncentive({
        variables: {
          data: {
            ...(isRole(Roles.ADMIN) && { level: input.level }),
            ...(isRole(Roles.ADMIN) && { companyId: input.companyId }),
            status: input.status,
            couponCode: input.code,
            contact: input.contact.toLowerCase(),
            link: input.link,
            texts: Object.keys(input.texts).map((key) => ({
              key,
              lang: i18n.resolvedLanguage,
              value: input.texts[key]
            }))
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        if (file?.upload && result.data?.createIncentive) {
          await uploadFile(
            file.upload.file,
            file.upload.data.key,
            'Incentive',
            result.data.createIncentive.id,
            file.config
          )
        }
      })

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/products')
  }

  return (
    <CreateEditLayout
      title={t('products.createTitle')}
      description={t('products.createDescription')}
      headerExtension={(
        <StatusSelectInput formKey="status" options={{ required: true }} />
      )}
      loading={companyLoading}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!dirtyFields || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <ProductCreateEditInputs companies={companyData?.companies} loading={loading} />

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
      >
        {t('products.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const ProductCreateView: React.FC = () => {
  const formData = useForm<ProductInputType>({
    defaultValues: {
      status: Status.Draft
    },
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <ProductCreateViewContent />
    </FormProvider>
  )
}
