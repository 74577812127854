import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { IconButton, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { InstructorsRowModel, useInstructorsContext } from '../provider/InstructorsProvider'

import { InstructorColumnType, instructorsDataGridColumns } from './instructorsDataGridColumns'

export const InstructorsDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const {
    updateSelectedIds, loading, instructorsData,
    handleSendInvite, handleDelete, updateSelectedItem, selectedIds
  } = useInstructorsContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: InstructorsRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<InstructorColumnType[]>(() => {
    return [...instructorsDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can('users:create.instructor') && {
            additionalActions: (
              <Tooltip title={t('tooltips.sendInvitation')}>
                <IconButton onClick={() => handleSendInvite(params.row.id)}>
                  {params.row.active ? <themeIcons.Drafts sx={{ width: 20, height: 20 }} /> : <themeIcons.Mail sx={{ width: 20, height: 20 }} />}
                </IconButton>
              </Tooltip>
            )
          })}
          {...(can('users:write.instructor') && { onEdit: () => navigate(`${params.row.id}`) })}
          {...(can('users:delete.instructor') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as InstructorColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`instructors.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={instructorsData}
      gridKey='instructors'
      loading={loading}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
