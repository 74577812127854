import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { palette } from '@theme/core/palette/palette'

export type MuiSwitchType = {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
}

export const MuiSwitch:MuiSwitchType = {
  defaultProps: {
    color: 'success',
    disableRipple: true
  },
  styleOverrides: {
    root: {
      height: '24px',
      width: '44px',
      padding: 0,
      overflow: 'visible',
      '.MuiButtonBase-root': {
        padding: '2px'
      }
    },
    input: {
      height: '100%'
    },
    switchBase: {
      padding: 0,
      '&.Mui-disabled': {
        pointerEvents: 'none',
        '+.MuiSwitch-track': {
          opacity: 0.3
        },
        '&.Mui-checked': {
          '+.MuiSwitch-track': {
            opacity: 0.3
          }
        }
      },
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        '+.MuiSwitch-track': {
          opacity: 1,
          backgroundColor: palette.primary?.main
        }
      }
    },
    thumb: {
      height: '20px',
      width: '20px',
      boxShadow: 'none',
      backgroundColor: 'white'
    },
    track: {
      borderRadius: 25,
      opacity: 1,
      backgroundColor: palette.grey?.['300']
    }
  }
}
