import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'

import { TopicRowModel } from '../provider/TopicsProvider'

import { titleColumnModel } from './columns/titleColumn'
import { professionColumnModel } from './columns/professionColumnModel'
import { trainingYearColumnModel } from './columns/trainingYearColumn'
import { indexColumnModel } from './columns/indexColumn'
import { topicColumnModel } from './columns/topicColumn'

export type TopicsColumnField = keyof TopicRowModel

export type TopicsColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: TopicsColumnField,
  hideHeader?: boolean,
}

export const topicsDataGridColumns: TopicsColumnType[] = [
  professionColumnModel,
  topicColumnModel,
  trainingYearColumnModel,
  indexColumnModel,
  titleColumnModel
]
