import { useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { Box, Typography } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { themeIcons } from '@theme/icons'
import { timestampToWeekday } from '@utils/date'
import { convertMinutesToHoursAndMinutes, convertMinutesToHoursAndMinutes2Digit } from '@utils/format'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory'

const DAYS_TO_SHOW = 7
const HOURS_DOMAIN = 5

export const TraineeDetailLearningLoad: React.FC = () => {
  const { t } = useTranslation()
  const { traineeData } = useTraineesDetailContext()

  const learningLoadData = React.useMemo<{x: Date, y: number}[]>(() => {
    return new Array(DAYS_TO_SHOW).fill(0).map((_, index) => {
      const date = new Date()
      date.setDate(date.getDate() - index)

      const time = traineeData?.studyTimes.reduce((acc, curr) => {
        const currDate = new Date(curr.date)

        if (currDate.getDay() === date.getDay()) {
          return acc + curr.time
        }

        return acc
      }, 0)

      return {
        x: date,
        y: time || 0
      }
    })
  }, [traineeData])

  const getMinuteStepsByMaxValue = React.useCallback((maxValue: number): number[] => {
    const possibleStepSizes = [15, 30, 60]

    const stepSize = possibleStepSizes.find((size) => {
      return maxValue / size <= HOURS_DOMAIN
    }) || possibleStepSizes[0]

    const steps = new Array(HOURS_DOMAIN).fill(0).map((_, index) => {
      return stepSize * index
    })

    return [...steps, stepSize * steps.length]
  }, [])

  const verticalDomain = React.useMemo(() => {
    const max = Math.max(...learningLoadData.map((item) => item.y))

    return getMinuteStepsByMaxValue(max)
  }, [learningLoadData])

  const averageLearningLoad = React.useMemo(() => {
    const sum = learningLoadData.reduce((acc, curr) => acc + curr.y, 0)

    const average = Math.ceil(sum / DAYS_TO_SHOW)

    const converted = convertMinutesToHoursAndMinutes(average)

    return t(`trainees.${converted.hours > 0 ? 'learningLoadAverageHoursMins' : 'learningLoadAverageMins'}`, converted)
  }, [learningLoadData])

  return (
    <Box sx={{
      pt: 2
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        mb: 2
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >

          <themeIcons.Hourglass sx={{
            width: 20,
            height: 20
          }}
          />
          <Typography fontWeight="500" sx={{ ml: 0.5 }}>{t('trainees.learningLoad')}</Typography>
        </Box>
        <Typography fontWeight="500">{averageLearningLoad}</Typography>

      </Box>
      <VictoryChart
        domainPadding={{ x: 20 }}
        padding={{ top: 20, bottom: 30, left: 50, right: 0 }}
      >

        {verticalDomain.map((item) => (
          <VictoryAxis
            key={`help_line_${item}`}
            axisValue={item}
            style={{
              tickLabels: {
                fill: 'none'
              },
              axis: {
                stroke: palette.grey?.[300]
              }
            }}
          />))}

        <VictoryBar
          data={learningLoadData}
          cornerRadius={6}
          style={{
            data: { fill: palette.grey[400] }
          }}
        />

        <VictoryAxis
          tickValues={learningLoadData.map((item) => item.x.getTime())}
          tickFormat={(date) => timestampToWeekday(new Date(date))}
          style={{
            tickLabels: {
              fontFamily: 'Signika',
              fontSize: 11,
              fontWeight: 500
            }
          }}
        />

        <VictoryAxis
          dependentAxis
          tickValues={verticalDomain}
          tickFormat={(value) => t('common.hoursAndMinutesDigital', convertMinutesToHoursAndMinutes2Digit(value))}
          style={{
            tickLabels: {
              fontFamily: 'Signika',
              fontSize: 11,
              fontWeight: 500
            }
          }}
        />
      </VictoryChart>
    </Box>
  )
}
