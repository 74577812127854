import { Divider, Box } from '@mui/material'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { urlPattern, mailPattern, postalPattern } from '@utils/pattern'
import { themeIcons } from '@theme/icons'
import { CompaniesNameOnlyQuery, JobDepartmentsQuery, JobEmploymentTypesQuery } from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import { t } from 'i18next'
import React from 'react'
import { BaseDateInput } from '@shared/components/inputs/BaseDateInput'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'

type Props = {
  companies?: CompaniesNameOnlyQuery['companies']
  jobDepartments?: JobDepartmentsQuery['jobDepartments']
  jobEmploymentTypes?: JobEmploymentTypesQuery['jobEmploymentTypes']
  loading?: boolean
}

export const JobWallCreateEditInputs: React.FC<Props> = ({ companies, jobDepartments, jobEmploymentTypes, loading }) => {
  const mappedCompanies = React.useMemo(() => {
    return companies?.map((company) => ({
      id: company.id,
      name: company.name
    })) || []
  }, [companies])

  const mappedJobDepartments = React.useMemo(() => {
    return jobDepartments?.map((jobDepartment) => ({
      id: jobDepartment.id,
      name: jobDepartment.texts.name
    })) || []
  }, [jobDepartments])

  const mappedJobEmploymentTypes = React.useMemo(() => {
    return jobEmploymentTypes?.map((type) => ({
      id: type.id,
      name: type.texts.name
    })) || []
  }, [jobDepartments])

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <form>
        <BaseTextInput
          formKey='texts.name'
          options={{ required: true }}
          title={t('jobwall.title')}
          inputProps={{
            max: 50
          }}
        />
        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='code'
          options={{ required: true }}
          title={t('common.internalId')}
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='texts.subtitle'
          options={{ required: true }}
          title={t('common.tagline')}
          description={t('common.taglineDescription')}
          inputProps={{
            max: 100
          }}
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseSingleFileInput
          formKey="files.keyvisual"
          fileKey="keyvisual"
          title={t('common.keyVisual')}
          description={t('jobwall.keyVisualDescription')}
          loading={loading}
          ratioModal
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseMarkDownEditor
          formKey='texts.description'
          title={t('jobwall.description')}
          options={{ required: true }}
          description={t('jobwall.createDescription')}
          max={3000}
          height={600}
          previewLabel
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='postalCode'
          options={{ required: true, pattern: postalPattern }}
          title={t('common.postalCode')}
          description={t('jobwall.postalCodeDescription')}
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseSelectInput
          formKey="jobDepartmentId"
          title={t('common.department')}
          items={mappedJobDepartments}
          options={{ required: true }}
        />

        <Divider
          sx={{
            my: 3.5
          }}
          light
          orientation='horizontal'
        />

        <BaseSelectInput
          formKey="jobEmploymentTypeId"
          title={t('common.workingHours')}
          items={mappedJobEmploymentTypes}
          options={{ required: true }}
        />

        <Divider
          sx={{
            my: 3.5
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='link'
          options={{ pattern: urlPattern }}
          title={t('jobwall.buttonLink')}
          type='url'
          InputProps={{
            startAdornment: (<themeIcons.Link />)
          }}
        />

        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='contact'
          options={{ required: true, pattern: mailPattern }}
          title={t('common.email')}
          type='email'
          description={t('jobwall.emailDescription')}
          InputProps={{
            startAdornment: (<themeIcons.Email />)
          }}
          inputProps={{
            max: 100
          }}
        />

        {isRole(Roles.ADMIN) && (
        <>
          <Divider
            sx={{
              mb: 3.5,
              mt: 1
            }}
            light
            orientation='horizontal'
          />

          <BaseSelectInput
            formKey="companyId"
            title={t('common.company')}
            items={mappedCompanies}
            options={{ required: true }}
          />
          <Divider
            sx={{
              my: 3.5
            }}
            light
            orientation='horizontal'
          />

          <BaseDateInput
            formKey='expiresAt'
            title={t('dataGrid.column.expiresAt')}
            description={t('jobwall.expiresAtDescription')}
          />
        </>
        )}
      </form>
    </Box>
  )
}
