import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { palette } from '@theme/core/palette/palette'
import { lineHeights } from '@theme/core/typography/typography'
import { fontSizes } from '@theme/theme'

export type MuiChipType = {
  defaultProps?: ComponentsProps['MuiChip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip'];
  variants?: ComponentsVariants['MuiChip'];
}

export const MuiChip:MuiChipType = {
  styleOverrides: {
    root: {
      height: 'auto',
      color: palette.text?.primary,
      backgroundColor: palette.grey?.[200]
    },
    label: {
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.xs,
      fontWeight: 500,
      padding: '2px 8px'
    }
  }
}
