import { Box, Tooltip, Typography } from '@mui/material'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'
import { SortableList } from '@shared/components/sortable/SortableList'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import { themeIcons } from '@theme/icons'
import { QuestionGroupQuery } from '@typings/graphql'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Question = QuestionGroupQuery['questionGroup']['questions'][0]
type Props = {
  questions: Question[]
}

export const QuestionGroupOrderInput: React.FC<Props> = ({
  questions
}) => {
  const { t } = useTranslation()

  const { setValue } = useFormContext()

  const [internalItems, setInternalItems] = React.useState<Question[]>(questions)

  React.useEffect(() => {
    setValue('questionIdsOrder', internalItems.map((item) => item.id), {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [internalItems])

  const reorderQuestions = React.useCallback((items: Question[]) => {
    setInternalItems(items)
  }, [])

  return (
    <BaseInputRow
      title={t('questions.questionGroupOrder')}
      description={t('questions.questionGroupOrderDescription')}
    >
      <SortableList
        items={internalItems}
        onDragEnd={reorderQuestions}
        id="sortable_questions"
        grabber
        renderItem={(item, activeId, index, level, clone) => (
          <Box
            key={`sort_question_${item.id}`}
            sx={{
              mb: 2,
              opacity: (activeId === item.id && !clone) ? 0 : 1
            }}
          >
            <DefaultAnswerRow
              grabber={(
                <SortableGrabber
                  id={item.id}
                >
                  <themeIcons.Grab />
                </SortableGrabber>
                )}
            >
              <Tooltip title={item.texts.text}>
                <Typography sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                >
                  {`${index + 1}. ${item.texts.text}`}
                </Typography>
              </Tooltip>
            </DefaultAnswerRow>
          </Box>
        )
        }
      />
    </BaseInputRow>
  )
}
