import { CSSObject } from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export const reactMarkDown:CSSObject = {
  '.wmde-markdown-var ': {
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      pointerEvents: 'none',
      border: '1px solid',
      borderColor: palette.grey[400],
      borderRadius: '8px'
    },
    svg: {
      '&.MuiSvgIcon-root': {
        padding: 0,
        width: '32px',
        height: '32px'
      },
      width: '18px',
      height: '18px'
    },
    '.wmde-markdown': {
      fontFamily: 'Signika'
    },
    'w-md-editor-text-input': {
      fontFamily: 'Signika'
    },
    '.w-md-editor-toolbar': {
      ul: {
        display: 'flex',
        alignItems: 'center'
      },
      'li > button': {
        height: '40px',
        width: '40px',
        palette: 0
      },
      padding: '12px'
    },
    '.w-md-editor-toolbar-divider': {
      height: '32px',
      margin: '0 4px',
      width: '2px'
    },
    '.w-md-editor-text': {
      padding: '14px'
    },
    '.w-md-editor-preview': {
      padding: '14px'
    },
    '.w-md-editor-bar': {
      bottom: '5px',
      right: '5px',
      width: 'auto',
      height: 'auto'
    }
  }
}
