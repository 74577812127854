import { Grid, Typography } from '@mui/material'
import React from 'react'

import { InputTitleField } from './InputRequiredIndicator'

type Props = React.PropsWithChildren & {
  title: string
  description?: string,
  required?: boolean
}

export const BaseInputRow: React.FC<Props> = ({ title, description, required, children }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        {children}
      </Grid>
    </Grid>

  )
}
