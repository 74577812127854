import React from 'react'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'
import { QuestionListView } from '@features/questionList/views/QuestionListView'
import { QuestionsEditView } from '@features/questions/views/QuestionsEditView'
import { QuestionsCreateView } from '@features/questions/views/QuestionsCreateView'

import { AppRouteObject } from '../router'

export const questionListRoutes: AppRouteObject[] = [{
  path: '/question-list',
  element: <ProtectedRoute component={<BreadcrumbLayout />} />,
  access: {
    roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
  },
  meta: {
    name: 'questionList',
    navItem: true,
    navIcon: 'FileQuestion',
    navOrder: 2
  },
  children: [{
    index: true,
    element: <QuestionListView />
  }, {
    path: 'edit/:questionId',
    element: <QuestionsEditView />,
    meta: {
      name: 'editQuestion'
    },
    access: {
      roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
    }
  }, {
    path: 'create',
    element: <QuestionsCreateView />,
    meta: {
      name: 'createQuestion'
    },
    access: {
      roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
    }
  }] as AppRouteObject[]
}]
