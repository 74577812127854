import React, { useState } from 'react'
import {
  useDroppable,
  DndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay
} from '@dnd-kit/core'
import {
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
  SortableContext,
  arrayMove
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis
} from '@dnd-kit/modifiers'
import { Box } from '@mui/material'

import { SortableItem } from './SortableItem'

export type SortableSortingType = 'vertical' | 'horizontal' | 'grid';

export type SortableListItem = {
  id: string | number
  [key: string]: any
};

export type SortableListProps<T extends SortableListItem> = {
  id: string
  items: T[]
  sortingType?: SortableSortingType
  grabber?: boolean,
  renderItem: (item: T, activeId: string, index: number, level?: number, clone?: boolean) => React.ReactElement
  renderCurrentItem?: (item: T, activeId: string, level?: number, clone?: boolean) => React.ReactElement
  onDragEnd: (items: T[]) => void
  useDragOverlay?: boolean
}

export const SortableList = function<D extends SortableListItem> (props: SortableListProps<D>): React.ReactElement {
  const {
    id, items, renderItem, renderCurrentItem, onDragEnd, grabber = false, sortingType = 'vertical', useDragOverlay = true
  } = props
  const [activeId, setActiveId] = useState<string>('')
  const [currentItem, setCurrentItem] = useState<D | null>(null)
  const { setNodeRef } = useDroppable({ id })

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor)
  )

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id.toString())

    setCurrentItem(items.filter((item) => item.id === event.active.id)[0])
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    const itemIds = items.map((item) => item.id)
    if (over && (active.id !== over.id)) {
      onDragEnd(arrayMove(items, itemIds.indexOf(active.id.toString()), itemIds.indexOf(over.id.toString())))
    }

    setCurrentItem(null)
    setActiveId('')
  }

  const handleDragCancel = () => {
    setCurrentItem(null)
    setActiveId('')
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      {...(sortingType !== 'grid' && { modifiers: [sortingType === 'horizontal' ? restrictToHorizontalAxis : restrictToVerticalAxis] })}
    >
      <SortableContext
        id={id}
        items={items.map((item) => item.id)}
        strategy={sortingType === 'vertical' ? verticalListSortingStrategy : horizontalListSortingStrategy}
      >
        <Box
          ref={setNodeRef}
          display="flex"
          flexDirection={sortingType === 'horizontal' || sortingType === 'grid' ? 'row' : 'column'}
          flexWrap={sortingType === 'grid' ? 'wrap' : 'nowrap'}
        >
          {items.map((item, index) => (
            <SortableItem key={item.id} id={item.id.toString()} grabber={grabber}>
              {renderItem(item, activeId, index)}
            </SortableItem>
          ))}
        </Box>
      </SortableContext>
      <DragOverlay adjustScale>
        {currentItem && useDragOverlay
          ? (renderCurrentItem
              ? renderCurrentItem(currentItem, activeId)
              : renderItem(currentItem, activeId, items.findIndex((item) => item.id === currentItem.id), 0, true))
          : null}
      </DragOverlay>
    </DndContext>
  )
}
