import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLegalTextQuery } from '@typings/graphql'
import ReactMarkdown from 'react-markdown'

import { ConfirmDialog, ConfirmDialogProps } from '../ui/ConfirmDialog'

type Props = Omit<ConfirmDialogProps, 'title'> & {
  title?: string
  legalKey: string
}

export const LegalTextModal: React.FC<Props> = ({ title, legalKey, ...props }) => {
  const { t } = useTranslation()

  const { data } = useLegalTextQuery({
    variables: {
      key: legalKey
    }
  })

  return (
    <ConfirmDialog
      title={title || t(`common.${legalKey}`)}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '90%',
          maxHeight: '95%'
        }
      }}
      {...props}
      hideCancel
    >
      {data?.legalText && <ReactMarkdown>
        {data.legalText}
        </ReactMarkdown>}
    </ConfirmDialog>
  )
}
