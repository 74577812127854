import { useAppSelector } from '@services/store/store'
import React from 'react'
import { Roles } from '@typings/roles'

import { DashboardCompanyView } from '../roleViews/company/views/DashboardCompanyView'
import { DashboardTrainingCompanyView } from '../roleViews/trainingCompany/views/DashboardTrainingCompanyView'
import { AdminDashboardView } from '../roleViews/admin/views/AdminDashboardView'
import { EditorDashboardView } from '../roleViews/editor/views/EditorDashboardView'

import { DefaultDashboardView } from './DefaultDashboardView'

type DashboardRoleBasedViews = {
  roles: Roles[]
  component: React.FC
}

const dashboardRoleBasedViews: DashboardRoleBasedViews[] = [{
  roles: [Roles.COMPANY],
  component: DashboardCompanyView
}, {
  roles: [Roles.ADMIN, Roles.MANAGER],
  component: AdminDashboardView
}, {
  roles: [Roles.TRAINING_COMPANY_MANAGER, Roles.INSTRUCTOR],
  component: DashboardTrainingCompanyView
}, {
  roles: [Roles.EDITOR],
  component: EditorDashboardView
}]

export const DashboardView: React.FC = () => {
  const { user } = useAppSelector(({ auth }) => auth)

  const DashboardContent = React.useMemo(() => {
    const RoleBasedView = dashboardRoleBasedViews.find(view => view.roles.includes(user?.role.key as Roles))?.component
    return RoleBasedView ? <RoleBasedView /> : <DefaultDashboardView />
  }, [])

  return DashboardContent
}
