import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'
import { SortableList } from '@shared/components/sortable/SortableList'
import { BaseInputRow } from '@shared/components/ui/BaseInputRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import { themeIcons } from '@theme/icons'
import { useQuestionGroupWithQuestionsQuery } from '@typings/graphql'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

type Props = {
  questionGroupId: string
}

type QuestionGroupItem = {
  id: string
  title?: string
  current: boolean
}

export const QuestionsGroupOrderInput: React.FC<Props> = ({
  questionGroupId
}) => {
  const { questionId } = useParams<{ questionId: string }>()
  const { t } = useTranslation()

  const { control, setValue } = useFormContext()

  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [internalItems, setInternalItems] = React.useState<QuestionGroupItem[]>([])

  const watchedTitle = useWatch({
    name: 'texts.text',
    control
  })

  const { data, loading: dataLoading } = useQuestionGroupWithQuestionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: questionGroupId
    }
  })

  React.useEffect(() => {
    const mapped = data?.questionGroup.questions.map<QuestionGroupItem>((question) => ({
      id: question.id,
      title: question.texts?.text ?? '',
      current: question.id === questionId
    })) ?? []

    if (!mapped.find(({ id }) => id === questionId)) {
      mapped.push({
        id: questionId ?? Math.random().toString().slice(16),
        current: true
      })
    }

    setInternalItems(mapped)
  }, [data])

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  React.useEffect(() => {
    setValue('questionGroupOrder', internalItems.findIndex((item) => item.current), {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [internalItems])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading
  }, [artificialLoading, dataLoading])

  const reorderQuestions = React.useCallback((items: QuestionGroupItem[]) => {
    setInternalItems(items)
  }, [])

  return (
    <BaseInputRow
      title={t('questions.questionGroupOrder')}
      description={t('questions.questionGroupOrderDescription')}
      required
    >
      {loading
        ? (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            <CircularProgress />
          </Box>
          )
        : (
          <SortableList
            items={internalItems}
            onDragEnd={reorderQuestions}
            id="sortable_answers"
            grabber
            renderItem={(item, activeId, index, level, clone) => (
              <Box
                key={`sort_answer_${item.id}`}
                sx={{
                  mb: 2,
                  opacity: (activeId === item.id && !clone) ? 0 : 1
                }}
              >
                <DefaultAnswerRow
                  {...item.current && {
                    sx: {
                      border: '1px solid',
                      borderColor: 'primary.main'
                    }
                  }}
                  grabber={(
                    <SortableGrabber
                      id={item.id}
                    >
                      <themeIcons.Grab />
                    </SortableGrabber>
                )}
                >
                  {item.current
                    ? (
                      <Tooltip title={watchedTitle}>
                        <Typography sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}
                        >
                          {`${index + 1}. ${watchedTitle || ''}`}
                        </Typography>
                      </Tooltip>
                      )
                    : (
                      <Tooltip title={item.title}>
                        <Box>
                          {`${index + 1}. ${item.title}`}
                        </Box>
                      </Tooltip>
                      )}
                </DefaultAnswerRow>
              </Box>
            )
        }
          />
          )}
    </BaseInputRow>
  )
}
