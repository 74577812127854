import React from 'react'
import { ProductCreateView } from '@features/products/views/ProductCreateView'
import { ProductDetailView } from '@features/products/views/ProductDetailView'
import { ProductEditView } from '@features/products/views/ProductEditView'
import { ProductsView } from '@features/products/views/ProductsView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const productsRoutes: AppRouteObject[] = [
  {
    path: '/products',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.COMPANY, Roles.ADMIN],
      partner: true
    },
    meta: {
      name: 'products',
      navItem: true,
      navIcon: 'Layers',
      navOrder: 3
    },
    children: [{
      index: true,
      element: <ProductsView />
    }, {
      path: 'create',
      meta: {
        name: 'productCreate'
      },
      element: <ProductCreateView />,
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN],
        partner: true
      }
    }, {
      path: ':id',
      element: <ProductDetailView />,
      meta: {
        name: 'productDetails'
      },
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN],
        partner: true
      }
    }, {
      path: ':id/edit',
      element: <ProductEditView />,
      meta: {
        name: 'productEdit'
      },
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN],
        partner: true
      }
    }]
  }
]
