import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { BoxProps, Box } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'

import { SortableListItem } from './SortableList'

export type SortableItemProps = BoxProps & SortableListItem & {
  grabber?: boolean,
  level?: number,
}

export const SortableItem:React.FC<SortableItemProps> = ({
  id, children, grabber = false, ...boxProps
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id })

  return (
    <Box
      {...boxProps}
      ref={setNodeRef}
      {...(!grabber && attributes)}
      {...(!grabber && listeners)}
      sx={{
        transition,
        transform: CSS.Transform.toString(transform),
        ...(!grabber && { userSelect: 'none' }),
        ...(!grabber && { cursor: 'grab' })
      }}
    >
      {children}
    </Box>
  )
}
