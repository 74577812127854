import { Box, IconButton } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = {
  onDelete: () => void
  onFullScreen?: () => void
  onResize?: () => void
}

export const FilePreviewBar:React.FC<Props> = ({ onDelete, onFullScreen, onResize }) => {
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    onDelete()
  }

  const handleFullscreen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    onFullScreen?.()
  }

  const handleResize = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    onResize?.()
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'grey.100'
    }}
    >
      <IconButton onClick={handleDelete}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'grey.700'
          }
        }}
      >
        <themeIcons.Delete sx={{
          width: '16px',
          height: '16px'
        }}
        />
      </IconButton>
      {onFullScreen && <IconButton onClick={handleFullscreen}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'grey.700'
          }
        }}
      >
        <themeIcons.Expand sx={{
          width: '16px',
          height: '16px'
        }}
        />
      </IconButton>
      }
      {onResize && <IconButton onClick={handleResize}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'grey.700'
          }
        }}
      >
        <themeIcons.Minimize sx={{
          width: '16px',
          height: '16px'
        }}
        />
      </IconButton>
      }
    </Box>
  )
}
