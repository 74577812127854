import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import {
  UpdateCompanyInput, useUpdateCompanyMutation, useCompanyQuery,
  useDeleteFileMutation, CompanyCreditType
} from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { useFileUpload } from '@hooks/useFileUpload'
import { EditableFile } from '@typings/files'
import { getFileByKey } from '@utils/files'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { clamp } from '@utils/math'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import i18n from '@services/i18n/i18n'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { CompaniesProvider, useCompaniesContext } from '../provider/CompaniesProvider'
import { CompanyCreateEditInputs } from '../components/CompanyCreateEditInputs'
import { CompanyCreateInputExtensions } from '../components/CompanyCreateInputExtensions'

type CompanyInputType = UpdateCompanyInput & {
  files: Record<string, EditableFile<any> | null>
  users: SearchInputItem[]
  texts: Record<string, string>
  credits: {
    jobBasic: number
    jobExtension: number
  }
}

const CompanyEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { handleDelete, updateSelectedItem, categoryData, handleRefetchCategories } = useCompaniesContext()

  const { data: companyData, loading: companyLoading } = useCompanyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !id
  })

  const [updateCompany] = useUpdateCompanyMutation()

  const { uploadFile } = useFileUpload()

  const [deleteFile] = useDeleteFileMutation()

  const { setMessageByCode, setErrorMessage, errorMessage } = useErrorMessage()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext<CompanyInputType>()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  React.useEffect(() => {
    if (companyData?.company && categoryData) {
      reset({
        name: companyData.company.name,
        isPartner: companyData.company.isPartner,
        category: categoryData.find((category) => category.id === companyData.company?.category?.id)?.name || '',
        postalCode: companyData.company?.postalCode,
        address: companyData.company?.address,
        city: companyData.company?.city,
        texts: companyData.company?.texts,
        credits: {
          jobBasic: companyData.company.credits?.jobBasic?.length || 0,
          jobExtension: companyData.company.credits?.jobExtension?.length || 0
        },
        files: {
          keyvisual: getFileByKey('keyvisual', companyData.company.files),
          logo: getFileByKey('logo', companyData.company.files)
        },
        users: companyData.company.users?.map((user) => ({
          id: user.id,
          name: user.email
        })) || []
      })
    }
  }, [companyData, categoryData])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    const baseCreditCount = input.credits.jobBasic - (companyData?.company?.credits?.jobBasic?.length || 0)
    const jobExtensionCreditCount = input.credits.jobExtension - (companyData?.company?.credits?.jobExtension?.length || 0)

    const jobBasicCredits = new Array(clamp(baseCreditCount, 0, baseCreditCount)).fill(0).map(() => CompanyCreditType.JobBasic)
    const jobExtensionCredits = new Array(clamp(jobExtensionCreditCount, 0, jobExtensionCreditCount))
      .fill(0).map(() => CompanyCreditType.JobExtension)

    try {
      await updateCompany({
        variables: {
          id: id as string,
          data: {
            name: input.name,
            isPartner: input.isPartner,
            credits: [...jobBasicCredits, ...jobExtensionCredits],
            postalCode: input.postalCode,
            address: input.address,
            city: input.city,
            category: input.category,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            })),
            userIds: input.users?.map((user) => user.id)
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        const fileId = getFileByKey(key, companyData?.company?.files)?.id

        if (file?.upload) {
          await uploadFile(file.upload.file, file.upload.data.key, 'Company', id as string)
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        }
      })

      handleRefetchCategories()
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setLoading(false)
      setShowToast(true)
    }
  })

  const handleCompanyDeleted = () => {
    setDeleted(true)
    navigate('/companies')
  }

  const onDelete = async () => {
    if (!companyData?.company) {
      return
    }

    updateSelectedItem(companyData.company)
    handleDelete(false, handleCompanyDeleted)
  }

  return (
    <CreateEditLayout
      title={t('companies.editTitle')}
      loading={companyLoading}
      description={
        <Trans
          i18nKey={'companies.editDescription'}
          values={{ name: companyData?.company?.name }}
          components={{ b: <strong /> }}
        />
      }
      headerExtension={(
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box mr={2}>
            <StandaloneSwitchInput
              formKey="isPartner"
              label={t('companies.column.isPartner')}
            />
          </Box>
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <CompanyCreateEditInputs loading={loading}>
        {!companyData?.company?.isPartner &&
          <CompanyCreateInputExtensions
            initialBaseCredits={companyData?.company?.credits?.jobBasic?.length || 0}
            initialJobExtensionCredits={companyData?.company?.credits?.jobExtension?.length || 0}
          />
        }
      </CompanyCreateEditInputs>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('companies.editSuccess')}
      />
    </CreateEditLayout>
  )
}

export const CompaniesEditView:React.FC = () => {
  const formData = useForm<CompanyInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <CompaniesProvider disableFetching>
      <FormProvider {...formData}>
        <CompanyEditViewContent />
      </FormProvider>
    </CompaniesProvider>
  )
}
