import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { generateId } from '@utils/random'
import { EditableFile, ResizeMode } from '@typings/files'

import FileDragDrop, { FileDimensions, FileDragDropHandler } from '../files/FileDragDrop'
import { FilePreviewBar } from '../files/FilePreviewBar'
import { FilePreviewModal } from '../files/FilePreviewModal'
import { FileUploadRatioModal } from '../files/FileUploadRatioModal'
import { FilePreview } from '../files/FilePreview'
import { FileDefaultRenderedPreview } from '../files/FileDefaultRenderedPreview'

type Props = {
  formKey: string
  title: string
  fileKey: string
  description?: string,
  loading?: boolean
  maxFileSize?: number
  maxFileDimensions?: FileDimensions
  ratioModal?: boolean
  acceptedFileTypes?: Record<string, string[]>
}

export const BaseSingleFileInput: React.FC<Props> = ({
  formKey,
  title,
  fileKey,
  description,
  maxFileSize,
  maxFileDimensions,
  ratioModal,
  loading,
  acceptedFileTypes = { 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }
}) => {
  const fileDragDropRef = React.useRef<FileDragDropHandler | null >(null)

  const [showFullScreen, setShowFullScreen] = React.useState(false)
  const [showRatioModal, setShowRatioModal] = React.useState(false)

  const { control, setValue, formState: { defaultValues } } = useFormContext()

  const watchedFile = useWatch({
    name: formKey,
    control
  }) as EditableFile<any>

  const isImage = React.useMemo(() => {
    const type = watchedFile?.upload?.file?.type ?? watchedFile?.mimeType

    return type?.includes('image') && !type?.includes('svg')
  }, [watchedFile])

  const deleteFile = () => {
    setValue(formKey, null, {
      shouldDirty: defaultValues?.[formKey] !== null
    })

    fileDragDropRef.current?.reset()
  }

  const onFilesChanged = (files: File[]) => {
    if (files.length === 0) {
      return
    }

    setShowRatioModal(true)

    setValue(formKey, {
      id: watchedFile?.id ?? generateId(10),
      remote: watchedFile?.remote,
      upload: {
        file: files[0],
        data: {
          key: fileKey
        },
        preview: URL.createObjectURL(files[0])
      }
    } as EditableFile<any>, {
      shouldDirty: true,
      shouldValidate: true
    })
  }

  const handleConfirmRatio = (resizeMode: ResizeMode) => {
    setValue(formKey, {
      ...watchedFile,
      config: {
        ...(watchedFile.config && watchedFile.config),
        resizeMode
      }
    } as EditableFile<any>, {
      shouldDirty: true,
      shouldValidate: true
    })
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Typography variant="subtitle1" fontWeight={600}>{title}</Typography>
          {description && <Typography variant="subtitle1">{description}</Typography>}
        </Grid>
        <Grid item xs={8} xl={6}>
          <Box position="relative">
            <Controller
              name={formKey}
              control={control}
              render={({ field: { value } }) => (
                <FileDragDrop
                  ref={fileDragDropRef}
                  accept={acceptedFileTypes}
                  limit={1}
                  loading={loading}
                  maxFileSize={maxFileSize}
                  maxFileDimensions={maxFileDimensions}
                  onFilesChanged={onFilesChanged}
                  renderedPreview={(accepted, maxSize) => (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItem: 'center',
                      height: '100%'
                    }}
                    >
                      {value && (
                      <Box sx={{
                        flex: '0 0 auto',
                        width: '30%',
                        height: '100%',
                        position: 'relative',
                        borderRight: 'solid 1px',
                        borderColor: 'grey.300'
                      }}
                      >
                        <FilePreview file={value} resizeMode={watchedFile?.config?.resizeMode || 'cover'} />
                        <FilePreviewBar
                          onDelete={deleteFile}
                          onFullScreen={() => setShowFullScreen(true)}
                          {...(isImage && ratioModal && {
                            onResize: () => setShowRatioModal(true)
                          })}
                        />
                      </Box>
                      )}
                      <Box sx={{ flex: 1 }}>
                        <FileDefaultRenderedPreview
                          accept={accepted}
                          maxSize={maxSize}
                          replace={!!value}
                        />
                      </Box>
                    </Box>
                  )}
                >
                </FileDragDrop>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <FilePreviewModal
        open={showFullScreen}
        onClose={() => setShowFullScreen(false)}
        file={watchedFile}
      />
      {ratioModal && isImage && (
        <FileUploadRatioModal
          previewImage={watchedFile?.upload?.preview || watchedFile?.remote?.url}
          resizeMode={watchedFile?.config?.resizeMode}
          open={showRatioModal}
          onConfirm={handleConfirmRatio}
          onClose={() => setShowRatioModal(false)}
        />
      )}
    </>
  )
}
