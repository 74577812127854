import React from 'react'
import { CategoryBreadcrumb } from '@features/categories/components/CategoryBreadcrumb'
import { SubCategoryBreadcrumb } from '@features/categories/components/SubCategoryBreadcrumb'
import { CategoriesCreateView } from '@features/categories/views/CategoriesCreateView'
import { CategoriesEditView } from '@features/categories/views/CategoriesEditView'
import { CategoriesView } from '@features/categories/views/CategoriesView'
import { QuestionsCreateView } from '@features/questions/views/QuestionsCreateView'
import { QuestionsEditView } from '@features/questions/views/QuestionsEditView'
import { QuestionsView } from '@features/questions/views/QuestionsView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'
import { ProfessionsView } from '@features/professions/views/ProfessionsView'
import { ProfessionsCreateView } from '@features/professions/views/ProfessionsCreateView'
import { ProfessionsEditView } from '@features/professions/views/ProfessionsEditView'
import { ProfessionBreadcrumb } from '@features/professions/components/ProfessionBreadcrumb'
import { QuestionGroupsView } from '@features/questionGroups/views/QuestionGroupsView'
import { QuestionGroupsCreateView } from '@features/questionGroups/views/QuestionGroupsCreateView'
import { QuestionGroupsEditView } from '@features/questionGroups/views/QuestionGroupsEditView'
import { TopicsView } from '@features/topics/views/TopicsView'
import { TopicsEditView } from '@features/topics/views/TopicsEditView'
import { TopicsCreateView } from '@features/topics/views/TopicsCreateView'
import { TopicBreadcrumb } from '@features/topics/components/TopicBreadcrumb'

import { AppRouteObject } from '../router'

export const questionEditorRoutes: AppRouteObject[] = [
  {
    path: '/professions',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
    },
    meta: {
      name: 'professions',
      navItem: true,
      navIcon: 'Certificate',
      navOrder: 1
    },
    children: [{
      index: true,
      element: <ProfessionsView />
    }, {
      path: 'create',
      element: <ProfessionsCreateView />,
      meta: {
        name: 'createProfession'
      },
      access: {
        roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
      }
    }, {
      path: ':professionId/edit',
      element: <ProfessionsEditView />,
      meta: {
        name: 'editProfession'
      },
      access: {
        roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
      }
    }, {
      path: ':professionId',
      access: {
        roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
      },
      breadcrumb: <ProfessionBreadcrumb />,
      children: [{
        index: true,
        element: <CategoriesView />
      }, {
        path: 'create',
        element: <CategoriesCreateView />,
        meta: {
          name: 'createCategory'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        }
      }, {
        path: ':categoryId',
        meta: {
          name: 'subCategories'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        },
        breadcrumb: <CategoryBreadcrumb />,
        children: [{
          index: true,
          element: <CategoriesView />
        }, {
          path: ':subCategoryId',
          meta: {
            name: 'questions'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          },
          breadcrumb: <SubCategoryBreadcrumb />,
          children: [{
            index: true,
            element: <QuestionsView />
          }, {
            path: 'create',
            element: <QuestionsCreateView />,
            meta: {
              name: 'createQuestion'
            },
            access: {
              roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
            }
          }, {
            path: ':questionId',
            element: <QuestionsEditView />,
            meta: {
              name: 'editQuestion'
            },
            access: {
              roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
            }
          }]
        }, {
          path: ':subCategoryId/groups',
          meta: {
            name: 'questionGroups'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          },
          breadcrumb: <SubCategoryBreadcrumb />,
          children: [{
            index: true,
            element: <QuestionGroupsView />
          }, {
            path: 'create',
            element: <QuestionGroupsCreateView />,
            meta: {
              name: 'createQuestionGroup'
            },
            access: {
              roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
            }
          }, {
            path: ':groupId',
            element: <QuestionGroupsEditView />,
            meta: {
              name: 'editQuestionGroup'
            },
            access: {
              roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
            }
          }]
        }, {
          path: ':subCategoryId/edit',
          element: <CategoriesEditView />,
          meta: {
            name: 'editSubCategory'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          }
        }, {
          path: 'create',
          element: <CategoriesCreateView />,
          meta: {
            name: 'createSubCategory'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          }
        }]
      }, {
        path: ':categoryId/edit',
        element: <CategoriesEditView />,
        meta: {
          name: 'editCategory'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        }
      }] as AppRouteObject[]
    }, {
      path: ':professionId/topics',
      meta: {
        name: 'topics'
      },
      access: {
        roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
      },
      breadcrumb: <ProfessionBreadcrumb />,
      children: [{
        index: true,
        element: <TopicsView />
      }, {
        path: ':topicId',
        meta: {
          name: 'questions'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        },
        breadcrumb: <TopicBreadcrumb />,
        children: [{
          index: true,
          element: <QuestionsView />
        }, {
          path: 'create',
          element: <QuestionsCreateView />,
          meta: {
            name: 'createQuestion'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          }
        }, {
          path: ':questionId',
          element: <QuestionsEditView />,
          meta: {
            name: 'editQuestion'
          },
          access: {
            roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
          }
        }]
      }, {
        path: 'create',
        element: <TopicsCreateView />,
        meta: {
          name: 'createTopic'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        }
      }, {
        path: ':topicId/edit',
        element: <TopicsEditView />,
        meta: {
          name: 'editTopic'
        },
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EDITOR]
        }
      }
      ]
    }
    ] as AppRouteObject[]
  }
]
