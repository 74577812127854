import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { QuestionsTabBar } from '@shared/components/ui/QuestionsTabBar'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionGroupsDataGrid } from '../dataGrid/QuestionGroupsDataGrid'
import { QuestionGroupsProvider, useQuestionGroupsContext } from '../provider/QuestionGroupsProvider'

const QuestionGroupsViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { categoryData, selectedIds, handleDelete } = useQuestionGroupsContext()

  const validParent = React.useMemo(() => {
    if (!categoryData) {
      return false
    }

    return true
  }, [categoryData])

  return (
    <DefaultLayout
      title={t('routes.questionGroups')}
      description={
        <Trans
          i18nKey="questionGroups.questionListDescription"
          values={{ category: categoryData?.texts?.title }}
          components={{ b: <strong /> }}
        />
      }
      tabBar={<QuestionsTabBar />}
      headerExtension={
        <Box>
          {can('questions:delete') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['questions:create', 'questions:create.partial']) && validParent && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('questionGroups.addQuestionGroup')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <QuestionGroupsDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const QuestionGroupsView: React.FC = () => (
  <QuestionGroupsProvider>
    <QuestionGroupsViewContent />
  </QuestionGroupsProvider>
)
