import { GridInitialState } from '@mui/x-data-grid-pro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

type AppState = {
  dataGridState: Record<string, GridInitialState>
}

const slice = createSlice({
  name: 'dataGrid',
  initialState: {
    dataGridState: {}
  } as AppState,
  reducers: {
    updateDataGridState: (state, { payload }: PayloadAction<{ key: string, value: GridInitialState }>) => {
      if (!state.dataGridState) {
        state.dataGridState = {}
      }

      state.dataGridState[payload.key] = payload.value
    },
    resetDataGridState: (state) => {
      state.dataGridState = {}
    }
  }
})

export const {
  updateDataGridState, resetDataGridState
} = slice.actions

export default slice.reducer

export const getDataGridState = (key?: string) =>
  (state: RootState): GridInitialState | undefined => key && state.dataGrid.dataGridState[key] || undefined
