import { Box, Grid, IconButton } from '@mui/material'
import { DashboardClicks } from '@shared/components/cards/DashboardClicks'
import { DashboardConversionRate } from '@shared/components/cards/DashboardConversionRate'
import { DashboardItemList, DashboardTopItemsType } from '@shared/components/cards/DashboardItemList'
import { DashboardYearOverview } from '@shared/components/cards/DashboardYearOverview'
import { CONVERSION_TIME_SPAN } from '@shared/constants/dashboard'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { palette } from '@theme/core/palette/palette'
import { themeIcons } from '@theme/theme'
import { useIncentiveQuery, useIncentiveTrackingQuery, useIncentivesQuery } from '@typings/graphql'
import { Roles } from '@typings/roles'
import { mapYearData } from '@utils/dashboard'
import { can, isRole } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

export const ProductDetailView: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { data: trendingData, loading: trendingLoading } = useIncentivesQuery()

  const { data: incentiveData, loading: incentiveLoading } = useIncentiveQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const { data, loading } = useIncentiveTrackingQuery({
    variables: {
      incentiveId: id as string
    },
    skip: !id
  })

  const navigate = useNavigate()

  const mappedIncentives = React.useMemo<DashboardTopItemsType[]>(() => {
    return trendingData?.incentives.map((incentive) => ({
      id: incentive.id,
      name: incentive.texts.name,
      performance: incentive.bimonthlyPerformance
    })
    )?.sort((a, b) => b.performance - a.performance) || []
  }, [trendingData])

  const mappedIncentiveData = React.useMemo(() => {
    if (!data) {
      return {}
    }

    const {
      button_click: buttonClick,
      product_click: productClick
    } = data?.dashboardTrackingIncentive || {}

    return {
      buttonClick: buttonClick as number[][],
      productClick: productClick as number[][],
      partnerClicks: (data?.dashboardTrackingIncentive?.partner_click || []) as number[][]
    }
  }, [data])

  const mappedYearData = React.useMemo(() => {
    if (!mappedIncentiveData) {
      return []
    }

    return [
      {
        name: 'productClicks',
        color: palette.primary[900] || '',
        values: mapYearData(mappedIncentiveData.productClick || [])
      },
      {
        name: 'buttonClicks',
        color: palette.primary.main || '',
        values: mapYearData(mappedIncentiveData.buttonClick || [])
      },
      {
        name: 'companyClicks',
        color: palette.secondary[300] || '',
        values: mapYearData(mappedIncentiveData.partnerClicks || [])
      }
    ]
  }, [mappedIncentiveData])

  const conversionData = React.useMemo(() => {
    const { buttonClick, productClick } = mappedIncentiveData

    const flattenedButtonClicks = productClick?.flat() || []
    const flattenedProductClicks = buttonClick?.flat() || []

    return {
      productClicks: flattenedProductClicks.slice(flattenedProductClicks.length -
        CONVERSION_TIME_SPAN, flattenedProductClicks.length).reduce((a, b) => a + b, 0),
      buttonClicks: flattenedButtonClicks.slice(flattenedButtonClicks.length -
        CONVERSION_TIME_SPAN, flattenedButtonClicks.length).reduce((a, b) => a + b, 0)
    }
  }, [mappedIncentiveData])

  return (
    <DefaultLayout
      title={incentiveData?.incentive?.texts?.name}
      {...(isRole(Roles.ADMIN) && { subtitle: incentiveData?.incentive?.company?.name })}
      description={t('products.performanceDescription')}
      loading={incentiveLoading || trendingLoading || loading}
      headerExtension={
        can(['incentives:write', 'incentives:write.own']) && <IconButton onClick={() => navigate('edit')}>
          <themeIcons.Edit />
        </IconButton>
      }
    >
      <Box sx={{
        pt: 4.5
      }}
      >

        <Grid container spacing={3}>
          <Grid height="auto" item xs={9}>
            <DashboardYearOverview data={mappedYearData}
              showYAxis
            />
          </Grid>
          <Grid item xs={3}>
            <DashboardConversionRate
              primary={conversionData.buttonClicks}
              secondary={conversionData.productClicks}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DashboardClicks
                  keyVal="products"
                  title={t('dashboard.productClicks')}
                  values={mappedIncentiveData.productClick || []}
                />
              </Grid>
              <Grid item xs={6}>
                <DashboardClicks
                  keyVal="products"
                  title={t('dashboard.buttonClicks')}
                  values={mappedIncentiveData.buttonClick || []}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={4}
            sx={{
              height: '100%'
            }}
          >
            <DashboardItemList
              title={t('dashboard.topProducts')}
              data={mappedIncentives}
              max={3}
              onItemClicked={(itemId) => navigate(`/products/${itemId}`)}
              onShowAll={() => navigate('/products')}
            />
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  )
}
