import React from 'react'

import { ModalPreviewContentImage } from './ModalPreviewContentImage'
import { ModalPreviewContentVideo } from './ModalPreviewContentVideo'
import { ModalPreviewContentPdf } from './ModalPreviewContentPdf'

export type PreviewContentProps = {
  src: string
  type?: string
}

export type PreviewContent = Record<'image' | 'video' | 'pdf', React.FC<PreviewContentProps>>

export const modalPreviewContent: PreviewContent = {
  image: ModalPreviewContentImage,
  video: ModalPreviewContentVideo,
  pdf: ModalPreviewContentPdf
}
