import { Box } from '@mui/material'
import {
  Status,
  UpdateProfessionInput,
  useProfessionQuery,
  useUpdateProfessionMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import i18n from '@services/i18n/i18n'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { StatusSelectInput } from '@shared/components/inputs/StatusSelectInput'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'

import { ProfessionsProvider } from '../provider/ProfessionsProvider'
import { ProfessionCreateEditInputs } from '../components/ProfessionCreateEditInputs'

type ProfessionInputType = Omit<UpdateProfessionInput, 'texts'> & {
  texts: Record<string, string>
}

const ProfessionsEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const { professionId } = useParams<{ professionId: string }>()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext<ProfessionInputType>()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage))

  const [updateProfession] = useUpdateProfessionMutation()

  const { data, loading: professionLoading } = useProfessionQuery({
    variables: {
      id: professionId as string
    },
    skip: !professionId
  })

  const profession = React.useMemo(() => {
    if (!data?.profession) {
      return null
    }

    return {
      ...data.profession,
      title: data.profession.texts.title
    }
  }, [data])

  React.useEffect(() => {
    if (profession) {
      reset({
        texts: profession.texts,
        status: profession.status,
        examDates: profession.examDates.map((examDate) => ({
          id: examDate.id,
          season: examDate.season,
          year: examDate.year,
          examDate: new Date(examDate.examDate)
        })),
        config: profession.config
      })
    }
  }, [profession])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateProfession({
        variables: {
          id: professionId as string,
          data: {
            config: input.config,
            status: input.status,
            examDates: input.examDates,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      setShowToast(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setShowToast(true)
      setMessageByCode(e)
    }
  })

  return (
    <CreateEditLayout
      title={t('professions.editTitle')}
      description={
        <Trans
          i18nKey={'professions.editDescription'}
          values={{ name: profession?.title }}
          components={{ b: <strong /> }}
        />
      }
      loading={professionLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          {isRole(Roles.ADMIN) && (
          <Box mr={2}>
            <StatusSelectInput formKey="status" options={{ required: true }} />
          </Box>
          )}
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <ProfessionCreateEditInputs />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('professions.entryEdited', { name: profession?.title })}
      />
    </CreateEditLayout>
  )
}

export const ProfessionsEditView:React.FC = () => {
  const formData = useForm<ProfessionInputType>({
    defaultValues: {
      status: Status.Draft
    },
    mode: 'onChange'
  })

  return (
    <ProfessionsProvider>
      <FormProvider {...formData}>
        <ProfessionsEditViewContent />
      </FormProvider>
    </ProfessionsProvider>
  )
}
