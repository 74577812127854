import { useDataGridPagination } from '@hooks/useDataGridPagination'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { Box } from '@mui/material'
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import {
  UsersPaginatedQuery, useDeleteUserMutation,
  useDeleteUsersMutation, useInviteUserMutation, useRolesQuery, useUsersPaginatedQuery
} from '@typings/graphql'
import { SelectType } from '@typings/select'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export type UserNode = Exclude<UsersPaginatedQuery['usersPaginated']['edges'], null | undefined>[0]
export type UsersRowModel = Omit<UserNode, '__typename'>['node']

export type UserColumnType = Omit<GridColDef<UsersRowModel>, 'field'> & {
  field: keyof UsersRowModel | 'action',
  hideHeader?: boolean,
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type UsersProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: UsersRowModel | null
  userData?: UsersPaginatedQuery['usersPaginated']
  loading: boolean
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  roles: SelectType[]
  updatePaginationModel: (pagination: GridPaginationModel) => void
  updateSortModel: (model: GridSortModel, details: GridCallbackDetails) => void
  updateFilterModel: (filter: GridFilterModel | undefined) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  handleSendInvite: (id: string) => void
  updateSelectedItem: (row: UsersRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  handleFetchMore: () => void
  updateInitialState: (initialState?: GridInitialState) => void
}

const UsersProviderContext = React.createContext<UsersProviderContextType>(
  {} as any
)

export const UsersProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [deleteUsers] = useDeleteUsersMutation()
  const [deleteUser] = useDeleteUserMutation()

  const [inviteUser] = useInviteUserMutation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<UsersRowModel | null>(null)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [showToast, setShowToast] = React.useState(false)
  const [loadMore, setLoadMore] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const { data, loading: dataLoading, refetch } = useUsersPaginatedQuery({
    skip: !!disableFetching,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      first: DATA_GRID_PAGE_SIZE
    }
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const userData = React.useMemo<UsersPaginatedQuery['usersPaginated'] | undefined>(() => {
    return data?.usersPaginated
  }, [data])

  const { data: roleData, loading: rolesLoading } = useRolesQuery()

  const mappedRoles = React.useMemo(() => {
    return roleData?.roles.map((role) => ({
      id: role.id,
      key: role.key,
      name: t(`roles.${role.key}`)
    })) || []
  }, [roleData])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading || loadMore || rolesLoading
  }, [artificialLoading, dataLoading, loadMore, rolesLoading])

  const {
    fetchVariablesRef, filterModel, paginationModel, sortModel,
    setFilterModelState, setPaginationModelState, setSortModelState, setInitialState
  } = useDataGridPagination<UsersPaginatedQuery>()

  const handleFetchMore = async () => {
    setErrorMessage('')
    setLoadMore(true)
    try {
      await refetch(fetchVariablesRef.current)
    } catch (e) {
      setMessageByCode(e, 'users')
      setShowToast(true)
    } finally {
      setLoadMore(false)
    }
  }

  const handleSendInvite = React.useCallback(async (id: string) => {
    setErrorMessage('')

    try {
      await inviteUser({
        variables: {
          id
        }
      })
      setSuccessMessage(t('users.inviteSuccess'))
    } catch (e) {
      setErrorMessage(t('errors.inviteError'))
    } finally {
      setShowToast(true)
    }
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: UsersRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteUsers({
          variables: {
            userIds: selectedIds as string[]
          }
        })
        updateSelectedIds([])
        setSuccessMessage(t('users.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteUser({
          variables: {
            id: selectedItem.id
          }
        })
        setSuccessMessage(t('users.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }

      setClickedRow(null)

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (e) {
      setMessageByCode(e, 'users')
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updatePaginationModel = React.useCallback((pagination: GridPaginationModel) => {
    setPaginationModelState(pagination, userData?.pageInfo.startCursor, userData?.pageInfo.endCursor)

    handleFetchMore()
  }, [userData])

  const updateSortModel = React.useCallback((model: GridSortModel) => {
    setSortModelState(model)
    handleFetchMore()
  }, [userData])

  const updateInitialState = React.useCallback((initialState?: GridInitialState) => {
    setInitialState(initialState)
    handleFetchMore()
  }, [])

  const updateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    const mappedFilter = {
      ...filter,
      items: filter?.items.map((item) => {
        if (typeof item.value === 'string' && !isNaN(Number(item.value))) {
          item.value = Number(item.value)
        }

        return item
      })
    } as GridFilterModel

    setFilterModelState(mappedFilter)
    handleFetchMore()
  }, [])

  const value = React.useMemo<UsersProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    userData,
    loading,
    paginationModel,
    sortModel,
    filterModel,
    roles: mappedRoles,
    updatePaginationModel,
    updateSortModel,
    updateFilterModel,
    handleSendInvite,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds,
    handleFetchMore,
    updateInitialState
  }), [paginationModel, mappedRoles, sortModel, filterModel, selectedIds, selectedItem, userData, loading])

  return (
    <UsersProviderContext.Provider value={value}>
      <Box sx={{ height: '100%', position: 'relative' }}>
        {children}

        <ConfirmDialog
          title={t('common.deleteEntry')}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={confirmDelete}
          loading={isDeleteLoading}
          open={showDeleteDialog}
        >
          <Trans
            i18nKey={deleteMany ? 'users.deleteEntries' : 'users.deleteEntry'}
            {...(!deleteMany ? { values: { name: selectedItem?.email } } : { count: selectedIds.length })}
            components={{ b: <strong /> }}
          />
        </ConfirmDialog>

        <DefaultSnackbar
          open={showToast}
          severity={errorMessage ? 'error' : 'success'}
          onClose={() => setShowToast(false)}
          message={errorMessage || successMessage}
        />
      </Box>
    </UsersProviderContext.Provider>
  )
}

export const useUsersContext = () => React.useContext(UsersProviderContext)
