import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Tooltip } from '@mui/material'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useUpdateTrainingCompaniesMutation } from '@typings/graphql'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { TrainingCompaniesDataGrid } from '../dataGrid/TrainingCompaniesDataGrid'
import { TrainingCompaniesProvider, useTrainingCompaniesContext } from '../provider/TrainingCompaniesProvider'

const TrainingCompaniesViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { selectedIds, trainingCompaniesData, handleDelete, updateSelectedIds, handleFetchMore } = useTrainingCompaniesContext()
  const [loading, setLoading] = React.useState(false)
  const [showEditDialog, setShowEditDialog] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { errorMessage, setErrorMessage, setMessageByCode } = useErrorMessage()

  const { setValue, handleSubmit } = useFormContext()

  const [updateTrainingCompanies] = useUpdateTrainingCompaniesMutation()

  const isActive = useWatch({
    name: 'active',
    defaultValue: false
  })

  const sameStates = React.useMemo<boolean>(() => {
    const activeCount = selectedIds.filter((id) => trainingCompaniesData
      ?.edges?.find(({ node: { id: trainingCompanyId } }) => trainingCompanyId === id)?.node.active).length

    setValue('active', activeCount / selectedIds.length > 0.5)

    return activeCount === 0 || activeCount === selectedIds.length
  }, [selectedIds])

  const handleConfirm = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateTrainingCompanies({
        variables: {
          data: {
            ids: selectedIds as string[],
            active: !input.active
          }
        }
      })

      updateSelectedIds([])
      handleFetchMore()
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setLoading(false)
      setShowEditDialog(false)
      setShowToast(true)
    }
  })

  return (
    <DefaultLayout
      title={t('routes.trainingCompanies')}
      description={t('trainingCompanies.trainingCompaniesDescription')}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
        >
          {can('training-companies:write') && selectedIds.length > 0 && (
            <Tooltip title={sameStates ? '' : t('trainingCompanies.notSameStates')}>
              <Box mr={2}>
                <StandaloneSwitchInput
                  formKey={'active'}
                  label={t('common.active')}
                  disabled={!sameStates}
                  onChange={() => setShowEditDialog(true)}
                />
              </Box>
            </Tooltip>
          )}

          {can('training-companies:delete') && selectedIds.length > 0 && (
            <DeleteIconButton
              sx={{ mr: 2 }}
              onClick={() => handleDelete(true)}
            />
          )}

          {(can('training-companies:create') || can('training-companies:create.own')) && (
            <Button startIcon={<themeIcons.Add />}
              onClick={() => navigate('create')}
            >
              {t('trainingCompanies.addTrainingCompany')}
              </Button>
          )}
        </Box>
      )}
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <TrainingCompaniesDataGrid />
      </Box>

      <ConfirmDialog
        title={t('trainingCompanies.changeTrainingCompany')}
        open={showEditDialog}
        onConfirm={handleConfirm}
        onCancel={() => setShowEditDialog(false)}
        loading={loading}
      >
        <Trans
          i18nKey="trainingCompanies.changeActiveTrainingCompany"
          values={{ count: selectedIds.length, status: t(`status.${isActive ? 'inactive' : 'active'}`) }}
          components={{
            b: <strong />
          }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('trainingCompanies.editSuccess')}
      />
    </DefaultLayout>
  )
}

export const TrainingCompaniesView: React.FC = () => {
  const formData = useForm({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <TrainingCompaniesProvider>
      <FormProvider {...formData}>
        <TrainingCompaniesViewContent />
      </FormProvider>
    </TrainingCompaniesProvider>
  )
}
