import { useAppDispatch } from '@services/store/store'
import { resetAuthData } from '@services/store/slices/auth'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { MeQuery, useDeleteMeMutation, useMeQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

export type SettingsProviderContextType = {
  me?: MeQuery['me']
  loading?: boolean
  handleDelete: (callback?: () => void) => void
  refetchMe: () => void
}

const SettingsProviderContext = React.createContext<SettingsProviderContextType>(
  {} as any
)

export const SettingsProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const deleteCallbackRef = React.useRef<() => void>()

  const [deleteMe] = useDeleteMeMutation()

  const { data: meData, loading, refetch } = useMeQuery({
    fetchPolicy: 'cache-and-network'
  })

  const me = React.useMemo(() => {
    return meData?.me
  }, [meData])

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)

  const { setMessageByCode, setErrorMessage, errorMessage } = useErrorMessage()

  const handleRedirect = () => {
    dispatch(resetAuthData())
    navigate('/login')
  }

  const confirmDelete = async () => {
    setDeleteLoading(true)
    setErrorMessage('')

    try {
      await deleteMe()

      handleRedirect()
    } catch (e) {
      setMessageByCode(e)
      setShowToast(true)
    } finally {
      deleteCallbackRef.current?.()
      setDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }

  const value = React.useMemo(() => ({
    me,
    loading,
    handleDelete: () => setShowDeleteDialog(true),
    refetchMe: refetch
  }), [me, loading])

  return (
    <SettingsProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('settings.deleteProfile')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={deleteLoading}
        open={showDeleteDialog}
      >
        {t('settings.deleteProfileDescription')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />

    </SettingsProviderContext.Provider>
  )
}

export const useSettingsContext = () => React.useContext(SettingsProviderContext)
