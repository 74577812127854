import React from 'react'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { CompaniesRowModel } from '@features/companies/provider/CompaniesProvider'
import { useTranslation } from 'react-i18next'

import { CompanyColumnType } from '../companiesDataGridColumns'

const RenderCell: React.FC<CompaniesRowModel> = ({ credits, isPartner }) => {
  const { t } = useTranslation()

  return isPartner
    ? <Typography
        variant="subtitle1"
        fontWeight={500}
    >
      {'-'}
    </Typography>
    : (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      >
        <Tooltip title={t('credits.jobBasicCredits')}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
          >
            {credits.jobBasic?.length || 0}
          </Typography>
        </Tooltip>
        <Divider
          light
          orientation="vertical"
          sx={{
            mx: 1,
            height: '20px'
          }}
        />
        <Tooltip title={t('credits.jobExtensionCredits')}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
          >
            {credits.jobExtension?.length || 0}
          </Typography>
        </Tooltip>
      </Box>
      )
}

export const jobCreditsColumnModel: CompanyColumnType = {
  field: 'credits',
  width: 100,
  filterable: false,
  sortable: false,
  renderCell: (params) => <RenderCell {...params.row} />
}
