import React from 'react'
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useTranslation } from 'react-i18next'

import { DashboardCard } from '../ui/DashboardCard'

export type DashboardTopItemsType = {
  id: string,
  key?: string,
  name: string,
  extension?: React.ReactNode,
}

type Props = {
  title?: string,
  data: DashboardTopItemsType[]
  max?: number,
  titleExtension?: React.ReactNode,
  onItemClicked?: (id: string) => void,
  onShowAll?: () => void
}

export const DashboardItemList: React.FC<Props> = ({ data, titleExtension, max = 5, onShowAll, onItemClicked, title }) => {
  const { t } = useTranslation()

  const filteredItems = React.useMemo(() => data.slice(0, max), [data, max])

  return (
    <DashboardCard
      title={title}
      additionalActions={titleExtension}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box mt={2}>
          {!(filteredItems?.length)
            ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 1
              }}
              >
                <Typography>{t('common.noEntries')}</Typography>
              </Box>
              )
            : (
              <Box>
                {filteredItems.map((item) => (
                  <Grid key={item.id}
                    container
                    alignItems="center"
                    spacing={2}
                  >
                    {item.key && (
                    <Grid item xs={2} md={2}>
                      <Tooltip title={item.key}>
                        <Typography variant="subtitle1"
                          sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                            mr: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%'
                          }}
                        >{item.key}</Typography>
                      </Tooltip>
                    </Grid>
                    )}

                    <Grid item xs={item.key ? 7 : 9} md={item.key ? 6 : 8}>
                      <Tooltip title={item.name}>
                        <Typography variant="subtitle1"
                          sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                            mr: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%'
                          }}
                        >{item.name}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                      >
                        <Box pr={1.5}>
                          {item.extension}
                        </Box>
                        <IconButton sx={{
                          '&:hover': {
                            color: 'primary.main',
                            backgroundColor: 'transparent'
                          }
                        }}
                          onClick={() => onItemClicked && onItemClicked(item.id)}
                        >
                          <themeIcons.ArrowRight />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box>
              )}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          mt: 3
        }}
        >
          {onShowAll && <Button sx={{
            padding: 0
          }}
            disableRipple
            variant="text"
            onClick={onShowAll}
          >
            {t('dashboard.viewAll')}
            </Button>
        }
        </Box>
      </Box>
    </DashboardCard>
  )
}
