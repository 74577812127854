import { Box, Grid } from '@mui/material'
import React from 'react'
import { useDashboardTrackingIncentivesQuery, useIncentivesQuery } from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { palette } from '@theme/core/palette/palette'
import { mapYearData, sumYearData } from '@utils/dashboard'
import { CONVERSION_TIME_SPAN } from '@shared/constants/dashboard'
import { DashboardItemList, DashboardTopItemsType } from '@shared/components/cards/DashboardItemList'
import { DashboardClicks } from '@shared/components/cards/DashboardClicks'
import { DashboardConversionRate } from '@shared/components/cards/DashboardConversionRate'
import { DashboardYearOverview } from '@shared/components/cards/DashboardYearOverview'
import { PerformanceChip } from '@shared/components/ui/PerformanceChip'

export const DashboardProductView: React.FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{id: string}>()

  const { data: dashboardData } = useDashboardTrackingIncentivesQuery({
    variables: {
      companyId: id
    }
  })

  const { data } = useIncentivesQuery({
    variables: {
      companyId: id
    },
    fetchPolicy: 'cache-and-network'
  })

  const mappedDashboardData = React.useMemo(() => {
    if (!dashboardData) {
      return {}
    }

    const {
      button_click: buttonClick,
      product_click: productClick
    } = dashboardData?.dashboardTrackingIncentives || {}

    const buttonClicks = buttonClick ? sumYearData(buttonClick) : []
    const productClicks = productClick ? sumYearData(productClick) : []

    return {
      partnerClicks: (dashboardData?.dashboardTrackingIncentives?.partner_click || []) as number[][],
      buttonClicks,
      productClicks
    }
  }, [dashboardData])

  const navigate = useNavigate()

  const mappedIncentives = React.useMemo<DashboardTopItemsType[]>(() => {
    return data?.incentives.sort((a, b) => b.bimonthlyPerformance - a.bimonthlyPerformance).map((incentive) => ({
      id: incentive.id,
      name: incentive.texts.name,
      extension: <PerformanceChip value={incentive.bimonthlyPerformance} />
    })) ?? []
  }, [data])

  const mappedYearData = React.useMemo(() => {
    if (!mappedDashboardData) {
      return []
    }

    return [
      {
        name: 'buttonClicks',
        color: palette.primary[900] || '',
        values: mapYearData(mappedDashboardData.buttonClicks || [])
      },
      {
        name: 'productClicks',
        color: palette.primary.main || '',
        values: mapYearData(mappedDashboardData.productClicks || [])
      },
      {
        name: 'companyClicks',
        color: palette.secondary[300] || '',
        values: mapYearData(mappedDashboardData.partnerClicks || [])
      }
    ]
  }, [mappedDashboardData])

  const conversionData = React.useMemo(() => {
    const { buttonClicks, partnerClicks } = mappedDashboardData

    const flattenedButtonClicks = buttonClicks?.flat() || []
    const flattenedPartnerClicks = partnerClicks?.flat() || []

    return {
      button: flattenedButtonClicks.slice(flattenedButtonClicks.length -
        CONVERSION_TIME_SPAN, flattenedButtonClicks.length).reduce((a, b) => a + b, 0),
      partner: flattenedPartnerClicks.slice(flattenedPartnerClicks.length -
        CONVERSION_TIME_SPAN, flattenedPartnerClicks.length).reduce((a, b) => a + b, 0)
    }
  }, [mappedDashboardData])

  return (
    <Grid container spacing={3}>
      <Grid height="auto" item xs={9} xl={10}>
        <DashboardYearOverview data={mappedYearData}
          showYAxis
        />
      </Grid>
      <Grid item xs={3} xl={2}>
        <DashboardConversionRate
          primary={conversionData.partner}
          secondary={conversionData.button}
        />
      </Grid>
      <Grid item xs={6}>
        <Box mb={1}>
          <DashboardClicks
            keyVal="products"
            title={t('dashboard.clicksFun')}
            values={mappedDashboardData.buttonClicks || []}
          />
        </Box>
      </Grid>
      <Grid item
        xs={6}
        sx={{
          height: '100%'
        }}
      >
        <DashboardItemList
          title={t('dashboard.trendingProducts')}
          data={mappedIncentives}
          onItemClicked={(incentiveId) => navigate(`/products/${incentiveId}`)}
          onShowAll={() => navigate('/products')}
        />
      </Grid>
    </Grid>
  )
}
