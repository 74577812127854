import { Box } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro'
import { themeIcons } from '@theme/theme'
import React from 'react'

export const DataGridToolbar: React.FC = () => {
  return (
    <GridToolbarContainer sx={{
      display: 'flex',
      justifyContent: 'space-between'
    }}
    >
      <Box>
        <GridToolbarColumnsButton
          startIcon={<themeIcons.Visibility />}
          variant='text'
        />
        <GridToolbarFilterButton
          componentsProps={{
            button: {
              variant: 'text'
              // startIcon: <themeIcons.Filter />
            }
          }}
        />
      </Box>
      <Box>
        <GridToolbarDensitySelector startIcon={<themeIcons.LineWeight />} variant='text'/>
      </Box>
    </GridToolbarContainer>
  )
}
