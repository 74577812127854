import React from 'react'
import { Button, Typography } from '@mui/material'
import { QuestionRowModel } from '@features/questions/provider/QuestionsProvider'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { ProductsColumnType } from '../productsDataGridColumns'

const RenderCell: React.FC<{row: QuestionRowModel}> = ({ row }) => {
  const navigate = useNewTabHook()

  return (
    <Button
      variant="text"
      sx={{
        '&.MuiButton-root': {
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: 'flex-start'
        }
      }}
      onClick={() => navigate(`${row.id}`)}
    >
      <Typography
        variant="subtitle1"
        fontWeight={500}
      >{row.texts.name}</Typography>
    </Button>
  )
}

export const nameColumnModel: ProductsColumnType = {
  field: 'name',
  flex: 1,
  valueGetter: (params) => params.row.texts.name,
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
