import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { useAppSelector } from '@services/store/store'
// import {
//  StudyStatus
// } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'

import { DashboardTraineeList } from '../components/DashboardTraineeList'
import { DashboardTrainingCompanyProvider, useDashboardTrainingCompanyContext } from '../provider/DashboardTrainingCompanyProvider'
import { DashboardTraineesToConfirm } from '../components/DashboardTraineesToConfirm'
import { TrainingYearFilter } from '../components/TrainingYearFilter'
import { ProfessionFilter } from '../components/ProfessionFilter'

// const STUDY_ORDER: StudyStatus[] = [StudyStatus.Done, StudyStatus.Failed, StudyStatus.Exam, StudyStatus.Ready, StudyStatus.Learning]

const DashboardTrainingCompanyViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { user } = useAppSelector(({ auth }) => auth)

  const {
    trainees,
    loading,
    notConfirmedTrainees,
    availableTrainingYears,
    availableProfessions
  } = useDashboardTrainingCompanyContext()

  const noData = React.useMemo(() => {
    return !trainees || Object.keys(trainees).length === 0
  }, [trainees])

  /**
  const filteredStudyStatus = React.useMemo<StudyStatus[]>(() => {
    return STUDY_ORDER.filter((status) => {
      return trainees?.[status]?.length
    })
  }, [trainees])
  */

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'auto'
    }}
    >
      <Box sx={{
        flex: 1
      }}
      >
        <Box sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 0,
            p: 4.5
          }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h1"
                mb={1}
                fontWeight={600}
              >{t('dashboard.userWelcome', {
                fullName: t('common.fullName', {
                  firstName: user?.firstname,
                  lastName: user?.lastname
                })
              })}</Typography>
              <Typography>
                {t('dashboard.trainingCompany.dashboardDescription')}
              </Typography>
            </Box>
            <Box sx={{ flex: 0, display: 'flex' }}>
              {(availableTrainingYears?.length || 0) > 1 &&
                <Box sx={{
                  ml: 2,
                  width: '200px'
                }}
                >
                  <TrainingYearFilter />
                </Box>
              }
              {(availableProfessions?.length || 0) > 1 &&
                <Box sx={{
                  ml: 2,
                  width: '200px'
                }}
                >
                  <ProfessionFilter />
                </Box>
              }
            </Box>
          </Box>
          <Box sx={{
            px: 4.5,
            flex: 0
          }}
          >
            <Divider light />
          </Box>
          <Box
            sx={{
              flex: 1,
              overflow: 'auto'
            }}
          >
            {loading
              ? (
                <Box px={4.5}>
                  <LinearProgress />
                </Box>
                )
              : (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                >

                  {!!notConfirmedTrainees?.length && can('users:write.trainee') && (
                  <DashboardTraineesToConfirm />
                  )}
                  {!noData && trainees
                    ? (
                      <Box>
                        <Box mt={3.5}>
                          <DashboardTraineeList
                            students={trainees ?? []}
                          />
                        </Box>
                      </Box>
                      )
                    : (
                      <Typography
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        variant="h4"
                      >{t('dashboard.trainingCompany.noTrainees')}</Typography>
                      )}
                </Box>
                )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const DashboardTrainingCompanyView: React.FC = () => (
  <DashboardTrainingCompanyProvider>
    <DashboardTrainingCompanyViewContent />
  </DashboardTrainingCompanyProvider>
)
