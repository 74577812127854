import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from '@shared/components/inputs/TextInput'
import { LoadingButton } from '@mui/lab'
import { useForgotPasswordMutation } from '@typings/graphql'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { mailPattern } from '@utils/pattern'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

type ForgotPasswordInput = {
  email: string
}

export const PasswordForgotView: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [showToast, setShowToast] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const formData = useForm<ForgotPasswordInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  const [forgotPassword] = useForgotPasswordMutation()
  const { setErrorMessage, setMessageByCode, errorMessage } = useErrorMessage()

  const { handleSubmit, formState: { isDirty, isValid } } = formData

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      await forgotPassword({
        variables: {
          email: input.email.toLowerCase().trim()
        }
      })
      setErrorMessage('')
      setShowToast(true)
    } catch (e) {
      setMessageByCode(e)
    }

    setShowToast(true)
    setLoading(false)
  })

  return (
    <AuthLayout>
      <Box my={2} textAlign="center">
        <Button variant="text" onClick={() => navigate('/login')}>
          <Typography>{t('auth.backToLogin')}</Typography>
        </Button>
      </Box>
      <Typography
        variant='h4'
        sx={{
          mb: 3,
          textAlign: 'center'
        }}
      >{t('auth.forgotPasswordDescription')}</Typography>
      <FormProvider {...formData}>
        <TextInput
          fullWidth
          formKey="email"
          label={t('common.email')}
          options={{
            required: true,
            pattern: mailPattern
          }}
        >

        </TextInput>

        <LoadingButton
          variant='contained'
          disabled={!isDirty || !isValid}
          loading={loading}
          onClick={onSubmit}
          sx={{
            mt: 2
          }}
        >
          {t('auth.passwordReset')}
        </LoadingButton>
      </FormProvider>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('auth.passwordResetMail')}
      />
    </AuthLayout>
  )
}
