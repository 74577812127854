import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  CategoriesWithChildrenQuery,
  ProfessionQuery,
  useCategoriesWithChildrenQuery,
  useDeleteCategoriesMutation,
  useDeleteCategoryMutation,
  useProfessionQuery
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export type CategoryType = Exclude<CategoriesWithChildrenQuery['categories'], null | undefined>[0]
export type CategoryRowModel = Omit<CategoryType, '__typename' | 'childCategories'> & {
  profession?: ProfessionQuery['profession']
  title?: string
}

export type CategoriesProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: CategoryRowModel | null
  parentCategoryId?: string
  categoryData: CategoryRowModel[]
  profession: ProfessionQuery['profession'] | null
  loading?: boolean
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: CategoryRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

export type CategorySelectionType = {
  id: string
  name: string
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const CategoriesProviderContext = React.createContext<CategoriesProviderContextType>(
  {} as any
)

export const CategoriesProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()
  const { professionId, categoryId } = useParams<{ professionId?: string, categoryId?: string }>()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<CategoryRowModel | null>(null)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  const deleteCallbackRef = React.useRef<() => void>()

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [deleteCategory] = useDeleteCategoryMutation()
  const [deleteCategories] = useDeleteCategoriesMutation()

  const { data, loading: categoriesLoading, refetch } = useCategoriesWithChildrenQuery({
    variables: {
      parentId: categoryId
    },
    fetchPolicy: 'cache-and-network',
    skip: disableFetching || !categoryId
  })

  const { data: professionData, loading: professionLoading, refetch: refetchProfession } = useProfessionQuery({
    variables: {
      id: professionId as string
    },
    fetchPolicy: 'cache-and-network',
    skip: disableFetching || !professionId
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const profession = React.useMemo(() => {
    if (!professionData) {
      return null
    }

    return professionData.profession
  }, [professionData])

  const loading = React.useMemo(() => {
    return professionLoading || categoriesLoading || artificialLoading
  }, [professionLoading, categoriesLoading, artificialLoading])

  const categoryData = React.useMemo<CategoryRowModel[]>(() => {
    if (data) {
      return data.categories || []
    }

    if (profession) {
      return profession.categories || []
    }

    return []
  }, [profession, data])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteCategories({
          variables: {
            ids: selectedIds as string[]
          }
        })
        setSelectedIds([])
        setSuccessMessage(t('categories.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteCategory({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('categories.deleteSuccess'))
      }

      if (!disableFetching) {
        if (categoryId) {
          await refetch()
        } else if (professionId) {
          await refetchProfession()
        }
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updateSelectedItem = React.useCallback((row: CategoryRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const value = React.useMemo(() => ({
    parentCategoryId: categoryId,
    categoryData,
    loading,
    selectedIds,
    selectedItem,
    profession,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [categoryId, data, profession, loading, selectedIds, selectedItem])

  return (
    <CategoriesProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'categories.deleteEntries' : 'categories.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.title } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </CategoriesProviderContext.Provider>
  )
}

export const useCategoriesContext = () => React.useContext(CategoriesProviderContext)
