import React from 'react'
import { LearningProgressDiagram } from '@typings/learningProgress'
import { Box, Tooltip, Typography } from '@mui/material'

type Props = {
  title?: string | React.ReactElement,
  data?: LearningProgressDiagram,
  overall?: boolean,
  hidePercentage?: boolean,
  thick?: boolean
}

export const TraineeDetailProgressBar: React.FC<Props> = ({ title, hidePercentage, overall, data, thick }) => {
  const percentage = React.useMemo(() => {
    if (!data) {
      return 0
    }

    let value = 0

    if (overall) {
      const values = Object.values(data).map((item) => item.value)
      value = values.slice(0, values.length - 1).reduce((acc, item) => acc + item, 0)
    } else {
      value = data?.correctAnswers?.value || 0
    }

    return value > 0 && value < 100 ? value.toFixed(1) : value
  }, [data])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
      >
        {title && (
          <Tooltip title={title}>
            <Box sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              pr: 2
            }}
            >
              {typeof title === 'string'
                ? <Typography sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                    fontWeight="500"
                >{title}</Typography>
                : title}
            </Box>
          </Tooltip>
        )}
        {!hidePercentage && <Typography fontWeight="500">{`${percentage}%`}</Typography>}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px',
        overflow: 'hidden',
        width: '100%',
        height: thick ? 10 : 6
      }}
      >
        {data
          ? Object.entries(data).map(([key, value], index) => (
            <Box
              key={key}
              sx={{
                width: `${value.value}%`,
                height: '100%',
                pr: index < Object.entries(data).length - 1 && value.value > 0 ? '4px' : 0
              }}
            >
              <Box sx={{
                width: '100%',
                height: '100%',
                backgroundColor: value.color
              }}
              />
            </Box>
          ))
          : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'grey.400'
              }}
            />
            )}
      </Box>
    </Box>
  )
}
