import {
  DataGridPro,
  DataGridProProps,
  useGridApiRef,
  GridInitialState,
  GridFilterModel,
  GridSortModel,
  GridPaginationModel
} from '@mui/x-data-grid-pro'
import React from 'react'
import { useDataGridTranslation } from '@hooks/useDataGridTranslation'
import { DATA_GRID_PAGE_SIZE, NOT_HIDABLE_COLUMNS } from '@shared/constants/dataGrid'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { getDataGridState, updateDataGridState } from '@services/store/slices/dataGrid'
import { useTheme } from '@mui/material'
import { theme } from '@theme/theme'

import { DataGridFooter } from './components/DataGridFooter'
import { DataGridToolbar } from './components/DataGridToolbar'
import { DataGridFilterPanel } from './components/DataGridFilterPanel'

type Props = DataGridProProps & {
  notHidableColumns?: string[]
  gridKey?: string
  onInitialState?: (state: GridInitialState) => void
}

export const BaseDataGrid: React.FC<Props> = ({ gridKey, onInitialState, notHidableColumns = [], sx, ...props }) => {
  const { breakpoints } = useTheme()
  const dataGridTranslation = useDataGridTranslation()

  const dataGridRef = useGridApiRef()
  const dataGridState = useAppSelector(getDataGridState(gridKey))
  const { personalSettings } = useAppSelector(({ app }) => app)

  const dispatch = useAppDispatch()

  const {
    checkboxSelection,
    onRowSelectionModelChange,
    filterModel,
    sortModel,
    paginationMode,
    paginationModel,
    loading,
    rowCount,
    ...restProps
  } = props

  const [basePaginationModel, setBasePaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GRID_PAGE_SIZE
  })
  const [baseFilterModel, setBaseFilterModel] = React.useState<GridFilterModel>()
  const [baseSortModel, setBaseSortModel] = React.useState<GridSortModel>()

  const setSortModel = React.useCallback((model: GridSortModel) => {
    setBaseSortModel(model)
    setBasePaginationModel((prev) => ({
      page: 0,
      pageSize: prev.pageSize
    }))
  }, [])

  const showCheckbox = React.useMemo(() => {
    if (checkboxSelection === undefined) {
      return true
    }

    return checkboxSelection
  }, [checkboxSelection])

  const combinedInitialState = React.useMemo(() => {
    return {
      ...restProps.initialState,
      dataGridState,
      pinnedColumns: { right: ['action'] }
    }
  }, [])

  React.useEffect(() => {
    if (dataGridState) {
      onInitialState?.(dataGridState)

      if (dataGridState.pagination?.paginationModel) {
        setBasePaginationModel({
          page: dataGridState.pagination.paginationModel.page || 0,
          pageSize: dataGridState.pagination.paginationModel.pageSize || DATA_GRID_PAGE_SIZE
        })
      }
      if (dataGridState.filter?.filterModel) {
        setBaseFilterModel(dataGridState.filter.filterModel)
      }
      if (dataGridState.sorting?.sortModel) {
        setBaseSortModel(dataGridState.sorting.sortModel)
      }
    }
  }, [])

  React.useEffect(() => {
    if (gridKey && personalSettings.dataGridState) {
      dispatch(updateDataGridState({
        key: gridKey,
        value: {
          pagination: { paginationModel: paginationModel || basePaginationModel },
          filter: { filterModel: filterModel || baseFilterModel },
          sorting: { sortModel: sortModel || baseSortModel }
        }
      }))
    }
  }, [paginationModel,
    filterModel,
    sortModel,
    baseFilterModel,
    basePaginationModel,
    baseSortModel])

  return (
    <DataGridPro
      apiRef={dataGridRef}
      checkboxSelection={showCheckbox}
      disableRowSelectionOnClick
      pagination
      pageSizeOptions={[10, 25, 50]}
      paginationModel={paginationModel || basePaginationModel}
      filterModel={filterModel || baseFilterModel}
      onFilterModelChange={setBaseFilterModel}
      sortModel={sortModel || baseSortModel}
      onSortModelChange={setSortModel}
      onPaginationModelChange={setBasePaginationModel}
      sx={{
        ...sx,
        [breakpoints.down('md')]: {
          '.MuiDataGrid-footerContainer': {
            minHeight: '40px'
          },
          '.MuiDataGrid-toolbarContainer': {
            minHeight: '40px',
            button: {
              ...theme.typography.subtitle1,
              py: 0
            }
          }
        },
        '.MuiDataGrid-selectedRowCount': {
          display: 'none'
        },
        '.MuiTablePagination-toolbar': {
          '.MuiInputBase-root': {
            paddingRight: '10px',
            '.MuiSelect-select': {
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5rem'
            }
          }
        }
      }}
      initialState={combinedInitialState}
      loading={loading}
      localeText={dataGridTranslation}
      rowCount={rowCount}
      paginationMode={paginationMode || 'client'}
      slots={{
        toolbar: DataGridToolbar,
        filterPanel: DataGridFilterPanel,
        footer: () => <DataGridFooter
          mode={paginationMode}
        />
      }}
      slotProps={{
        filterPanel: {
          logicOperators: []
        },
        baseSelect: { native: false },
        columnsPanel: {
          disableHideAllButton: true,
          getTogglableColumns: (columns) => {
            return columns.filter((column) => ![...notHidableColumns, ...NOT_HIDABLE_COLUMNS]
              .includes(column.field)).map((column) => column.field)
          }
        }
      }}
      onRowSelectionModelChange={showCheckbox ? onRowSelectionModelChange : undefined}
      {...restProps}
    />
  )
}
