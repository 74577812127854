import { Typography } from '@mui/material'
import { useTopicQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const TopicBreadcrumb:React.FC = () => {
  const { t } = useTranslation()
  const { topicId } = useParams<{ topicId: string }>()

  const { data } = useTopicQuery({
    variables: {
      id: topicId as string
    },
    skip: !topicId
  })

  const title = React.useMemo(() => {
    if (!data) {
      return t('topics.unknown')
    }

    return data.topic.texts?.title
  }, [data])

  return (
    <Typography
      variant="subtitle1"
      fontWeight="inherit"
    >
      {title}
    </Typography>
  )
}
