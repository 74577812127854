import React from 'react'
import { MyCompanyEditView } from '@features/company/views/MyCompanyEditView'
import { MyCompanyRequestUserView } from '@features/company/views/MyCompanyRequestUserView'
import { MyCompanyView } from '@features/company/views/MyCompanyView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const companyRoutes: AppRouteObject[] = [
  {
    path: '/company',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.COMPANY]
    },
    meta: {
      name: 'company',
      navItem: true,
      navIcon: 'Person',
      navOrder: 1
    },
    children: [{
      index: true,
      element: <MyCompanyView />
    }, {
      path: 'edit-profile',
      element: <MyCompanyEditView />,
      meta: {
        name: 'companyProfileEdit'
      }
    }, {
      path: 'request-user',
      element: <MyCompanyRequestUserView />,
      meta: {
        name: 'companyRequestUser'
      }
    }]
  }
]
