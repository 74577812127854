import React from 'react'
import { InstructorsCreateView } from '@features/instructors/views/InstructorsCreateView'
import { InstructorsEditView } from '@features/instructors/views/InstructorsEditView'
import { InstructorsView } from '@features/instructors/views/InstructorsView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const instructorsRoutes: AppRouteObject[] = [

  {
    path: '/instructors',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.TRAINING_COMPANY_MANAGER]
    },
    meta: {
      name: 'instructors',
      navItem: true,
      navIcon: 'Group',
      navOrder: 2
    },
    children: [{
      index: true,
      element: <InstructorsView />
    }, {
      path: 'create',
      meta: {
        name: 'instructorsCreate'
      },
      element: <InstructorsCreateView />,
      access: {
        roles: [Roles.TRAINING_COMPANY_MANAGER]
      }
    }, {
      path: ':id',
      meta: {
        name: 'InstructorsEdit'
      },
      element: <InstructorsEditView />,
      access: {
        roles: [Roles.TRAINING_COMPANY_MANAGER]
      }
    }]
  }
]
