import { Box, Divider } from '@mui/material'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { CompanyCategoriesQuery } from '@typings/graphql'
import { postalPattern } from '@utils/pattern'
import { t } from 'i18next'
import React from 'react'

type Props = {
  loading?: boolean
  categories?: CompanyCategoriesQuery['companyCategories']
}

export const CompanyProfileEditInputs: React.FC<Props> = ({ loading, categories }) => {
  const mappedCategories = React.useMemo(() => {
    return categories?.map((category) => {
      return {
        id: category.id,
        name: category.name
      }
    }) || []
  }, [categories])

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='name'
        options={{ required: true }}
        title={t('common.name')}
      />

      <Divider
        sx={{
          mt: 1,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSelectInput
        formKey="category"
        title={t('common.department')}
        items={mappedCategories}
        options={{ required: true }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='texts.description'
        title={t('common.tagline')}
        inputProps={{
          max: 100
        }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='address'
        options={{ required: true }}
        title={t('common.address')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='postalCode'
        options={{ required: true, pattern: postalPattern }}
        title={t('common.postalCode')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='city'
        options={{ required: true }}
        title={t('common.city')}
      />

      <Divider
        sx={{
          mt: 1.5,
          mb: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.keyvisual"
        fileKey="keyvisual"
        title={t('common.keyVisual')}
        description={t('myCompany.keyVisualDescription')}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.logo"
        fileKey="logo"
        title={t('common.logo')}
        description={t('myCompany.logoDescription')}
        loading={loading}
      />
    </Box>
  )
}
