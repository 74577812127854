import { Box, CheckboxProps, Grid, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { InputTitleField } from '../ui/InputRequiredIndicator'

type Props = CheckboxProps & {
  formKey: string
  options?: RegisterOptions
  title: string
  tooltipLabel?: string
  description?: string
}

export const BaseSwitchInput: React.FC<Props> = ({
  formKey,
  options,
  title,
  description,
  disabled,
  tooltipLabel,
  defaultChecked,
  ...props
}) => {
  const { control } = useFormContext()

  return (
    <Grid container spacing={4}>
      <Grid item
        xs={3}
        sx={{
          ...(disabled && {
            opacity: 0.2
          })
        }}
      >
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <Controller
          name={formKey}
          control={control}
          rules={options}
          render={({ field: { onChange, value, ...restFields } }) => {
            return (disabled && tooltipLabel)
              ? (
                <Tooltip title={tooltipLabel}>
                  <Box>
                    <Switch
                      disabled={disabled}
                      checked={typeof value === 'undefined' ? (defaultChecked || false) : value}
                      {...props}
                      {...restFields}
                      onChange={onChange}
                    />
                  </Box>
                </Tooltip>
                )
              : (<Switch disabled={disabled}
                  checked={typeof value === 'undefined' ? (defaultChecked || false) : value}
                  {...props}
                  {...restFields}
                  onChange={onChange}
              />)
          }
          }
        />
      </Grid>
    </Grid>

  )
}
