import { Grid, SelectProps, Typography } from '@mui/material'
import React from 'react'
import { RegisterOptions } from 'react-hook-form'

import { InputTitleField } from '../ui/InputRequiredIndicator'

import { SelectInput, SelectInputItem } from './SelectInput'

type Props = SelectProps & {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  items: SelectInputItem[]
}

export const BaseSelectInput: React.FC<Props> = ({ formKey, options, title, description, items, multiple, ...props }) => {
  const { disabled } = props

  return (
    <Grid container spacing={4}>
      <Grid item
        xs={3}
        sx={{
          ...(disabled && {
            opacity: 0.2
          })
        }}
      >
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <SelectInput
          formKey={formKey}
          items={items}
          multiple={multiple}
          options={options}
          disabled={disabled}
          {...props}
        />
      </Grid>
    </Grid>

  )
}
