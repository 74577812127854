import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/theme'

type Props = {
  text: string
}

export const ErrorLayout: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  const goBack = () => {
    window.history.back()
  }

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection={['column', 'row']}>
      <Box width="50%" display="flex" flexDirection="row" alignItems="center">
        <SecurityIcon sx={{ width: ['200px', '300px'], height: ['200px', '300px'] }} />

        <Box display="inline-block">
          <Typography variant="h2">{text}</Typography>
          <Button onClick={goBack} variant="text" sx={{ mt: 2 }}>
            <themeIcons.ArrowBack />
            <Typography ml={1}>{t('common.navigateBack')}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
