import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type PersonalSettings = {
  newTab: boolean
  dataGridState: boolean
}

type AppState = {
  personalSettings: PersonalSettings,
}

const slice = createSlice({
  name: 'app',
  initialState: {
    personalSettings: {
      newTab: false,
      dataGridState: false
    }
  } as AppState,
  reducers: {
    updatePersonalSettings: (state, { payload }: PayloadAction<PersonalSettings>) => {
      state.personalSettings = {
        ...state.personalSettings,
        ...payload
      }
    }
  }
})

export const {
  updatePersonalSettings
} = slice.actions

export default slice.reducer
