import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'
import { useNavigate } from 'react-router'

import { useDashboardTrainingCompanyContext } from '../provider/DashboardTrainingCompanyProvider'

export const DashboardTraineesToConfirm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { notConfirmedTrainees } = useDashboardTrainingCompanyContext()

  const onNavigateToList = React.useCallback(() => {
    navigate('/trainees')
  }, [])

  return (
    <Box px={4.5} mt={2}>
      <Box sx={{
        backgroundColor: 'grey.100',
        border: '1px solid',
        borderColor: 'grey.300',

        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        p: 2,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      >
        <Box sx={{ mr: 2, flex: 0 }}>
          <themeIcons.Info sx={{ height: 32, width: 32 }}/>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant='h4' fontWeight={600}>{t('dashboard.trainingCompany.notConfirmed')}</Typography>
          <Typography>
            {t('dashboard.trainingCompany.notConfirmedCount', { count: notConfirmedTrainees?.length || 0 })}
          </Typography>
        </Box>
        <Box sx={{ flex: 0 }}>
          <Button
            onClick={onNavigateToList}
            variant="text"
            disableRipple
            endIcon={<themeIcons.ArrowRight />}
          >
            <Typography fontWeight={600}>
              {t('dashboard.trainingCompany.toList')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
