import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { IncentivesQuery, useDeleteIncentiveMutation, useDeleteIncentivesMutation, useIncentivesQuery } from '@typings/graphql'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export type ProductType = Exclude<IncentivesQuery['incentives'], null | undefined>[0]
export type ProductsRowModel = Omit<ProductType, '__typename'>

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type ProductsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: ProductsRowModel | null
  productData: ProductsRowModel[]
  loading: boolean
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: ProductsRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

const ProductsProviderContext = React.createContext<ProductsProviderContextType>(
  {} as any
)

export const ProductsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [deleteIncentives] = useDeleteIncentivesMutation()
  const [deleteIncentive] = useDeleteIncentiveMutation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<ProductsRowModel | null>(null)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [showToast, setShowToast] = React.useState(false)

  const { setMessageByCode, errorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const { data, loading: dataLoading, refetch } = useIncentivesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !!disableFetching
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading
  }, [artificialLoading, dataLoading])

  const productData = React.useMemo(() => {
    return data?.incentives.map((product) => ({
      ...product
    }
    )) || []
  }, [data])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: ProductsRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleteLoading(true)

    try {
      if (selectedIds && deleteMany) {
        await deleteIncentives({
          variables: {
            data: {
              incentiveIds: selectedIds as string[]
            }
          }
        })
      } else if (selectedItem && !deleteMany) {
        await deleteIncentive({
          variables: {
            id: selectedItem.id
          }
        })
      }

      setClickedRow(null)
      updateSelectedIds([])

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const value = React.useMemo<ProductsProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    productData,
    loading,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [selectedIds, selectedItem, productData, loading])

  return (
    <ProductsProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'products.deleteEntries' : 'products.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.texts?.name } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t(`products.${deleteMany ? 'deleteManySuccess' : 'deleteSuccess'}`)}
      />
    </ProductsProviderContext.Provider>
  )
}

export const useProductsContext = () => React.useContext(ProductsProviderContext)
