import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import { useNavigate } from 'react-router'

import LoginForm from '../components/LoginForm'

const LoginView: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <AuthLayout>
      <Typography variant="h4"
        sx={{
          my: 3,
          textAlign: 'center'
        }}
      >{t('auth.instruction')}</Typography>
      <LoginForm />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <Button
          variant="text"
          sx={{
            mt: 2
          }}
          onClick={() => navigate('/registration-training-company')}
        >
          <Typography>{t('auth.registrationTrainingCompany')}</Typography>
        </Button>
        <Button
          variant="text"
          sx={{
            mt: 2
          }}
          onClick={() => navigate('/registration-company')}
        >
          <Typography>{t('auth.registrationCompany')}</Typography>
        </Button>
      </Box>
    </AuthLayout>
  )
}

export default LoginView
