import { getGridBooleanOperators, GridFilterItem, GridCellParams, GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { FilterOperators } from '@typings/graphql'
import React from 'react'

export const getFilterOperatorSelect = (RenderInput: React.FC<GridFilterInputValueProps>) => {
  return getGridBooleanOperators().map((operator) => {
    if (operator.value === 'is') {
      operator.value = FilterOperators.Equals
    }

    return {
      ...operator,
      getApplyFilterFn (filterItem: GridFilterItem) {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null
        }

        return (params: GridCellParams) => {
          return params.value === filterItem.value
        }
      },
      InputComponent: RenderInput
    }
  })
}
