import { QuestionType } from '@typings/graphql'
import React from 'react'

import { QuestionChoiceInput } from './types/QuestionChoiceInput'
import { QuestionNumericInput } from './types/QuestionNumericInput'
import { QuestionDateInput } from './types/QuestionDateInput'
import { QuestionTimeInput } from './types/QuestionTimeInput'
import { QuestionDragDropInput } from './types/QuestionDragDropInput'
import { QuestionSortableInput } from './types/QuestionSortableInput'
import { QuestionAssignmentInput } from './types/QuestionAssignmentInput'

export type QuestionExtensions = Record<QuestionType, React.FC>

export const questionTypeExtensions: QuestionExtensions = {
  [QuestionType.Choice]: QuestionChoiceInput,
  [QuestionType.NumericValue]: QuestionNumericInput,
  [QuestionType.Assignment]: QuestionAssignmentInput,
  [QuestionType.Date]: QuestionDateInput,
  [QuestionType.Time]: QuestionTimeInput,
  [QuestionType.DragDrop]: QuestionDragDropInput,
  [QuestionType.Sort]: QuestionSortableInput
}
