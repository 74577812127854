import { MutationHookOptions } from '@apollo/client'
import { EditableFile } from '@typings/files'
import {
  Exact,
  FileDataFragmentDoc,
  UploadFileInput,
  UploadFileMutation,
  useDeleteFileMutation,
  useUpdateFileMutation,
  useUploadFileMutation
} from '@typings/graphql'
import React from 'react'

type UseFileUpload = {
  uploadFile: (
    file: File,
    key: string,
    model: string,
    modelId: string,
    config?: Record<string, any>) => Promise<void>
  uploadAndDeleteFiles: (
    files: Record<string, EditableFile<any> | null>,
    model: string,
    modelId?: string,
    originalFiles?: EditableFile<any>[]
  ) => Promise<void>
}

export const useFileUpload = (baseOptions?: MutationHookOptions<UploadFileMutation, Exact<{
  data: UploadFileInput
  file: any
}>>): UseFileUpload => {
  const [uploadFileMutation] = useUploadFileMutation(baseOptions)

  const [deleteFile] = useDeleteFileMutation()
  const [updateFile] = useUpdateFileMutation()

  const uploadFile = React.useCallback(async (
    file: File,
    key: string,
    model: string,
    modelId: string,
    config?: Record<string, any>) => {
    await uploadFileMutation({
      variables: {
        file,
        data: {
          key,
          model,
          modelId,
          replace: true,
          config
        }
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: model,
            id: modelId
          }),
          fields: {
            files (existingFiles = [], { readField }) {
              const newFileRef = cache.writeFragment({
                data: data?.uploadFile,
                fragment: FileDataFragmentDoc
              })

              return [...existingFiles.filter((ref: any) => {
                return readField('key', ref) !== data?.uploadFile.key
              }), newFileRef]
            }
          }
        })
      }
    })
  }, [])

  const uploadAndDeleteFiles = React.useCallback(async (
    files: Record<string, EditableFile<any> | null>,
    model: string,
    modelId?: string,
    originalFiles?: EditableFile<any>[]) => {
    if (!modelId || !Object.keys(files)?.length) {
      return
    }

    if (originalFiles) {
      const fileValues = Object.values(files)

      const deletedFiles = originalFiles?.filter((file) => {
        return !fileValues.find((f) => f?.id === file.id)
      }).map((filtered) => filtered.id) || []

      for (const fileId of deletedFiles) {
        await deleteFile({
          variables: {
            id: fileId
          },
          update: (cache, { data: fileData }) => {
            cache.evict({
              id: cache.identify({
                __typename: 'File',
                id: fileData?.deleteFile.id
              })
            })

            cache.modify({
              id: cache.identify({
                __typename: model,
                id: modelId
              }),
              fields: {
                files (existingFiles, { readField }) {
                  return existingFiles.filter((fileRef: any) => fileId !== readField('id', fileRef))
                }
              }
            })

            cache.gc()
          }
        })
      }
    }

    for (const key of Object.keys(files)) {
      const file = files[key]

      if (file?.upload) {
        await uploadFile(
          file.upload.file,
          key,
          model,
          modelId,
          file.config
        )
      } else if (file && file.id) {
        await updateFile({
          variables: {
            id: file.id as string,
            data: {
              key,
              config: file.config
            }
          }
        })
      }
    }
  }, [])

  return { uploadFile, uploadAndDeleteFiles }
}
