import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

import { TopicsColumnType } from '../topicsDataGridColumns'

export const professionColumnModel: TopicsColumnType = {
  field: 'profession',
  width: 250,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter (params) {
    return params.row.profession.texts?.title
  },
  renderCell: (params) =>
    <Tooltip title={params.row.profession.texts?.title} placement="top">
      <Typography variant="subtitle1" fontWeight={500}>{params.row.profession.texts?.title}</Typography>
    </Tooltip>
}
