import React from 'react'
import { Typography } from '@mui/material'

import { InstructorColumnType } from '../instructorsDataGridColumns'

export const trainingCompanyColumnModel: InstructorColumnType = {
  field: 'name',
  width: 150,
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{params.value}</Typography>
  )
}
