import { Box, Divider } from '@mui/material'
import {
  UpdateQuestionGroupInput,
  useQuestionGroupQuery,
  useUpdateQuestionGroupMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import i18n from '@services/i18n/i18n'
import { EditableFile } from '@typings/files'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { useFileUpload } from '@hooks/useFileUpload'
import { getFileByKey, getFilesWithPrefix } from '@utils/files'

import { QuestionGroupsProvider, useQuestionGroupsContext } from '../provider/QuestionGroupsProvider'
import { QuestionGroupsCreateEditInputs } from '../components/QuestionGroupsCreateEditInputs'
import { QuestionGroupOrderInput } from '../components/QuestionGroupOrderInput'

type QuestionGroupInputType = Omit<UpdateQuestionGroupInput, 'texts'> & {
  files: Record<string, EditableFile<any> | null>
  texts: Record<string, string>
}

const QuestionGroupsEditViewContent: React.FC = () => {
  const location = useLocation()

  const { groupId } = useParams<{ groupId: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()
  const { handleDelete, updateSelectedItem } = useQuestionGroupsContext()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext()
  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateQuestionGroup] = useUpdateQuestionGroupMutation()

  const { uploadAndDeleteFiles } = useFileUpload()

  const { data, loading: questionGroupLoading } = useQuestionGroupQuery({
    variables: {
      id: groupId as string
    },
    skip: !groupId
  })

  React.useEffect(() => {
    if (data?.questionGroup) {
      reset({
        texts: data.questionGroup.texts,
        questionIdsOrder: data.questionGroup.questions.map((question) => question.id),
        files: {
          explanation: getFileByKey('explanation', data.questionGroup.files),
          video: getFileByKey('video', data.questionGroup.files)
        },
        attachments: getFilesWithPrefix('file_', data.questionGroup.files)
      })
    }
  }, [data])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateQuestionGroup({
        variables: {
          id: groupId as string,
          data: {
            questionIdsOrder: input.questionIdsOrder,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      await uploadAndDeleteFiles(
        {
          ...input.files,
          ...input.attachments
        },
        'QuestionGroup',
        groupId as string,
        data?.questionGroup?.files
      )

      setShowToast(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setShowToast(true)
      setMessageByCode(e)
    }
  })

  const deleteCallback = () => {
    navigate(location.pathname.replace(`/${data?.questionGroup.id}`, ''))
  }

  const onDelete = async () => {
    if (!data?.questionGroup) {
      return
    }

    setDeleted(true)
    updateSelectedItem(data.questionGroup)
    handleDelete(false, deleteCallback)
  }

  return (
    <CreateEditLayout
      title={t('questionGroups.editTitle')}
      description={
        <Trans
          i18nKey={'questionGroups.editDescription'}
          values={{ name: data?.questionGroup?.texts.title }}
          components={{ b: <strong /> }}
        />
      }
      loading={questionGroupLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <QuestionGroupsCreateEditInputs loading={loading}/>

      {!!data?.questionGroup?.questions?.length && (
      <>
        <Divider
          sx={{
            my: 3.5
          }}
          light
          orientation='horizontal'
        />

        <QuestionGroupOrderInput questions={data.questionGroup.questions} />
      </>
      )}

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('questionGroups.entryEdited', { name: data?.questionGroup?.texts.title })}
      />
    </CreateEditLayout>
  )
}

export const QuestionGroupsEditView:React.FC = () => {
  const formData = useForm<QuestionGroupInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <QuestionGroupsProvider disableFetching>
      <FormProvider {...formData}>
        <QuestionGroupsEditViewContent />
      </FormProvider>
    </QuestionGroupsProvider>
  )
}
