import { Box, Grid } from '@mui/material'
import { DashboardAccordion } from '@shared/components/ui/DashboardAccordion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTraineesDetailContext } from '@features/trainees/provider/TraineeDetailProvider'
import { DefaultButtonGroup } from '@shared/components/ui/DefaultButtonGroup'

import { TraineeDetailProgressBar } from './TraineeDetailProgressBar'
import { TraineeDetailLearningLoad } from './TraineeDetailLearningLoad'

enum Tabs {
  SUBJECTS = 'subjects',
  TOPICS = 'topics'
}

export const TraineeDetailLearningProgress: React.FC = () => {
  const { t } = useTranslation()

  const { traineeData, learningProgress } = useTraineesDetailContext()

  const [selectedTab, setSelectedTab] = React.useState<Tabs>(Tabs.SUBJECTS)

  const tabs = React.useMemo(() => {
    return [{
      id: Tabs.SUBJECTS,
      title: t('trainees.progressByCategories')
    }, {
      id: Tabs.TOPICS,
      title: t('trainees.progressByTopics')
    }]
  }, [])

  const subjects = React.useMemo(() => {
    if (!traineeData) {
      return []
    }
    const professionCategories = traineeData.profession?.categories.map((category) => category.childCategories)?.flat() || []

    return Object.entries(learningProgress?.categories ?? {}).filter(([key]) => {
      return professionCategories?.find(category => category.id === key)
    }).map(([key, value]) => ({
      key,
      title: professionCategories.find(category => category.id === key)?.texts?.title,
      data: value
    }))
  }, [traineeData, learningProgress])

  const topics = React.useMemo(() => {
    if (!traineeData) {
      return []
    }

    const professionTopics = traineeData.profession?.topics

    return Object.entries(learningProgress?.topics ?? {}).filter(([key]) => {
      return professionTopics?.find(topic => topic.id === key)
    }).map(([key, value]) => ({
      key,
      title: t('trainees.progressByTopic', {
        index: professionTopics?.find(topic => topic.id === key)?.index,
        title: professionTopics?.find(topic => topic.id === key)?.texts?.title
      }),
      data: value
    }))
  }, [traineeData, learningProgress])

  return (
    <DashboardAccordion
      title={t('trainees.learningProgress')}
      headerExtension={
        <Box sx={{
          width: '100%'
        }}
        >
          <TraineeDetailProgressBar
            title={t('trainees.learningProgressOverall')}
            thick
            data={learningProgress.overall}
          />
        </Box>
    }
    >
      <Grid container spacing={4}>
        <Grid
          item
          xl={8}
          lg={7}
          md={6}
          xs={12}
        >
          <Box sx={{ width: '100%' }}>
            <DefaultButtonGroup
              items={tabs}
              selectedId={selectedTab}
              onChange={({ id }) => setSelectedTab(id)}
              renderLabel={({ title }) => title}
              fullWidth
            />
          </Box>
          {selectedTab === Tabs.SUBJECTS && subjects.map(({ key, title, data }) => (
            <Box
              key={key}
              sx={{
                width: '100%',
                mt: 2
              }}
            >
              <TraineeDetailProgressBar
                title={title}
                data={data}
              />
            </Box>
          ))}
          {selectedTab === Tabs.TOPICS && topics.map(({ key, title, data }) => (
            <Box
              key={key}
              sx={{
                width: '100%',
                mt: 2
              }}
            >
              <TraineeDetailProgressBar
                title={title}
                data={data}
              />
            </Box>
          ))}
        </Grid>
        <Grid
          item
          xl={4}
          lg={5}
          md={6}
          xs={12}
        >
          <TraineeDetailLearningLoad />
        </Grid>
      </Grid>
    </DashboardAccordion>
  )
}
