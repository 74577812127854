import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { fontSizes, lineHeights } from '@theme/theme'
import { VictoryArea } from 'victory'
import { palette } from '@theme/core/palette/palette'

import { DashboardCard } from '../ui/DashboardCard'
import { PerformanceChip } from '../ui/PerformanceChip'

type Props = {
  keyVal: string
  values: number[][]
  title?: string
}

type Performance = {
  clicks: number
  graphValues: number[]
  currentMonth: number
  lastMonth: number
}

export const DashboardClicks: React.FC<Props> = ({ keyVal, title, values }) => {
  const { t } = useTranslation()
  const [isPositive, setIsPositive] = React.useState(false)

  const [boundingRect, setBoundingRect] = React.useState({ width: 0, height: 0 })

  const mappedData = React.useMemo<Performance>(() => {
    const flattened = values.flat()

    const monthClicks = flattened?.slice(flattened.length - 30, flattened.length)
    const lastMonthClicks = flattened?.slice(flattened.length - 90, flattened.length - 30)

    const monthClicksSum = monthClicks?.reduce((acc, item) => acc + item, 0) || 0
    const lastMonthClicksSum = lastMonthClicks?.reduce((acc, item) => acc + item, 0) || 0

    return {
      clicks: monthClicksSum,
      currentMonth: monthClicksSum,
      graphValues: [...lastMonthClicks, ...monthClicks],
      lastMonth: lastMonthClicksSum
    }
  }, [values])

  const graphRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect())
    }
  }, [])

  const percent = React.useMemo(() => {
    const { lastMonth, currentMonth } = mappedData

    setIsPositive(currentMonth >= lastMonth)

    return Math.round((currentMonth - lastMonth) / (lastMonth || 1) * 100)
  }, [mappedData])

  const graphData = React.useMemo(() => {
    return mappedData.graphValues?.length
      ? mappedData.graphValues.map((valueItem, index) => ({
        x: index + 1,
        y: valueItem || 0
      }))
      : [{
          x: 0,
          y: 0
        }]
  }, [mappedData])

  const chartDomain = React.useMemo(() => {
    const min = mappedData.graphValues?.length ? Math.min(...mappedData.graphValues) : 0
    const max = mappedData.graphValues?.length ? Math.max(...mappedData.graphValues) : 0

    return {
      x: [0, (mappedData.graphValues?.length || 0) + 1],
      y: [min - 20, max + 20]
    }
  }, [mappedData])

  return (
    <DashboardCard title={title || t('dashboard.buttonClicks')}>
      <svg style={{ height: 0, position: 'absolute' }}>
        <defs>
          <linearGradient id={`${keyVal}_positiveGradient`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={palette.success.main} stopOpacity={0.2}/>
            <stop offset="100%" stopColor="white" stopOpacity={0.2}/>
          </linearGradient>
          <linearGradient id={`${keyVal}_negativeGradient`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={palette.error.main} stopOpacity={0.2}/>
            <stop offset="100%" stopColor="white" stopOpacity={0.2}/>
          </linearGradient>
        </defs>
      </svg>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography sx={{
            my: 2,
            fontSize: fontSizes['4xl'],
            lineHeight: lineHeights['4xl'],
            fontWeight: 600
          }}
          >
            {mappedData.clicks}
          </Typography>
          <PerformanceChip value={percent}
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'transparent',
              '.MuiChip-label': {
                fontSize: fontSizes.md,
                fontWeight: 600
              },
              '.MuiSvgIcon-root': {
                width: 20,
                height: 20,
                color: 'inherit'
              }
            }}
          />
          <Typography>
            {t('dashboard.comparedLastMonth')}
          </Typography>
        </Grid>
        <Grid item
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'flex-end'
          }}
          ref={graphRef}
        >
          <VictoryArea
            interpolation="natural"
            data={graphData}
            padding={0}
            domain={{ y: [chartDomain.y[0], chartDomain.y[1]] }}
            width={boundingRect.width}
            height={100}
            style={{
              data: {
                stroke: isPositive ? palette.success.main : palette.error.main,
                strokeWidth: 3,
                fill: isPositive ? `url(#${keyVal}_positiveGradient)` : `url(#${keyVal}_negativeGradient)`
              }
            }}
          />
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
