import { Box, BoxProps, Divider, LinearProgress, Typography, useTheme } from '@mui/material'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  title?: string
  subtitle?: string
  description?: string | React.ReactNode
  tabBar?: React.ReactNode,
  loading?: boolean,
  headerExtension?: React.ReactNode,
  contentContainer?: BoxProps
};

export const DefaultLayout: React.FC<Props> = ({
  title,
  subtitle,
  description,
  loading,
  tabBar,
  headerExtension,
  children,
  contentContainer
}) => {
  const { sx: contentStyles, ...contentContainerProps } = contentContainer || {}
  const { breakpoints } = useTheme()

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    }}
    >
      <Box sx={{
        flex: 1,
        pt: 4.5,
        [breakpoints.down('md')]: {
          pt: 2
        },
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        >
          <Box sx={{
            flex: '0 1 auto',
            px: 4.5,
            [breakpoints.down('md')]: {
              px: 2
            }
          }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
            >
              <Box>
                <Typography variant="h1"
                  fontWeight={600}
                  mb={1}
                >
                  {title}
                </Typography>
                {subtitle && <Typography variant="h3"
                  fontWeight={600}
                  mb={1}
                >
                  {subtitle}
                </Typography>
              }
                {description &&
                <Typography mt={0.5}>
                  {description}
                </Typography>
              }
                {tabBar &&
                <Box sx={{
                  mt: 2,
                  [breakpoints.down('md')]: {
                    mt: 1
                  }
                }}
                >
                  {tabBar}
                </Box>
              }
              </Box>
              <Box>
                {headerExtension}
              </Box>
            </Box>

            <Divider
              light
              orientation='horizontal'
              sx={{
                mt: 3.5,
                [breakpoints.down('md')]: {
                  mt: 1
                },
                borderColor: 'grey.300'
              }}
            />
          </Box>
          <Box sx={{
            flex: '1 1 auto',
            overflow: 'auto',
            p: 4.5,
            [breakpoints.down('md')]: {
              p: 2,
              '>div': {
                pt: 0
              }
            },
            pt: 0,
            ...contentStyles
          }}
            {...contentContainerProps}
          >
            {loading
              ? (
                <LinearProgress />
                )
              : (
                  children
                )
          }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
