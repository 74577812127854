import { Button, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'

type Props = {
  label: string
  onAdd: () => void
}

export const AddDefaultRow: React.FC<Props> = ({ label, onAdd }) => {
  return (
    <Button
      variant="text"
      disableRipple
      sx={{
        border: '1px dashed',
        borderColor: 'grey.300',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiButton-text': {
          color: 'grey.700',
          '&:hover': {
            color: 'grey.900',
            borderColor: 'grey.500'
          },
          '.MuiTypography-button': {
            textTransform: 'none'
          }
        }
      }}
      onClick={onAdd}
    >
      <themeIcons.Add />
      <Typography sx={{
        ml: 1
      }}
        variant="h5"
      >{label}</Typography>
    </Button>
  )
}
