import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import { SimpleDataRow } from '@shared/components/ui/SimpleDataRow'
import { CompanyLogo } from '@shared/components/ui/CompanyLogo'
import { getFileByKey } from '@utils/files'
import { openBasicJobCreditsPayment } from '@utils/payments'
import { FormProvider, useForm } from 'react-hook-form'

import { MyCompanyProvider, useMyCompanyContext } from '../provider/MyCompanyProvider'

const MyCompanyViewContent: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { loading, companyData } = useMyCompanyContext()

  const logo = React.useMemo(() => {
    return getFileByKey('logo', companyData?.files)
  }, [companyData])

  const handleJobBasicCreditClick = React.useCallback(() => {
    if (companyData) {
      openBasicJobCreditsPayment(companyData?.id)
    }
  }, [])

  return (
    <DefaultLayout
      title={t('routes.myCompany')}
      description={t('myCompany.myCompanyDescription')}
      loading={loading}
      headerExtension={
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          <Button startIcon={<themeIcons.Edit />}
            onClick={() => navigate('edit-profile')}
          >
            {t('myCompany.editProfile')}
          </Button>
          <Button sx={{ ml: 1 }}
            startIcon={<themeIcons.Person />}
            onClick={() => navigate('request-user')}
          >
            {t('myCompany.requestUser')}
          </Button>
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <Box>
          <DashboardCard
            {...(logo?.remote.url && {
              titlePrefix: (
                <CompanyLogo src={logo?.remote.url} />
              )
            })}
            title={t('myCompany.companyData')}
          >
            <Divider
              sx={{
                mt: 1,
                mb: 2
              }}
              light
              orientation='horizontal'
            />

            <SimpleDataRow title={t('common.name')} value={companyData?.name} />

            <SimpleDataRow title={t('common.department')} value={companyData?.category?.name} />

            <SimpleDataRow title={t('common.address')} value={companyData?.address} />

            <SimpleDataRow title={t('common.location')}
              value={t('common.postalCodeCity', { postalCode: companyData?.postalCode, city: companyData?.city })}
            />

            {!companyData?.isPartner && (
              <>
                <SimpleDataRow title={t('credits.jobBasicCredits')}
                  value={companyData?.credits?.jobBasic?.length || 0}
                  actions={
                    <Tooltip title={t('paymentRedirection.jobBasicCredit')}>
                      <IconButton sx={{
                        padding: 0,
                        '&:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                        onClick={handleJobBasicCreditClick}
                      >
                        <themeIcons.CreditCard />
                      </IconButton>
                    </Tooltip>
                }
                />

                <SimpleDataRow title={t('credits.jobExtensionCredits')}
                  value={companyData?.credits?.jobExtension?.length || 0}
                />
              </>
            )}

          </DashboardCard>
        </Box>
      </Box>
    </DefaultLayout>
  )
}

export const MyCompanyView: React.FC = () => {
  const formData = useForm({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <MyCompanyProvider>
      <FormProvider {...formData}>
        <MyCompanyViewContent />
      </FormProvider>
    </MyCompanyProvider>
  )
}
