import { Box } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import React from 'react'

export const BaseTextOutline: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: palette.grey?.[400],
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        color: palette.text?.primary,
        backgroundColor: 'transparent',
        padding: '14px',
        height: '100%',
        fieldset: {
          border: 'none'
        }
      }}
    >
      {children}
    </Box>
  )
}
