import { useDataGridPagination } from '@hooks/useDataGridPagination'
import { useErrorMessage } from '@hooks/useErrorMessage'
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { DATA_GRID_PAGE_SIZE } from '@shared/constants/dataGrid'
import {
  TrainingCompaniesQuery, TrainingCompaniesPaginatedQuery,
  useDeleteTrainingCompanyMutation, useDeleteTrainingCompaniesMutation, useTrainingCompaniesPaginatedQuery
} from '@typings/graphql'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type TrainingCompanyType = Exclude<TrainingCompaniesQuery['trainingCompanies'], null | undefined>[0]
export type TrainingCompaniesRowModel = Omit<TrainingCompanyType, '__typename'> & {
  contacts: string[]
}

export type TrainingCompaniesColumnType = Omit<GridColDef<TrainingCompaniesRowModel>, 'field'> & {
  field: keyof TrainingCompaniesRowModel | 'action',
  hideHeader?: boolean,
}

export type TrainingCompaniesProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: TrainingCompaniesRowModel | null
  trainingCompaniesData: TrainingCompaniesPaginatedQuery['trainingCompaniesPaginated'] | undefined
  loading: boolean
  paginationModel: GridPaginationModel
  sortModel: GridSortModel[0] | undefined
  filterModel: GridFilterModel | undefined
  updatePaginationModel: (pagination: GridPaginationModel) => void
  updateSortModel: (model: GridSortModel, details: GridCallbackDetails) => void
  updateFilterModel: (filter: GridFilterModel | undefined) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: TrainingCompaniesRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
  handleFetchMore: () => void
  updateInitialState: (initialState?: GridInitialState) => void
}

const TrainingCompaniesProviderContext = React.createContext<TrainingCompaniesProviderContextType>(
  {} as any
)

export const TrainingCompaniesProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [deleteTrainingCompanies] = useDeleteTrainingCompaniesMutation()
  const [deleteTrainingCompany] = useDeleteTrainingCompanyMutation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<TrainingCompaniesRowModel | null>(null)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [loadMore, setLoadMore] = React.useState(false)

  const { errorMessage, setErrorMessage, setMessageByCode } = useErrorMessage()

  const {
    fetchVariablesRef, filterModel, paginationModel, sortModel,
    setFilterModelState, setPaginationModelState, setSortModelState, setInitialState
  } = useDataGridPagination<TrainingCompaniesPaginatedQuery>()

  const deleteCallbackRef = React.useRef<() => void>()

  const { data, loading: dataLoading, refetch } = useTrainingCompaniesPaginatedQuery({
    skip: !!disableFetching,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      first: DATA_GRID_PAGE_SIZE
    }
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading || loadMore
  }, [artificialLoading, dataLoading, loadMore])

  const trainingCompaniesData = React.useMemo<TrainingCompaniesPaginatedQuery['trainingCompaniesPaginated'] | undefined>(() => {
    return data?.trainingCompaniesPaginated
  }, [data])

  const handleFetchMore = async () => {
    setErrorMessage('')
    setLoadMore(true)
    try {
      await refetch(fetchVariablesRef.current)
    } catch (e) {
      setMessageByCode(e, 'users')
      setShowToast(true)
    } finally {
      setLoadMore(false)
    }
  }

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: TrainingCompaniesRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const updatePaginationModel = React.useCallback((pagination: GridPaginationModel) => {
    setPaginationModelState(pagination, trainingCompaniesData?.pageInfo.startCursor, trainingCompaniesData?.pageInfo.endCursor)

    handleFetchMore()
  }, [trainingCompaniesData])

  const updateSortModel = React.useCallback((model: GridSortModel) => {
    setSortModelState(model)
    handleFetchMore()
  }, [trainingCompaniesData])

  const updateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    setFilterModelState(filter)
    handleFetchMore()
  }, [])

  const updateInitialState = React.useCallback((initialState?: GridInitialState) => {
    setInitialState(initialState)
    handleFetchMore()
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteTrainingCompanies({
          variables: {
            data: {
              ids: selectedIds as string[]
            }
          }
        })
        updateSelectedIds([])
        setSuccessMessage(t('trainingCompanies.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteTrainingCompany({
          variables: {
            id: selectedItem.id
          }
        })

        setSuccessMessage(t('trainingCompanies.deleteSuccess'))
        setClickedRow(null)
      }

      if (!disableFetching) {
        await refetch()
      }

      setIsDeleteLoading(false)
      setShowDeleteDialog(false)

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const value = React.useMemo<TrainingCompaniesProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    trainingCompaniesData,
    loading,
    filterModel,
    paginationModel,
    sortModel,
    updateFilterModel,
    updatePaginationModel,
    updateSortModel,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds,
    handleFetchMore,
    updateInitialState
  }), [
    selectedIds,
    selectedItem,
    trainingCompaniesData,
    loading,
    filterModel,
    paginationModel,
    sortModel
  ])

  return (
    <TrainingCompaniesProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'trainingCompanies.deleteEntries' : 'trainingCompanies.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.name } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </TrainingCompaniesProviderContext.Provider>
  )
}

export const useTrainingCompaniesContext = () => React.useContext(TrainingCompaniesProviderContext)
