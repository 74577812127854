import React from 'react'
import { useTranslation } from 'react-i18next'
import { VictoryLine, VictoryArea, VictoryAxis, VictoryChart } from 'victory'
import { clamp } from '@utils/math'
import { Box } from '@mui/material'
import { palette } from '@theme/core/palette/palette'

import { DashboardCard } from '../ui/DashboardCard'
import { DashboardLegendItem } from '../ui/DashboardLegendItem'

const MONTH_LIST = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

type DashboardYearOverviewData = {
  name: string,
  color: string,
  values: {
    timestamp: Date
    value: number
  }[]
}

type Props = {
  data: DashboardYearOverviewData[]
  lineCount?: number
  title?: string,
  colorSecondary?: boolean
  showYAxis?: boolean
  yBuffer?: number
}

export const DashboardYearOverview: React.FC<Props> = ({ title, data, showYAxis, colorSecondary, yBuffer, lineCount = 5 }) => {
  const { t } = useTranslation()

  const [boundingRect, setBoundingRect] = React.useState({ width: 0, height: 0 })
  const [backgroundDomains, setBackgroundDomains] = React.useState<{ x: Date, y: number }[][]>([])

  const graphRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect())
    }
  }, [])

  const monthCount = React.useMemo(() => {
    if (!data[0]?.values[0]?.timestamp) {
      return 1
    }

    const firstDate = new Date(data[0]?.values[0]?.timestamp)

    if (!firstDate) {
      return 1
    }

    const lastDate = new Date()

    return (lastDate.getFullYear() - firstDate.getFullYear()) * 12 + lastDate.getMonth() - firstDate.getMonth() + 1
  }, [data])

  const mappedData = React.useMemo(() => {
    const newData = data.map((item) => ({
      ...item,
      values: item.values.map((value) => {
        return {
          x: value.timestamp,
          y: value.value
        }
      })
    })) || []

    return newData
  }, [data])

  const chartDomain = React.useMemo(() => {
    const allValues = mappedData.reduce((acc, item) => {
      return [...acc, ...item.values]
    }, [] as { x: Date, y: number }[])

    if (allValues.length === 0) {
      return {
        x: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        y: [0, (yBuffer || 100)]
      }
    }

    const min = Math.min(...allValues.map((item) => item.y))
    const max = Math.max(...allValues.map((item) => item.y))

    const newMax = Math.pow(10, Math.ceil(Math.log10(max))) || 100

    setBackgroundDomains(new Array(lineCount).fill(0).map((_, index) => ([
      {
        x: allValues[0].x,
        y: clamp(min, 0, newMax) + (newMax - clamp(min, 0, newMax)) / lineCount * index
      },
      {
        x: new Date(),
        y: clamp(min, 0, newMax) + (newMax - clamp(min, 0, newMax)) / lineCount * index
      }
    ])
    ))

    return {
      x: [allValues[0].x, new Date()],
      y: [clamp(min, 0, newMax), newMax]
    }
  }, [mappedData])

  const yAxisValues = React.useMemo(() => {
    const min = chartDomain.y[0]
    const max = chartDomain.y[1]

    return new Array(lineCount).fill(0).map((_, index) => {
      return Math.ceil(clamp(min, 0, max) + (max - clamp(min, 0, max)) / lineCount * index)
    })
  }, [chartDomain])

  return (
    <DashboardCard
      title={title || t('dashboard.yearOverview')}
    >
      <Box
        height="100%"
        ref={graphRef}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          pt: 3
        }}
        >
          {mappedData.map((item) => (
            <Box key={`${item.name}_legend`} ml={1.5}>
              <DashboardLegendItem
                title={t(`dashboard.${item.name}`)}
                color={item.color}
              />
            </Box>
          ))}
        </Box>
        <svg style={{
          height: 0,
          position: 'absolute'
        }}
        >
          <defs>
            <linearGradient id={'lightBlueGradient'} x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={colorSecondary ? palette.secondary?.[400] : palette.primary?.[200]} stopOpacity={0.2}/>
              <stop offset="100%" stopColor="white" stopOpacity={0.2}/>
            </linearGradient>
          </defs>
        </svg>
        <VictoryChart
          width={boundingRect.width}
          height={200}
          domain={{
            y: [chartDomain.y[0], chartDomain.y[1]],
            x: [chartDomain.x[0], chartDomain.x[1]]
          }}
          padding={{
            top: 10,
            bottom: 30,
            left: (showYAxis ? 30 : 0),
            right: 0
          }}
          style={{
            parent: {
              height: 'auto'
            }
          }}
        >
          {
            showYAxis && (
              <VictoryAxis
                dependentAxis
                tickValues={yAxisValues}
                style={{
                  tickLabels: {
                    fontFamily: 'Signika',
                    fontSize: 14,
                    fontWeight: 400
                  },
                  axis: {
                    stroke: palette.grey?.[400]
                  }
                }}

              />
            )
          }
          <VictoryAxis
            tickCount={monthCount}
            tickValues={new Array(monthCount).fill(0).map((_, index) => {
              const month = new Date().getMonth() - index

              if (month < 0) {
                return new Date(new Date().getFullYear() - 1, 12 + month, 15)
              }

              return new Date(new Date().getFullYear(), index, 15)
            })}
            tickFormat={(date) => t(`months.${MONTH_LIST[new Date(date).getMonth()]}_short`)}
            style={{
              tickLabels: {
                fontFamily: 'Signika',
                fontSize: 14,
                fontWeight: 400
              },
              axis: {
                stroke: palette.grey?.[400]
              }
            }}
          />

          {
            backgroundDomains.map((domain, index) => {
              return (
                <VictoryLine
                  key={`axis-${index}`}
                  data={domain}
                  style={{
                    data: {
                      stroke: palette.grey?.[200]
                    }
                  }}
                />
              )
            })
          }

          {mappedData.map((item, index) => (
            <VictoryArea
              key={item.name}
              domain={{
                y: [chartDomain.y[0], chartDomain.y[1]],
                x: [chartDomain.x[0], chartDomain.x[1]]
              }}
              data={item.values}
              width={boundingRect.width}
              interpolation="natural"
              style={{
                data: {
                  fill: index === mappedData.length - 1 ? 'url(#lightBlueGradient)' : 'transparent',
                  stroke: item.color,
                  strokeWidth: 3
                }
              }}
            />
          ))}
        </VictoryChart>
      </Box>
    </DashboardCard>
  )
}
