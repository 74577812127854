import React from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from '@shared/layouts/AuthLayout'
import { useCheckInvitationTokenQuery, useConfirmMailMutation } from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'

export const RegistrationConfirmationView: React.FC = () => {
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>()
  const [success, setSuccess] = React.useState(false)

  const navigate = useNavigate()

  const [confirmMail] = useConfirmMailMutation()

  const { data, loading } = useCheckInvitationTokenQuery({
    variables: {
      token: token as string
    }
  })

  const handleConfirmMail = React.useCallback(async () => {
    if (!data) {
      return
    }

    try {
      await confirmMail({
        variables: {
          token: token as string
        }
      })

      setSuccess(true)
    } catch (error) {
      console.log(error)
    }
  }, [confirmMail, data, token])

  React.useEffect(() => {
    if (!data || success) {
      return
    }

    if (!data.checkInvitationToken) {
      handleConfirmMail()
    }
  }, [data])

  const invitationInvalid = React.useMemo(() => {
    if (!data) {
      return false
    }

    return data.checkInvitationToken
  }, [data])

  return <AuthLayout>
    <Box sx={{
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
    >
      {loading
        ? <CircularProgress sx={{ mt: 2 }} />
        : (
            invitationInvalid && !success
              ? (
                <>
                  <Typography
                    variant='h4'
                    sx={{
                      mt: 2,
                      textAlign: 'center'
                    }}
                  >{t('auth.invalidInvitation')}</Typography>

                  <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/login')}>
                    <Typography>{t('auth.backToLogin')}</Typography>
                  </Button>
                </>
                )
              : (
                <>
                  <Box>
                    <Typography
                      variant='h4'
                      sx={{
                        mb: 3,
                        textAlign: 'center'
                      }}
                    >{t('auth.registrationConfirmation')}</Typography>
                  </Box>

                  <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/login')}>
                    <Typography>{t('auth.backToLogin')}</Typography>
                  </Button>
                </>
                )
          )}
    </Box>
  </AuthLayout>
}
