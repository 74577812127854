import React from 'react'
import { Typography } from '@mui/material'

import { JobColumnType } from '../jobDataGridColumns'

export const companyColumnModel: JobColumnType = {
  field: 'company',
  width: 125,
  valueGetter: (params) => params.row.company.name,
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{params.row.company.name}</Typography>
  )
}
