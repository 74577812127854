import { Box, Button } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { themeIcons } from '@theme/theme'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CopyIconButton } from '@shared/components/ui/CopyIconButton'
import { QuestionDuplicateDialog } from '@shared/components/modals/QuestionDuplicateDialog'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionListDataGrid } from '../dataGrid/QuestionsListDataGrid'
import { QuestionListProvider, useQuestionListContext } from '../provider/QuestionListProvider'

const QuestionListViewContent: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNewTabHook()

  const {
    selectedIds,
    manySelected,
    selectedItem,
    showDuplicateDialog,
    handleFetchMore,
    handleStatus,
    handleDelete,
    updateShowDuplicateDialog,
    updateSelectedIds
  } = useQuestionListContext()

  const onDuplicated = React.useCallback(() => {
    updateShowDuplicateDialog(false)
    handleFetchMore()
    updateSelectedIds([])
  }, [])

  const onCancel = React.useCallback(() => {
    updateShowDuplicateDialog(false)
    updateSelectedIds([])
  }, [])

  return (
    <DefaultLayout
      title={t('routes.questionList')}
      description={t('questionList.listDescription')}
      headerExtension={
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'

        }}
        >
          {can(['questions:create', 'questions:create.partial']) && selectedIds?.length > 0 && (
          <CopyIconButton
            sx={{ mr: 2 }}
            onClick={() => updateShowDuplicateDialog(true, true)}
          />
          )}
          {can('categories:delete') && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['questions:write']) && selectedIds?.length > 0 && (
            <Button
              startIcon={<themeIcons.Flag />}
              sx={{ mr: 2 }}
              onClick={() => handleStatus(true)}
            >
              {t('common.changeStatus')}
            </Button>
          )}

          {can(['questions:create', 'questions:create.partial']) && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('questions.addQuestion')}
          </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <QuestionListDataGrid />
      </Box>

      <QuestionDuplicateDialog
        selectedIds={selectedIds as string[]}
        selectedItem={selectedItem}
        manySelected={manySelected}
        show={showDuplicateDialog}
        onCompleted={onDuplicated}
        onCancel={onCancel}
      />

    </DefaultLayout>
  )
}

export const QuestionListView: React.FC = () => (
  <QuestionListProvider>
    <QuestionListViewContent />
  </QuestionListProvider>
)
