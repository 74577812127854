import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'
import { statusColumn } from '@shared/dataGrid/columns/statusColumn'

import { ProfessionRowModel } from '../provider/ProfessionsProvider'

import { titleColumnModel } from './columns/titleColumn'
import { trainingYearColumnModel } from './columns/trainingYearColumn'

export type ProfessionsColumnField = keyof ProfessionRowModel

export type ProfessionsColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: ProfessionsColumnField,
  hideHeader?: boolean,
}

export const professionsDataGridColumns: ProfessionsColumnType[] = [
  titleColumnModel,
  trainingYearColumnModel,
  statusColumn
]
