import { palette } from '@theme/core/palette/palette'
import { ReviewStatus, Status } from '@typings/graphql'

export const STATUS_COLOR: Record<Status | ReviewStatus, string> = {
  [Status.Active]: palette.success[600] || '',
  [Status.Inactive]: palette.warning[600] || '',
  [Status.Draft]: palette.grey?.[600] || '',
  [ReviewStatus.Approved]: palette.success[600] || '',
  [ReviewStatus.Rejected]: palette.error[600] || '',
  [ReviewStatus.Review]: palette.primary[600] || ''
}
