import React from 'react'
import { JobWallCreateView } from '@features/jobWall/views/JobWallCreateView'
import { JobWallDetailView } from '@features/jobWall/views/JobWallDetailView'
import { JobWallEditView } from '@features/jobWall/views/JobWallEditView'
import { JobWallView } from '@features/jobWall/views/JobWallView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const jobWallRoutes: AppRouteObject[] = [
  {
    path: '/jobwall',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.COMPANY, Roles.ADMIN]
    },
    meta: {
      name: 'jobwall',
      navItem: true,
      navIcon: 'PushPin',
      navOrder: 4
    },
    children: [{
      index: true,
      element: <JobWallView />
    }, {
      path: 'create',
      meta: {
        name: 'jobwallCreate'
      },
      element: <JobWallCreateView />,
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN]
      }
    }, {
      path: ':id',
      element: <JobWallDetailView />,
      meta: {
        name: 'jobwallDetails'
      },
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN]
      }
    }, {
      path: ':id/edit',
      element: <JobWallEditView />,
      meta: {
        name: 'jobwallEdit'
      },
      access: {
        roles: [Roles.COMPANY, Roles.ADMIN]
      }
    }]
  }
]
