import { Divider, Box } from '@mui/material'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { urlPattern, mailPattern } from '@utils/pattern'
import { themeIcons } from '@theme/icons'
import { CompaniesNameOnlyQuery } from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import { t } from 'i18next'
import React from 'react'

type Props = {
  companies?: CompaniesNameOnlyQuery['companies']
  loading?: boolean
}

export const ProductCreateEditInputs: React.FC<Props> = ({ loading, companies }) => {
  const mappedCompanies = React.useMemo(() => {
    return companies?.map((company) => ({
      id: company.id,
      name: company.name
    })) || []
  }, [companies])

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='texts.name'
        options={{ required: true }}
        title={t('common.title')}
      />
      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='code'
        options={{ required: true }}
        title={t('common.internalId')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='texts.subtitle'
        options={{ required: true }}
        title={t('common.tagline')}
        description={t('common.taglineDescription')}
        inputProps={{
          max: 100
        }}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.keyvisual"
        fileKey="keyvisual"
        title={t('common.keyVisual')}
        description={t('products.keyVisualDescription')}
        loading={loading}
        ratioModal
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseMarkDownEditor
        formKey='texts.description'
        title={t('common.description')}
        description={t('products.createDescription')}
        max={1000}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='link'
        options={{ pattern: urlPattern }}
        title={t('common.buttonLink')}
        type='url'
        InputProps={{
          startAdornment: (<themeIcons.Link />)
        }}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='contact'
        options={{ required: true, pattern: mailPattern }}
        title={t('common.email')}
        type='email'
        description={t('products.emailDescription')}
        InputProps={{
          startAdornment: (<themeIcons.Email />)
        }}
        inputProps={{
          max: 100
        }}
      />

      {isRole(Roles.ADMIN) && (
      <>
        <Divider
          sx={{
            mb: 3.5,
            mt: 1
          }}
          light
          orientation='horizontal'
        />

        <BaseSelectInput
          formKey="companyId"
          title={t('common.company')}
          items={mappedCompanies}
          options={{ required: true }}
        />
      </>
      )}

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey="level"
        title={t('common.level')}
        description={t('products.levelDescription')}
        type='number'
        options={{ required: true }}
      />

    </Box>
  )
}
