import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDashboardTrainingCompanyContext } from '../provider/DashboardTrainingCompanyProvider'

export const ProfessionFilter: React.FC = () => {
  const { t } = useTranslation()
  const {
    availableProfessions,
    professionFilter,
    updateProfessionFilter
  } = useDashboardTrainingCompanyContext()

  const tooltipText = React.useMemo(() => {
    const trainingYears = availableProfessions.filter((profession) => professionFilter.includes(profession.id))

    return trainingYears.map((profession) => profession.name).join(', ')
  }, [availableProfessions, professionFilter])

  return (
    <FormControl fullWidth>
      <InputLabel
        id={'profession_label'}
      >
        {t('common.profession')}
      </InputLabel>
      <Select
        fullWidth
        labelId="profession_label"
        multiple
        input={<OutlinedInput label={t('common.profession')} />}
        value={professionFilter}
        renderValue={() => (
          <Tooltip title={tooltipText}>
            <Typography sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            >
              {tooltipText}
            </Typography>
          </Tooltip>
        )}
        onChange={(e) => updateProfessionFilter(e.target.value as string[])}
      >
        {availableProfessions?.map((profession) => (
          <MenuItem key={`profession_${profession.id}`} value={profession.id}>
            {profession.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
