import { PropsWithChildren } from 'react'

export type GlobalDialogProps = PropsWithChildren & {
  confirmCallback?: () => void,
  cancelCallback?: () => void,
}

export enum GlobalDialogType {
  UNSAVED_CHANGES = 'unsavedChanges',
}
