import { Autocomplete, Box, Grid, IconButton, ListItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { themeIcons } from '@theme/theme'

import { InputTitleField } from '../ui/InputRequiredIndicator'

export type AutocompleteSelectItem = {
  id: string
  name: string
}

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  items: AutocompleteSelectItem[],
  onDeleteItem?: (item?: AutocompleteSelectItem) => void
}

export const BaseAutoCompleteInput: React.FC<Props> = ({ formKey, options, title, description, onDeleteItem, items }) => {
  const { control, setValue } = useFormContext()

  const mappedItems = React.useMemo(() => {
    return items?.map((item) => item.name
    ) || []
  }, [items])

  const onSelect = (event: any, value: string | null) => {
    setValue(formKey, value, {
      shouldDirty: true,
      shouldValidate: true
    })
  }

  const handleOnDelete = (e: React.MouseEvent, item: string) => {
    e.stopPropagation()
    onDeleteItem && onDeleteItem(items.find((i) => i.name === item))
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <Controller
          name={formKey}
          control={control}
          rules={options}
          render={({ field: { onChange, value, ...restFields } }) => (
            <Autocomplete
              renderInput={(params) => <TextField {...params}
                value={value || ''}
                onChange={onChange}
              />}
              value={value || ''}
              renderOption={(props, option) => (
                <ListItem {...props}>
                  <Box flex={1}>
                    {option}
                  </Box>
                  {onDeleteItem &&
                    <IconButton
                      disableRipple
                      sx={{ flex: 0, width: '20px', height: '20px', padding: 0 }}
                      onClick={(e) => handleOnDelete(e, option)}
                    >
                      <themeIcons.Delete />
                    </IconButton>
                  }
                </ListItem>
              )}
              freeSolo
              fullWidth
              onChange={onSelect}
              {...restFields}
              options={mappedItems}
            />
          )
          }
        />
      </Grid>
    </Grid>

  )
}
