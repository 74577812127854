import React from 'react'
import { Box, useTheme } from '@mui/material'
import { Outlet } from 'react-router'
import { DashboardBreadcrumbs } from '@shared/components/ui/DashboardBreadcrumbs'
import { CompanyLogoWarning } from '@shared/components/ui/CompanyLogoWarning'

import { Sidebar } from '../navSidebar/NavSidebar'

export const BreadcrumbLayout: React.FC = () => {
  const { breakpoints } = useTheme()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%'
    }}
    >
      <Box sx={{
        width: '312px',
        [breakpoints.down('lg')]: {
          width: '225px'
        },
        height: '100%'
      }}
      >
        <Sidebar />
      </Box>
      <Box sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      >
        <Box sx={{ flex: 0 }}>
          <DashboardBreadcrumbs />
        </Box>
        <Box sx={{
          flex: 1,
          overflow: 'hidden',
          position: 'relative'
        }}
        >
          <Outlet />
        </Box>
      </Box>

      <CompanyLogoWarning />
    </Box>
  )
}
