import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { QuestionType, useProfessionsMinimalQuery } from '@typings/graphql'
import { firstLetterToLowerCase } from '@utils/string'
import { useFormContext, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router'

import { useQuestionsContext } from '../provider/QuestionsProvider'

type Props = {
  open: boolean
  onClose: () => void
}

export const QuestionsTypeDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()

  const { professionId: professionIdFromParams } = useParams<{professionId: string}>()

  const { professionId, updateProfessionId } = useQuestionsContext()

  const { data } = useProfessionsMinimalQuery({
    skip: !!professionIdFromParams
  })

  const { control } = useFormContext()

  const watchedType = useWatch({
    control,
    name: 'type'
  })

  return (
    <Dialog
      open={open}
      onClose={() => false}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: 500,
          minWidth: 400
        }
      }}
    >
      <DialogTitle>{t('questions.selectQuestionType')}</DialogTitle>
      <DialogContent>
        <Typography sx={{
          mb: 2
        }}
        >{t('questions.selectQuestionTypeDescription')}</Typography>

        {!professionIdFromParams && (
          <Box sx={{ mb: 2 }}>
            <Select fullWidth
              value={professionId || ''}
              onChange={(e) => updateProfessionId?.(e.target.value as string)}
              displayEmpty
              renderValue={(renderValue) => (
                <Typography sx={{
                  color: renderValue ? 'text.primary' : 'grey.500'
                }}
                >
                  {renderValue ? data?.professions.find((item) => item.id === renderValue)?.texts.title : t('common.profession')}
                </Typography>
              )}
            >
              {data?.professions.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.texts.title}</MenuItem>
              ))}
            </Select>
          </Box>
        )}

        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select fullWidth
              value={value || ''}
              onChange={onChange}
              displayEmpty
              renderValue={(renderValue) => (
                <Typography sx={{
                  color: renderValue ? 'text.primary' : 'grey.500'
                }}
                >
                  {renderValue ? t(`questions.types.${firstLetterToLowerCase(renderValue)}`) : t('common.questionType')}
                </Typography>
              )}
            >
              {Object.keys(QuestionType).map((item) => (
                <MenuItem key={item} value={item}>{t(`questions.types.${firstLetterToLowerCase(item)}`)}</MenuItem>
              ))}
            </Select>
          )
          }
        />
      </DialogContent>
      <DialogActions sx={{
        px: 0,
        flexDirection: 'row',
        justifyContent: 'center'
      }}
      >
        <LoadingButton
          sx={{
            width: '50%'
          }}
          variant='contained'
          disabled={!watchedType || !professionId}
          onClick={onClose}
        >
          {t('common.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
