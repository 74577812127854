import { Box } from '@mui/material'
import React from 'react'

type Props = {
  src: string
}

export const CompanyLogo: React.FC<Props> = ({ src }) => {
  return (
    <Box sx={{
      width: 40,
      height: 40,
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}
    />
  )
}
