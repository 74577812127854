import React from 'react'
import { Box, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'

type IconWithTextRowProps = {
  title?: string,
  icon: keyof typeof themeIcons,
  iconColor?: string,
  content: string,
  inline?: boolean
}

export const IconWithTextRow: React.FC<IconWithTextRowProps> = ({ title, icon, iconColor, inline, content }) => {
  const Icon = React.useMemo(() => themeIcons[icon], [icon])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Icon sx={{
        mr: 1,
        ...(iconColor && {
          color: iconColor
        })
      }}
      />
      <Box sx={{
        position: 'relative',
        ...(inline && {
          display: 'flex',
          flexDirection: 'row'
        })
      }}
      >
        {title && <Typography
          sx={{
            fontWeight: '600'
          }}
        >
          {title}
        </Typography>}
        <Typography sx={{
          ...(inline && title && {
            ml: 1
          })
        }}
        >{content}</Typography>
      </Box>
    </Box>
  )
}
