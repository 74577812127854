import { useAppSelector } from '@services/store/store'
import { useMyCompanyQuery } from '@typings/graphql'
import { getFileByKey } from '@utils/files'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultSnackbar } from './DefaultSnackbar'

export const CompanyLogoWarning: React.FC = () => {
  const { t } = useTranslation()

  const { user } = useAppSelector(({ auth }) => auth)
  const [showSnackbar, setShowSnackbar] = React.useState(false)

  const { data } = useMyCompanyQuery({
    skip: !user?.company
  })

  const hasLogo = React.useMemo(() => {
    if (!data?.myCompany?.files) {
      return false
    }

    const file = getFileByKey('logo', data?.myCompany?.files)

    setShowSnackbar(!file)
    return !!file
  }, [data?.myCompany?.files])

  return data && !hasLogo
    ? (
      <DefaultSnackbar
        open={showSnackbar}
        severity='warning'
        message={t('warning.companyLogoWarning')}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={null}
      />
      )
    : null
}
