import React from 'react'
import { Typography } from '@mui/material'

import { JobColumnType } from '../jobDataGridColumns'

export const jobDepartmentColumnModel: JobColumnType = {
  field: 'jobDepartment',
  width: 150,
  renderCell: (params) => {
    return (
      <Typography variant="subtitle1" fontWeight={500}>{params.value?.texts?.name || '-'}</Typography>
    )
  }
}
