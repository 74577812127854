import { Box, IconButton, Typography } from '@mui/material'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { themeIcons } from '@theme/theme'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { upperFirstLetter } from '@utils/format'

import { TraineeDetailLearningProgress } from '../components/details/TraineeDetailLearningProgress'
import { TraineeDetailDuelStatistic } from '../components/details/TraineeDetailDuelStatistic'
import { TraineesDetailProvider, useTraineesDetailContext } from '../provider/TraineeDetailProvider'
import { TraineeDetailPersonalInformation } from '../components/details/TraineeDetailPersonalInformation'
import { TraineeDetailDryRun } from '../components/details/TraineeDetailDryRun'

const TraineesDetailViewContent: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { traineeData, loading } = useTraineesDetailContext()

  const fullName = React.useMemo<string>(() => {
    if (!traineeData) {
      return ''
    }

    return `${upperFirstLetter(traineeData.firstname)} ${upperFirstLetter(traineeData.lastname)}`
  }, [traineeData])

  return (
    <DefaultLayout
      title={fullName || t('routes.traineesDetail')}
      description={t('trainees.detailDescription')}
      loading={loading}
      contentContainer={{
        sx: {
          p: 0,
          '.MuiLinearProgress-root': {
            mx: 4.5
          }
        }
      }}
      headerExtension={
        <Box>
          {can('users:writeStudents') && (
          <IconButton
            onClick={() => navigate('edit')}
          >
            <themeIcons.Edit />
          </IconButton>
          )}
        </Box>
      }
    >
      {traineeData && traineeData.profession
        ? (
          <Box
            sx={{
              height: '100%',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box sx={{
              overflow: 'auto',
              height: '100%',
              p: 4.5
            }}
            >
              <TraineeDetailPersonalInformation />
              <Box sx={{
                mt: 2
              }}
              >
                <TraineeDetailLearningProgress />
              </Box>
              <Box sx={{
                mt: 2
              }}
              >
                <TraineeDetailDryRun />
              </Box>
              <Box sx={{
                mt: 2
              }}
              >
                <TraineeDetailDuelStatistic />
              </Box>
            </Box>
          </Box>
          )
        : (
          <Box sx={{
            p: 4.5,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <Typography variant="h4">
              {t('trainees.missingProfession')}
            </Typography>
          </Box>
          )}
    </DefaultLayout>
  )
}

export const TraineesDetailView: React.FC = () => {
  return (
    <TraineesDetailProvider>
      <TraineesDetailViewContent />
    </TraineesDetailProvider>
  )
}
