import { Divider, Box } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { t } from 'i18next'
import React from 'react'

export const TopicCreateEditInputs: React.FC = () => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseTextInput
        formKey='texts.title'
        options={{ required: true }}
        title={t('common.title')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='trainingYear'
        options={{ required: true, max: 3, min: 1 }}
        title={t('topics.trainingYear')}
        description={t('topics.trainingYearDescription', { min: 1, max: 3 })}
        type='number'
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='index'
        options={{ required: true }}
        title={t('topics.index')}
        description={t('topics.indexDescription')}
        type='number'
      />

    </Box>
  )
}
