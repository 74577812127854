import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import { CompanyCreditsMinimalFragment } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  jobBasicCredits: CompanyCreditsMinimalFragment[]
  jobExtensionCredits: CompanyCreditsMinimalFragment[]
}

export const JobCreditExtension: React.FC<Props> = ({ jobBasicCredits, jobExtensionCredits }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}
    >
      <Tooltip title={t('credits.jobBasicCredits')}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <themeIcons.Coins sx={{ mr: 0.5 }} />
          <Typography variant="subtitle1" fontWeight={500}>{jobBasicCredits?.length || 0}</Typography>
        </Box>
      </Tooltip>
      <Divider light
        orientation="vertical"
        sx={{
          mx: 1.5,
          height: '20px'
        }}
      />
      <Tooltip title={t('credits.jobExtensionCredits')}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <themeIcons.Refresh sx={{ mr: 0.5 }} />
          <Typography variant="subtitle1" fontWeight={500}>{jobExtensionCredits?.length || 0}</Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}
