import React from 'react'
import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

import { themeIcons } from '../icons'

export type MuiCheckboxType = {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
}

export const MuiCheckbox:MuiCheckboxType = {
  defaultProps: {
    disableRipple: true,
    checkedIcon: <themeIcons.Check />,
    icon: <></>,
    indeterminateIcon: <themeIcons.Remove />
  },
  styleOverrides: {
    root: {
      padding: '0',
      width: '20px',
      height: '20px',
      border: '1px solid',
      borderRadius: '6px',
      borderColor: palette.grey?.[400],
      '&:hover': {
        borderColor: palette.primary.main,
        backgroundColor: palette.primary[200]
      },
      '&.Mui-checked': {
        borderColor: palette.primary.main,
        backgroundColor: palette.primary[200]
      },
      '.MuiSvgIcon-root': {
        width: '14px',
        height: '14px',
        color: palette.primary[900]
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    }
  }
}
