import { BaseColumnType } from '@shared/dataGrid/types/types'
import { GridColDef } from '@mui/x-data-grid-pro'

import { QuestionRowModel } from '../provider/QuestionsProvider'

import { titleColumnModel } from './columns/titleColumn'
import { typeColumnModel } from './columns/typeColumn'
import { inDuelAvailableColumnModel } from './columns/inDuelAvailableColumn'
import { questionGroupColumnModel } from './columns/questionGroupColumn'
import { questionGroupOrderColumnModel } from './columns/questionGroupOrderColumn'
import { reviewStatusColumnModel } from './columns/reviewStatusColumn'
import { keyColumnModel } from './columns/keyColumn'

export type QuestionsColumnField = keyof QuestionRowModel

export type QuestionsColumnType = BaseColumnType | Omit<GridColDef, 'field'> & {
  field: QuestionsColumnField,
  hideHeader?: boolean,
}

export const questionsDataGridColumns: QuestionsColumnType[] = [
  keyColumnModel,
  typeColumnModel,
  titleColumnModel,
  inDuelAvailableColumnModel,
  reviewStatusColumnModel,
  questionGroupColumnModel,
  questionGroupOrderColumnModel
]
