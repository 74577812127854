import React from 'react'
import { DashboardView } from '@features/dashboard/views/DashboardView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'

import { AppRouteObject } from '../router'

export const dashboardRoutes: AppRouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    meta: {
      name: 'dashboard',
      navItem: true,
      navIcon: 'Home',
      navOrder: 0
    },
    children: [{
      index: true,
      element: <DashboardView />
    }]
  }
]
