import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  CompanyCreditType,
  JobsQuery, MyCompanyCreditsQuery,
  useActivateJobMutation,
  useDeleteJobMutation, useDeleteJobsMutation, useExtendJobMutation, useJobsQuery, useMyCompanyCreditsQuery
} from '@typings/graphql'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export type JobType = Exclude<JobsQuery['jobs'], null | undefined>[0]
export type JobsRowModel = Omit<JobType, '__typename'>

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

export type JobWallProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: JobsRowModel | null
  jobData: JobsRowModel[]
  loading: boolean
  myCompany?: MyCompanyCreditsQuery['myCompany']
  handleJobStatusChange: (id: JobsRowModel, credit: CompanyCreditType) => void
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: JobsRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

const JobWallProviderContext = React.createContext<JobWallProviderContextType>(
  {} as any
)

export const JobWallProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()

  const [deleteJobs] = useDeleteJobsMutation()
  const [deleteJob] = useDeleteJobMutation()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<JobsRowModel | null>(null)
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)
  const [toastSuccessMessage, setToastSuccessMessage] = React.useState<string>('')
  const [showToast, setShowToast] = React.useState(false)
  const [showCreditDialog, setShowCreditDialog] = React.useState(false)
  const [creditType, setCreditType] = React.useState<CompanyCreditType | null>(null)
  const [changeJobStateLoading, setChangeJobStateLoading] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()

  const deleteCallbackRef = React.useRef<() => void>()

  const { data, loading: dataLoading, refetch } = useJobsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !!disableFetching
  })

  const { data: creditData, loading: creditLoading, refetch: refetchMyCompany } = useMyCompanyCreditsQuery()

  const [activateJob] = useActivateJobMutation()
  const [extendJob] = useExtendJobMutation()

  const myCompany = React.useMemo(() => {
    return creditData?.myCompany || undefined
  }, [creditData])

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo<boolean>(() => {
    return artificialLoading || dataLoading || creditLoading
  }, [artificialLoading, dataLoading, creditLoading])

  const jobData = React.useMemo(() => {
    return data?.jobs.map((job) => ({
      ...job
    }
    )) || []
  }, [data])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const updateSelectedItem = React.useCallback((row: JobsRowModel | null) => {
    setClickedRow(row)
  }, [])

  const handleDelete = useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteJobs({
          variables: {
            data: {
              jobIds: selectedIds as string[]
            }
          }
        })
        setToastSuccessMessage(t('jobwall.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteJob({
          variables: {
            id: selectedItem.id
          }
        })
        setToastSuccessMessage(t('jobwall.deleteSuccess'))
      }

      setClickedRow(null)
      updateSelectedIds([])

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const handleJobStatusChange = useCallback(async (row: JobsRowModel, credit: CompanyCreditType) => {
    setShowCreditDialog(true)
    setClickedRow(row)
    setCreditType(credit)
  }, [])

  const confirmCreditDialog = useCallback(async () => {
    if (!selectedItem) {
      return
    }

    setErrorMessage('')

    setChangeJobStateLoading(true)

    try {
      switch (creditType) {
        case CompanyCreditType.JobBasic:
          await activateJob({
            variables: {
              id: selectedItem?.id
            }
          })
          setToastSuccessMessage(t('jobwall.jobActivated', { name: selectedItem?.texts?.name }))
          break
        case CompanyCreditType.JobExtension:
          await extendJob({
            variables: {
              id: selectedItem?.id
            }
          })
          setToastSuccessMessage(t('jobwall.jobExtended', { name: selectedItem?.texts?.name }))
          break
      }

      refetchMyCompany()
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setChangeJobStateLoading(false)
      setShowCreditDialog(false)
    }
  }, [selectedItem, creditType])

  const value = React.useMemo<JobWallProviderContextType>(() => ({
    selectedIds,
    selectedItem,
    jobData,
    loading,
    myCompany,
    handleJobStatusChange,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [selectedIds, selectedItem, jobData, loading, myCompany])

  return (
    <JobWallProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'jobwall.deleteEntries' : 'jobwall.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.texts?.name } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <ConfirmDialog
        title={creditType === CompanyCreditType.JobBasic ? t('jobwall.activateJob') : t('jobwall.extendJob')}
        onCancel={() => setShowCreditDialog(false)}
        onConfirm={confirmCreditDialog}
        loading={changeJobStateLoading}
        open={showCreditDialog}
      >
        <Trans
          i18nKey={creditType === CompanyCreditType.JobBasic
            ? (myCompany?.isPartner ? 'jobwall.activateJobConfirmPartner' : 'jobwall.activateJobConfirm')
            : (myCompany?.isPartner ? 'jobwall.extendJobConfirmPartner' : 'jobwall.extendJobConfirm')}
          values={{ name: selectedItem?.texts?.name }}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || toastSuccessMessage}
      />
    </JobWallProviderContext.Provider>
  )
}

export const useJobsContext = () => React.useContext(JobWallProviderContext)
