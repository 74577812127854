import { BaseSearchInput, SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { FilterOperators, useFilterUsersQuery } from '@typings/graphql'
import { Roles } from '@typings/roles'
import { isRole } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MIN_VALUE_LENGTH = 3

type Props = {
  title?: string
  description?: string
  additionalFilters?: {
    field: string
    value: string | string[]
    operator: FilterOperators
  }[]
  roles?: Roles[]
  canRead?: boolean
}

export const UserSearch:React.FC<Props> = ({ title, description, additionalFilters, roles, canRead }) => {
  const [queriedUsers, setQueriedUsers] = React.useState<SearchInputItem[]>([])
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation()

  const { fetchMore } = useFilterUsersQuery({
    skip: true
  })

  const handleInputChange = React.useCallback(async (value: string) => {
    setLoading(true)

    if (!value || value.length < MIN_VALUE_LENGTH) {
      setQueriedUsers([])
      setLoading(false)

      return
    }

    const defaultFilter = {
      field: 'email',
      value: value.toLowerCase(),
      operator: FilterOperators.Contains
    }
    const filters = additionalFilters
      ? [defaultFilter, ...additionalFilters]
      : [defaultFilter]

    const { data } = await fetchMore({
      variables: {
        filter: filters,
        roles
      }
    })

    const users = data?.filterUsers?.map((user) => ({
      id: user.id,
      name: user.email
    }))

    setQueriedUsers(users || [])

    setLoading(false)
  }, [])

  return (
    <BaseSearchInput
      formKey="users"
      title={title || t('common.users')}
      description={description}
      onInputChange={handleInputChange}
      loading={loading}
      cantRead={!isRole(Roles.ADMIN) && !canRead}
      minLength={MIN_VALUE_LENGTH}
      searchItems={queriedUsers}
    />
  )
}
