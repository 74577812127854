import React from 'react'
import { Button, Tooltip, Typography } from '@mui/material'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { QuestionGroupRowModel } from '@features/questionGroups/provider/QuestionGroupsProvider'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionGroupsColumnType } from '../questionGroupsDataGridColumns'

const RenderCell: React.FC<{row: QuestionGroupRowModel}> = ({ row }) => {
  const navigate = useNewTabHook()

  return (
    <Tooltip title={row?.texts.title}>
      <Button
        variant="text"
        sx={{
          '&.MuiButton-root': {
            paddingLeft: 0,
            paddingRight: 0,
            justifyContent: 'flex-start'
          }
        }}
        onClick={() => navigate(`${row.id}`)}
      >
        <Typography
          variant="subtitle1"
          fontWeight={500}
        >{row?.texts.title}</Typography>
      </Button>
    </Tooltip>
  )
}

export const titleColumnModel: QuestionGroupsColumnType = {
  field: 'title',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter: (params) => params.row?.texts?.title,
  renderCell: (params) => (
    <RenderCell row={params.row} />
  )
}
