import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiAccordionType = {
  defaultProps?: ComponentsProps['MuiAccordion'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordion'];
  variants?: ComponentsVariants['MuiAccordion'];
}

export const MuiAccordion:MuiAccordionType = {
  styleOverrides: {
    root: {
      '&.Mui-expanded': {
        minHeight: 'auto'
      },

      '.MuiAccordionDetails-root': {
        padding: '4px 0'
      },

      '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        top: 0,
        right: 0
      },

      '.MuiAccordionSummary-root': {
        padding: '4px 0',
        minHeight: 'auto',

        '&.Mui-expanded': {
          minHeight: 'auto'
        },

        '.MuiAccordionSummary-content': {
          margin: 0
        }
      }
    }
  }
}
