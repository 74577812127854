import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'

export type MuiIconButtonType = {
  defaultProps?: ComponentsProps['MuiIconButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
  variants?: ComponentsVariants['MuiIconButton'];
}

export const MuiIconButton:MuiIconButtonType = {
  styleOverrides: {
    root: {
    }
  }
}
