import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { useNavigate, useParams } from 'react-router'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { CategoryRowModel, useCategoriesContext } from '../provider/CategoriesProvider'

import { CategoriesColumnType, categoriesDataGridColumns } from './categoriesDataGridColumns'

export const CategoriesDataGrid: React.FC = () => {
  const { t } = useTranslation()
  const { categoryId, professionId } = useParams<{ categoryId: string, professionId: string }>()
  const navigate = useNavigate()
  const navigateNewTab = useNewTabHook()

  const {
    updateSelectedIds, handleDelete, parentCategoryId,
    updateSelectedItem, loading, categoryData
  } = useCategoriesContext()

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: CategoryRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const columnData = React.useMemo<CategoriesColumnType[]>(() => {
    return [...categoriesDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params) => (
        <ActionColumnRenderCell
          {...(can('categories:read') && { onShow: () => navigate(`${params.row.id}`) })}
          {...(can(['categories:write', 'categories:write.partial']) && { onEdit: () => navigateNewTab(`${params.row.id}/edit`) })}
          {...(can('categories:delete') && { onDelete: () => handleDeleteRow(params.row) })}
        />
      )
    } as CategoriesColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`categories.column.${column.field}`),
        ...column
      }
    })
  }, [parentCategoryId])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey={categoryId ? `sub-categories-${categoryId}` : (professionId ? `categories-${professionId}` : 'categories')}
      rows={categoryData}
      loading={loading}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
