import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiFormLabelType = {
  defaultProps?: ComponentsProps['MuiFormLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormLabel'];
  variants?: ComponentsVariants['MuiFormLabel'];
}

export const MuiFormLabel:MuiFormLabelType = {
  styleOverrides: {
    root: {
      color: palette.text?.primary,
      '&.MuiInputLabel-outlined': {
        color: palette.grey[500],
        '&.MuiInputLabel-shrink': {
          top: '1px',
          backgroundColor: 'white',
          color: palette.text?.primary
        }
      }
    }
  }
}
