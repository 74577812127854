import { Box, Button, useTheme } from '@mui/material'
import { LegalTextModal } from '@shared/components/modals/LegalTextModal'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const [showTerms, setShowTerms] = React.useState(false)
  const [showImprint, setShowImprint] = React.useState(false)

  const logoUrl = `/${import.meta.env.REACT_APP_ID}/logo.svg`

  return (
    <Box height="100%"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'grey.A100',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          width: '50%',
          maxHeight: '85%',
          backgroundColor: 'white',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
          [breakpoints.down('md')]: {
            width: '95%'
          }
        }}
      >
        <Box sx={{
          overflowY: 'auto',
          width: '75%',
          p: 6,
          [breakpoints.down('xl')]: {
            width: '95%'
          },
          [breakpoints.down('md')]: {
            width: '95%',
            px: 2,
            py: 4,
            maxHeight: 'unset',
            overflowY: 'auto'
          }
        }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
          >
            <img src={logoUrl} alt="logo" height="80" />
          </Box>
          {children}
        </Box>
      </Box>

      <Box sx={{
        position: 'absolute',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [breakpoints.down('md')]: {
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          bottom: 0,
          pt: 2,
          '.MuiButton-root': {
            paddingTop: 0,
            paddingBottom: 0
          }
        },
        '.MuiButton-root': {
          paddingTop: '4px',
          paddingBottom: '4px',
          color: 'grey.500',
          height: 'auto',
          minHeight: 'auto',
          '&:hover': {
            color: 'grey.300'
          }
        },
        bottom: 30
      }}
      >
        <Button variant="text" onClick={() => setShowImprint(true)}>
          { t('common.imprint') }
        </Button>
        <Button variant="text" onClick={() => setShowTerms(true)}>
          { t('common.terms') }
        </Button>
      </Box>

      <LegalTextModal
        legalKey='terms'
        open={showTerms}
        onClose={() => setShowTerms(false)}
        onConfirm={() => setShowTerms(false)}
      />

      <LegalTextModal
        legalKey='imprint'
        open={showImprint}
        onClose={() => setShowImprint(false)}
        onConfirm={() => setShowImprint(false)}
      />
    </ Box>
  )
}
