export type YearData = {
  timestamp: Date,
  value: number
}

export const sumYearData = (data: Record<string, number[][]>): number[][] => {
  const values = Object.values(data)

  const summedValues: number[][] = []

  values.forEach((item) => {
    item.forEach((month, monthIndex) => {
      if (!summedValues[monthIndex]) {
        summedValues[monthIndex] = []
      }

      summedValues[monthIndex] = month.map((value, dayIndex) => {
        return value + (summedValues[monthIndex][dayIndex] || 0)
      }
      )
    })
  })

  return summedValues
}

export const mapYearData = (data: number[][]): YearData[] => {
  const date = new Date()

  const relevantData = data.slice(data.length - 12, data.length)

  return relevantData.reduce<YearData[]>((acc, curr, monthIndex) => {
    const values = curr.map((item, dayIndex) => {
      const month = date.getMonth() - ((relevantData.length - 1) - monthIndex)
      const year = date.getFullYear()

      return {
        timestamp: new Date(year, month, dayIndex + 1),
        value: item
      }
    })

    return [...acc, ...values] as YearData[]
  }, [])
}
