import { Box, IconButton, Tooltip } from '@mui/material'
import { actionColumnIconStyles } from '@shared/dataGrid/columns/actionColumn'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Status } from '@typings/graphql'

import { JobsRowModel, useJobsContext } from '../provider/JobWallProvider'

type Props = {
  onActivate?: () => void
  onExtend?: () => void
  row: JobsRowModel
}

export const JobActionExtension: React.FC<Props> = ({ row, onActivate, onExtend }) => {
  const { t } = useTranslation()

  const { myCompany } = useJobsContext()

  const canActivate = React.useMemo(() => {
    const expired = row.expiresAt && new Date(row.expiresAt) < new Date()

    return (row.status !== Status.Active) && (expired || !row.expiresAt) && (myCompany?.isPartner || myCompany?.credits?.jobBasic?.length > 0)
  }, [row, myCompany])

  const canExtend = React.useMemo(() => {
    const expired = row.expiresAt && new Date(row.expiresAt) < new Date()

    return (row.status === Status.Active && row.expiresAt) &&
    (myCompany?.isPartner || myCompany?.credits?.jobExtension?.length > 0) && !expired && !row.extended
  }, [row, myCompany])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}
    >
      {onActivate && canActivate && (
        <Tooltip title={t('jobwall.activateJob')}>
          <IconButton
            onClick={onActivate}
          >
            <themeIcons.Share sx={actionColumnIconStyles} />
          </IconButton>
        </Tooltip>
      )}

      {onExtend && canExtend && (
        <Tooltip title={t('jobwall.extendJob')}>
          <IconButton onClick={onExtend}>
            <themeIcons.Refresh sx={actionColumnIconStyles}/>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
