import { useErrorMessage } from '@hooks/useErrorMessage'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import {
  TopicsQuery,
  useTopicsQuery,
  useDeleteTopicsMutation,
  useDeleteTopicMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export type TopicType = Exclude<TopicsQuery['topics'], null | undefined>[0]
export type TopicRowModel = Omit<TopicType, '__typename'> & {
  title?: string
  topic?: string
}

export type TopicsProviderContextType = {
  selectedIds: GridRowSelectionModel
  selectedItem: TopicRowModel | null
  parentTopicId?: string
  topicData: TopicRowModel[]
  loading?: boolean
  handleDelete: (multiple: boolean, callback?: () => void) => void
  updateSelectedItem: (row: TopicRowModel | null) => void
  updateSelectedIds: (ids: GridRowSelectionModel) => void
}

export type TopicSelectionType = {
  id: string
  name: string
}

type Props = React.PropsWithChildren & {
  disableFetching?: boolean
}

const TopicsProviderContext = React.createContext<TopicsProviderContextType>(
  {} as any
)

export const TopicsProvider:React.FC<Props> = ({ disableFetching, children }) => {
  const { t } = useTranslation()
  const { professionId } = useParams<{ professionId?: string }>()

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([])
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [selectedItem, setClickedRow] = React.useState<TopicRowModel | null>(null)
  const [showToast, setShowToast] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deleteMany, setDeleteMany] = React.useState(false)
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  const deleteCallbackRef = React.useRef<() => void>()

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [deleteTopic] = useDeleteTopicMutation()
  const [deleteTopics] = useDeleteTopicsMutation()

  const { data, loading: topicsLoading, refetch } = useTopicsQuery({
    variables: {
      professionId: professionId as string
    },
    fetchPolicy: 'cache-and-network',
    skip: disableFetching || !professionId
  })

  React.useEffect(() => {
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500)
  }, [])

  const loading = React.useMemo(() => {
    return topicsLoading || artificialLoading
  }, [topicsLoading, artificialLoading])

  const topicData = React.useMemo<TopicRowModel[]>(() => {
    if (data) {
      return data.topics || []
    }

    return []
  }, [data])

  const handleDelete = React.useCallback((multiple: boolean, callback?: () => void) => {
    deleteCallbackRef.current = callback || undefined

    setDeleteMany(multiple)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = React.useCallback(async () => {
    setIsDeleteLoading(true)
    setErrorMessage('')

    try {
      if (selectedIds && deleteMany) {
        await deleteTopics({
          variables: {
            ids: selectedIds as string[]
          }
        })
        setSelectedIds([])
        setSuccessMessage(t('topics.deleteManySuccess'))
      } else if (selectedItem && !deleteMany) {
        await deleteTopic({
          variables: {
            id: selectedItem.id
          }
        })

        setClickedRow(null)
        setSuccessMessage(t('topics.deleteSuccess'))
      }

      if (!disableFetching) {
        await refetch()
      }

      if (deleteCallbackRef.current) {
        deleteCallbackRef.current()

        deleteCallbackRef.current = undefined
      }
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setIsDeleteLoading(false)
      setShowDeleteDialog(false)
    }
  }, [selectedIds, deleteMany, selectedItem])

  const updateSelectedItem = React.useCallback((row: TopicRowModel | null) => {
    setClickedRow(row)
  }, [])

  const updateSelectedIds = React.useCallback((ids: GridRowSelectionModel) => {
    setSelectedIds(ids)
  }, [])

  const value = React.useMemo(() => ({
    parentTopicId: professionId,
    topicData,
    loading,
    selectedIds,
    selectedItem,
    handleDelete,
    updateSelectedItem,
    updateSelectedIds
  }), [professionId, data, loading, selectedIds, selectedItem])

  return (
    <TopicsProviderContext.Provider value={value}>
      {children}

      <ConfirmDialog
        title={t('common.deleteEntry')}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={confirmDelete}
        loading={isDeleteLoading}
        open={showDeleteDialog}
      >
        <Trans
          i18nKey={deleteMany ? 'topics.deleteEntries' : 'topics.deleteEntry'}
          {...(!deleteMany ? { values: { name: selectedItem?.title } } : { count: selectedIds.length })}
          components={{ b: <strong /> }}
        />
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || successMessage}
      />
    </TopicsProviderContext.Provider>
  )
}

export const useTopicsContext = () => React.useContext(TopicsProviderContext)
