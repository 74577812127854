import { Divider } from '@mui/material'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const QuestionNumericInput: React.FC = () => {
  const { t } = useTranslation()

  const { control, trigger } = useFormContext()

  const watchedSolution = useWatch({
    name: 'config.solution',
    control
  }) as number

  const watchedDecimal = useWatch({
    name: 'config.numberOfDecimals',
    control
  }) as number

  const checkValueIsValid = React.useCallback((value: number): boolean => {
    if (!watchedDecimal) {
      return true
    }

    const decimals = value?.toString().split('.')[1]?.length ?? 0

    return decimals <= watchedDecimal
  }, [watchedDecimal])

  const validSolution = React.useMemo(() => {
    return checkValueIsValid(watchedSolution)
  }, [watchedSolution, watchedDecimal])

  React.useEffect(() => {
    trigger('config.solution')
  }, [watchedDecimal])

  return (
    <>
      <BaseTextInput
        formKey='config.solution'
        options={{
          required: true,
          valueAsNumber: true,
          validate: {
            validSolution: (value: number) => !value ? false : checkValueIsValid(value)
          },
          deps: ['config.numberOfDecimals']
        }}
        validationText={!validSolution ? t('questions.numberOfDecimalsError', { decimals: watchedDecimal }) : undefined}
        title={t('questions.answer')}
        type='number'
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='config.unit'
        title={t('questions.unit')}
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseTextInput
        formKey='config.numberOfDecimals'
        title={t('questions.numberOfDecimals')}
        description={t('questions.numberOfDecimalsDescription')}
        type="number"
      />

      <Divider
        sx={{
          mb: 3.5,
          mt: 1
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.displayCalculator'
        title={t('questions.displayCalculator')}
        description={t('questions.displayCalculatorDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
    </>
  )
}
