import { MenuItem, Select } from '@mui/material'
import { useGridApiContext, useGridSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro'
import { DATA_GRID_PAGE_SIZES } from '@shared/constants/dataGrid'
import React from 'react'

export const DataGridRowSizeSelection: React.FC = () => {
  const apiRef = useGridApiContext()
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)

  return (
    <Select
      value={pageSize}
      onChange={(event) => apiRef.current.setPageSize(event.target.value as number)}
      sx={{
        '&.MuiInputBase-root': {
          padding: '8px 12px!important'
        }
      }}
    >
      {DATA_GRID_PAGE_SIZES.map((size) => (
        <MenuItem key={size} value={size}>
          {size}
        </MenuItem>
      ))}
    </Select>
  )
}
