import React from 'react'
import { StatusChip } from '@shared/components/ui/StatusChip'
import i18n from '@services/i18n/i18n'
import { firstLetterToLowerCase } from '@utils/string'

import { BaseColumnType } from '../types/types'

export const statusColumn: BaseColumnType = {
  field: 'status',
  width: 100,
  filterable: false,
  valueGetter: (params) => i18n.t(`status.${firstLetterToLowerCase(params.row?.status)}`),
  renderCell: (params) => (<StatusChip value={params.row.status} />)
}
