import { Box } from '@mui/material'
import {
  UpdateTopicInput,
  useTopicQuery,
  useUpdateTopicMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import i18n from '@services/i18n/i18n'
import { EditableFile } from '@typings/files'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { TopicsProvider, useTopicsContext } from '../provider/TopicsProvider'
import { TopicCreateEditInputs } from '../components/TopicCreateEditInputs'

type TopicInputType = Omit<UpdateTopicInput, 'texts'> & {
  files: Record<string, EditableFile<any> | null>
  texts: Record<string, string>
}

const TopicsEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { topicId } = useParams<{ topicId: string }>()

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()
  const { handleDelete, updateSelectedItem } = useTopicsContext()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateTopic] = useUpdateTopicMutation()

  const { data, loading: topicLoading } = useTopicQuery({
    variables: {
      id: topicId as string
    },
    skip: !topicId
  })

  React.useEffect(() => {
    if (data?.topic) {
      reset({
        trainingYear: data.topic.trainingYear,
        index: data.topic.index,
        texts: data.topic.texts
      })
    }
  }, [data])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateTopic({
        variables: {
          id: topicId as string,
          data: {
            trainingYear: input.trainingYear,
            index: input.index,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      setShowToast(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setShowToast(true)
      setMessageByCode(e)
    }
  })

  const deleteCallback = () => {
    setDeleted(true)
    navigate(location.pathname.replace(`/${data?.topic.id}/edit`, ''))
  }

  const onDelete = async () => {
    if (!data?.topic) {
      return
    }

    updateSelectedItem(data.topic)
    handleDelete(false, deleteCallback)
  }

  return (
    <CreateEditLayout
      title={t('topics.editTitle')}
      description={
        <Trans
          i18nKey={'topics.editDescription'}
          values={{ name: data?.topic?.texts?.title }}
          components={{ b: <strong /> }}
        />
      }
      loading={topicLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <TopicCreateEditInputs />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('topics.entryEdited', { name: data?.topic?.texts?.title })}
      />
    </CreateEditLayout>
  )
}

export const TopicsEditView:React.FC = () => {
  const formData = useForm<TopicInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <TopicsProvider>
      <FormProvider {...formData}>
        <TopicsEditViewContent />
      </FormProvider>
    </TopicsProvider>
  )
}
