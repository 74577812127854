import React from 'react'
import { Button } from '@mui/material'
import { themeIcons } from '@theme/theme'
import { useTranslation } from 'react-i18next'
import { can, isRole } from '@utils/permissions'
import { useNavigate } from 'react-router'
import { Roles } from '@typings/roles'

import { useDashboardContext } from '../provider/DashboardProvider'
import { DashboardTabTypes } from '../tabs/DashboardTabs'

const HIDE_ON: DashboardTabTypes[] = [DashboardTabTypes.General]

export const DashboardCreateButton: React.FC = () => {
  const { t } = useTranslation()
  const { selectedTab, availableTabs } = useDashboardContext()
  const navigate = useNavigate()

  const canCreate = React.useMemo(() => {
    const permission = availableTabs.find(tab => tab.key === selectedTab)?.createPermission || null

    return (!permission || can(permission)) && isRole(Roles.COMPANY)
  }, [selectedTab, availableTabs])

  const handleNavigate = () => {
    switch (selectedTab) {
      case DashboardTabTypes.Jobs:
        navigate('/jobwall/create')
        break
      case DashboardTabTypes.Products:
        navigate('/products/create')
        break
      default:
        break
    }
  }

  return !selectedTab || HIDE_ON.includes(selectedTab) || !canCreate
    ? null
    : (
      <Button startIcon={<themeIcons.Add />} onClick={handleNavigate}>
        {t(`dashboard.create.${selectedTab}`)}
      </Button>
      )
}
