import { Divider, Box } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { BaseSingleFileInput } from '@shared/components/inputs/BaseSingleFileInput'
import { useFormContext, useWatch } from 'react-hook-form'
import { CreateQuestionInput, QuestionType, useTopicsQuery } from '@typings/graphql'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { BaseSelectInput } from '@shared/components/inputs/BaseSelectInput'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseMultiFileInput } from '@shared/components/inputs/BaseMultiFileInput'
import { useLocation } from 'react-router'

import { useQuestionsContext } from '../provider/QuestionsProvider'

import { questionTypeExtensions } from './questionTypeExtensions'
import { QuestionsGroupOrderInput } from './QuestionsGroupOrderInput'
import { QuestionTopicInputs } from './QuestionTopicInputs'

type Props = React.PropsWithChildren & {
  loading?: boolean
}

export const QuestionsCreateEditInputs: React.FC<Props> = ({ loading }) => {
  const location = useLocation()

  const { categoryData, professionId } = useQuestionsContext()

  const isTopics = React.useMemo(() => location.pathname.includes('topics'), [location])

  const { control, setValue } = useFormContext<CreateQuestionInput & {professionId: string}>()

  const { data: topicsData } = useTopicsQuery({
    fetchPolicy: 'network-only',
    variables: {
      professionId: professionId as string
    },
    skip: !professionId || isTopics
  })

  const mappedTopics = React.useMemo(() => {
    if (!topicsData) {
      return []
    }

    return topicsData?.topics.map((topic) => ({
      id: topic.id,
      name: `${t('common.topicWithIndex', { index: topic.index })}: 
      ${topic.texts.title} (${t('common.trainingYearWithNumber', { year: topic.trainingYear })})`
    }))
  }, [topicsData])

  const mappedQuestionGroups = React.useMemo(() => {
    if (!categoryData) {
      return []
    }

    return categoryData.questionGroups.map((questionGroup) => ({
      id: questionGroup.id,
      name: questionGroup.texts.title
    }))
  }, [categoryData])

  const watchedQuestionType = useWatch({
    name: 'type',
    control
  })

  const watchedQuestionGroup = useWatch({
    name: 'questionGroupId',
    control
  })

  const watchedDuel = useWatch({
    name: 'inDuelAvailable',
    control
  })

  React.useEffect(() => {
    if (!watchedQuestionGroup) {
      setValue('questionGroupOrder', null, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }, [watchedQuestionGroup])

  const TypeBasedInputs = React.useMemo(() => {
    if (!watchedQuestionType) {
      return null
    }

    return questionTypeExtensions[watchedQuestionType]
  }, [watchedQuestionType])

  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <BaseMarkDownEditor
        formKey='texts.text'
        options={{ required: true }}
        title={t('questions.questionText')}
        {...(watchedQuestionType === QuestionType.Assignment && { description: t('questions.explanationAssignmentDescription') })}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />
      <BaseMarkDownEditor
        formKey='texts.explanation'
        title={t('common.explanation')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSingleFileInput
        formKey="files.explanation"
        fileKey="explanation"
        title={t('questions.explanationFile')}
        description={t('questions.explanationFileDescription')}
        acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg', '.png', '.webp'], 'application/pdf': ['.pdf'], 'video/*': ['.mp4'] }}
        maxFileSize={10485760}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      {TypeBasedInputs && <TypeBasedInputs />}

      <BaseSwitchInput
        formKey='inDuelAvailable'
        disabled={!!watchedQuestionGroup}
        title={t('questions.inDuelAvailable')}
        description={t('questions.inDuelAvailableDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      {!!categoryData && !isTopics && mappedQuestionGroups.length > 0 && (
      <>
        <BaseSelectInput
          formKey="questionGroupId"
          title={t('questions.questionGroup')}
          description={t('questions.questionGroupDescription')}
          options={{
            setValueAs: (value) => {
              if (value) {
                return value.id
              }

              return null
            }
          }}
          disabled={watchedDuel}
          items={mappedQuestionGroups}
        />

        <Divider
          sx={{
            my: 3.5
          }}
          light
          orientation='horizontal'
        />

        {!!watchedQuestionGroup && (
        <>
          <QuestionsGroupOrderInput
            questionGroupId={watchedQuestionGroup}
          />

          <Divider
            sx={{
              my: 3.5
            }}
            light
            orientation='horizontal'
          />
        </>
        )}
      </>
      )}

      {!!mappedTopics.length && (!isTopics || !categoryData) && (
        <>
          <BaseSelectInput
            formKey="topicId"
            title={t('common.topic')}
            description={t('questions.topicDescription')}
            items={mappedTopics}
          />

          <Divider
            sx={{
              my: 3.5
            }}
            light
            orientation='horizontal'
          />
        </>
      )}

      { (isTopics || !categoryData) && (
        <QuestionTopicInputs />
      )}

      <BaseSingleFileInput
        formKey="files.video"
        fileKey="video"
        title={t('common.video')}
        description={t('questions.videoDescription')}
        acceptedFileTypes={{ 'video/*': ['.mp4'] }}
        maxFileSize={10485760}
        loading={loading}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseMultiFileInput
        formKey="attachments"
        fileKey="file"
        title={t('common.attachments')}
        description={t('common.attachmentsDescription')}
        acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg', '.png', '.webp'], 'application/pdf': ['.pdf'], 'video/*': ['.mp4'] }}
        maxFileSize={10485760}
        loading={loading}
      />
    </Box>
  )
}
