import { Box, Checkbox, Grid, Link } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { mailPattern, postalPattern } from '@utils/pattern'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { PasswordInput } from '@shared/components/ui/PasswordInput'
import { LegalTextModal } from '@shared/components/modals/LegalTextModal'

import { RegistrationInput } from '../views/RegistrationView'

export const RegistrationForm: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  // const [showPrivacy, setShowPrivacy] = React.useState(false)
  const [showTerms, setShowTerms] = React.useState(false)

  const { control } = useFormContext<RegistrationInput>()

  return (
    <Box width="100%">
      <form>
        <Box mb={2}>
          <TextInput
            label={t('common.email') as string}
            formKey="email"
            fullWidth
            type='email'
            options={{ required: true, pattern: mailPattern }}
          />
        </Box>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <TextInput
              label={t('common.firstName') as string}
              formKey="firstname"
              fullWidth
              options={{ required: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={t('common.lastName') as string}
              formKey="lastname"
              fullWidth
              options={{ required: true }}
            />
          </Grid>
        </Grid>

        {children}
        <Box mb={2}>
          <TextInput
            label={t('common.address') as string}
            formKey="street"
            fullWidth
            options={{ required: true }}
          />
        </Box>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <TextInput
              label={t('common.postalCode') as string}
              formKey="postalCode"
              fullWidth
              options={{ required: true, pattern: postalPattern }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={t('common.city') as string}
              formKey="city"
              fullWidth
              options={{ required: true }}
            />
          </Grid>
        </Grid>

        <PasswordInput
          formKey="password"
          formKeyConfirm='passwordConfirm'
        />

        <Box mb={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Controller
            name="acceptTerms"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                sx={{ mr: 1, flex: '0 1 auto' }}
              />
            )}
          />
          <Trans
            i18nKey="auth.acceptTerms"
            components={{
              terms: <Link ml={0.5} sx={{ cursor: 'pointer' }} onClick={() => setShowTerms(true)} />
            }}
          />
        </Box>
      </form>

      {
        /**
        <LegalTextModal
          legalKey='privacy'
          open={showPrivacy}
          onClose={() => setShowPrivacy(false)}
          onConfirm={() => setShowPrivacy(false)}
        />
        */
      }

      <LegalTextModal
        legalKey='terms'
        open={showTerms}
        onClose={() => setShowTerms(false)}
        onConfirm={() => setShowTerms(false)}
      />
    </Box>
  )
}
