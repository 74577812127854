import React from 'react'
import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { themeIcons } from '@theme/icons'

export type MuiAlertType = {
  defaultProps?: ComponentsProps['MuiAlert'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlert'];
  variants?: ComponentsVariants['MuiAlert'];
}

export const MuiAlert:MuiAlertType = {
  defaultProps: {
    iconMapping: {
      error: <themeIcons.Error />
    }
  },
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  }
}
