import { MyCompanyQuery, useMyCompanyQuery } from '@typings/graphql'
import React from 'react'

export type MyCompanyProviderContextType = {
  companyData?: MyCompanyQuery['myCompany']
  loading?: boolean
}

const MyCompanyProviderContext = React.createContext<MyCompanyProviderContextType>(
  {} as any
)

export const MyCompanyProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, loading } = useMyCompanyQuery({
    fetchPolicy: 'cache-and-network'
  })

  const companyData = React.useMemo(() => {
    return data?.myCompany
  }, [data])

  const value = React.useMemo(() => ({
    companyData,
    loading
  }), [companyData, loading])

  return (
    <MyCompanyProviderContext.Provider value={value}>
      {children}
    </MyCompanyProviderContext.Provider>
  )
}

export const useMyCompanyContext = () => React.useContext(MyCompanyProviderContext)
