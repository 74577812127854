import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'

type Props = React.PropsWithChildren & {
  title: string
  value?: string
  noDivider?: boolean
  actions?: React.ReactNode
  actionWidth?: number
  actionTop?: boolean
}

export const SimpleDataRow: React.FC<Props> = ({ title, value, children, noDivider, actions, actionWidth, actionTop }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography fontWeight={600}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8 - (actionWidth ? actionWidth - 1 : 0)}>
          {children || (
          <Typography>
            {value}
          </Typography>
          )}
        </Grid>
        <Grid item xs={actionWidth || 1}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: actionTop ? 'flex-start' : 'center',
            height: '100%'
          }}
          >
            {actions}
          </Box>
        </Grid>
      </Grid>

      {!noDivider && <Divider
        sx={{
          my: 2
        }}
        light
        orientation='horizontal'
      />}
    </>
  )
}
