import { Box, Divider, Grid, Typography } from '@mui/material'
import { BaseButtonGroupInput } from '@shared/components/inputs/BaseButtonGroupInput'
import { BaseSwitchInput } from '@shared/components/inputs/BaseSwitchInput'
import { TextInput } from '@shared/components/inputs/TextInput'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { DefaultAnswerRow } from '@shared/components/ui/DefaultAnswerRow'
import { InputTitleField } from '@shared/components/ui/InputRequiredIndicator'
import React from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Answer = {
  id: number
  textKey?: string
  correct: boolean
}

export const QuestionChoiceInput: React.FC = () => {
  const { t } = useTranslation()

  const [answers, setAnswers] = React.useState<Answer[]>([])

  const {
    control,
    getValues,
    setValue
  } = useFormContext()

  const { field: solutionField } = useController({
    name: 'config.solution',
    control,
    defaultValue: [],
    rules: {
      validate: (value) => value.length > 0
    }
  })

  const watchedSolution = React.useMemo(() => {
    return solutionField.value as number[]
  }, [solutionField.value])

  const watchedMultiple = useWatch({
    name: 'config.multiple',
    control,
    defaultValue: false
  })

  React.useEffect(() => {
    const solution = getValues('config.solution')
    const texts = getValues('texts')

    setValue('config.multiple', getValues('config.multiple') || false)

    const answerTextKeys = Object.keys(texts).filter((key) => key.startsWith('answer_'))
    answerTextKeys.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    const existingAnswers = answerTextKeys.map((key, index) => {
      const id = parseInt(key.split('_')[1], 10)

      return {
        id,
        textKey: key,
        correct: solution.includes(index)
      }
    })

    setAnswers(existingAnswers)
  }, [])

  const handleOnAddAnswer = () => {
    const sortedAnswers = answers.sort((a, b) => a.id - b.id).map((answer) => answer.id)
    const newAnswerId = sortedAnswers?.[sortedAnswers.length - 1] + 1 || 0

    setAnswers([...answers, {
      id: newAnswerId,
      textKey: `answer_${newAnswerId}`,
      correct: false
    }])
  }

  const setSolution = (newAnswers: Answer[]) => {
    setValue('config.solution', newAnswers.sort((a, b) => a.id - b.id).filter((answer) => answer.correct)
      .map((filtered) => newAnswers.findIndex((item) => filtered.id === item.id)), {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleRemoveAnswer = React.useCallback((value: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.filter((item) => item.id !== value)

      setSolution(newAnswers)

      return newAnswers
    })

    const texts = getValues('texts')

    delete texts[`answer_${value}`]

    setValue('texts', texts, {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [answers])

  const handleSwitchChange = React.useCallback((value: number) => {
    setAnswers((prev) => {
      const newAnswers = prev.map((item) => {
        return {
          ...item,
          correct: value === item.id ? !item.correct : (watchedMultiple ? item.correct : false)
        }
      })

      setSolution(newAnswers)

      return newAnswers
    })
  }, [watchedMultiple])

  return (
    <>
      <BaseButtonGroupInput
        formKey='config.multiple'
        title={t('questions.types.multipleChoice')}
        items={[{
          id: 'single',
          label: t('questions.types.singleChoice'),
          value: false,
          disabled: watchedSolution.length > 1,
          tooltip: t('questions.multipleChoiceTooltip')
        }, {
          id: 'multiple',
          label: t('questions.types.multipleChoice'),
          value: true
        }]}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <Grid container spacing={4}>
        <Grid item xs={3}>
          <InputTitleField
            required={true}
            text={t('questions.answers')}
          />
          <Typography variant="subtitle1">{t('questions.answersDescription')}</Typography>
        </Grid>
        <Grid item xs={8} xl={6}>
          {
          answers?.map(({ id, textKey, correct }) => (
            <Box key={`choice_answer_${id}`} mb={2}>
              <DefaultAnswerRow
                switchLabel={t('questions.correctAnswer')}
                checked={correct}
                onSwitchChange={() => handleSwitchChange(id)}
                onRemove={() => handleRemoveAnswer(id)}
              >
                <TextInput
                  formKey={`texts.${textKey}`}
                  hideValidationText
                  options={{
                    required: true
                  }}
                  sx={{
                    backgroundColor: 'white'
                  }}
                />
              </DefaultAnswerRow>
            </Box>
          ))
        }

          {
            <AddDefaultRow
              label={t('questions.addAnswer')}
              onAdd={handleOnAddAnswer}
            />
        }
        </Grid>
      </Grid>

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.displayCalculator'
        title={t('questions.displayCalculator')}
        description={t('questions.displayCalculatorDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

      <BaseSwitchInput
        formKey='config.shuffle'
        defaultChecked
        title={t('questions.shuffle')}
        description={t('questions.shuffleDescription')}
      />

      <Divider
        sx={{
          my: 3.5
        }}
        light
        orientation='horizontal'
      />

    </>
  )
}
