import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import React from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router'

type UseUnsavedChanges = {
  proceed?: (() => void)
  reset?: (() => void)
}

export const useUnsavedChanges = (when: boolean): UseUnsavedChanges => {
  const { dialogManagerRef } = useDialogContext()

  const blocker = useBlocker(when)

  const triggerDialog = React.useCallback(() => {
    const { proceed, reset } = blocker

    dialogManagerRef?.current?.showDialog(GlobalDialogType.UNSAVED_CHANGES, {
      confirmCallback: proceed,
      cancelCallback: reset
    })
  }, [dialogManagerRef, blocker])

  React.useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return ''
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [when])

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      triggerDialog()
    }
  }, [blocker])

  return {
    proceed: blocker.proceed,
    reset: blocker.reset
  }
}
