import React from 'react'
import { RouteObject } from 'react-router'
import { themeIcons } from '@theme/icons'
import { Roles } from '@typings/roles'

import { traineesRoutes } from './routes/trainees'
import { instructorsRoutes } from './routes/instructors'
import { trainingCompaniesRoutes } from './routes/trainingCompanies'
import { usersRoutes } from './routes/users'
import { companiesRoutes } from './routes/companies'
import { jobWallRoutes } from './routes/jobWall'
import { questionEditorRoutes } from './routes/questionEditor'
import { productsRoutes } from './routes/products'
import { companyRoutes } from './routes/company'
import { errorRoutes } from './routes/error'
import { authRoutes } from './routes/auth'
import { dashboardRoutes } from './routes/dashboard'
import { settingsRoutes } from './routes/settings'
import { questionListRoutes } from './routes/questionList'

type RouteMetaData = {
  navItem?: boolean
  navIcon?: keyof typeof themeIcons
  navOrder?: number
  [key: string]: any
}

type RouteAccessData = {
  roles: Roles[]
  partner?: boolean
}

export type AppRouteObject = RouteObject & {
  children?: AppRouteObject[]
  meta?: RouteMetaData
  access?: RouteAccessData
  breadcrumb?: React.ReactElement
}

export const baseRoutes: AppRouteObject[] = [
  ...dashboardRoutes,
  ...settingsRoutes,
  ...authRoutes,
  ...errorRoutes
]

export const restrictedRoutes: AppRouteObject[] = [
  ...companyRoutes,
  ...questionEditorRoutes,
  ...(import.meta.env.REACT_APP_HAS_PARTNERS === 'true' ? productsRoutes : []),
  ...(import.meta.env.REACT_APP_HAS_JOBS === 'true' ? jobWallRoutes : []),
  ...companiesRoutes,
  ...usersRoutes,
  ...trainingCompaniesRoutes,
  ...instructorsRoutes,
  ...traineesRoutes,
  ...questionListRoutes
]
