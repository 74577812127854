import { Box } from '@mui/material'
import {
  UpdateCategoryInput, useCategoryQuery,
  useDeleteFileMutation, useUpdateCategoryMutation, useUpdateFileMutation
} from '@typings/graphql'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import i18n from '@services/i18n/i18n'
import { EditableFile } from '@typings/files'
import { getFileByKey } from '@utils/files'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { useFileUpload } from '@hooks/useFileUpload'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { CategoriesProvider, useCategoriesContext } from '../provider/CategoriesProvider'
import { CategoryCreateEditInputs } from '../components/CategoryCreateEditInputs'

type CategoryInputType = Omit<UpdateCategoryInput, 'texts'> & {
  files: Record<string, EditableFile<any> | null>
  texts: Record<string, string>
}

const CategoriesEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { categoryId, subCategoryId } = useParams<{ categoryId: string, subCategoryId: string }>()

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { setMessageByCode, errorMessage, setErrorMessage } = useErrorMessage()
  const { handleDelete, updateSelectedItem } = useCategoriesContext()

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateCategory] = useUpdateCategoryMutation()

  const { data, loading: categoryLoading } = useCategoryQuery({
    variables: {
      id: subCategoryId as string || categoryId as string
    },
    skip: !subCategoryId && !categoryId
  })

  const { uploadFile } = useFileUpload()

  const [deleteFile] = useDeleteFileMutation()
  const [updateFile] = useUpdateFileMutation()

  React.useEffect(() => {
    if (data?.category) {
      reset({
        parentId: data.category.parentId,
        texts: data.category.texts,
        files: {
          icon: getFileByKey('icon', data.category.files)
        }
      })
    }
  }, [data])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateCategory({
        variables: {
          id: subCategoryId as string || categoryId as string,
          data: {
            parentId: input.parentId,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            }))
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        const fileId = getFileByKey(key, data?.category?.files)?.id

        if (file?.upload) {
          await uploadFile(
            file.upload.file,
            file.upload.data.key,
            'Category',
            subCategoryId as string || categoryId as string,
            file.config
          )
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        } else if (file && fileId) {
          await updateFile({
            variables: {
              id: fileId as string,
              data: {
                config: file.config
              }
            }
          })
        }
      })

      setShowToast(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setShowToast(true)
      setMessageByCode(e)
    }
  })

  const deleteCallback = () => {
    setDeleted(true)
    navigate(location.pathname.replace(`/${data?.category.id}/edit`, ''))
  }

  const onDelete = async () => {
    if (!data?.category) {
      return
    }

    updateSelectedItem(data.category)
    handleDelete(false, deleteCallback)
  }

  return (
    <CreateEditLayout
      title={t('categories.editTitle')}
      description={
        <Trans
          i18nKey={'categories.editDescription'}
          values={{ name: data?.category?.texts?.title }}
          components={{ b: <strong /> }}
        />
      }
      loading={categoryLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          <DeleteIconButton onClick={onDelete} />
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <CategoryCreateEditInputs loading={loading}/>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('categories.entryEdited', { name: data?.category?.texts?.title })}
      />
    </CreateEditLayout>
  )
}

export const CategoriesEditView:React.FC = () => {
  const formData = useForm<CategoryInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <CategoriesProvider>
      <FormProvider {...formData}>
        <CategoriesEditViewContent />
      </FormProvider>
    </CategoriesProvider>
  )
}
