import { SxProps, Theme } from '@mui/material'
import { ReviewStatus, Status } from '@typings/graphql'
import { palette } from '@theme/core/palette/palette'

export const CHIP_STATUS_COLORS: Record<Status | ReviewStatus, SxProps> = {
  [Status.Active]: {
    backgroundColor: palette.primary[100],
    color: palette.primary[800]
  },
  [Status.Inactive]: {
    backgroundColor: palette.warning[100],
    color: palette.warning[800]
  },
  [Status.Draft]: {},
  [ReviewStatus.Approved]: {
    backgroundColor: palette.success[100],
    color: palette.success[800]
  },
  [ReviewStatus.Rejected]: {
    backgroundColor: palette.error[100],
    color: palette.error[800]
  },
  [ReviewStatus.Review]: {
    backgroundColor: palette.primary[100],
    color: palette.primary[800]
  }
}

export enum ChipPerformanceType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral'
}

export const CHIP_PERFORMANCE_COLORS: Record<ChipPerformanceType, SxProps<Theme>> = {
  [ChipPerformanceType.POSITIVE]: {
    backgroundColor: palette.success[100],
    color: palette.success[800],
    '.MuiSvgIcon-root': {
      color: palette.success.main,
      width: '12px',
      height: '12px'
    }
  },
  [ChipPerformanceType.NEGATIVE]: {
    backgroundColor: palette.error[100],
    color: palette.error[800],
    '.MuiSvgIcon-root': {
      color: palette.error.main,
      width: '12px',
      height: '12px'
    }
  },
  [ChipPerformanceType.NEUTRAL]: {
    backgroundColor: palette.warning[100],
    color: palette.warning[800],
    '.MuiSvgIcon-root': {
      color: palette.warning[800],
      width: '12px',
      height: '12px'
    }
  }
}
