import {
  ComponentsOverrides, Theme
} from '@mui/material'

import { wysiwyg } from './wysiwyg'
import { reactMarkDown } from './markDown'

export type MuiCssBaselineType = {
  styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline'];
}

export const MuiCssBaseline:MuiCssBaselineType = {
  styleOverrides: {
    html: {
      height: '100%',
      body: {
        height: '100%',
        overscrollBehaviorX: 'none',
        overflow: 'hidden',

        '#root': {
          height: '100%'
        }
      },
      ...wysiwyg,
      ...reactMarkDown
    }
  }
}
