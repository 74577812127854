import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type SelectInputItem = {
  id: string | number
  name: string
}

type Props = SelectProps & {
  formKey: string
  inputLabel?: string
  options?: RegisterOptions
  items: SelectInputItem[]
}

export const SelectInput: React.FC<Props> = ({ formKey, options, inputLabel, items, ...props }) => {
  const { t } = useTranslation()
  const { disabled, multiple, ...restProps } = props

  const { control } = useFormContext()

  return (
    <FormControl fullWidth>
      {inputLabel && <InputLabel
        sx={{
          opacity: disabled ? 0.3 : 1
        }}
        id={`${formKey}_label`}
      >{inputLabel}</InputLabel>}
      <Controller
        name={formKey}
        control={control}
        rules={options}
        render={({ field: { onChange, value, ...restFields } }) => (
          <Select fullWidth
            labelId={`${formKey}_label`}
            {...restProps}
            {...restFields}
            disabled={disabled}
            sx={{
              ...(disabled && {
                pointerEvents: 'none'
              })
            }}
            multiple={multiple}
            value={value || (multiple ? [] : '')}
            onChange={onChange}
          >
            {!options?.required && !multiple && (
            <MenuItem value="">
              {t('common.none')}
            </MenuItem>
            )}
            {items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )
          }
      />
    </FormControl>
  )
}
