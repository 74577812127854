import { UserColumnType } from '@features/users/provider/UsersProvider'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

export const playerColumnModel: UserColumnType = {
  field: 'player',
  width: 150,
  sortable: false,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter: (params) => params?.row?.player?.username ?? '-'
}
