import { Box, Typography } from '@mui/material'
import { useAppSelector } from '@services/store/store'
import React from 'react'

type Props = {
  src?: string
}
export const UserAvatar: React.FC<Props> = ({ src }) => {
  const authUser = useAppSelector((state) => state.auth.user)

  const initials = React.useMemo(() => {
    if (!authUser) {
      return ''
    }

    const { firstname, lastname } = authUser

    return `${firstname[0]}${lastname[0]}`
  }, [authUser])

  return (
    <Box sx={{
      width: 40,
      height: 40,
      backgroundColor: 'white',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...(src && {
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      })
    }}
    >
      { initials && !src && (
        <Typography>
          { initials }
        </Typography>
      )}
    </Box>
  )
}
