import { Box } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const RegistrationFormTrainingCompanyExtension: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={2}>
        <TextInput
          label={t('common.trainingCompany') as string}
          formKey="name"
          fullWidth
          options={{ required: true }}
        />
      </Box>
    </>
  )
}
