import { LoadingButton } from '@mui/lab'
import i18n from '@services/i18n/i18n'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import {
  CreateTrainingCompanyInput, useDeleteFileMutation,
  useTrainingCompanyQuery, useUpdateTrainingCompanyMutation
} from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { Box } from '@mui/material'
import { useFileUpload } from '@hooks/useFileUpload'
import { EditableFile } from '@typings/files'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { getFileByKey } from '@utils/files'
import { isRole } from '@utils/permissions'
import { Roles } from '@typings/roles'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'

import { TrainingCompaniesCreateEditInputs } from '../components/TrainingCompaniesCreateEditInputs'
import { TrainingCompaniesProvider, useTrainingCompaniesContext } from '../provider/TrainingCompaniesProvider'

type TrainingCompanyInputType = Omit<CreateTrainingCompanyInput, 'texts'> & {
  texts: Record<string, string>
  files: Record<string, EditableFile<any> | null>
  users: SearchInputItem[]
}

const TrainingCompaniesEditViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const { handleDelete, updateSelectedItem } = useTrainingCompaniesContext()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const { errorMessage, setErrorMessage, setMessageByCode } = useErrorMessage()

  const { uploadFile } = useFileUpload()

  const { data: trainingCompaniesData, loading: trainingCompanyLoading } = useTrainingCompanyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !id
  })

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = useFormContext<TrainingCompanyInputType>()

  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const [updateTrainingCompany] = useUpdateTrainingCompanyMutation()

  const [deleteFile] = useDeleteFileMutation()

  React.useEffect(() => {
    if (trainingCompaniesData?.trainingCompany) {
      reset({
        name: trainingCompaniesData.trainingCompany.name,
        ...(isRole(Roles.ADMIN) && { active: trainingCompaniesData.trainingCompany.active }),
        texts: trainingCompaniesData.trainingCompany.texts,
        address: trainingCompaniesData.trainingCompany.address,
        city: trainingCompaniesData.trainingCompany.city,
        postalCode: trainingCompaniesData.trainingCompany.postalCode,
        provided: trainingCompaniesData.trainingCompany.provided,
        files: {
          logo: getFileByKey('logo', trainingCompaniesData.trainingCompany.files)
        },
        users: trainingCompaniesData.trainingCompany.instructors.map((user) => ({
          id: user.id,
          name: user.email
        })) || []
      })
    }
  }, [trainingCompaniesData])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')
    const { texts, files, users, active, ...rest } = input

    try {
      await updateTrainingCompany({
        variables: {
          id: id as string,
          data: {
            ...rest,
            ...(isRole(Roles.ADMIN) && { active }),
            instructorIds: users.map(({ id: userId }) => userId),
            texts: Object.keys(texts).filter((key) => !!texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: texts[filtered]
            }))
          }
        }
      })

      Object.keys(files)?.forEach(async (key) => {
        const file = files[key]

        const fileId = getFileByKey(key, trainingCompaniesData?.trainingCompany?.files)?.id

        if (file?.upload) {
          await uploadFile(file.upload.file, file.upload.data.key, 'TrainingCompany', id as string)
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        }
      })
    } catch (e) {
      setMessageByCode(e)
    } finally {
      setShowToast(true)
      setLoading(false)
    }
  })

  const handleDeleted = () => {
    setDeleted(true)
    navigate('/training-companies')
  }

  const onDelete = async () => {
    if (!trainingCompaniesData?.trainingCompany) {
      return
    }

    updateSelectedItem(trainingCompaniesData?.trainingCompany)
    handleDelete(false, handleDeleted)
  }

  return (
    <CreateEditLayout
      title={t('routes.trainingCompaniesEdit')}
      description={
        <Trans
          i18nKey={'trainingCompanies.editDescription'}
          values={{ name: trainingCompaniesData?.trainingCompany?.name }}
          components={{ b: <strong /> }}
        />
      }
      loading={trainingCompanyLoading}
      headerExtension={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
        >
          {isRole(Roles.ADMIN) &&
          <>
            <Box mr={2}>
              <StandaloneSwitchInput
                formKey="active"
                label={t('trainingCompanies.column.active')}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <DeleteIconButton onClick={onDelete} />
            </Box>
          </>
          }
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >

      <TrainingCompaniesCreateEditInputs loading={loading} />

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('trainingCompanies.editedCompleted')}
      />
    </CreateEditLayout>
  )
}

export const TrainingCompaniesEditView: React.FC = () => {
  const formData = useForm<TrainingCompanyInputType>({
    defaultValues: {
      active: false
    },
    mode: 'onChange'
  })

  return (
    <TrainingCompaniesProvider disableFetching>
      <FormProvider {...formData}>
        <TrainingCompaniesEditViewContent />
      </FormProvider>
    </TrainingCompaniesProvider>
  )
}
