import { CircularProgress, IconButton, IconButtonProps } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { themeIcons } from '@theme/icons'
import { convertHEXtoRGB } from '@utils/color'
import React from 'react'

type Props = IconButtonProps & {
  loading?: boolean
  icon: keyof typeof themeIcons
}

export const DefaultIconButton:React.FC<Props> = ({ loading, icon, sx, ...props }) => {
  const Icon = React.useMemo(() => themeIcons[icon], [icon])

  return (
    <IconButton
      disabled={loading}
      sx={{
        opacity: loading ? 0.5 : 1,
        minWidth: 'auto',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'primary.500',
        color: 'primary.500',
        boxShadow: `0px 1px 2px 0px ${convertHEXtoRGB(palette?.primary[500] as string)}`,
        p: '11px',
        '.MuiButton-startIcon': {
          m: 0
        },
        '&:hover': {
          backgroundColor: 'primary.100'
        },
        ...sx
      }}
      {...props}
    >
      {loading
        ? (
          <CircularProgress
            size={20}
          />
          )
        : (
          <Icon sx={{
            width: '20px',
            height: '20px'
          }}
          />
          )}
    </IconButton>
  )
}
