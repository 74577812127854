import i18n from '@services/i18n/i18n'

import { JobColumnType } from '../jobDataGridColumns'

export const expiresAtColumnModel: JobColumnType = {
  field: 'expiresAt',
  width: 100,
  filterable: false,
  renderCell: (params) => {
    const date = new Date(params.value as string)

    return params.value
      ? date.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      })
      : '-'
  }
}
