import React from 'react'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { Box } from '@mui/material'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { Trans, useTranslation } from 'react-i18next'
import { useDuplicateQuestionsMutation, useProfessionsWithCategoriesQuery } from '@typings/graphql'
import { SelectInput } from '@shared/components/inputs/SelectInput'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

type SelectionItem = {
  id: string,
  texts: Record<string, string>
}

type Props<T extends SelectionItem> = {
  selectedItem: T | null,
  selectedIds: string[]
  show: boolean,
  manySelected: boolean,
  onCompleted: () => void,
  onCancel: () => void,
}

type DuplicateQuestionFormInput = {
  professionId: string,
  categoryId: string,
  subCategoryId: string,
}

const QuestionDuplicateDialogContent = function<T extends SelectionItem> (props: Props<T>): React.ReactElement {
  const {
    selectedItem,
    selectedIds,
    show,
    manySelected,
    onCompleted,
    onCancel
  } = props

  const { t } = useTranslation()

  const { formState: { isValid, isDirty }, reset, control, resetField, handleSubmit } = useFormContext()

  const { data: professionData } = useProfessionsWithCategoriesQuery()

  const [duplicateQuestions] = useDuplicateQuestionsMutation({
    onCompleted () {
      onCompleted()
    }
  })

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [showToast, setShowToast] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const watchedProfessionId = useWatch({
    control,
    name: 'professionId'
  })

  const watchedCategoryId = useWatch({
    control,
    name: 'categoryId'
  })

  const mappedProfessions = React.useMemo(() => {
    if (!professionData) return []

    return professionData.professions.map((profession) => ({
      id: profession.id,
      name: profession.texts?.title
    }))
  }, [professionData])

  const mappedCategories = React.useMemo(() => {
    resetField('categoryId')
    resetField('subCategoryId')

    const profession = professionData?.professions.find((item) => item.id === watchedProfessionId)

    if (!profession) {
      return []
    }

    return profession.categories.map((category) => ({
      id: category.id,
      name: category.texts?.title
    }))
  }, [watchedProfessionId])

  const mappedSubCategories = React.useMemo(() => {
    resetField('subCategoryId')

    const category = professionData?.professions.filter((item) => item.id === watchedProfessionId)[0]
      ?.categories.find((filtered) => filtered.id === watchedCategoryId)

    if (!category) {
      return []
    }

    return category.childCategories.map((subCategory) => ({
      id: subCategory.id,
      name: subCategory.texts?.title
    }))
  }, [watchedCategoryId])

  const handleCancel = React.useCallback(() => {
    reset({})
    onCancel()
  }, [])

  const handleCopyQuestion = handleSubmit(async (input) => {
    setErrorMessage('')
    setLoading(true)

    try {
      await duplicateQuestions({
        variables: {
          ids: manySelected ? selectedIds as string[] : [selectedItem?.id as string],
          data: {
            categoryId: input.subCategoryId
          }
        }
      })

      handleCancel()
    } catch (error) {
      setMessageByCode(error)
    } finally {
      setShowToast(true)
      setLoading(false)
    }
  })

  return (
    <>
      <ConfirmDialog
        title={t('questions.duplicateQuestion', { count: manySelected ? selectedIds.length : 1 })}
        open={show}
        onCancel={handleCancel}
        onConfirm={handleCopyQuestion}
        confirmProps={{
          loading,
          disabled: !isValid || !isDirty
        }}
      >
        <Trans
          i18nKey={manySelected ? 'questions.duplicateQuestionsDescription' : 'questions.duplicateQuestionDescription'}
          values={{
            name: selectedItem?.texts.text,
            count: selectedIds.length
          }}
          components={{
            b: <strong />
          }}
        />

        <Box sx={{ mt: 3 }}>
          <SelectInput
            items={mappedProfessions}
            inputLabel={t('common.profession')}
            options={{
              required: true
            }}
            formKey="professionId"
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <SelectInput
            items={mappedCategories}
            inputLabel={t('common.category')}
            disabled={!watchedProfessionId}
            options={{
              required: true
            }}
            formKey="categoryId"
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <SelectInput
            items={mappedSubCategories}
            inputLabel={t('common.subCategory')}
            disabled={!watchedCategoryId}
            options={{
              required: true
            }}
            formKey="subCategoryId"
          />
        </Box>
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('questions.questionCopiedSuccessfully')}
      />
    </>
  )
}

export const QuestionDuplicateDialog = function<T extends SelectionItem> (props: Props<T>): React.ReactElement {
  const formData = useForm<DuplicateQuestionFormInput>({
    defaultValues: {
      professionId: '',
      categoryId: '',
      subCategoryId: ''
    },
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <QuestionDuplicateDialogContent {...props}/>
    </FormProvider>
  )
}
