import React from 'react'
import { Typography } from '@mui/material'
import { TrainingCompaniesColumnType } from '@features/trainingCompanies/provider/TrainingCompaniesProvider'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

export const cityColumnModel: TrainingCompaniesColumnType = {
  field: 'city',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => (
    <Typography variant="subtitle1" fontWeight={500}>{params.value}</Typography>
  )
}
