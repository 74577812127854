import { Box, Divider } from '@mui/material'
import { BaseTextInput } from '@shared/components/inputs/BaseTextInput'
import { mailPattern } from '@utils/pattern'
import { t } from 'i18next'
import React from 'react'

export const CompanyRequestUserInputs: React.FC = () => {
  return (
    <Box sx={{
      pt: 4.5
    }}
    >
      <form>
        <BaseTextInput
          formKey='email'
          options={{ required: true, pattern: mailPattern }}
          title={t('common.email')}
        />

        <Divider
          sx={{
            mt: 1,
            mb: 3.5
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='firstname'
          options={{ required: true }}
          title={t('common.firstName')}
        />

        <Divider
          sx={{
            mt: 1,
            mb: 3.5
          }}
          light
          orientation='horizontal'
        />

        <BaseTextInput
          formKey='lastname'
          options={{ required: true }}
          title={t('common.lastName')}
        />
      </form>
    </Box>
  )
}
