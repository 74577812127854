import { Box, Button, FormControlLabel, Switch, SxProps, Typography } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = React.PropsWithChildren & {
  switchLabel?: string
  switchColor?: string
  checked?: boolean
  grabber?: React.ReactNode
  renderedActions?: React.ReactNode
  onSwitchChange?: (value: boolean) => void
  onRemove?: () => void
  removeLabel?: string,
  sx?: SxProps
}

const textButtonStyle = {
  '&.MuiButton-text': {
    color: 'grey.700',
    pb: 0,
    pt: 0,
    mt: 2,
    height: 'auto',
    minHeight: 'auto',
    '&:hover': {
      color: 'grey.900',
      borderColor: 'grey.500'
    },
    '.MuiTypography-button': {
      textTransform: 'none'
    }
  }
}

export const DefaultAnswerRow: React.FC<Props> = ({
  switchLabel,
  switchColor,
  grabber,
  renderedActions,
  onSwitchChange,
  onRemove,
  checked,
  children,
  removeLabel,
  sx
}) => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      backgroundColor: 'grey.100',
      borderRadius: '12px',
      px: 2,
      border: '1px solid',
      borderColor: 'grey.300',
      ...(sx || {})
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
      >
        {grabber &&
        <Box sx={{
          flex: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: 1
        }}
        >
          {grabber}
          </Box>
        }
        <Box sx={{
          flex: 1,
          overflow: 'hidden',
          py: 2
        }}
        >
          <Box>
            {children}
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
          >
            {renderedActions &&
              <Box sx={{ mt: 2 }}>
                {renderedActions}
              </Box>
            }
            {onSwitchChange && <Button
              disableRipple
              sx={textButtonStyle}
              variant="text"
            >
              <FormControlLabel
                control={
                  <Switch checked={checked}
                    sx={{
                      mr: 1,
                      '&.MuiSwitch-root': {
                        '&.Mui-checked': {
                          '.MuiSwitch-track': {
                            backgroundColor: switchColor || 'success.main'
                          }
                        }
                      }
                    }}
                    onChange={(e, isChecked) => onSwitchChange(isChecked)}
                  />}
                label={<Typography>{switchLabel}</Typography>}
              />
            </Button>}
            {onRemove && (
            <Button
              disableRipple
              sx={textButtonStyle}
              variant="text"
              startIcon={<themeIcons.Delete /> }
              onClick={onRemove}
            >
              <Typography>{removeLabel || t('common.remove')}</Typography>
            </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
