import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EditableFile } from '@typings/files'
import { UpdateUserInput, useDeleteFileMutation, useUpdateMeMutation } from '@typings/graphql'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { getAuthUser, updateAuthUser } from '@services/store/slices/auth'
import { LoadingButton } from '@mui/lab'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { useFileUpload } from '@hooks/useFileUpload'
import { getFileByKey } from '@utils/files'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { SettingsProvider, useSettingsContext } from '../provider/SettingsProvider'
import { ProfileEditInputs } from '../components/ProfileEditInputs'

type UserInputType = UpdateUserInput & {
  files: Record<string, EditableFile<any> | null>
}

const ProfileEditViewContent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { handleDelete, loading: providerLoading, me } = useSettingsContext()

  const { errorMessage, setMessageByCode, setErrorMessage } = useErrorMessage()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const authUser = useAppSelector(getAuthUser)

  const [updateMe] = useUpdateMeMutation({
    onCompleted (data) {
      if (data?.updateMe && authUser) {
        dispatch(updateAuthUser({
          ...authUser,
          firstname: data?.updateMe.firstname,
          lastname: data?.updateMe.lastname,
          email: data?.updateMe.email
        }))
      }
    }
  })

  const formData = useForm<UserInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  const watchedFiles = useWatch({
    control: formData.control,
    name: 'files'
  })

  const { formState: { isDirty, isValid, isSubmitted }, handleSubmit, reset } = formData
  useUnsavedChanges(isDirty && (!isSubmitted || !!errorMessage) && !deleted)

  const { uploadFile } = useFileUpload()

  const [deleteFile] = useDeleteFileMutation()

  React.useEffect(() => {
    if (me) {
      reset({
        email: me.email,
        firstname: me.firstname,
        lastname: me.lastname,
        files: {
          avatar: watchedFiles ? watchedFiles.avatar : getFileByKey('avatar', me?.files)
        }
      })
    }
  }, [me])

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateMe({
        variables: {
          data: {
            ...(input.email && { email: input.email.toLowerCase().trim() }),
            firstname: input.firstname,
            lastname: input.lastname
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        const fileId = getFileByKey(key, me?.files)?.id

        if (file?.upload) {
          await uploadFile(file.upload.file, file.upload.data.key, 'User', authUser?.id as string)
        } else if (!file && fileId) {
          await deleteFile({
            variables: { id: fileId }
          })
        }
      })
    } catch (e) {
      setMessageByCode(e, 'settings')
    } finally {
      setLoading(false)
      setShowToast(true)
    }
  })

  const handleOnDelete = () => {
    setDeleted(true)
    handleDelete()
  }

  return (
    <CreateEditLayout
      title={t('routes.profileEdit')}
      description={t('settings.editDescription')}
      loading={providerLoading}
      headerExtension={(
        <DeleteIconButton onClick={handleOnDelete} />
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <FormProvider {...formData}>
        <ProfileEditInputs loading={loading} />
      </FormProvider>

      <DefaultSnackbar
        open={showToast}
        severity={errorMessage ? 'error' : 'success'}
        onClose={() => setShowToast(false)}
        message={errorMessage || t('settings.editSuccess')}
      />

    </CreateEditLayout>
  )
}

export const ProfileEditView: React.FC = () => (
  <SettingsProvider>
    <ProfileEditViewContent />
  </SettingsProvider>
)
