import { Button } from '@mui/material'
import { themeIcons } from '@theme/theme'
import { openBasicJobCreditsPayment } from '@utils/payments'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum PaymentRedirectionTypes {
  JOB_BASIC_CREDIT = 'jobBasicCredit',
  JOB_EXTENSION = 'jobExtension'
}

type Props = {
  type: PaymentRedirectionTypes,
  params: Record<string, string>
}

export const PaymentRedirectionButton: React.FC<Props> = ({ type, params }) => {
  const { t } = useTranslation()

  const handleRedirect = () => {
    switch (type) {
      case PaymentRedirectionTypes.JOB_BASIC_CREDIT:
        openBasicJobCreditsPayment(params.id)
        break
      default:
        break
    }
  }

  return (
    <Button
      onClick={handleRedirect}
      sx={{
        ml: 1
      }}
      startIcon={<themeIcons.CreditCard />}
    >
      {t(`paymentRedirection.${type}`)}
    </Button>
  )
}
