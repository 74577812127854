import { GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnIconStyles, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { Tooltip, IconButton } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { QuestionListRowModel, useQuestionListContext } from '../provider/QuestionListProvider'

import { QuestionListColumnType, questionListDataGridColumns } from './questionListDataGridColumns'

const MIN_FILTER_TEXT_LENGTH = 3

const TEXT_FILTER_FIELDS: (keyof QuestionListRowModel)[] = ['title']

export const QuestionListDataGrid: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNewTabHook()

  const {
    updateSelectedIds,
    loading,
    questions,
    selectedIds,
    updateSelectedItem,
    handleStatus,
    paginationModel,
    updatePaginationModel,
    sortModel,
    updateSortModel,
    filterModel,
    updateFilterModel,
    handleDelete,
    updateShowDuplicateDialog,
    updateInitialState
  } = useQuestionListContext()

  const rowData = React.useMemo(() => {
    return questions?.edges?.map(({ node }) => node) || []
  }, [questions])

  const onRowSelectionChange = React.useCallback((params: GridRowSelectionModel) => {
    updateSelectedIds(params)
  }, [])

  const onStatusDialog = React.useCallback((row: QuestionListRowModel) => {
    updateSelectedItem(row)
    handleStatus(false)
  }, [])

  const handleDeleteRow = React.useCallback(async (row: QuestionListRowModel) => {
    updateSelectedItem(row)
    handleDelete(false)
  }, [])

  const handleDuplicateRow = React.useCallback(async (row: QuestionListRowModel) => {
    updateSelectedItem(row)
    updateShowDuplicateDialog(true)
  }, [])

  const rowCountState = React.useMemo(() => questions?.totalCount ?? 0, [questions])

  const columnData = React.useMemo<QuestionListColumnType[]>(() => {
    return [
      ...questionListDataGridColumns,
       {
         ...actionColumnModel,
         width: 160,
         renderCell: (params) => (
           <ActionColumnRenderCell
             {...(can(['questions:write', 'questions:write.partial']) && {
               onEdit: () =>
                 navigate(`/professions/${params.row.profession.id}/${params.row.category.parentId}/${params.row.category.id}/${params.row.id}`)
             })}
             {...(can(['questions:write', 'questions:write.partial']) && { onCopy: () => handleDuplicateRow(params.row) })}
             {...(can('questions:delete') && { onDelete: () => handleDeleteRow(params.row) })}
             {...(can(['questions:write']) && {
               additionalActions: (
                 <Tooltip title={t('common.changeStatus')}>
                   <IconButton onClick={() => onStatusDialog(params.row)}>
                     <themeIcons.Flag sx={actionColumnIconStyles} />
                   </IconButton>
                 </Tooltip>)
             })}
           />
         )
       } as QuestionListColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`questionList.column.${column.field}`),
        ...column
      }
    })
  }, [])

  const onUpdateFilterModel = React.useCallback((filter: GridFilterModel | undefined) => {
    const lengthFilter = filter?.items?.filter((item) => TEXT_FILTER_FIELDS.includes(item.field as keyof QuestionListRowModel))

    if (lengthFilter?.some((item) => item.value?.length < MIN_FILTER_TEXT_LENGTH && item.value?.length > 0)) {
      return
    }

    updateFilterModel(filter)
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      gridKey='questionList'
      rows={rowData}
      rowCount={rowCountState}
      autoPageSize={false}
      loading={loading}
      paginationModel={paginationModel}
      paginationMode='server'
      onInitialState={updateInitialState}
      onPaginationModelChange={updatePaginationModel}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode='server'
      onSortModelChange={updateSortModel}
      filterModel={filterModel}
      filterMode='server'
      onFilterModelChange={onUpdateFilterModel}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={onRowSelectionChange}
    />
  )
}
