import React from 'react'
import { CompanyCreateView } from '@features/companies/views/CompaniesCreateView'
import { CompaniesEditView } from '@features/companies/views/CompaniesEditView'
import { CompaniesView } from '@features/companies/views/CompaniesView'
import { DashboardCompanyView } from '@features/dashboard/roleViews/company/views/DashboardCompanyView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { BreadcrumbLayout } from '@shared/layouts/BreadcrumbLayout'
import { Roles } from '@typings/roles'

import { AppRouteObject } from '../router'

export const companiesRoutes: AppRouteObject[] = [
  {
    path: '/companies',
    element: <ProtectedRoute component={<BreadcrumbLayout />} />,
    access: {
      roles: [Roles.ADMIN]
    },
    meta: {
      name: 'companies',
      navItem: true,
      navIcon: 'Work',
      navOrder: 6
    },
    children: [{
      index: true,
      element: <CompaniesView />
    }, {
      path: 'create',
      meta: {
        name: 'companiesCreate'
      },
      element: <CompanyCreateView />,
      access: {
        roles: [Roles.ADMIN]
      }
    }, {
      path: ':id/edit',
      meta: {
        name: 'companiesEdit'
      },
      element: <CompaniesEditView />,
      access: {
        roles: [Roles.ADMIN]
      }
    }, {
      path: ':id',
      meta: {
        name: 'companiesDetails'
      },
      element: <DashboardCompanyView />,
      access: {
        roles: [Roles.ADMIN]
      }
    }
    // ,{
    //   path: ':id/credits',
    //   meta: {
    //     name: 'companiesEditCredits'
    //   },
    //   element: <CompaniesEditCreditsView />,
    //   access: {
    //     roles: [Roles.ADMIN],
    //   }
    // }
    ]
  }
]
