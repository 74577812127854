import { Box, Button } from '@mui/material'
import { themeIcons } from '@theme/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { DeleteIconButton } from '@shared/components/ui/DeleteIconButton'
import { DefaultLayout } from '@shared/layouts/DefaultLayout'
import { useNewTabHook } from '@hooks/useNewTabHook'

import { ProductDataGrid } from '../dataGrid/ProductDataGrid'
import { ProductsProvider, useProductsContext } from '../provider/ProductsProvider'

const ProductsViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNewTabHook()

  const { selectedIds, handleDelete } = useProductsContext()

  return (
    <DefaultLayout
      title={t('routes.products')}
      description={t('products.productListDescription')}
      headerExtension={
        <Box>
          {can(['incentives:delete', 'incentives:delete.own']) && selectedIds.length > 0 && (
          <DeleteIconButton
            sx={{ mr: 2 }}
            onClick={() => handleDelete(true)}
          />
          )}

          {can(['incentives:create', 'incentives:create.own']) && (
          <Button startIcon={<themeIcons.Add />}
            onClick={() => navigate('create')}
          >
            {t('products.addProduct')}
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{
        pt: 4.5,
        height: '100%'
      }}
      >
        <ProductDataGrid />
      </Box>
    </DefaultLayout>
  )
}

export const ProductsView: React.FC = () => (
  <ProductsProvider>
    <ProductsViewContent />
  </ProductsProvider>
)
