import { Typography } from '@mui/material'
import { useProfessionQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const ProfessionBreadcrumb:React.FC = () => {
  const { t } = useTranslation()
  const { professionId } = useParams<{ professionId: string }>()

  const { data } = useProfessionQuery({
    variables: {
      id: professionId as string
    },
    skip: !professionId
  })

  const title = React.useMemo(() => {
    if (!data) {
      return t('professions.unknown')
    }

    return data.profession.texts.title
  }, [data])

  return (
    <Typography
      variant="subtitle1"
      fontWeight="inherit"
    >
      {title}
    </Typography>
  )
}
