import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TimePicker } from '@mui/x-date-pickers'

import { InputTitleField } from '../ui/InputRequiredIndicator'

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  formatter?: (value: Date) => string,
  minutesStep?: number
}

export const BaseTimeInput: React.FC<Props> = ({ formKey, options, title, formatter, description, minutesStep }) => {
  const { control } = useFormContext()

  const parseDigitalClockValue = (value: string) => {
    const [hours, minutes] = value.split(':')

    return new Date(0, 0, 0, parseInt(hours, 10), parseInt(minutes, 10))
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Grid>
      <Grid item xs={8} xl={6}>
        <Controller
          name={formKey}
          control={control}
          rules={options}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <TimePicker
                sx={{
                  width: '100%'
                }}
                closeOnSelect={false}
                timezone='Europe/Berlin'
                onChange={(v, context) => {
                  onChange(formatter ? formatter(v) : v, context)
                }}
                timeSteps={{
                  hours: 1,
                  minutes: minutesStep || 5
                }}
                ampm={false}
                value={typeof value === 'string' ? parseDigitalClockValue(value) : (value || new Date())}
                format='HH:mm'
                slotProps={{
                  actionBar: {
                    actions: ['accept']
                  }
                }}
              />
            </LocalizationProvider>
          )
          }
        />
      </Grid>
    </Grid>

  )
}
