import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { StandaloneSwitchInput } from '@shared/components/inputs/StandaloneSwitchInput'
import { CreateEditLayout } from '@shared/layouts/CreateEditLayout'
import { CompanyCreditType, CreateCompanyInput, useCreateCompanyMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SearchInputItem } from '@shared/components/inputs/BaseSearchInput'
import { EditableFile } from '@typings/files'
import { useFileUpload } from '@hooks/useFileUpload'
import { useErrorMessage } from '@hooks/useErrorMessage'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import i18n from '@services/i18n/i18n'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'

import { CompanyCreateEditInputs } from '../components/CompanyCreateEditInputs'
import { CompanyCreateInputExtensions } from '../components/CompanyCreateInputExtensions'
import { CompaniesProvider } from '../provider/CompaniesProvider'

type CompanyInputType = CreateCompanyInput & {
  name: string,
  texts: Record<string, string>
  users: SearchInputItem[]
  credits: {
    jobBasic: number
    jobExtension: number
  }
  files: Record<string, EditableFile<any> | null>
}

const CompanyCreateViewContent: React.FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [showCreatedDialog, setShowCreatedDialog] = React.useState(false)
  const [created, setCreated] = React.useState(false)

  const navigate = useNavigate()

  const { setMessageByCode, setErrorMessage, errorMessage } = useErrorMessage()

  const { formState, handleSubmit, control } = useFormContext<CompanyInputType>()
  const { isDirty, isValid } = formState

  useUnsavedChanges(isDirty && !created && (!formState.isSubmitted || !!errorMessage))

  const [createCompany] = useCreateCompanyMutation()

  const { uploadFile } = useFileUpload()

  const isPartner = useWatch({
    name: 'isPartner',
    control
  })

  const onSubmit = handleSubmit(async (input) => {
    setLoading(true)
    setErrorMessage('')

    try {
      const result = await createCompany({
        variables: {
          data: {
            name: input.name,
            category: input.category,
            isPartner: input.isPartner,
            postalCode: input.postalCode,
            city: input.city,
            address: input.address,
            texts: Object.keys(input.texts).filter((key) => !!input.texts[key]).map((filtered) => ({
              key: filtered,
              lang: i18n.resolvedLanguage,
              value: input.texts[filtered]
            })),
            credits: [
              ...new Array(input.credits.jobBasic || 0).fill(0).map(() => CompanyCreditType.JobBasic),
              ...new Array(input.credits.jobExtension || 0).fill(0).map(() => CompanyCreditType.JobExtension)
            ],
            userIds: input.users?.map((user) => user.id)
          }
        }
      })

      Object.keys(input.files)?.forEach(async (key) => {
        const file = input.files[key]

        if (file?.upload && result.data?.createCompany) {
          await uploadFile(file.upload.file, file.upload.data.key, 'Company', result.data.createCompany.id)
        }
      })

      setCreated(true)
      setShowCreatedDialog(true)
    } catch (e) {
      setMessageByCode(e, 'company')
      setShowToast(true)
    } finally {
      setLoading(false)
    }
  })

  const handleConfirm = () => {
    navigate('/companies')
  }

  return (
    <CreateEditLayout
      title={t('companies.createTitle')}
      description={t('companies.createDescription')}
      headerExtension={(
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box mr={2}>
            <StandaloneSwitchInput
              formKey="isPartner"
              label={t('companies.column.isPartner')}
            />
          </Box>
        </Box>
      )}
      buttonComp={(
        <LoadingButton
          variant="contained"
          type='submit'
          loading={loading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          {t('common.save')}
        </LoadingButton>
      )}
    >
      <CompanyCreateEditInputs loading={loading}>
        {
          !isPartner && (
            <CompanyCreateInputExtensions />
          )
        }
      </CompanyCreateEditInputs>

      <ConfirmDialog
        title={t('common.entryCreated')}
        open={showCreatedDialog}
        hideCancel
        onConfirm={handleConfirm}
      >
        {t('companies.createCompleted')}
      </ConfirmDialog>

      <DefaultSnackbar
        open={showToast}
        severity={'error'}
        onClose={() => setShowToast(false)}
        message={errorMessage}
      />
    </CreateEditLayout>
  )
}

export const CompanyCreateView: React.FC = () => {
  const formData = useForm<CompanyInputType>({
    defaultValues: {
      credits: {
        jobBasic: 0,
        jobExtension: 0
      }
    },
    mode: 'onChange'
  })

  return (
    <CompaniesProvider disableFetching>
      <FormProvider {...formData}>
        <CompanyCreateViewContent />
      </FormProvider>
    </CompaniesProvider>
  )
}
