import React from 'react'
import { PerformanceChip } from '@shared/components/ui/PerformanceChip'

import { BaseColumnType } from '../types/types'

export const performanceColumnModel: BaseColumnType = {
  field: 'bimonthlyPerformance',
  width: 100,
  filterable: false,
  renderCell: (params) => <PerformanceChip value={params.value} />
}
